﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require('knockout.mapping')
import Chart = require("chart.js")

import { LoadingIndicator } from "../LoadingIndicator"
import { TagsReported } from "./TagsReported"
import { isNullOrUndefined } from "util"

const common = globalThis.DIG.Common;

export class RollCallChart {
    loading: LoadingIndicator
    chart: Chart
    when: ko.Observable<moment.Moment>
    lastUpdated: ko.Observable<moment.Moment>
    summary: ko.ObservableArray<any> = ko.observableArray([])
    bump: ko.Observable<boolean> = ko.observable(false)

    private lineChartConfiguration: Chart.ChartConfiguration = {
        type: 'line',
        data: {
            labels: [],
            datasets: []
        },
        options: {
            legend: { display: false },
            scales: {
                yAxes: [{
                    afterDataLimits: (axis) => {
                        axis.min = Math.max(axis.min - axis.min % 5, 0);
                        axis.max;
                    },
                    ticks: {
                        maxTicksLimit: 10,
                        stepSize: 1.0
                    }
                }],
                xAxes: [{
                    ticks: {
                        maxTicksLimit: 13,
                        minRotation: 0,
                        maxRotation: 0,
                        autoSkipPadding: 4
                    }
                }]
            },
            tooltips: {
                mode: 'index',
                position: 'nearest',
                callbacks: {
                    title: (items) => moment('2000-01-01 00').add(items[0].index, 'hour').format('hA'),
                    label: (item, data) => {
                        let result = `${data.datasets[item.datasetIndex].label}: ${item.value}`;

                        if (item.datasetIndex === 1) {
                            if ((data.datasets[2].data[item.index] as number) > 0) {
                                result = `${result} (${data.datasets[2].data[item.index]} In Strap Alarm)`;
                            }
                        }

                        return result;
                    }
                }
            }
        }
    };

    constructor(canvas: any, container: any) {
        this.loading = new LoadingIndicator(container);
        this.when = ko.observable(common.FacilityEndDate.clone());
        this.lastUpdated = ko.observable(moment().add(-1, 'day'));
        
        this.chart = new Chart(canvas, this.lineChartConfiguration);

        this.chart.canvas.onclick = this.showDetail;
    }

    description: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.when !== undefined
            ? this.when().format('M/D/YYYY')
            : '';
    });

    changeDay = (date) => {
        this.loading.show();

        $.ajax({
            url: '/api/rollcall/dashboardchart',
            cache: false,
            method: 'POST',
            data: {
                when: date.format()
            },
            success: (results) => this.setData(results, true, date),
            error: (a, b, c) => { console.error("RollCallChart::changeDay()", date, a, b, c); },
            complete: _ => this.loading.hide()
        });
    };

    setData = (data, animate?: boolean, date?: moment.Moment) => {
        if (date !== undefined) {
            this.when(date);
            this.chart.data = null;
        }

        if (this.chart.data === null || (this.chart.data as any).dataKey === data[0].dataKey) {
            this.chart.data = data[0];
            if (animate === undefined || animate) {
                this.chart.update();
            } else {
                this.chart.update({ duration: 0 });
            }
        }

        if (!isNullOrUndefined(data[2]) && data[2].dataKey === 'rollcall.summary') {
            const summaryMap = {
                key: (item) => ko.utils.unwrapObservable(item.data.label),
                create: (item) => new TagsReported(item)
            }
            this.summary = mapping.fromJS(data[2].datasets, summaryMap);
            this.bump(!this.bump());
        }
    };

    summaryItems: ko.Computed<any> = ko.computed(() => {
        var bumpRead = this.bump();
        return this.summary();
    });

    refresh = () => {
        this.changeDay(common.FacilityEndDate.clone());
    };

    allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().isAfter(common.FacilityStartDate);
    });

    previous = () => {
        if (this.allowPrevious()) {
            this.changeDay(this.when().add(-1, 'day'));
        }
    };

    allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().isBefore(common.FacilityEndDate);
    });

    next = () => {
        if (this.allowNext()) {
            this.changeDay(this.when().add(1, 'day'));
        }
    };

    showDetail = (event) => {
        const point: any = this.chart.getElementAtEvent(event)[0];
        const hour = point._index;
        const day = this.when().format("YYYYMMDD");

        $.get(`/rollcall/hourlydetail?day=${day}&hour=${hour}`)
            .done(content => {
                $('#rollCallDetailDiv').html(content);
                $('#rollCallDetailModal').modal('show');
            })
            .fail((request, textStatus, error) => { console.error("RollCallChart::showDetail()", request, textStatus, error); });
    }

    showSummaryDetail = (hours) => {
        $.get(`/rollcall/pastdetail?hours=${hours}`)
            .done(content => {
                $('#rollCallDetailDiv').html(content);
                $('#pastDetailModal').modal('show');
            })
            .fail((request, textStatus, error) => { console.error("RollCallChart::showSummaryDetail()", request, textStatus, error); });
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dashboard ??= () => { /* */ };
globalThis.DIG.Dashboard.RollCallChart = RollCallChart;
