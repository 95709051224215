﻿import { DeviceLocation } from "./DeviceLocation"

import ko = require("knockout");
import mapping = require("knockout.mapping")

export class DeviceLocationViewModel {

    searchString: ko.Observable<string> = ko.observable("");
    deviceId: ko.Observable<number> = ko.observable(0);
    deviceLocations: any = ko.observableArray<DeviceLocation>([]);
    bump: ko.Observable<boolean> = ko.observable<boolean>(false);
    //noResultsClass: ko.Observable<string> = ko.observable("d-none");

    constructor() {
        ko.applyBindings(this);


        this.searchString.subscribe(newValue => {
            this.setDeviceForSearch(newValue);
        });

        window.setInterval(() => this.getDeviceLocationDetail(), 10000);
    }

    locationDetailData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.deviceLocations();
    });

    hasData: ko.Computed<boolean> = ko.computed<boolean>(() => {
        var bump = this.bump();
        return this.deviceLocations().length > 0;
    })

    noResultsClass: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();
        if (this.deviceLocations().length > 0) {
            return "d-none";
        }
        else {
            return "d-flex";
        }
    })

    setDeviceForSearch = (newValue: string) => {
        if (newValue.length == 6 && !isNaN(+newValue)) {
            this.deviceId(Number(newValue));
            this.getDeviceLocationDetail();
        }
        else {
            this.deviceId(0);
            this.setLocationDetailData([]);
        }

        
    }

    getDeviceLocationDetail = () => {

        if (this.deviceId() != 0) {
            $.get(`/api/device/${this.deviceId()}/locationDetail`)
                .done(results => this.setLocationDetailData(results))
                .fail((a, b, c) => { console.error("SecurityCenter::fetchData()", a, b, c) })
        }
    }

    setLocationDetailData = (data) => {

        //if (data.length > 0) {
            const presentMap = {
                create: (gPop) => new DeviceLocation(gPop.data)
            };
            this.deviceLocations = mapping.fromJS(data, presentMap);
        //}
        //else {
        //    this.deviceLocations.removeAll()
        //    this.deviceLocations.push(new DeviceLocation());
        //}
        this.bump(!this.bump());
    }

    // only used if using _layout
    //init = () => {
    //    ko.applyBindings(this);


    //    this.searchString.subscribe(newValue => {
    //        this.setDeviceForSearch(newValue);
    //    });

    //    window.setInterval(() => this.getDeviceLocationDetail(), 10000);
    //}
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.DeviceLocation === undefined) {
    globalThis.DIG.DeviceLocation = () => { };
}

globalThis.DIG.DeviceLocation.ViewModel = DeviceLocationViewModel