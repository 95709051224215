﻿import ko = require("knockout")
import $ = require('jquery')
import moment = require("moment-timezone")

import { CommonViewModel, DeviceType } from "../Common"
import { Validation } from "../ValidationExtenders"

const common: CommonViewModel = globalThis.DIG.Common

export class FirmwareMuDialogModel {
    //
    //  Events
    //
    onSave: Function = null
    onCancel: Function = null

    //
    //  Properties
    //
    fwUploadType: ko.Observable<string> = ko.observable("1")
    fwIsRelease: ko.Observable<boolean> = ko.observable(false)
    fwVersionMajor: ko.Observable<number> = ko.observable(0)
    fwVersionMinor: ko.Observable<number> = ko.observable(0)
    fwBuildTimestamp: ko.Observable<string> = ko.observable(moment().format('MM/DD/YYYY hh:mm A'))
    fwDescription: ko.Observable<string> = ko.observable("")
    fwReleaseNotes: ko.Observable<string> = ko.observable("")

    fileNameHex: ko.Observable<string> = ko.observable("")
    fileNameDfu: ko.Observable<string> = ko.observable("")

    hasValidation: boolean = false;


    hasErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        //return false;

        if (this.hasValidation) {
            return (this.fwVersionMajor as any).hasError()
                || (this.fwVersionMinor as any).hasError()
                || (this.fwDescription as any).hasError()
                || (this.fwReleaseNotes as any).hasError()
                || (this.fileNameHex as any).hasError()
                || (this.fileNameDfu as any).hasError()
        }
        else {
            return false;
        }

    })

    //
    //  Methods
    //
    constructor(options) {

    }


    showDialog = () => {

        this._addValidators();

        if ($('#firmwareManualUploadDiv').length === 0) {
            $.get('/support/firmware/upload')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'firmwareManualUploadDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#firmwareManualUploadDiv').html(results);
                    $('#firmwareManualUploadModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            $('#chooseHexFile').on('change', () => {
               
                const chooseHexFile: any = $('#chooseHexFile')[0];

                if (chooseHexFile.files.length > 0) {
                    this.fileNameHex(chooseHexFile.files[0].name);
                }
            });

            $('#chooseDfuFile').on('change', () => {

                const chooseDfuFile: any = $('#chooseDfuFile')[0];

                if (chooseDfuFile.files.length > 0) {
                    this.fileNameDfu(chooseDfuFile.files[0].name);
                }
            });

            this._dialogInitialized = true;
        } else {
            ko.cleanNode($('#firmwareManualUploadModal')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#firmwareManualUploadModal')[0]);

        window.eval('$("#firmwareManualUploadModal").modal("show")');

        this.fwUploadType("1");
        this.fwIsRelease(false);
        this.fwVersionMajor(0);
        this.fwVersionMinor(0);
        this.fwBuildTimestamp(moment().format('MM/DD/YYYY hh:mm A'));
        this.fwDescription("");
        this.fwReleaseNotes("");
        this.fileNameHex("");
        this.fileNameDfu("");
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        window.eval('$("#firmwareManualUploadModal").modal("hide")');
    }

    
    getHexFile = () => {
        $('#chooseHexFile').trigger('click');
    }

    getDfuFile = () => {
        $('#chooseDfuFile').trigger('click');
    }

    private _onSave = (firmwareVersionId: number, firmwareDetails) => {
        if (this.onSave) {
            this.onSave(firmwareVersionId, firmwareDetails);
        }
    }

    private _onCancel = () => {
        if (this.onCancel) {
            this.onCancel();
        }
    }

    private _save = () => {
        if (!this.hasErrors()) {
            $.ajax({
                url: '/api/firmware/manual',
                method: 'POST',
                data: this.toFormData(),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {
                    this._hideDialog();
                    this._onSave(results.firmwareVersionId, results);
                })
                .fail((request, textStatus, error) => {
                    console.error("FirmwareUploadDialog::_save", request, textStatus, error);
                });
        }
    }

    private _addValidators = () => {
        if (!this.hasValidation) {
            this.fwVersionMajor = Validation.addRange(this.fwVersionMajor, { min: 1, max: 1000, fieldName: 'Major Version', overrideMessage: 'Cannot be 0' });
            this.fwVersionMinor = Validation.addRange(this.fwVersionMinor, { min: 1, max: 1000, fieldName: 'Minor Version', overrideMessage: 'Cannot be 0' });
            this.fwDescription = Validation.addLength(this.fwDescription, { min: 2, max: 255, fieldName: 'Description', isRequired: true });
            this.fwReleaseNotes = Validation.addLength(this.fwReleaseNotes, { min: 2, max: 2000, fieldName: 'ReleaseNodes', isRequired: false });
            this.fileNameHex = Validation.addLength(this.fwReleaseNotes, { min: 2, max: 2000, fieldName: 'FileName', overrideMessage: 'Select a file', isRequired: true });
            this.fileNameDfu = Validation.addLength(this.fwReleaseNotes, { min: 2, max: 2000, fieldName: 'FileName', overrideMessage: 'Select a file', isRequired: true });

            this.hasValidation = true;
        }
    }

    private toFormData = (): object => {
        const data = {
            UploadType: this.fwUploadType(),
            MajorVersion: this.fwVersionMajor(),
            BuildVersion: this.fwVersionMinor(),
            Description: this.fwDescription(),
            ReleaseNotes: this.fwReleaseNotes(),
            BuildTimestamp: this.fwBuildTimestamp(),
            IsRelease: this.fwIsRelease()
        }

        const formData = common.objectToFormData(data);

        let firmwareFileElement = $('#chooseHexFile')[0];

        if (firmwareFileElement != null && (firmwareFileElement as any).files.length !== 0) {
            formData.append('data', (firmwareFileElement as any).files[0], 'data');
        }

        let firmwareDfuFileElement = $('#chooseDfuFile')[0];

        if (firmwareDfuFileElement != null && (firmwareDfuFileElement as any).files.length !== 0) {
            formData.append('dfuData', (firmwareDfuFileElement as any).files[0], 'dfuData');
        }

        return formData;
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Firmware === undefined) {
    globalThis.DIG.Firmware = () => { /* */ };
}

globalThis.DIG.Firmware.FirmwareMuDialog = FirmwareMuDialogModel
