﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { CommonViewModel } from "../Common"
import { Dialogs, InmateDialogOptions } from "../Dialogs";
import { InmateDormPresence } from "../Inmates/InmateDormPresence";
import { LoadingIndicator } from "../LoadingIndicator";

const common: CommonViewModel = globalThis.DIG.Common

export class NoBuildingMonitorViewModel {
    constructor() {
    }

    dialogOptions: InmateDialogOptions = {
        isReadOnly: false,
        showAlarms: true,
        showConfiguration: false,
        showTemp: true,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onHidden: null,
        onDeactivate: null
    }

    facilityBuildingId: number = 0;
    loading = new LoadingIndicator($('#inmateTiles'));

    inmates = ko.observableArray<InmateDormPresence>([]);

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    displayDate: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('MMMM Do, YYYY');
    });

    displayTime: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('h:mm A');
    });

    inmatesData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.inmates();
    });

    fetchData = () => {
        this.loading.show();

        $.get(`/api/dashboard/location/${this.facilityBuildingId}/dorm`)
            .done(results => this.setData(results))
            .fail((a, b, c) => { console.error("DormResidentWidget::fetchData()", a, b, c) })
            .always(_ => this.loading.hide());
    }

    setData = (data) => {
        const presentMap = {
            key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmate) => new InmateDormPresence(inmate.data)
        };
        this.inmates = mapping.fromJS(data, presentMap);

        this.bump(!this.bump());
    }

    editInmate = (data: InmateDormPresence, event) => {
        //event.stopPropagation();
        Dialogs.editInmate(data.inmateId(), this.dialogOptions);
    }

    init = () => {

        this.fetchData();

        ko.applyBindings(this);

        window.setTimeout(() => window.setInterval(() => this.bump(!this.bump()), 60000), 61000 - (moment().seconds() * 1000));
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dashboard ??= () => { /* */ };
globalThis.DIG.Dashboard.NoBuildingMonitor ??= () => { /* */ };
globalThis.DIG.Dashboard.NoBuildingMonitor.ViewModel = NoBuildingMonitorViewModel;