﻿import ko = require("knockout")
import moment = require("moment-timezone")

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class BuildingMonitorViewModel {
    constructor() {
    }

    displayDate: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('MMMM Do, YYYY');
    });

    displayTime: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('h:mm A');
    });

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    init = () => {
        ko.applyBindings(this);

        window.setTimeout(() => window.setInterval(() => this.bump(!this.bump()), 60000), 61000 - (moment().seconds() * 1000));
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dashboard ??= () => { /* */ };
globalThis.DIG.Dashboard.BuildingMonitor ??= () => { /* */ };
globalThis.DIG.Dashboard.BuildingMonitor.ViewModel = BuildingMonitorViewModel;