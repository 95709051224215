﻿import ko = require("knockout")
import Chart = require("chart.js")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { Inmate } from "./Inmate"
import { TemperatureChart } from "./TemperatureChart"
import { AlarmSummaryChart } from "./AlarmSummaryChart"
import { AlarmHistoryList } from "./AlarmHistoryList"
import { EntitySelectViewModel } from "../Components/EntitySelectDropdown"
import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"
import { ChartExtenders } from "../ChartExtenders"
import { Validation } from "../ValidationExtenders"

import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'

import { CommonViewModel, EntityType, DeviceType } from "../Common"
import { isNullOrUndefined } from "util"
import { FacilityBuilding } from "../Facility/FacilityBuilding"
import { InmatePresence } from "../Facility/InmatePresence"


Chart.defaults.global.maintainAspectRatio = false;
Chart.defaults.global.responsive = true;

const common: CommonViewModel = globalThis.DIG.Common

export class InmateDialogModel {
    self: InmateDialogModel = this;

    //
    //  Events
    //
    onSave: Function = null
    onBeforeShow: Function = null
    onShown: Function = null
    onBeforeHide: Function = null
    onHidden: Function = null
    onDeactivate: Function = null

    //
    //  Properties
    //
    inmate: ko.Observable<Inmate> = ko.observable(null)
    //temperature: TemperatureChart = null
    apexTemperature: TemperatureChart = null
    //alarmSummaryChart: AlarmSummaryChart = null
    apexAlarmSummaryChart: AlarmSummaryChart = null
    alarmHistoryList: AlarmHistoryList = null
    locationCalendar: Calendar = null
    tagSelector: EntitySelectViewModel = null

    allowLocationPrevious: ko.Observable<boolean> = ko.observable(true)
    allowLocationNext: ko.Observable<boolean> = ko.observable(true)

    showAlarms: ko.Observable<boolean> = ko.observable(true)
    showTemp: ko.Observable<boolean> = ko.observable(true)
    showLocation: ko.Observable<boolean> = ko.observable(true)
    showConfiguration: ko.Observable<boolean> = ko.observable(false)
    showAssignments: ko.Observable<boolean> = ko.observable(true)

    dialogTitle: ko.Observable<string> = ko.observable('New Inmate')
    allowEdit: ko.Observable<boolean> = ko.observable(true)
    isReadOnly: ko.Observable<boolean> = ko.observable(true)
    isReadOnlyDefault = true
    locationView: ko.Observable<number> = ko.observable(2)
    displayLocationDate: ko.Observable<string> = ko.observable('')

    isEditingGeneral: ko.Observable<boolean> = ko.observable(false)

    currentAssignmentDetails: ko.Observable<string> = ko.observable('')
    forceAssignmentApproved: boolean = false;
    originalTagId: number;
    unassignmentReasonId: ko.Observable<number> = ko.observable()
    forceUnassignmentReasonId: ko.Observable<number> = ko.observable(0)
    strapSwapReason: ko.Observable<string> = ko.observable('')

    facilityBuildings: ko.ObservableArray<FacilityBuilding> = ko.observableArray<FacilityBuilding>()
    inmatePresence: ko.ObservableArray<InmatePresence> = ko.observableArray<InmatePresence>()
    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    isEditing: ko.Computed<boolean> = ko.computed((): boolean => {
        return !this.isReadOnly();
    })

    showEditButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.isReadOnly() && this.allowEdit());
    })

    showDeactivateButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return ((this.inmate()?.inmateId() ?? 0) !== 0 && this.allowEdit());
    })

    dialogSecondaryTitle: ko.PureComputed<string> = ko.pureComputed((): string => {
        if (this.showLocation()) {
            return this.inmate().location();
        }
        else {
            return '';
        }
    })

    hasInmateErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        if (this.inmate && this.inmate().hasValidators()) {
            return (this.inmate().inmateIdentifier as any).hasError()
                || (this.inmate().firstName as any).hasError()
                || (this.inmate().middleName as any).hasError()
                || (this.inmate().lastName as any).hasError()
        }
        else {
            return false;
        }
    })

    hasConfigErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        if (this.inmate && this.inmate().hasValidators()) {
            let configHasError = false;

            try {
                configHasError = ko.utils.arrayFilter(this.inmate().configuration(), (i) => i.editValue.hasError()).length > 0;
            } catch { /* */ }

            return configHasError;
        }
        else {
            return false;
        }
    })

    hasErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        return this.hasInmateErrors()
            || this.hasConfigErrors();
    })

    facilityBuildingsData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.facilityBuildings().sort((a, b) => {
            return a.displayOrder() - b.displayOrder();
        });
    })

    inmatePresenceData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.inmatePresence();
    })

    showDormWarning: ko.Computed<boolean> = ko.computed((): boolean => {
        var bump = this.bump();
        // if the dorm doesn't require a device and an unasignment reason is set, unassign the device, don't prompt for the unassignment reason.
        const dorm = this.facilityBuildings().find(x => x.facilityBuildingId() == this.inmate().building.id());

        if (dorm !== undefined && !dorm.isDeviceRequired() && dorm.forceUnassignmentReasonId() > 0) {
            return true;
        }
        else {
            return false;
        }
    })

    showPresenceWarning: ko.Computed<boolean> = ko.computed((): boolean => {
        var bump = this.bump();
        // see if the presence requires a device unassignment
        const presence = this.inmatePresence().find(x => x.inmatePresenceTypeId() == this.inmate().presence.inmatePresenceTypeId());

        if (presence !== undefined && presence.forceUnassignmentReasonId() > 0) {
            return true;
        }
        else {
            return false;
        }
    })

    //
    //  Methods
    //
    constructor(options) {
        if (options.isReadOnly !== undefined) this.isReadOnlyDefault = options.isReadOnly;
        if (options.showAlarms !== undefined) this.showAlarms(options.showAlarms);
        if (options.showTemp !== undefined) this.showTemp(options.showTemp);
        if (options.showLocation !== undefined) this.showLocation(options.showLocation);
        //if (options.showConfiguration !== undefined) this.showConfiguration(options.showConfiguration);
        if (options.allowEdit !== undefined) this.allowEdit(options.allowEdit);

        Chart.plugins.register(ChartExtenders.RangePlugin);
        Chart.plugins.register(ChartExtenders.NoDataPlugin);

        this.tagSelector = new EntitySelectViewModel({
            entityType: EntityType.Device,
            subTypes: [DeviceType.Tag, DeviceType.OfficerSafetyDevice],
            showNoSelection: true,
            noSelectionDescription: "Unassigned",
            autoSelectSingle: true
        });        
    }

    private _nameChanged = () => {
        const title = this.inmate().inmateId() === 0
            ? 'New Inmate'
            : `${this.inmate().lastName()}, ${this.inmate().firstName()}`;

        this.dialogTitle(title);
    }

    edit = (inmateId?: number, tab?: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            this._getInmate(inmateId)
                .then(() => this._getConfiguration())
                .then(() => this._getAssignments())
                .then(() => {
                    this._addValidators();
                    this._nameChanged();
                    this.showDialog();
                    resolve()
                })
        })
    };

    private _getInmate = (inmateId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (inmateId) {
                this.isReadOnly(this.isReadOnlyDefault);
                Inmate.getById(inmateId)
                    .then(inmate => {
                        this.inmate(inmate)
                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                this.isReadOnly(false);
                this.inmate(new Inmate());
                resolve();
            }
        })
    }

    private _getConfiguration = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.showConfiguration()) {
                this.inmate().getConfiguration().then(() => resolve(), rejectReason => reject(rejectReason));
            } else {
                resolve();
            }
        })
    }

    private _getAssignments = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.showAssignments()) {
                this.inmate().getAssignments().then(() => resolve(), rejectReason => reject(rejectReason));
            } else {
                resolve();
            }
        })
    }

    private _getFacilityBuildings = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sessionStorage.getItem("facilityBuildings")) {
                //alert("from Session");

                let results = sessionStorage.getItem("facilityBuildings");
                let resultOjbect = JSON.parse(results);
                this._setFacilityBuildings(resultOjbect);
                resolve();
            }
            else {

                //alert("api call");

                const path = `/api/facility/building/list`;
                $.get(path)
                    .done(results => {
                        sessionStorage.setItem("facilityBuildings", JSON.stringify(results));
                        this._setFacilityBuildings(results);
                        resolve();
                    })

                    .fail((a, b, c) => {
                        console.error("_getFacilityBuildings::fetchData()", a, b, c);
                        reject();
                    })
            }
        })
    }

    private _setFacilityBuildings = (data?: object) => {
        var map = {
            create: (dorm) => new FacilityBuilding(dorm.data)
        };
        this.facilityBuildings = mapping.fromJS(data, map);
        this.bump(!this.bump());
    }

    private _getFacilityInmatePresence = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sessionStorage.getItem("facilityInmatePresence")) {
                
                let results = sessionStorage.getItem("facilityInmatePresence");
                let resultOjbect = JSON.parse(results);
                this._setFacilityInmatePresence(resultOjbect);
                resolve();
            }
            else {
                const path = `/api/facility/inmatePresenceList`;
                $.get(path)
                    .done(results => {
                        sessionStorage.setItem("facilityInmatePresence", JSON.stringify(results));
                        this._setFacilityInmatePresence(results);
                        resolve();
                    })

                    .fail((a, b, c) => {
                        console.error("_getFacilityInmatePresence::fetchData()", a, b, c);
                        reject();
                    })
            }
        })
    }

    private _setFacilityInmatePresence = (data?: object) => {
        var map = {
            create: (dorm) => new InmatePresence(dorm.data)
        };
        this.inmatePresence = mapping.fromJS(data, map);
        this.bump(!this.bump());
    }

    private _addValidators = () => {
        if (!this.inmate().hasValidators()) {
            this.inmate().inmateIdentifier = Validation.addLength(this.inmate().inmateIdentifier, { min: 2, max: 50, fieldName: 'Inmate identifier' });
            this.inmate().firstName = Validation.addLength(this.inmate().firstName, { min: 2, max: 50, fieldName: 'First name' });
            this.inmate().middleName = Validation.addLength(this.inmate().middleName, { min: 0, max: 50, fieldName: 'Middle name' });
            this.inmate().lastName = Validation.addLength(this.inmate().lastName, { min: 2, max: 100, fieldName: 'Last name' });

            for (let index = 0; index < this.inmate().configuration().length; index++) {
                this.inmate().configuration()[index].addValidator();
            }

            this.inmate().hasValidators(true);

            this.inmate().firstName.subscribe(this._nameChanged);
            this.inmate().lastName.subscribe(this._nameChanged);
        }
    }

    showDialog = () => {
        if ($('#inmateEditorDiv').length === 0) {
            this._getFacilityBuildings()
                .then(() => this._getFacilityInmatePresence())
                .then(() => this._getDialog())
                .then(() => $('#inmateEditorDiv').ready(this._showDialog));
        }
        else {
            this._showDialog();

        }
    }

    private _getDialog = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get('/inmates/fullscreeneditor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'inmateEditorDiv';
                    div.className = 'editor'
                    div.style.cssText = 'position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 10; display: none';
                    $('.body-content')[0].appendChild(div);
                    $('#inmateEditorDiv').html(results);
                    resolve();
                })
                .fail((a, b, c) => {
                    console.error("InmateDialog::_getDialog", a, b, c);
                    reject();
                });
        });
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            this.apexTemperature = new TemperatureChart(
                document.querySelector("#chartTemp"),
                this.inmate().inmateId()
            );

            this.alarmHistoryList = new AlarmHistoryList(
                $('#alarmhistory-container'),
                this.inmate().inmateId()
            );

            this.apexAlarmSummaryChart = new AlarmSummaryChart(
                document.querySelector("#chartAlarmSummary"),
                this.inmate().inmateId()
            );

            this._setupKeyBindings();

            this._dialogInitialized = true;

            window.eval(`$(document).popover({               
                html: true,
                container: 'body',
                boundary: 'window',
                placement: 'top',
                selector: '.validationTip',
                trigger: 'hover focus',
                template: '<div class="popover" role="tooltip"><div class="popover-body validation"></div></div>'
            })`);

        } else {
            ko.cleanNode($('#inmateEditorDiv')[0]);
            this.apexTemperature.setInmate(this.inmate().inmateId());
            this.alarmHistoryList.setInmate(this.inmate().inmateId());
            this.apexAlarmSummaryChart.setInmate(this.inmate().inmateId());
        }

        this.isEditingGeneral((this.inmate().inmateId() ?? 0) == 0);

        //this._getFacilityBuildings();

        ko.applyBindingsToDescendants(this, $('#inmateEditorDiv')[0]);

        this.tagSelector.init(this.inmate().deviceId, this.inmate().deviceDescription);

        this.getLocationViewSetting();

        this._initLocation();
        this.alarmHistoryList.getAlarms();
        this.apexAlarmSummaryChart.getAlarms();

        //window.eval('$("[data-toggle=confirmation]").confirmation({rootSelector:"[data-toggle=confirmation]"});');

        this._onBeforeShow();

        $('#inmateEditorDiv').addClass('show');

        this.locationCalendar.render();

        this._onShown();

        this.inmate().deviceId.subscribe(newValue => {
            this._verifyTagAssignment();
        });

        this.inmate().building.id.subscribe(newValue => {
            const dorm = this.facilityBuildings().find(x => x.facilityBuildingId() == this.inmate().building.id());

            if (dorm !== undefined) {
                this.inmate().building.description(dorm.description());
            }
        });

        this.inmate().presence.inmatePresenceTypeId.subscribe(newValue => {
            const presence = this.inmatePresence().find(x => x.inmatePresenceTypeId() == this.inmate().presence.inmatePresenceTypeId());

            if (presence !== undefined) {
                this.inmate().presence.description(presence.description());
            }
        });

        this.originalTagId = this.inmate().deviceId();
    }

    _initLocation = () => {
        if (this.locationCalendar) this.locationCalendar.destroy();

        const container: HTMLElement = document.getElementById('location-container');

        this.locationCalendar = new Calendar(container, {
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
            headerToolbar: false,
            businessHours: {
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
                startTime: '5:30',
                endTime: '21:00'
            },
            initialDate: moment().startOf('week').toDate(), // first day of current week.
            initialView: 'timeGridWeek',
            navLinks: true, // can click day/week names to navigate views
            editable: false,
            dayMaxEvents: true, // allow "more" link when too many events
            eventMinHeight: 15,
            eventMaxStack: 1,
            defaultTimedEventDuration: 1, //events with same stop and start time (less than one minute) will show as 1 minutes visually (graph height).
            allDaySlot: false,
            slotDuration: '00:15:00',
            slotLabelFormat: function (date) { return moment(date.date).format('h:mm A'); },
            //datesSet: Setting the event here will have "this" = "calendar" instead of "viewmodel"

            events: {
                url: `/api/inmate/${this.inmate().inmateId()}/locationhistory`,//'/api/inmate/10358/location?startTime=03%2f21%2f2022+11%3a44+AM&endTime=03%2f22%2f2022+08%3a45+AM', //'/api/inmate/${this.inmate().inmateId()}/deactivate',
                method: 'GET',

                //failure: function () {
                //    alert('there was an error while fetching events!');
                //},
            }
        });

        //
        //  Set the handler here instead of in the configuration so that we
        //  can have access to the view model.
        //
        this.locationCalendar.on("datesSet", (dateInfo) => {
            //TODO: limit to inmate start date
            //TODO: limit to inmate end date

            this.allowLocationPrevious(moment(dateInfo.start).isAfter(common.FacilityStartDate.startOf('day')));
            this.allowLocationNext(moment(dateInfo.end).isBefore(moment().startOf('day')));

            this.displayLocationDate(dateInfo.view.title);
        });
    }

    changeLocationTimeRange = (a, b) => {
        const id = parseInt(b.target.dataset.direction);

        switch (id) {
            case -1:
                if (this.allowLocationPrevious) {
                    this.locationCalendar.prev();
                }
                break;

            case 1:
                if (this.allowLocationNext) {
                    this.locationCalendar.next();
                }
                break;

            default:
                this.locationCalendar.today();
                break;
        }
    }

    changeLocationView = (a, b) => {
        const id: number = parseInt(b.target.dataset.view);
        this.locationView(id);

        this.saveSetting("LocationView", this.locationView())
        this.setLocationView(id);
    }

    setLocationView = (viewId: number) => {
        let view = '';
        switch (viewId) {
            case 1: view = 'timeGridDay'; break;
            case 2: view = 'listWeek'; break;

            default: view = 'timeGridWeek'; break;
        }

        this.locationCalendar.changeView(view);
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        this._onBeforeHide();
        $('#inmateEditorDiv').removeClass('show');
        this._onHidden();
    }

    saveSetting = async (field, newValue) => {
        const setting = `/api/user/setting/Inmates-${field}`;
        $.post(setting, { settingValue: newValue });
    }

    getLocationViewSetting = () => {
        const setting = `/api/user/setting/Inmates-LocationView`;
        $.get(setting)
            .done(results => {
                let id: number = parseInt(results);
                if (isNaN(id)) {
                    id = 2;
                }
                this.locationView(id);
                this.setLocationView(id);
            })

            .fail((a, b, c) => { console.error("SecurityCenter::fetchData()", a, b, c) })
    }

    unlockForm = () => {
        this.isReadOnly(false);
    }

    editGeneral = () => {
        this.isEditingGeneral(true);
    }

    saveGeneral = () => {
        this.isEditingGeneral(false);
        //this._saveGeneral();
        //this._showUnassignReasonModal();
        this._verifyDormPresenceAndDevice();
    }

    cancelGeneral = () => {
        this._getInmate(this.inmate().inmateId())
        this.isEditingGeneral(false);
    }

    private _onSave = (inmateId: number, inmateDetails) => {
        if (this.onSave) {
            this.onSave(inmateId, inmateDetails);
        }
    }

    private _onBeforeShow = () => {
        if (this.onBeforeShow) {
            this.onBeforeShow();
        }
    }

    private _onShown = () => {
        if (this.onShown) {
            this.onShown();
        }
    }

    private _onBeforeHide = () => {
        if (this.onBeforeHide) {
            this.onBeforeHide();
        }
    }

    private _onHidden = () => {
        if (this.onHidden) {
            this.onHidden();
        }
    }

    private _onDeactivate = (inmateId) => {
        if (this.onDeactivate) {
            this.onDeactivate(inmateId);
        }
    }

    deactivate = () => {

        //if (this.deviceId() != 0) {
        //    //Prompt to unassign
        //}

        $.post(`/api/inmate/${this.inmate().inmateId()}/deactivate`)
            .done(() => {
                this._hideDialog();
                this._onDeactivate(this.inmate().inmateId());
            })
            .fail((request, textStatus, error) => {
                console.error(request, textStatus, error);
            });
    }

    restoreValue = (setting) => {
        ConfigurationSetting.dataFor(setting).restore();
    }

    getPhoto = () => {
        $('#inmatePhotoFile').trigger('click');
    }

    private _photoChanged = () => {
        const imgReader = new FileReader();

        imgReader.onload = (image) => {
            $('#inmatePhoto').attr('src', image.target.result as string);
        };

        imgReader.readAsDataURL(($('#inmatePhotoFile')[0] as any).files[0]);
    }

    private _saveGeneral = () => {
        if (!this.hasInmateErrors()) {

            $.ajax({
                url: '/api/inmate/general',
                method: this.inmate().inmateId() === 0 ? 'POST' : 'PUT',
                data: this._packageData(),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {
                    this._onSave(this.inmate().inmateId(), results);
                    this._getAssignments();
                })
                .fail((request, textStatus, error) => {
                    console.error("Save Inmate Failed", request, textStatus, error);

                    //since saving inmate failed, reload fresh version and display on screen
                    this._getInmate(this.inmate().inmateId());

                    //
                    //  Since there was an error, put the general section back in edit mode
                    //
                    this.isEditingGeneral(true);

                    //QUESTION: JPN - Why did we stop telling them there was an error?
                    //common.toast("error", errorMessage, 'Edit failure.');
                });
        }
    }

    private _packageData = ():FormData => {
        let formData = <FormData>this.inmate().toFormData(false);

        this.forceAssignmentApproved = true;

        //formData.append('assignmentOverride', this.forceAssignmentApproved.toString()); // not used for now
        if (this.forceUnassignmentReasonId() == 0) {
            formData.append('unassignmentReasonId', this.unassignmentReasonId().toString());
        }
        else {
            formData.append('unassignmentReasonId', this.forceUnassignmentReasonId().toString());
        }

        return formData;
    }

    private _verifyTagAssignment = (): boolean => {
        $.ajax({
            url: `/api/device/${this.inmate().deviceId()}/verifyAssignment?inmateId=${this.inmate().inmateId()}`,
            method: 'GET',
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(results => {
                if (results.displayPrompt) {
                    this._showDeviceTransferModal(results);
                }
                //else {
                //    this._saveGeneral();
                //}
            })
            .fail((request, textStatus, error) => {
                console.error("Device Validation Failed", request, textStatus, error);

            });

        return true;
    }

    private _showDeviceTransferModal = (result) => {
        
        let message = undefined;
        if (!isNullOrUndefined(result.currentOwnerName)) {
            message = `This device currently belongs to ${result.currentOwnerName}`;
        }

        if (!isNullOrUndefined(result.assignedInmateLastName)) {
            if (isNullOrUndefined(message)) {
                message = `Device ${this.inmate().deviceId()} is currently assigned to ${result.assignedInmateLastName}, ${result.assignedInmateFirstName} (${result.assignedInmateIdentifier}). <br /> Do you want to remove it from ${result.assignedInmateLastName}, ${result.assignedInmateFirstName} and assign it to ${this.inmate().lastName()}, ${this.inmate().firstName()}?`;
            }
            else {
                message += ` and is assigned to ${result.assignedInmateLastName}, ${result.assignedInmateFirstName} (${result.assignedInmateIdentifier})`;
            }
        }

        this.currentAssignmentDetails(message);        
        window.eval('$("#tagTransferModal").modal("show")');
    }

    private restoreOriginalDevice = () => {

        this.inmate().deviceId(this.originalTagId);
        this.tagSelector.revert();
    }

    private forceAssignment = () => {

        this.forceAssignmentApproved = true;
        window.eval('$("#tagTransferModal").modal("hide")');
    }

    private _verifyDormPresenceAndDevice = () => {

        // if the dorm doesn't require a device and an unasignment reason is set, unassign the device, don't prompt for the unassignment reason.
        const dorm = this.facilityBuildings().find(x => x.facilityBuildingId() == this.inmate().building.id());

        const presence = this.inmatePresence().find(x => x.inmatePresenceTypeId() == this.inmate().presence.inmatePresenceTypeId());

        if (!dorm.isDeviceRequired() && dorm.forceUnassignmentReasonId() > 0) {
            this.forceUnassignmentReasonId(dorm.forceUnassignmentReasonId());
            this.inmate().deviceId(0);
            this._saveGeneral();
        }
        else if (presence !== undefined && presence.forceUnassignmentReasonId() > 0) {
            this.forceUnassignmentReasonId(presence.forceUnassignmentReasonId());
            this.inmate().deviceId(0);
            this._saveGeneral();
        }
        else {
            this._showUnassignReasonModal();
        }
    }

    private _showUnassignReasonModal = () => {
        if (this.originalTagId == this.inmate().deviceId() || this.originalTagId == 0) {
            this._saveGeneral();
        }
        else {
            window.eval('$("#tagUnassignReasonModal").modal("show")');
        }
    }

    reasonSelected = () => {
        this._saveGeneral();
        window.eval('$("#tagUnassignReasonModal").modal("hide")');
    }

    showStrapSwapModal = () => {
        window.eval('$("#strapSwapModal").modal("show")');
    }

    saveStrapSwap = () => {
        const data = {
            reason: this.strapSwapReason()
        }

        $.ajax({
            url: `/api/inmate/${this.inmate().inmateId()}/strapSwapReason`,
            method: 'POST',
            data: common.objectToFormData(data),
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(results => {
                common.toast("success", "Strap Swap Recorded");
            })
            .fail((request, textStatus, error) => {
                console.error("Strap Swap Failed", request, textStatus, error); 
            });

        window.eval('$("#strapSwapModal").modal("hide")');
    }

    strapSwapCancel = () => {
        window.eval('$("#strapSwapModal").modal("hide")');
    }

    //private _save = () => {
    //    if (!this.hasErrors()) {

    //        $.ajax({
    //            url: '/api/inmate',
    //            method: this.inmate().inmateId() === 0 ? 'POST' : 'PUT',
    //            data: this.inmate().toFormData(),
    //            cache: false,
    //            contentType: false,
    //            processData: false,
    //        })
    //            .done(results => {
    //                this._hideDialog();
    //                this._onSave(this.inmate().inmateId(), results);
    //            })
    //            .fail((request, textStatus, error) => {
    //                console.error("Save Inmate Failed", request, textStatus, error);
    //                //let startIndex = request.responseText.indexOf('(') + 1;
    //                let startIndex = request.responseText.indexOf(':') + 1;
    //                let endIndex = request.responseText.indexOf('\r\n');
    //                let errorMessage = request.responseText.substring(startIndex, endIndex);

    //                //since saving inmate failed, reload fresh version and display on screen
    //                this._getInmate(this.inmate().inmateId());

    //                common.toast("error", errorMessage, 'Edit failure.');
    //            });
    //    }
    //}

    private _setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 27: //ESC
                        this.hideDialog();
                        e.preventDefault();
                        break;
                }
            }
        });
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Inmates ??= () => { /* */ };
globalThis.DIG.Inmates.InmateDialog = InmateDialogModel
