﻿export enum EventTypeEnum {
    Undefined = 0,
    AlarmStrap = 1,
    AlarmLiveness = 2,
    AlarmShielding = 3,
    AlarmCellDetect = 4,
    AlarmCaseTamper = 5,
    AlarmLowBattery = 6,
    AlarmUtc = 7,
    AlarmBodyTemp = 8,
    AlarmPanicButton = 9,
    AlarmOfficerDown = 10,

    PrepareToHibernate = 13,
    WakeupFromHibernation = 14,
    TagRestarted = 15,
    CallBackEvent = 16,
    MagnetPatternDetected = 17,
    GatorEvent = 18,
    RFIDEvent = 38, 

    InfoSOS = 19,
    InfoBaseline = 20,
    InfoLoRa = 21,
    InfoStrap1 = 22,
    InfoStrap2 = 23,
    InfoDetector1 = 24,
    InfoDetector2 = 25,
    InfoFirmware = 26,
    InfoGeneral = 27,
    InfoMotion = 28,
    InfoOtherTampers = 29,
    InfoMessageIntegrity = 30,
    InfoGeneral2 = 31,
    InfoBodyTemperature = 32,
    InfoBleListener = 33,
    InfoLiveness = 34,
    AlarmLocation = 35,
    AlarmBlagOSDPanic = 36,
    AlarmBlagOSDTest = 37,
    InfoBleListener2 = 39,
    InfoRfId1 = 40,
    InfoRfId2 = 41,
    InfoOSD = 42
}

export class EventType {
    public id: number
    public type: string
    public description: string
    public sort: number
    public appendType: boolean

    constructor(id: number, type: string, description: string, sort: number, appendType?: boolean) {
        this.id = id;
        this.type = type;
        this.description = description;
        this.sort = sort;
        this.appendType = appendType !== undefined ? appendType : true;
    }

    displayDescription = (): string => {
        const append: string = this.appendType ? this.type : '';
        return `${this.description} ${append}`.trim();
    }
}

export class EventTypes {
    _eventTypes: Array<EventType> = [
        //
        //  Alarm Types
        //
        new EventType(EventTypeEnum.AlarmStrap, 'Alarm', 'Strap', 2),
        new EventType(EventTypeEnum.AlarmLiveness, 'Alarm', 'No Motion', 6),
        new EventType(EventTypeEnum.AlarmShielding, 'Alarm', 'Shielding', 7),
        new EventType(EventTypeEnum.AlarmCellDetect, 'Alarm', 'Cell Detect', 1, false),
        new EventType(EventTypeEnum.AlarmCaseTamper, 'Alarm', 'Case Tamper', 5),
        new EventType(EventTypeEnum.AlarmLowBattery, 'Alarm', 'Low Battery', 4),
        new EventType(EventTypeEnum.AlarmUtc, 'Alarm', 'UTC', 3),
        new EventType(EventTypeEnum.AlarmBodyTemp, 'Alarm', 'Body Temp', 8),
        new EventType(EventTypeEnum.AlarmOfficerDown, 'Alarm', 'Officer Down', 9),
        new EventType(EventTypeEnum.AlarmPanicButton, 'Alarm', 'Panic Button', 10),


        new EventType(EventTypeEnum.PrepareToHibernate, 'Alarm', 'Preparing To Hibernate', 18),
        new EventType(EventTypeEnum.WakeupFromHibernation, 'Alarm', 'Wakeup From Hibernation', 19),
        new EventType(EventTypeEnum.TagRestarted, 'Alarm', 'Tag Restarted', 20),
        new EventType(EventTypeEnum.InfoSOS, 'Alarm', 'SOS', 21),
        new EventType(EventTypeEnum.AlarmLocation, 'Alarm', 'AlarmLocation', 25),
        new EventType(EventTypeEnum.AlarmBlagOSDPanic, 'Alarm', 'Blag Detected OSD Panic', 36),
        new EventType(EventTypeEnum.AlarmBlagOSDTest, 'Alarm', 'Blag Detected OSD Test', 37),

        //
        //  Information Types
        //    
        new EventType(EventTypeEnum.GatorEvent, 'Info', 'Gator Event', 22),
        new EventType(EventTypeEnum.RFIDEvent, 'Info', 'RFID Event', 22),
        new EventType(EventTypeEnum.CallBackEvent, 'Info', 'Call Back Event', 22),
        new EventType(EventTypeEnum.MagnetPatternDetected, 'Info', 'Magnet Pattern Detected', 23),
        new EventType(EventTypeEnum.InfoBaseline, 'Info', 'Baseline', 8),
        new EventType(EventTypeEnum.InfoLoRa, 'Info', 'LoRa', 9),
        new EventType(EventTypeEnum.InfoStrap1, 'Info', 'Strap1', 10),
        new EventType(EventTypeEnum.InfoStrap2, 'Info', 'Strap2', 11),
        new EventType(EventTypeEnum.InfoDetector1, 'Info', 'Detector1', 12),
        new EventType(EventTypeEnum.InfoDetector2, 'Info', 'Detector2', 13),
        new EventType(EventTypeEnum.InfoFirmware, 'Info', 'Firmware', 14),
        new EventType(EventTypeEnum.InfoGeneral, 'Info', 'General', 14),
        new EventType(EventTypeEnum.InfoMotion, 'Info', 'Motion', 15),
        new EventType(EventTypeEnum.InfoOtherTampers, 'Info', 'Other Tampers', 16),
        new EventType(EventTypeEnum.InfoMessageIntegrity, 'Info', 'Message Integrity', 17),
        new EventType(EventTypeEnum.InfoGeneral2, 'Info', 'General 2', 18),
        new EventType(EventTypeEnum.InfoBodyTemperature, 'Info', 'Body Temperature', 19),
        new EventType(EventTypeEnum.InfoBleListener, 'Info', 'Ble Listerner', 20),
        new EventType(EventTypeEnum.InfoLiveness, 'Info', 'Liveness', 21),
        new EventType(EventTypeEnum.InfoBleListener2, 'Info', 'Ble Listerner2', 20),
        new EventType(EventTypeEnum.InfoRfId1, 'Info', 'RfId1', 20),
        new EventType(EventTypeEnum.InfoRfId2, 'Info', 'RfId2', 20),
        new EventType(EventTypeEnum.InfoOSD, 'Info', 'OSD', 20)
    ]

    public getEventTypes (sortField?: string): EventType[] {
        sortField = sortField !== undefined ? sortField : 'sort';
        return this._eventTypes
            .slice()
            .sort((a, b) => { return a[sortField].toString().localeCompare(b[sortField].toString()); });
    }

    public getAlarmEventTypes (sortField?: string): EventType[] {
        sortField = sortField !== undefined ? sortField : 'sort';
        return this._eventTypes
            .filter(item => item.type === 'Alarm')
            .sort((a, b) => { return a[sortField].toString().localeCompare(b[sortField].toString()); });
    }

    public getInfoEventTypes (sortField?: string): EventType[] {
        sortField = sortField !== undefined ? sortField : 'sort';
        return this._eventTypes
            .filter(item => item.type === 'Info')
            .sort((a, b) => { return a[sortField].toString().localeCompare(b[sortField].toString()); });
    }

    public getEventTypeById (id: EventTypeEnum): EventType {
        return this._eventTypes.filter(item => item.id === id)[0];
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.EventTypeEnum = EventTypeEnum;
globalThis.DIG.EventTypes = EventTypes;