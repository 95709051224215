﻿import ko = require('knockout');

ko.bindingHandlers.readonly = {
    update: function (element, valueAccessor) {
        if (valueAccessor()) {
            $(element).attr("readonly", "readonly");
            $(element).addClass("disabled");
        } else {
            $(element).removeAttr("readonly");
            $(element).removeClass("disabled");
        }
    }
};

ko.bindingHandlers.customdisable = {
    update: function (element, valueAccessor) {
        if (valueAccessor()) {
            $(element).attr("disabled", "");
            $(element).addClass("disabled");
        } else {
            $(element).removeAttr("disabled");
            $(element).removeClass("disabled");
        }
    }
};
