﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class DeviceLocation {

    deviceId: ko.Observable<number> = ko.observable(0);
    facilityBeaconGroupId: ko.Observable<number> = ko.observable(0);
    locationName: ko.Observable<string> = ko.observable('');
    locationComment: ko.Observable<string> = ko.observable('');
    detectedTime: moment.Moment = ''.toMoment();
    //lostTime: moment.Moment = ''.toMoment();

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.detectedTime = moment(data["detectedTime"]);
        }
        else {
            this.deviceId = ko.observable(0);
            this.locationName = ko.observable('No Location');
            this.locationComment = ko.observable('');
            this.detectedTime = common.toFacilityTime(moment().utc());
        }
    }

    displayDetectedTimestamp: ko.PureComputed<string> = ko.pureComputed<string>(() => `${this.detectedTime.format('M/D/YYYY')} <span style="white-space: nowrap;">${this.detectedTime.format('h:mm:ss A')}</span>`)

}
