﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")
require('knockout.contextmenu')

import { CommonViewModel } from "./Common"
import { LoadingIndicator } from "./LoadingIndicator"
import { ViewModelItem } from "./ViewModelItem"
import { isNullOrUndefined } from "util"

const common: CommonViewModel = globalThis.DIG.Common

export class Action {
    description: string
    class: string
    callback: Function
    altKey: string
}

export class ViewModelBaseOptions {
    settingPrefix = '';
    allowSelection = false;
    allowMultipleSelections = false;
    flashNewRows = true;
    clearSelectionsOnPageChange = true;
    showAddNew = false;
    detail = {
        enabled: false,
        nextRow: false,
        right: true,
        bottom: false,
        expandAllRows: false,
        detailSize: 0
    };
    autoRefresh = {
        show: false,
        enabled: false,
        intervalSeconds: 60
    };
    showExportExcel = false;
    showExportPDF = false;
    allowMouseScroll = false;
    keyBindings = {
        enable: true,
        enter: false,
        esc: false
    };
    templateSections = {
        queryParameters: false,
    };
    selectionActions: Action[] = [];
    pageActions: Action[] = [];
    rightClickActions: Action[] = [];
    sortFields = [];
    pageSizeOptions = [10, 25, 50];
    loadDataOnInit = true;
}

export interface ViewModelBase<T extends ViewModelItem> {
    onEnter?(event, activeRow?: T): void;
    onClick?(id: number): void;
    onAddNew?(): void;
    onSelectionChanged?(): void;
    removeById?(id: number): boolean;
    initChild?(): void;
    afterBinding?(): void;
    beforeSetData?(data?: []): void;
    afterSetData?(data?: []): void;
    filterData?(): T[];
}

export abstract class ViewModelBase<T extends ViewModelItem> {
    data: any = ko.observableArray<T>([])
        .extend({
            rateLimit: {
                timeout: 250,
                method: 'notifyWhenChangesStop'
            }
        })

    mapConfig = {
        //key: (item) => ko.utils.unwrapObservable(item.getId()),
        create: (item) => this.createItem(item.data, this),
        observe: [],
        ignore: [],
        copy: []
    }

    loading: LoadingIndicator = new LoadingIndicator($('.toolbar-page-content'));

    summaryRowSize: number = 32; //TODO: consider making this part of the option config.

    //
    //  Filter and paging properties
    //
    page: ko.Observable<number> = ko.observable(-1)
    direction: ko.Observable<number> = ko.observable(-1)
    startIndex: ko.Observable<number> = ko.observable(-1)
    endIndex: ko.Observable<number> = ko.observable(-1)
    pageSize: ko.Observable<string> = ko.observable('20')
    sort: ko.Observable<string> = ko.observable('')

    firstSearch = true;

    //
    //  Refresh properties
    //
    displayRefreshTime: ko.Observable<string> = ko.observable('')
    displayTimezone: string = common.longTimezoneNames[common.toFacilityTime(moment.utc()).format('zz')]
    autoRefresh: ko.Observable<boolean> = ko.observable(false)
    updateAvailable: ko.Observable<boolean> = ko.observable(false)
    timerPaused = true
    refreshTimer: number
    nextRefreshTime: moment.Moment
    bump: ko.Observable<boolean> = ko.observable(false)
    gridScroller: JQuery = $(".dig-table-body .grid-scroll")
    scrollHeight: ko.Observable<number> = ko.observable(0)
    scrollTop: ko.Observable<number> = ko.observable(0)

    //
    //  Selection properties
    //
    activeId: ko.Observable<number> = ko.observable<number>(0)
    selectAllValue: ko.Observable<boolean> = ko.observable(false)
    isSelectionActionsVisible: ko.Observable<boolean> = ko.observable(false)
    displaySelectionCount: ko.Observable<string> = ko.observable("")

    filter: ko.Observable<string> = ko.observable('').extend({
        rateLimit: {
            timeout: 250,
            method: 'notifyWhenChangesStop'
        }
    })

    //
    //  Right Click menu propertie(s)
    //
    rightClickItemId: number = 0;

    pageActions = (): Action[] => this.options.pageActions;
    selectionActions = (): Action[] => this.options.selectionActions;
    rightClickActions = (): Action[] => this.options.rightClickActions;

    public constructor() { }

    private _sort: string = null;
    private _direction: string = null;
    private _pageSize: string = null;

    public init = () => {
        this._sort = this.sort();
        this._direction = this.direction().toString();
        this._pageSize = this.pageSize();

        this.sort.subscribe(newValue => {
            if (this._sort !== null && newValue !== this._sort) {
                this.saveSetting('SortField', newValue);
            }
            this._sort = newValue;
        });

        this.direction.subscribe(newValue => {
            if (this._direction !== null && newValue.toString() !== this._direction) {
                this.saveSetting('SortDirection', newValue);
            }
            this._direction = newValue.toString();
        });

        this.pageSize.subscribe(newValue => {
            if (this._pageSize !== null && newValue !== this._pageSize) {
                this.saveSetting('PageSize', newValue);
            }
            this._pageSize = newValue;
        });

        this.page.subscribe(this._pageChanged);

        this.autoRefresh(this.options.autoRefresh.enabled);

        if (this.options.keyBindings.enable) {
            this.setupKeyBindings();
        }

        if (this.options.allowMouseScroll) {
            this.setupMouseBindings();
        }

        if (this.initChild !== undefined && typeof (this.initChild) === 'function') {
            this.initChild();
        }

        ko.applyBindings(this);

        if (this.afterBinding !== undefined !== undefined && typeof (this.afterBinding) === 'function') {
            this.afterBinding();
        }

        if (this.options.loadDataOnInit) {
            this.loadData();
        }

        this.autoRefresh.subscribe(this._autoRefreshChanged);
    }

    private _pageChanged = () => {
        if (this.options.clearSelectionsOnPageChange) {
            this.clearSelection();
        }
    }

    private _autoRefreshChanged = (newValue) => {
        if (newValue) {
            //
            //  Calling loadData will kick off the auto-refresh timer
            //
            this.loadData();
        } else {
            //
            //  Kill timer (if exists)
            //
            try { window.clearInterval(this.refreshTimer) } catch { /* */ }
            this.refreshTimer = null;
        }
    }

    refresh = () => {
        this.timerPaused = true;
        this.loadData();
    }

    _refresh = () => {
        if (!this.timerPaused && moment.utc().diff(this.nextRefreshTime) >= 0) {
            this.timerPaused = true;
            this.loadData();
        }
    }

    saveSetting = async (field: string, newValue) => {
        const setting = `/api/user/setting/${this.options.settingPrefix}-${field}`
        $.post(setting, { settingValue: newValue });
    }

    loadData = async () => {
        this.loading.show();

        try {
            this.setData(await this.fetchData());
        } catch (exception) {
            console.debug("ViewModelBase::loadData()", exception);

            //TODO: Add an offline indicator, error count (lengthen the wait interval for each) *** CHECK ERROR TO MAKE SURE IT WASN'T PERMISSIONS
            //TODO: Add check to primary page controller to check if you are still logged in warn when getting close to being logged out. (setting for keep logged in with pulse). kick to logged out page when timed out. 

            //
            //  Restart timer if needed (This is handled by setData if no errors occurred)
            //
            if (this.autoRefresh()) {
                this.nextRefreshTime = moment.utc().add(this.options.autoRefresh.intervalSeconds, 'seconds');
                if (this.refreshTimer == null) {
                    this.refreshTimer = window.setInterval(this._refresh, 1000);
                }
                this.timerPaused = false;
            }
        }

        this._selectionChanged();
        this.loading.hide();
    }

    setData = (data) => {
        if (this.beforeSetData !== undefined && typeof (this.beforeSetData) === 'function') {
            this.beforeSetData(data);
        }

        let maxId = 0;
        //TODO: make sure table has rows
        if (this.options.flashNewRows) {
            maxId = Math.max.apply(null, ko.utils.arrayMap(this.data(), item => item.getId()));
        }

        //this.data = mapping.fromJS(data, this.mapConfig);
        //clear the array before processing results
        this.data = ko.observableArray<T>([]);
        ko.utils.arrayForEach(data, item => { this.data().push(this.createItem(item, this)) });
        this.data.valueHasMutated();

        this.displayRefreshTime(moment().format('MMMM Do YYYY, h:mm:ss a'));

        if (maxId > 0) { //maxId will be 0 if this.options.flashNewRows is false (don't need to check again)
            ko.utils.arrayForEach(this.data(), (item) => {
                if (item.getId() > maxId) {
                    item.flash(true);
                }
            });
        }

        if (this.afterSetData !== undefined && typeof (this.afterSetData) === 'function') {
            this.afterSetData(data);
        }

        this.activeId(0);
        this.page(0);
        this.bump(!this.bump());

        if (!this.firstSearch && !this.autoRefresh()) {
            common.toast("success", 'Cache has been refreshed');
        } else {
            this.firstSearch = false;
        }

        if (this.updateAvailable()) {
            this.updateAvailable(false);
        }

        if (this.autoRefresh()) {
            this.nextRefreshTime = moment.utc().add(this.options.autoRefresh.intervalSeconds, 'seconds');
            if (this.refreshTimer == null) {
                this.refreshTimer = window.setInterval(this._refresh, 1000);
            }
            this.timerPaused = false;
        }
    }

    insertItem = (item): T => {
        const result = this.createItem(item, this);
        this.data().push(result);
        this.data.valueHasMutated();
        return result;
    }

    recordCount: ko.PureComputed<number> = ko.pureComputed(() => this.filtered().length)

    pageCount: ko.PureComputed<number> = ko.pureComputed(() => Math.ceil(this.recordCount() / parseInt(this.pageSize())))

    filtered: ko.Computed = ko.computed(() => {
        let result = [];
        var bump = this.bump();

        this.startIndex(0);

        if (this.filterData !== undefined && typeof (this.filterData) === 'function') {
            result = this.filterData();
        } else {
            const filterText = this.filter().trim();
            result = ko.utils.arrayFilter(this.data(), (item: T) => item.isMatch(filterText));
        }

        //this.scrollHeight(result.length * 32);
        let offset = 0;
        if (this.options?.detail?.expandAllRows ?? false) {
            offset = this.options.detail.detailSize;
        }

        //this.scrollHeight(result.length * (32 + offset));
        //$('.dig-table-body .grid-scroll')[0].scrollTop = 0;

        return result;
    })

    sorted: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        var sort = this.sort();
        var direction = this.direction();
        
        this.activeId(0);
        this.page(0);

        return this.filtered().sort((a: T, b: T) => a.compare(b, sort, direction))
    })

    dataPage: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        const count: number = this.recordCount();

        return this.sorted();

    //    const maxRows: number = this.maxRows();

    //    var startIndex: number = this.startIndex() < 0 ? 0 : Math.min(this.startIndex(), count);
    //    var endIndex: number = Math.min(startIndex + maxRows, count) ;

    //    if (startIndex != this.startIndex()) this.startIndex(startIndex);
    //    if (endIndex != this.endIndex()) this.endIndex(endIndex);

    //    let offset = 0;
    //    if (this.options?.detail?.expandAllRows ?? false) {
    //        offset = this.options.detail.detailSize;
    //    }

    //    const scroll = startIndex * (32 + offset);
    //    if (scroll != this.gridScroller.scrollTop()) this.gridScroller[0].scroll(0, scroll);

    //    return this.sorted().slice(startIndex, endIndex);
    })

    protected pageDescription(page: number): string {
        let result = '';

        //var pageSize: number = parseInt(this.pageSize());
        //var startIndex = page * pageSize;
        //var endIndex = startIndex + pageSize;
        //var sort = this.sort();

        //if (startIndex < this.recordCount()) {
        //    const pageData: T[] = this.sorted().slice(startIndex, endIndex);

        //    if (pageData.length > 0) {
        //        const start = pageData[0].itemDescription(sort);
        //        const end = pageData[pageData.length - 1].itemDescription(sort);

        //        if (start !== end) {
        //            result = `${start} to ${end}`;
        //        } else {
        //            result = start;
        //        }
        //    }
        //}

        return result;
    }

    protected find(id: number) {
        return ko.utils.arrayFirst(this.data(), (item) => item.getId() === id);
    }

    protected clickedRow = (row): void => {
        if (row !== undefined) {
            const id: number = row.getId();
            this._clickedRow(id);
        }

        //
        //  Activate the selected row
        //
        this.activateRow(row);
    }

    private _clickedRow = (id): void => {
        if (this.onClick !== undefined && typeof (this.onClick) === 'function') {
            this.onClick(id);
        }
    }

    protected activateRow = (row): void => {
        if (row !== undefined && this.options.allowSelection) {
            const id: number = row.getId();
            this.activeId(id);
            this._activateRow(row);
            this.makeVisible();
        }
    }

    private _activateRow = (row): void => {
        if (row?.activated !== undefined && typeof (row.activated) === 'function') {
            row.activated();
        }
    }

    protected active: ko.Computed<T> = ko.computed(() => {
        const id = this.activeId();
        return this.find(id);
    })

    protected dataFor = (item) => ko.dataFor(item);

    protected add(item) {
        this.data.mappedCreate(item);
    }

    protected changeSort(a, b) {
        const field = b.target.dataset.sort;

        if (field === this.sort()) {
            this.direction(this.direction() * -1);
        } else {
            this.sort(field);
            this.direction(1);
        }
    }

    //
    //  Magic Number 32 = Height of a 1 line grid row using current styling
    //      Todo: Allow setting of row height
    //      Todo: Allow setting edit row height
    //      Todo: Configure page to hold 1 edit row and n detail rows
    //
    private maxRows(): number {
        const table = $('.dig-table').parent();
        let offset = 0;

        if (this.options?.detail?.expandAllRows ?? false) {
            offset = this.options.detail.detailSize;
        }

        return Math.floor(table[0].clientHeight / (this.summaryRowSize + offset));
    }

    protected makeVisible = () => {
        let row = $('.dig-table .summary.active');

        if (row.length == 0 && this.dataPage().length > 0 && this.options.allowSelection) {
            this.activeId(this.dataPage()[0].getId());
            row = $('.dig-table-content .dig-table-row.active');
        }

        if (row.length != 0) {
            const rowHeight = row[0].clientHeight;
            const divParent = $('.dig-table').parent();

            if (row[0].offsetTop <= divParent[0].scrollTop + rowHeight) {
                divParent[0].scrollTop = row[0].offsetTop - rowHeight;
            } else if (row[0].offsetTop + rowHeight > divParent[0].scrollTop + divParent[0].clientHeight) {
                divParent[0].scrollTop = row[0].offsetTop + rowHeight - divParent[0].clientHeight;
            }
        }

    }

    protected addNew = () => {
        if (this.onAddNew !== undefined && typeof (this.onAddNew) === 'function') {
            this.onAddNew();
        }
    }

    protected scrollUp = () => {
        const divParent = $('.dig-table').parent();
        divParent[0].scrollTop = divParent[0].scrollTop - this.summaryRowSize;
    }

    protected scrollDown = () => {
        const divParent = $('.dig-table').parent();
        divParent[0].scrollTop = divParent[0].scrollTop + this.summaryRowSize;
    }

    protected scrollFirst = () => {
        const divParent = $('.dig-table').parent();
        divParent[0].scrollTop = 0;
    }

    protected scrollLast = () => {
        const max = this.maxRows();
        const divParent = $('.dig-table').parent();

        divParent[0].scrollTop = divParent[0].scrollTop + (max * this.recordCount());
    }

    protected scrollUpPage = () => {
        const max = this.maxRows();
     
        const divParent = $('.dig-table').parent();
        divParent[0].scrollTop = divParent[0].scrollTop - (max * this.summaryRowSize);
    }

    protected scrollDownPage = () => {
        const max = this.maxRows();
        const divParent = $('.dig-table').parent();

        divParent[0].scrollTop = divParent[0].scrollTop + (max * this.summaryRowSize);
    }

    protected selectPrevious = () => {
        const index = this.sorted().indexOf(this.active());

        if (index == this.startIndex() && index > 0) {
            this.scrollUp();
        }

        if (index > 0) {
            this.activeId(this.sorted()[index - 1].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
    }

    protected selectNext = () => {
        const index = this.sorted().indexOf(this.active());

        if (index == this.endIndex() - 1 && index < this.data().length - 1) {
            this.scrollDown();
        }

        if (index < this.data().length - 1) {
            this.activeId(this.sorted()[index + 1].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
    }

    protected selectPageFirst = () => {
        if (this.sorted().length !== 0) {
            this.scrollFirst();
            this.activeId(this.sorted()[0].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
    }

    protected selectPageLast = () => {
        if (this.sorted().length !== 0) {
            this.scrollLast();
            this.activeId(this.sorted()[this.sorted().length - 1].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
    }

    protected selectPreviousPage = () => {
        const max = this.maxRows();
        const index = this.sorted().indexOf(this.active());

        if (index - max > 0) {
            this.activeId(this.sorted()[index - max].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
        else {
            this.activeId(this.sorted()[0].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
    }

    protected selectNextPage = () => {
        const max = this.maxRows();
        const index = this.sorted().indexOf(this.active());

        if (index < this.data().length - max) {
            this.activeId(this.sorted()[index + max].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }
        else {
            this.activeId(this.sorted()[this.sorted().length - 1].getId());
            this._activateRow(this.active());
            this.makeVisible();
        }

    }

    private setupMouseBindings = () => {

        // why didn't this.rightClickActions.length > 0    work??? returns 0
        if (this.options.rightClickActions.length > 0) {

            $(".dig-table").on("contextmenu", (e) => {
                const mouse: any = e.originalEvent;

                let tableContainer = $("#tableContainer")[0];
                let table = $(".dig-table");
                let x = mouse.x - table.offset().left;
                let y = mouse.y - table.offset().top - tableContainer.scrollTop + 32; //32 is to offset header row.

                this.rightClickItemId = this.dataFor(document.elementFromPoint(mouse.x, mouse.y)).getId();
                $("#rightClickMenu").css({ top: y + 'px', left: x + 'px' }).show();

                e.preventDefault();
            });

            $(document).on("click", (e) => {
                $("#rightClickMenu").hide();

            });
        }


        //$(".dig-table-body").on("wheel", (e) => {
        //    const mouse: any = e.originalEvent;

        //    if (mouse.deltaY > 0) {
        //        this.options.allowSelection ? this.selectNext() : this.scrollDown();
        //    } else if (mouse.deltaY < 0) {
        //        this.options.allowSelection ? this.selectPrevious() : this.scrollUp(); 
        //    }

        //    e.preventDefault();
        //});

    //    let offset = 0;
    //    if (this.options?.detail?.expandAllRows ?? false) {
    //        offset = this.options.detail.detailSize;
    //    }

    //    let previousScrollValue: number = 0;
    //    this.gridScroller
    //        .on("scroll", (e) => {
    //            const me = $(e.currentTarget);
    //            let value: number = this.startIndex();
    //            let current: number = me.scrollTop();

    //            const adjust: number = previousScrollValue < current
    //                ? Math.ceil(current / (32 + offset)) - value
    //                : Math.floor(current / (32 + offset)) - value;

    //            //value = previousScrollValue < current
    //            //    ? Math.ceil(current / 32) // * 32
    //            //    : Math.floor(current / 32) // * 32;

    //            value += adjust;
    //            previousScrollValue = value * (32 + offset);

    //            if (value != this.startIndex()) {
    //                this.startIndex(value);
    //            }            
    //        });
    }

    private setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show, .modal.show, .editor.show, #dangerDialog.d-flex').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 13:
                        if (this.options.keyBindings.enter) {
                            if (this.onEnter !== undefined && typeof (this.onEnter) === 'function') {
                                this.onEnter(e, this.active());
                            }
                        }
                        break;

                    case 33: //Page Up
                        this.options.allowSelection ? this.selectPreviousPage() : this.scrollUpPage();
                        e.preventDefault();
                        break;

                    case 34: //Page Down
                        this.options.allowSelection ? this.selectNextPage() : this.scrollDownPage();
                        e.preventDefault();
                        break;

                    case 38: //Arrow Up
                        if (e.ctrlKey) {
                            this.options.allowSelection ? this.selectPageFirst() : this.scrollFirst();
                        } else {
                            this.options.allowSelection ? this.selectPrevious() : this.scrollUp();
                        }
                        e.preventDefault();
                        break;

                    case 40: //Arrow Down
                        if (e.ctrlKey) {
                            this.options.allowSelection ? this.selectPageLast() : this.scrollLast();
                        } else {
                            this.options.allowSelection ? this.selectNext() : this.scrollDown();
                        }
                        e.preventDefault();
                        break;

                    //case 27: //esc key
                    //    if (this.options.keyBindings.esc) {
                    //        this.collapseSummary();
                    //    }
                    //    e.preventDefault();
                    //    break;

                    default: // Page Actions
                        if (e.altKey) {
                            this.options.pageActions.find(action => action.altKey.toLowerCase() === e.key.toLowerCase())?.callback();
                        }
                }
            }
        });
    }

    selectAll = (): void => {
        this.selectAllValue(!this.selectAllValue());
        //this.dataPage().forEach(item => item.isSelected(this.selectAllValue()));
        //this.data().forEach(item => item.isSelected(this.selectAllValue()));

        if (this.selectAllValue()) {
            this.selectAllFiltered();
        }
        else {
            this.clearSelection();
        }

        this._selectionChanged();

        window.event.preventDefault();
        window.event.stopPropagation();
    }

    selectAllFiltered = (): void => {
        this.selectAllValue(true);
        this.activeId(0);

        console.log("Selecting " + this.filterData().length);
        this.filterData().forEach(item => {
            if (item.allowSelect()) {
                item.isSelected(true);
            }
        });

        this._selectionChanged();
    }

    // changed the purpose, initially this was used when grid used paging. 
    clearSelection = (): void => {
        this.selectAllValue(false);
        this.activeId(0);

        console.log("Clearing " + this.data().length);
        this.data().forEach(item => item.isSelected(false));

        //if (this.data() && this.dataPage()) {
        //    this.data().forEach(dataItem => {
        //        if (dataItem.isSelected() && isNullOrUndefined(ko.utils.arrayFirst<T>(this.dataPage(), (item) => item.getId() === dataItem.getId()))) {
        //            dataItem.isSelected(false);
        //        }
        //    });
        //}

        this._selectionChanged();
    }

    collapseSummary = (): void => {
        this.activeId(0);
    }

    itemSelected = () => {
        this._selectionChanged();
    }

    private _selectionChanged = () => {
        const count: number = ko.utils.arrayFilter(this.data(), (item: T) => item.isSelected()).length;
        const plural: string = count > 1 ? 's' : '';
        this.displaySelectionCount(`${count} row${plural} selected`);

        //this.isSelectionActionsVisible(!isNullOrUndefined(ko.utils.arrayFirst<T>(this.data(), (item) => item.isSelected())));
        this.isSelectionActionsVisible(count > 0);

        this.autoRefresh(this.options.autoRefresh.enabled && count == 0);

        if (this.onSelectionChanged !== undefined && typeof (this.onSelectionChanged) === 'function') {
            this.onSelectionChanged();
        }
    }

    protected abstract options: ViewModelBaseOptions
    protected abstract fetchData(): Promise<object>
    protected abstract createItem(data?: object, view?: object): T
}