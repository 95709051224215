﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import Common = require("../Common")

import { LoadingIndicator } from "../LoadingIndicator"
import { Dialogs } from "../Dialogs"
//import { InmateLocationInfo } from "../Inmates/InmateLocationInfo"
import { EntityLocationInfo } from "../FacilityMap/EntityLocationInfo"

const common: Common.CommonViewModel = globalThis.DIG.Common;

ko.components.register('BuildingOccupantsWidget', {
    viewModel: function (params) {
        this.containerId = common.uniqueId();
        this.loading = new LoadingIndicator($(`#${this.containerId}`));
        this.noResultsClass = ko.observable<string>('d-none');
        this.inmateTableClass = ko.observable<string>('d-flex');
        this.osdTableClass = ko.observable<string>('d-flex');

        this.locationKey = params.locationKey ?? ko.observable<string>('');
        this.facilityBeaconGroupId = params.beaconGroupId ?? ko.observable<number>(0);
        this.facilityBeaconGroupDescription = params.beaconGroupDescription ?? ko.observable<string>('');
        this.showMonitorLink = params.showMonitorLink ?? false;
        this.highlightDeviceId = params.highlightDeviceId ?? ko.observable<number>(0);

        this.presentEntities = ko.observableArray([]);
        this.bump = ko.observable<boolean>(false);

        this.sortedInmates = ko.computed(() => {
            var bumpRead = this.bump();
            //return this.inmates();

            let filteredResult = ko.utils.arrayFilter(this.presentEntities(), (item: EntityLocationInfo) => item.entityType() == Common.EntityType.Inmate);

            return filteredResult.sort((a, b) => {
                let result = a.displayName().localeCompare(b.displayName());
                
                //if (result === 0) {
                //    let aDate: any = a.WhenEntered;
                //    let bDate: any = b.WhenEntered;
                //    result = (aDate - bDate) * -1; // latest date at the top
                //}

                return result;
            });

        });

        this.sortedOsd = ko.computed(() => {
            var bumpRead = this.bump();
            //return this.inmates();

            let filteredResult = ko.utils.arrayFilter(this.presentEntities(), (item: EntityLocationInfo) => item.entityType() != Common.EntityType.Inmate);

            return filteredResult.sort((a, b) => {
                let result = a.displayName().localeCompare(b.displayName());

                //if (result === 0) {
                //    let aDate: any = a.WhenEntered;
                //    let bDate: any = b.WhenEntered;
                //    result = (aDate - bDate) * -1; // latest date at the top
                //}

                return result;
            });

        });

        this.fetchData = () => {
            this.loading.show();

            $.get(`/api/dashboard/location/entityaccess`, { locationKey: this.locationKey(), unauthorizedonly: false })
                .done(results => this.setData(results))
                .fail((a, b, c) => { console.error("BuildingOccupantsWidget::fetchData()", a, b, c) })
                .always(_ => this.loading.hide());
        };

        this.setData = (data) => {
            if (data != null && data != "") {
                const presentMap = {
                    key: (inmate) => ko.utils.unwrapObservable(inmate.deviceId),
                    create: (inmate) => new EntityLocationInfo(inmate.data)
                };

                if (data == null) {
                    this.presentEntities = ko.observableArray([]);
                }
                else {
                    this.presentEntities = mapping.fromJS(data, presentMap);
                }

                this.bump(!this.bump());

                this.noResultsClass(this.presentEntities().length === 0 ? 'd-flex' : 'd-none');
                this.inmateTableClass(this.presentEntities().length === 0 ? 'd-none' : 'd-flex');
                this.osdTableClass(this.sortedOsd().length === 0 ? 'd-none' : 'd-flex');
            }
        };

        this.editInmate = (e) => {
            Dialogs.editInmate(e.entityId());
        };

        this.navigateToMonitor = (e) => {
            common.navigate(`/monitor/dorm?lid=${this.locationKey()}`, false);
        };

        this.highlightClass = (deviceId): string => {
            if (deviceId() == this.highlightDeviceId()) {
                return "blue-highlight";
            }

            return "";
        };

        this.inmateLabel = () : string => {
            return common.customStrings.find(e => e.customStringType == 41).shortDescription; //41 is label_inmate consider making enum
        };

        this.inmatePluralLabel = (): string => {
            return common.customStrings.find(e => e.customStringType == 41).description; //41 is label_inmate consider making enum
        };

        this.identifierLabel = (): string => {
            return common.customStrings.find(e => e.customStringType == 30).description; //30 is indentifier consider making enum
        };

        this.emptyListLabel = (): string => {
            return "No " + this.inmatePluralLabel() + " Detected"
        };

        this.fetchData();
        this.facilityBeaconGroupId.subscribe(_ => this.fetchData());

        this.changeDeviceClick = (data: EntityLocationInfo, event) => {
            //let rcAssignmentDialogOptions: InmateAssignmentRcDialogDelegates = {
            //    onSave: null
            //}
            //rcAssignmentDialogOptions.onSave = this.afterRCDeviceChanged;

            //Dialogs.rcInmateAsssignment(data.inmateId(), rcAssignmentDialogOptions);

            Dialogs.rcInmateAsssignment(data.entityId());
        }

        this.changePresenceClick = (data: EntityLocationInfo, event) => {
            //let rcPresenceDialogOptions: InmatePresenceRcDialogDelegates = {
            //    onSave: null
            //}
            //rcPresenceDialogOptions.onSave = this.afterRCPresenceChanged;

            //Dialogs.rcInmatePresence(data.inmateId(), rcPresenceDialogOptions);

            Dialogs.rcInmatePresence(data.entityId());
        }


        this.contextMenuItems = ko.observableArray([
            { text: "Assignment/Unassignment", action: this.changeDeviceClick },

            { text: "Change Presence", action: this.changePresenceClick },
        ]);

        window.setInterval(() => this.fetchData(), 15000);
    },

    template:
        '<style> \
            .dw .status-good { background: var(--success); background: linear-gradient(90deg, rgba(0,255,8,0.3) 0%, rgba(16,185,47,1) 50%, rgba(0,255,8,0.3) 100%); } \
            .dw .status-bad { background: var(--danger); background: linear-gradient(90deg, rgba(255,0,0,0.4) 0%, rgba(225,19,19,1) 50%, rgba(255,0,0,0.4) 100%); } \
            .dw .status-neutral { background: rgb(0,0,0); background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(173,173,173,1) 50%, rgba(0,0,0,0.4) 100%); } \
            .dw .darker { background-color: #1c2327; } \
            .blue-highlight { color: cornflowerblue !important; } \
        </style> \
        <div data-bind="attr: {id: containerId}" class="dw d-flex flex-column flex-fill" style="color: silver;"> \
            <div class="status-neutral p-2 text-center" style="color: black; font-size: 1.3rem; font-weight: 700;"> \
                <span data-bind="text: facilityBeaconGroupDescription"></span> \
                <span data-bind="visible: showMonitorLink"><i class="far fa-tv ml-2" data-bind="click: navigateToMonitor" style="cursor: pointer;"></i></span> \
            </div>\
            <div class="flex-fill pb-2" data-bind="class: osdTableClass;" style="overflow-y: auto; height: 100%; max-height: 30%"> \
                <table class="dig-table flex-fill dark">\
                    <thead class="header" style="font-weight: bold;height: 26px;min-width: 100%;"> \
                        <td class="dig-col flex-fill" style="width: 100%; min-width: 240px;">Name</td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 150x; max-width: 150px;">Device</td> \
                    </thead> \
                    <!-- ko foreach: sortedOsd --> \
                    <tbody style="font-size: 1.2rem; line-height: 1.3rem; height: 32px;"> \
                        <tr class="summary no-hover" data-bind="class: $parent.highlightClass(deviceId)"> \
                            <td class="dig-col flex-fill" style="width: 100%; min-width: 240px; text-overflow: ellipsis;"><span data-bind="text: displayName"></span> <a href="#" data-bind="visible: isInmate, click: $parent.editInmate"> <i class="far fa-address-card hover-link"></i></a></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 150px; max-width: 150px;" data-bind="text: deviceId"></td> \
                        </tr> \
                    </tbody> \
                    <!-- /ko --> \
                    <tbody style="font-size: 1.2rem; line-height: 1.3rem;"> \
                        <tr class="summary no-hover"> \
                            <td class="dig-col flex-fill" style="width: 100%; min-width: 240px;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 150px; max-width: 150px;"></td> \
                        </tr> \
                    </tbody> \
                </table>\
            </div> \
            <div class="flex-fill" data-bind="class: inmateTableClass;" style="overflow-y: auto;"> \
                <table class="dig-table flex-fill dark">\
                    <thead class="header" style="font-weight: bold;height: 26px;min-width: 100%;"> \
                        <td class="dig-col flex-fill" style="width: 100%; min-width: 150px;" data-bind="text: inmateLabel()"></td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;" data-bind="text: identifierLabel()"></td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 150px; max-width: 150px;">Living Unit</td> \
                    </thead> \
                    <!-- ko foreach: sortedInmates --> \
                    <tbody style="font-size: 1.2rem; line-height: 1.3rem; height: 32px;"> \
                        <tr class="summary no-hover" data-bind="class: $parent.highlightClass(deviceId)"> \
                            <td class="dig-col flex-fill" style="width: 100%; min-width: 150px; text-overflow: ellipsis;"><span data-bind="text: displayName"></span> <a href="#" data-bind="visible: isInmate, click: $parent.editInmate, contextMenu: $parent.contextMenuItems "> <i class="far fa-address-card hover-link"></i></a></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;" data-bind="text: identifier"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 150px; max-width: 150px;" data-bind="text: displayDetailedDorm"></td> \
                        </tr> \
                    </tbody> \
                    <!-- /ko --> \
                    <tbody style="font-size: 1.2rem; line-height: 1.3rem;"> \
                        <tr class="summary no-hover"> \
                            <td class="dig-col flex-fill" style="width: 100%; min-width: 150px;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 150px; max-width: 150px;"></td> \
                        </tr> \
                    </tbody> \
                </table>\
            </div> \
            <div class="flex-fill" data-bind="class: noResultsClass" style="background-color: gainsboro; display: none;"> \
                <span data-bind="text: emptyListLabel()" style="font: 24px Arial;color: black;text-align: center;" class="m-auto"></span> \
            </div> \
        </div>'
})