﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { CommonViewModel } from "../Common"
import { Dialogs, InmateDialogOptions } from "../Dialogs";
import { MealSchedule } from "../Facility/MealSchedule";
import { InmateMeal } from "../Inmates/InmateMeal";
import { LoadingIndicator } from "../LoadingIndicator";

const common: CommonViewModel = globalThis.DIG.Common

export class MealMonitorViewModel {
    constructor() {
    }

    dialogOptions: InmateDialogOptions = {
        isReadOnly: false,
        showAlarms: true,
        showConfiguration: false,
        showTemp: true,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onHidden: null,
        onDeactivate: null
    }

    facilityBuildingId: number = 0;
    loading = new LoadingIndicator($('#inmateTiles'));
    inmates = ko.observableArray<InmateMeal>([]);
    mealInfo = ko.observable<MealSchedule>();
    nextMealInfo = ko.observable<MealSchedule>();
    isMealCurrent = ko.observable<boolean>(false);
    nextSessionRefreshTime = common.toFacilityTime(moment().utc()).startOf('day');
    printSchedules = ko.observableArray<MealSchedule>([]);


    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    displayDate: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('MMMM Do, YYYY');
    });

    displayTime: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('h:mm A');
    });

    displayMealTitle: ko.Computed<string> = ko.computed(() => {
        var bumpRead = this.bump();
        if (this.mealInfo != null && this.mealInfo() != null) {
            return this.mealInfo().description;
        }
        else {
            return "No Current Meal";
        }
    });

    displayMealSubTitle: ko.Computed<string> = ko.computed(() => {
        var bumpRead = this.bump();
        if (this.mealInfo != null && this.mealInfo() != null) {
            return this.mealInfo().line2Description;
        }
        else {
            return "";
        }
    });

    isMealTime: ko.Computed<boolean> = ko.computed(() => {
        var bumpRead = this.bump();
        if (this.mealInfo != null && this.mealInfo() != null) {
            return true;
        }
        else {
            return false;
        }
    });

    displayNextMealTime: ko.Computed<string> = ko.computed(() => {
        var bumpRead = this.bump();

        if (this.nextMealInfo != null && this.nextMealInfo() != null) {
            var time = common.toFacilityTime(moment.utc()).startOf('day').add(this.nextMealInfo().mealStartMinutesFromMidnight, 'minutes').format('h:mm A');

            return `Next meal is ${this.nextMealInfo().description} ${this.nextMealInfo().dayFoundDescription} at ${time}.`;
        }
        else {
            return "";
        }
    });

    displayInmateCounts: ko.Computed<string> = ko.computed(() => {
        var bumpRead = this.bump();

        if (this.inmates() != null) {
            let allCount = this.inmates().length;
            let scannedCount = this.inmates().filter(x => {
                return x.mealCount() > 0;
            }).length;

            let inmateString = "";

            if (common.customStrings != null) {
                inmateString = common.customStrings.find(e => e.customStringType == 41).description;
            }

            return `${scannedCount} of ${allCount} ${inmateString}`;
        }
        else {
            return "";
        }
    });

    allowPrevious: ko.Computed<boolean> = ko.computed(() => {
        var bumpRead = this.bump();

        return true;
    });

    allowNext: ko.Computed<boolean> = ko.computed(() => {
        var bumpRead = this.bump();

        return true;
    });

    allInmatesSorted = ko.computed(() => {
        var bumpRead = this.bump();

        var sortRead = "mealCount"; //this.sort();
        var directionRead = 1;//this.direction();

        return this.inmates().sort((a, b) => {

            var result = 0;

            if (a.mealCount() == 0 && b.mealCount() > 0) {
                return -1;
            }
            else if (a.mealCount() > 0 && b.mealCount() == 0) {
                return 1;
            }

            if (result == 0) {
                result = a.lastName().localeCompare(b.lastName()) * directionRead;

                if (result == 0) {
                    result = a.firstName().localeCompare(b.firstName()) * directionRead;
                }
            }

            return result;


            // ordering by meal count then name
            //var result = a.mealCount() - b.mealCount();

            //if (result == 0) {
            //    result = a.lastName().localeCompare(b.lastName()) * directionRead;

            //    if (result == 0) {
            //        result = a.firstName().localeCompare(b.firstName()) * directionRead;
            //    }
            //}

            //return result;
        });

    });

    latestInmateMeals = ko.computed(() => {
        var bumpRead = this.bump();
        //return this.inmates(); //.sort((a, b) => a.whenEntered.compare(b.whenEntered));

        var sortRead = "mealCount"; //this.sort();
        var directionRead = -1;//this.direction();

        return this.inmates().filter(x => {
           return x.lastMealTimestamp.isValid();
        }).sort((a, b) => {

            if (!a.lastMealTimestamp.isValid() && !b.lastMealTimestamp.isValid()) {
                return 0;
            }
            else if (!a.lastMealTimestamp.isValid() && b.lastMealTimestamp.isValid())
            {
                return 1;
            }
            else if (a.lastMealTimestamp.isValid() && !b.lastMealTimestamp.isValid()) {
                return -1;
            }
            else {
                return a.lastMealTimestamp.compare(b.lastMealTimestamp) * directionRead;
            }

        }).slice(0, 5);

    });

    allPrintOptions = ko.computed(() => {
        var bumpRead = this.bump();

        return this.printSchedules();
    });


    fetchScheduleData = () => {

        if (moment().utc().isAfter(this.nextSessionRefreshTime)) {
            console.info("fetching session data");

            this.fetchPrintScheduleData();

            this.loading.show();

            $.get(`/api/dashboard/meal/schedule/current`)
                .done(results => this.setScheduleData(results))
                .fail((a, b, c) => { console.error("MealMonitor::fetchScheduleData()", a, b, c) })
                .always(_ => this.loading.hide());
        }
        else {
            console.info("session update skipped");
        }
    }

    setScheduleData = (data) => {
        this.mealInfo(data);
        this.bump(!this.bump());

        if (data == null) {
            this.fetchNextScheduleData();
            this.isMealCurrent(false);
            this.fetchPreviousScheduleData();
        }
        else {
            this.nextMealInfo(null);
            this.isMealCurrent(true);
            this.fetchAttendanceData();

            //currently in a session, don't bother updating until the session ends.
            this.nextSessionRefreshTime = common.toFacilityTime(moment().utc()).startOf('day').add(this.mealInfo().mealEndMinutesFromMidnight, 'minutes');
        }
    }

    fetchPrintScheduleData = () => {

        if (moment().utc().isAfter(this.nextSessionRefreshTime)) {
            console.info("fetching print session data");

            $.get(`/api/dashboard/meal/schedule/previous/list/6`)
                .done(results => this.setPrintScheduleData(results))
                .fail((a, b, c) => { console.error("MealMonitor::fetchPrintSchedulesData()", a, b, c) })
                .always(_ => this.loading.hide());
        }
        else {
            console.info("print session update skipped");
        }
    }

    setPrintScheduleData = (data) => {
        const presentMap = {
            //key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmate) => new MealSchedule(inmate.data)
        };
        this.printSchedules = mapping.fromJS(data, presentMap);

        this.bump(!this.bump());
    }

    printMealAttendence = (a, b) => {
        const id: string = b.target.attributes.sessionIdentifier.nodeValue;
        
        const data = common.objectToFormData({
            dayAndSession: id
        });

        common.postBinaryRequest(`/api/report/rfschedule/meal`, data);
    }

    fetchPreviousScheduleData = () => {
        this.loading.show();

        $.get(`/api/dashboard/meal/schedule/previous`)
            .done(results => this.setPreviousScheduleData(results))
            .fail((a, b, c) => { console.error("MealMonitor::fetchPreviousScheduleData()", a, b, c) })
            .always(_ => this.loading.hide());
    }

    setPreviousScheduleData = (data) => {
        this.mealInfo(data);
        this.bump(!this.bump());

        if (data != null) {
            this.isMealCurrent(false);
            this.fetchAttendanceData();
        }
    }

    fetchNextScheduleData = () => {
        this.loading.show();

        $.get(`/api/dashboard/meal/schedule/next`)
            .done(results => this.setNextScheduleData(results))
            .fail((a, b, c) => { console.error("MealMonitor::fetchNextScheduleData()", a, b, c) })
            .always(_ => this.loading.hide());
    }

    setNextScheduleData = (data) => {
        this.nextMealInfo(data);
        this.bump(!this.bump());

        //currently outside a session, don't bother updating until the next session starts .
        this.nextSessionRefreshTime = common.toFacilityTime(this.nextMealInfo().foundOnDate).startOf('day').add(this.nextMealInfo().mealStartMinutesFromMidnight, 'minutes');
    }

    fetchAttendanceData = () => {
        this.loading.show();

        let path = `/api/dashboard/meal/attendance/current`;
        if (!this.isMealCurrent()) {
            path = `/api/dashboard/meal/attendance/previous`
        }

        $.get(path)
            .done(results => this.setAttendanceData(results))
            .fail((a, b, c) => { console.error("MealMonitor::fetchAttendanceData()", a, b, c) })
            .always(_ => this.loading.hide());
    }

    setAttendanceData = (data) => {
        const presentMap = {
            key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmate) => new InmateMeal(inmate.data)
        };
        this.inmates = mapping.fromJS(data, presentMap);

        this.bump(!this.bump());
    }

    columns = 6;
    inmateClass = (index): string => {
        const width: number = Math.floor(12 / this.columns);
        return ((index() + 1) % this.columns) == 0 ? `col-${width} pt-1 px-0` : `col-${width} pb-0 pl-0 pr-1 pt-1`;
    }

    editInmate = (data: InmateMeal, event) => {
        //event.stopPropagation();
        Dialogs.editInmate(data.inmateId(), this.dialogOptions);
    }

    init = () => {
        common.inmateRFEventOccurred = this.inmateRFEventOccurred;

        this.fetchScheduleData();

        //this.fetchAttendanceData();

        ko.applyBindings(this);

        //window.setTimeout(() => window.setInterval(() => this.bump(!this.bump()), 60000), 61000 - (moment().seconds() * 1000));

        window.setInterval(() => this.fetchScheduleData(), 30000);  // this call is clutched by nextSessionRefreshTime
        window.setInterval(() => this.fetchAttendanceData(), 60000);

    }

    inmateRFEventOccurred = (inmateId, RFZoneId) => {
        this.fetchAttendanceData();
    }

    public printClick = () => {

        console.info('print pressed');
    };

    public previousClick = () => {

        console.info('previous pressed');
    };

    public nextClick = () => {

        console.info('next pressed');
    };
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dashboard ??= () => { /* */ };
globalThis.DIG.Dashboard.MealMonitor ??= () => { /* */ };
globalThis.DIG.Dashboard.MealMonitor.ViewModel = MealMonitorViewModel;