﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import moment = require('moment-timezone')

import { isNullOrUndefined } from 'util'
import { InmatesImportDetail } from './InmatesImportDetail'
import { InmatesImportSummary } from './InmatesImportSummary'

export class InmatesImport {
    inmateImportId: number
    filename: string
    uploadUserId: number
    uploadUserName: string
    uploadTimestamp: moment.Moment
    executedUserId: number
    executedUserName: string
    executedTimestamp: moment.Moment
    isExecuted: boolean

    executionSummary: InmatesImportSummary
    importDetails: InmatesImportDetail[]


    constructor(data) {

        var map = {
            'copy': ["inmateImportId", "filename", "uploadUserId", "uploadUserName", "executedUserId", "executedUserName", "isExecuted"],
            'ignore': ["executionSummary", "importDetails"]
        }

        mapping.fromJS(data, map, this);



        if (!isNullOrUndefined(data["uploadTimestamp"])) {
            this.uploadTimestamp = moment(data["uploadTimestamp"])
        }
        else {
            this.uploadTimestamp = ''.toMoment();
        }

        if (!isNullOrUndefined(data["executedTimestamp"])) {
            this.executedTimestamp = moment(data["executedTimestamp"])
        }
        else {
            this.executedTimestamp = ''.toMoment();
        }

        var summaryMap = {
            create: (inObj) => new InmatesImportSummary(inObj.data)
        };

        if (!isNullOrUndefined(data["executionSummary"])) {
            this.executionSummary = ko.utils.unwrapObservable(mapping.fromJS(data["executionSummary"], summaryMap));
        }
        else {
            this.executionSummary = null;
        }


        var childMap = {
            create: (inObj) => new InmatesImportDetail(inObj.data)
        };

        if (!isNullOrUndefined(data["importDetails"])) {
            this.importDetails = ko.utils.unwrapObservable(mapping.fromJS(data["importDetails"], childMap));
        }
        else {
            this.importDetails = null;
        }


    }



}