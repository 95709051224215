﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { RangeValidationOptions, Validation } from "../ValidationExtenders"

export class ConfigurationSetting {
    parent: any

    configurationSettingId: ko.Observable<number> = ko.observable(0)
    editValue: ko.Observable<string> = ko.observable('0')

    description: ko.Observable<string> = ko.observable('')
    notes: ko.Observable<string> = ko.observable('')
    defaultValue: ko.Observable<string> = ko.observable('0')
    loadedValue: ko.Observable<string> = ko.observable('0')
    minValue: ko.Observable<number> = ko.observable(0)
    maxValue: ko.Observable<number> = ko.observable(0)
    editorType: ko.Observable<string> = ko.observable('')
    isEditorActive: ko.Observable<boolean> = ko.observable(false)
    valueList: ko.ObservableArray = ko.observableArray([])
    isOverride: ko.Observable<boolean> = ko.observable(false)

    configurationSettingOverrideId: ko.Observable<number> = ko.observable(0)
    overrideValue: ko.Observable<string> = ko.observable('0')

    isOverridden: ko.PureComputed<boolean> = ko.pureComputed(() => {
        const value = parseInt(this.editValue().toString());
        const defaultValue = parseInt(this.defaultValue().toString());
        //console.debug('Setting Overridden?', 'value', value, 'defaultValue', defaultValue);

        if (this.editorType() === 'bitmask') this._loadSelectedOptions();

        return value !== defaultValue;
    })

    isRestored: ko.PureComputed<boolean> = ko.pureComputed(() => {
        const value = parseInt(this.editValue().toString());
        const defaultValue = parseInt(this.defaultValue().toString());
        const overrideId = parseInt(this.configurationSettingOverrideId().toString() ?? '0');

        return overrideId !== 0 && value === defaultValue;
    })

    needsUpdate: ko.PureComputed<boolean> = ko.pureComputed(() => {
        const value = parseInt(this.editValue().toString());
        const overrideValue = parseInt(this.overrideValue().toString());

        return (this.isOverridden() && !this.isRestored() && (!this.isOverride() || value !== overrideValue));
    })

    editClass: ko.PureComputed<string> = ko.pureComputed(() => {
        //
        //  This "var temp..." line is just to trigger a recompute if editValue changes 
        //      Shouldn't be necessary. Probably isn't.
        //
        var temp = this.editValue();
        return this.isOverridden() ? 'setting-override' : ''
    })

    configTemplateType: ko.Computed<string> = ko.computed(() => {
        let result;

        switch (this.editorType().toLowerCase()) {
            case 'time:minute':
                result = 'tod-minute';
                break;

            default:
                result = this.editorType().toLowerCase();
                break;
        }

        return `config-template-${result}`;
    })

    toggleEditor = () => {
        let value: boolean = !this.isEditorActive();

        if (this.parent?.closeConfigurationEditors !== undefined && typeof this.parent?.closeConfigurationEditors === 'function') {
            this.parent.closeConfigurationEditors();
        }

        this.isEditorActive(value);
    }

    showEditor = (e, sender) => {
        if (sender.currentTarget) {
            $(sender.currentTarget).next('.click-me').click();
        }
    }

    selectedOptions = ko.observableArray<any>(ko.utils.arrayMap(this.valueList(), (e) => e.key())).extend({
        rateLimit: {
            timeout: 50,
            method: 'notifyWhenChangesStop'
        }
    })

    private _loadSelectedOptions = () => {
        let temp = parseInt(this.editValue());
        let bitIndex = 0;

        this.selectedOptions.removeAll();

        if (!isNaN(temp)) {
            while (temp !== 0) {
                const value = Math.pow(2, bitIndex);
                if ((temp & value) === value) {
                    this.selectedOptions.push((bitIndex + 1).toString());
                    temp -= value;
                }
                bitIndex++;
            }
        }
    }

    private _recalculateValue = () => {
        let result = 0;

        for (let index = 0, length = this.selectedOptions().length; index < length; index++) {
            const bitIndex = parseInt(this.selectedOptions()[index].toString()) - 1;
            result |= Math.pow(2, bitIndex);
            //console.debug(`Result: ${result}, Bit: ${bitIndex}, Bit-Value: ${Math.pow(2, bitIndex)}`);
        }

        this.editValue(result.toString());
    }

    selectAll = () => this.valueList().forEach(item => this.selectedOptions.push(item.key().toString()))
    deselectAll = () => this.editValue('0')
    restore = () => this.editValue(this.defaultValue())
    undoChange = () => this.editValue(this.loadedValue())

    noClickHandler = (e, f) => f.stopPropagation()

    static dataFor = (setting): ConfigurationSetting => ko.dataFor(setting)

    constructor(data: any, parent: any) {
        mapping.fromJS(data, {}, this);

        if (parent !== undefined) {
            this.parent = parent;
        }

        this.editValue(this.configurationSettingOverrideId() !== 0
            ? this.overrideValue()
            : this.defaultValue());

        this.loadedValue(this.editValue());

        if (this.editorType() === 'bitmask') {
            this._loadSelectedOptions();

            this.selectedOptions.subscribe(_ => {
                this._recalculateValue();
            });

            //this.editValue.subscribe(_ => {
            //    this._loadSelectedOptions();
            //})
        }
       
    }

    addValidator = () => {
        this.editValue = Validation.addRangeS(this.editValue, new RangeValidationOptions(this.description(), this.minValue(), this.maxValue(), this.defaultValue()));
    }
}
