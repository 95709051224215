﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class BeaconGroup {
    
    facilityBeaconGroupId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
        }
    }

    protected saveZone(): Promise<object> {
        return new Promise((resolve, reject) => {

            $.post('/api/facility/beacon', {
                facilityBeaconGroupId: this.facilityBeaconGroupId(),
                description: this.description()
            })
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("BeaconZone::saveZone()", request, textStatus, error);
                    reject();
                })
        });
    }

}
