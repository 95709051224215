﻿import { CommonViewModel, DeviceType, EntityType } from "../Common"
const common: CommonViewModel = globalThis.DIG.Common

import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { ViewModelItem } from "../ViewModelItem"
import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"
import { DeviceAssignment } from "./DeviceAssignment"
import { Dialogs } from "../Dialogs"
import { isNullOrUndefined } from "util"

export class Device extends ViewModelItem {
    hasDetail: ko.Observable<boolean> = ko.observable(false)
    hasConfiguration: ko.Observable<boolean> = ko.observable(false)
    hasAssignments: ko.Observable<boolean> = ko.observable(false)
    hasValidators: ko.Observable<boolean> = ko.observable(false)

    deviceId: number
    deviceType = { id: 0, description: '' }
    type: DeviceType
    inmateId: number
    inmateIdentifier: string
    firstName: string
    lastName: string
    lastEventTimestamp: moment.Moment
    lastSTimer: number
    deviceEUI: string
    l21SerialNumber: string
    beaconGroupName: string
    configCrc: number
    shouldSendConfig: ko.Observable<boolean> = ko.observable(true)

    l21ProtocolVersion: number
    l21BuildVersion: number
    r35ProtocolVersion: number
    r35BuildVersion: number
    batteryLevel: number

    lastEventId: number
    isUtc: number

    location: ko.Observable<string> = ko.observable<string>('')

    configuration: any = ko.observableArray<ConfigurationSetting>([])
    assignments: any = []

    constructor(data?: object, view?: object) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('lastName')
            };
        }

        this.mapConfig.copy = ['deviceId', 'lastName', 'firstName', 'inmateIdentifier', 'deviceType', 'type', 'inmateId',
            'lastSTimer', 'deviceEUI', 'l21SerialNumber', 'l21ProtocolVersion', 'l21BuildVersion', 'r35ProtocolVersion',
            'r35BuildVersion', 'batteryLevel', 'lastEventId', 'isUtc', 'lastEventTimestamp', 'beaconGroupName', 'configCrc'];

        if (data) {
            mapping.fromJS(data, this.mapConfig, this);
        }
    }

    displayLastEventTimestamp: ko.PureComputed<string> = ko.pureComputed(() => {
        let last: moment.Moment = moment(this.lastEventTimestamp) || ''.toMoment();

        return last.isAfter(''.toMoment())
            ? globalThis.DIG.Common.toFacilityTime(last).format('M/D/YYYY h:mm:ss A')
            : '';
    });

    displayName: ko.PureComputed<string> = ko.pureComputed(() => {
        let result = '* Unassigned *';

        if (this.type === DeviceType.ILinkLocator) {
            if (!isNullOrUndefined(this.lastName)) {
                result = this.beaconGroupName;
            }
        }
        else if (this.type === DeviceType.OfficerSafetyDevice
                || this.type === DeviceType.OSDnRF) {

            if (!isNullOrUndefined(this.lastName)) {

                let sort = '';

                if (this.view.sort !== undefined) {
                    sort = this.view.sort().toLowerCase();
                }

                switch (sort) {
                    case 'first name':
                        result = `${this.firstName} ${this.lastName}`
                        break;

                    default:
                        result = `${this.lastName}, ${this.firstName}`
                        break;
                }
            }
        }
        else {
            if (this.inmateId !== 0) {
                let sort = '';

                if (this.view.sort !== undefined) {
                    sort = this.view.sort().toLowerCase();
                }

                switch (sort) {
                    case 'inmate id':
                        result = `${this.inmateIdentifier} (${this.lastName}, ${this.firstName})`
                        break;

                    case 'first name':
                        result = `${this.firstName} ${this.lastName} (${this.inmateIdentifier})`
                        break;

                    default:
                        result = `${this.lastName}, ${this.firstName} (${this.inmateIdentifier})`
                        break;
                }
            }
        }
        
        return result;
    });

    displayType: ko.PureComputed<string> = ko.pureComputed(() => {
        return common.customStrings.find(e => e.entityType == EntityType.Device && e.entityId == this.type).shortDescription;
    });

    displayL21Version: ko.PureComputed<string> = ko.pureComputed(() => `${this.l21ProtocolVersion}.${this.l21BuildVersion}`);
    displayR35Version: ko.PureComputed<string> = ko.pureComputed(() => `${this.r35ProtocolVersion}.${this.r35BuildVersion}`);

    getDetail = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/device/${this.deviceId}`,
                cache: false
            })
                .done(data => {
                    this.setDetail(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Device::getDetail', request, textStatus, error);
                    reject();
                })
        })
    };

    setDetail = (detail) => {
        mapping.fromJS(detail, this.mapConfig, this);
        this.hasDetail(true);
    };

    public selected = () => {
        if (!this.hasDetail()) {
            this.getDetail();
        }
    }

    getConfiguration = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/configuration/device/${this.deviceId}`,
                cache: false
            })
                .done(data => {
                    this.setConfiguration(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Device::getConfiguration', request, textStatus, error);
                    reject();
                })
        })
    };

    private setConfiguration = (data) => {
        const configurationMap = {
            key: (setting) => ko.utils.unwrapObservable(setting.configurationSettingId),
            create: (setting) => new ConfigurationSetting(setting.data, this)
        };

        this.configuration = mapping.fromJS(data, configurationMap);
        this.hasConfiguration(true);
    };

    getAssignments = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/device/${this.deviceId}/assignments`,
                cache: false
            })
                .done(data => {
                    this.setAssignments(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Device::getAssignments', request, textStatus, error);
                    reject();
                })
        })
    };

    private setAssignments = (data) => {
        const configurationMap = {
            key: (assignment) => ko.utils.unwrapObservable(assignment.deviceAssignmentId),
            create: (assignment) => new DeviceAssignment(assignment.data)
        };

        this.assignments = mapping.fromJS(data, configurationMap);
        this.hasAssignments(true);
    };

    // subscribed to in DeviceDialog
    public toggleSendConfig(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: `/api/device/${this.deviceId}/shouldSendConfig`,
                method: 'POST',
                data: {
                    shouldSendConfig: this.shouldSendConfig()
                },
                cache: false,
            })
                .done(results => {

                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Device::toggleSendConfig()", request, textStatus, error);
                    reject();
                })


        });
    }


    clone = (): object => mapping.toJS(this, {
        ignore: ['clone', 'view']
    });

    toFormData = (): object => {
        //
        //  Today, this is not the place to assign an inmate (later perhaps)
        //
        const data = {
            configRestores: this._restoredConfigurations()
        }

        ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.needsUpdate())
            .forEach((value, index, array) => data[`cfg_${array[index].configurationSettingId()}`] = array[index].editValue());

        return common.objectToFormData(data);
    }

    private _restoredConfigurations: ko.PureComputed<string> = ko.pureComputed(() => {
        let result = '';
        ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.isRestored())
            .forEach((setting) => result += (result !== '' ? ',' : '') + setting.configurationSettingId().toString());
        return result;
    })

    static getById = async (deviceId: number): Promise<Device> => {
        const result = new Device();
        result.deviceId = deviceId;
        await result.getDetail();
        return result;
    }

    editDevice = () => {
        if (this.deviceId !== 0 && this.view?.deviceDialog) {
            this.view.deviceDialog.edit(this.deviceId);
        } else {
            Dialogs.editDevice(this.deviceId);
        }
    }

    editInmate = () => {
        if (this.inmateId !== 0) {
            event.stopPropagation();
            Dialogs.editInmate(this.inmateId);
        }
    }

    public compare(compareTo, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'device id':
                result = (this.deviceId - compareTo.deviceId) * direction;
                break;

            case 'type':
                result = this.displayType().localeCompare(compareTo.displayType()) * direction;
                break;

            case 'assignment':
                result = this.displayName().localeCompare(compareTo.displayName()) * direction;

                break;

            case 'inmate id':
                result = this.inmateIdentifier.localeCompare(compareTo.inmateIdentifier) * direction;
                break;

            case 'last event':
                let aDate: any = moment(this.lastEventTimestamp);
                let bDate: any = moment(compareTo.lastEventTimestamp);
                result = (aDate - bDate) * direction;
                break;

            case 'l21 version':
                result = (this.l21ProtocolVersion - compareTo.l21ProtocolVersion) * direction;
                if (result === 0) {
                    result = (this.l21BuildVersion - compareTo.l21BuildVersion) * direction;
                }
                break;

            case 'r35 version':
                result = (this.r35ProtocolVersion - compareTo.r35ProtocolVersion) * direction;
                if (result === 0) {
                    result = (this.r35BuildVersion - compareTo.r35BuildVersion) * direction;
                }
                break;

            case 'battery':
                result = (this.batteryLevel - compareTo.batteryLevel) * direction;
                break;

            case 'location':
                result = this.location().localeCompare(compareTo.location()) * direction;
                break;
        }

        return result;
    }
    
    public itemDescription(sort: string): string {
        switch (sort.toLowerCase()) {
            case 'device id': sort = 'deviceId'; break;
            case 'type': sort = 'type'; break;
            case 'l21 version': sort = 'displayL21Version'; break;
            case 'r35 version': sort = 'displayR35Version'; break;
            case 'battery': sort = 'batteryLevel'; break;
            case 'assignment': sort = 'displayName'; break;
            case 'last name': sort = 'displayName'; break;
            case 'first name': sort = 'displayName'; break;
            case 'inmate id': sort = 'inmateIdentifier'; break;
            case 'last event': sort = 'displayLastEventTimestamp'; break;
            case 'location': sort = 'location'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public isMatch(filter: string, deviceTypes?: string[]): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            } 

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');

            if (this.type === DeviceType.ILinkLocator) {
                result = regexName1.test(this.deviceId.toString())
                    || regexName1.test(this.beaconGroupName);
            }
            else {
                result = (regexName1.test(this.firstName) && regexName2.test(this.lastName))
                    || (regexName1.test(this.lastName) && regexName2.test(this.firstName))
                    || regexName1.test(this.deviceId.toString()) //regexId.test(this.deviceId.toString())
                    || regexName1.test(this.inmateIdentifier) //regexId.test(this.inmateIdentifier)
                    || regexName1.test(this.location());
            }

        }

        if (result && (deviceTypes ?? []).length > 0) {
            result = deviceTypes.indexOf(this.type.toString()) > -1;
        }

        return result;
    }

    public getId = (): number => this.deviceId;
}
