﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import Common = require("../Common")
import { LoadingIndicator } from "../LoadingIndicator"

import { Dialogs } from "../Dialogs"
import { InmateLocationInfo } from "../Inmates/InmateLocationInfo"

const common: Common.CommonViewModel = globalThis.DIG.Common;

ko.components.register('PresentInmateWidget', {
    viewModel: function (params) {
        globalThis.widget = this;

        this.dialogOptions = {
            isReadOnly: false,
            showAlarms: true,
            showConfiguration: false,
            showTemp: true,
            showLocation: true,
            allowEdit: true,
            onSave: null,
            onShown: null,
            onHidden: null,
            onDeactivate: null
        }

        this.containerId = common.uniqueId();
        this.loading = new LoadingIndicator($(`#${this.containerId}`));
        this.zoneId = params.buildingId || 0;
        this.overflow = params.overflow || 'clip';
        this.columns = params.columns || 4;

        this.historyViewMode = ko.observable<number>(0);
        this.historyTitle = ko.observable<string>("Access History");
        this.inmates = ko.observableArray<InmateLocationInfo>([]);
        this.history = ko.observableArray<InmateLocationInfo>([]);

        //this.inmates = ko.observableArray<InmateLocationPresence>([]);
        this.bump = ko.observable<boolean>(false);

        this.sorted = ko.computed(() => {
            var bumpRead = this.bump();
            return this.inmates(); //.sort((a, b) => a.whenEntered.compare(b.whenEntered));
        });

        this.sortedHistory = ko.computed(() => {
            var bumpRead = this.bump();
            return this.history(); //.sort((a, b) => a.whenEntered.compare(b.whenEntered));
        });

        this.widgetClass = ko.computed(() => {
            var bumpRead = this.bump();
            //return this.inmates().length == 0 ? 'status-good' : 'status-bad'
            return 'status-good';
        });

        this.fetchCurrentData = () => {
            this.loading.show();

            let unauthorizedOnly: boolean = false;
            
            $.get(`/api/dashboard/location/${this.zoneId}/access`, { unauthorizedonly: unauthorizedOnly })
                .done(results => this.setCurrentData(results))
                .fail((a, b, c) => { console.error("PresentInmateWidget::fetchCurrentData()", a, b, c) })
                .always(_ => this.loading.hide());
        };

        this.setCurrentData = (data) => {
            const presentMap = {
                key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
                create: (inmate) => new InmateLocationInfo(inmate.data)
            };
            this.inmates = mapping.fromJS(data, presentMap);

            this.bump(!this.bump());
        };

        this.fetchHistoryData = () => {
            this.loading.show();

            let unauthorizedOnly: boolean = false;
            let hours: number = -24;

            switch (this.historyViewMode()) {
                case 0: {
                    unauthorizedOnly = false;
                    hours = -24;
                    this.historyTitle("Access History (24 Hours)");
                    break;
                }
                case 1: {
                    unauthorizedOnly = false;
                    hours = -48;
                    this.historyTitle("Access History (48 Hours)");
                    break;
                }
                case 2: {
                    unauthorizedOnly = false;
                    hours = -72;
                    this.historyTitle("Access History (72 Hours)");
                    break;
                }
            }


            let endTime: moment.Moment = globalThis.DIG.Common.toFacilityTime(moment.utc());
            let startTime: moment.Moment = moment(endTime).add(hours, 'hour');

            $.get(`/api/dashboard/location/${this.zoneId}/accesshistory`, {
                unauthorizedonly: unauthorizedOnly,
                startTime: startTime.format('MM/DD/YYYY hh:mm A'),
                endTime: endTime.format('MM/DD/YYYY hh:mm A')
            })
                .done(results => this.setHistoryData(results))
                .fail((a, b, c) => { console.error("PresentInmateWidget::fetchHistoryData()", a, b, c) })
                .always(_ => this.loading.hide());
        };

        this.setHistoryData = (data) => {
            const historyMap = {
                //key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
                create: (inmate) => new InmateLocationInfo(inmate.data)
            };
            this.history = mapping.fromJS(data, historyMap);

            this.bump(!this.bump());
        };

        this.fetchData = () => {
            this.fetchCurrentData();
            this.fetchHistoryData();
        };

        this.changeHistoryViewMode = (a, b) => {
            const id: number = parseInt(b.target.dataset.view);

            //this.saveSetting("DormHistoryView", this.historyViewMode())

            this.historyViewMode(id);
        }

        this.inmateClass = (index): string => {
            const width: number = Math.floor(12 / this.columns);
            return ((index() + 1) % this.columns) == 0 ? `col-${width} pt-1 px-0` : `col-${width} pb-0 pl-0 pr-1 pt-1`;
        }

        this.inmateLabel = (): string => {
            return common.customStrings.find(e => e.customStringType == 41).description; //41 is label_inmate consider making enum
        };

        this.identifierLabel = (): string => {
            return common.customStrings.find(e => e.customStringType == 30).description; //30 is indentifier consider making enum
        };

        this.titleLabel = (): string => {
            return "Present " + this.inmateLabel();
        }

        this.editInmate = (data: InmateLocationInfo, event) => {
            //event.stopPropagation();
            Dialogs.editInmate(data.inmateId(), this.dialogOptions);
        }

        this._inmateSaved = (inmateId, results) => {

            common.toast("success", this.inmateLabel() + " Data Saved", 'Update ' + this.inmateLabel());
            
        }

        this.historyViewMode.subscribe(_ => this.fetchHistoryData());

        this.fetchData();

        this.dialogOptions.onSave = this._inmateSaved;

        window.setInterval(() => this.fetchData(), 15000);
    },

    template:
        '<style> \
            .dw .status-good { background: var(--success); background: linear-gradient(90deg, rgba(0,255,8,0.3) 0%, rgba(16,185,47,1) 50%, rgba(0,255,8,0.3) 100%); } \
            .dw .status-bad { background: var(--danger); background: linear-gradient(90deg, rgba(255,0,0,0.4) 0%, rgba(225,19,19,1) 50%, rgba(255,0,0,0.4) 100%); } \
            .dw .status-neutral { background: rgb(0,0,0); background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(173,173,173,1) 50%, rgba(0,0,0,0.4) 100%); } \
            .dw .darker { background-color: #1c2327; } \
        </style> \
        <div class="dw d-flex flex-column" style="position: absolute; right: 0px; left: 0px; bottom: 0px; top: 15px;"> \
            <div data-bind="class: widgetClass, text: titleLabel()" class="pt-1 px-2" style="text-align: center; font-size: 1.6rem; font-weight: 900;"></div> \
            <div data-bind="foreach: sorted" class="flex-fill d-flex flex-wrap flex-grow-0" style="font-size: 1rem; font-weight: 600"> \
                <div data-bind="class: $parent.inmateClass($index), click: $parent.editInmate" style="cursor: pointer;"> \
                    <div class="d-flex" style="background-color: silver; border: silver solid 3px;"> \
                        <div style="width: 120px;height: 120px;"><img data-bind="attr: { src: imageSource }" style="max-height: 120px; max-width: 120px;" /></div> \
                        <div class="d-flex flex-fill flex-column p-2" style="height: 109px; align-self: center;"> \
                            <div data-bind="text: displayInmateName" style="font-size: 1.1rem;"></div> \
                            <div data-bind="text: inmateIdentifier"></div> \
                            <div data-bind="text: displayDetailedDorm" class="mt-auto"></div> \
                        </div> \
                    </div> \
                </div> \
            </div> \
            <div class="mt-4 pt-1 px-2 status-neutral d-flex"> \
                <div class="mx-auto" style="text-align: center; font-size: 1.6rem; font-weight: 900;" data-bind="text: historyTitle"></div>  \
                <a href="#!" role="button" class="my-auto" data-toggle="dropdown"><i class="fas fa-cog text-white" style="font-size: 1.6rem;"></i></a> \
                <div class="p-0 dropdown-menu dropdown-menu-right allow-focus bg-silver dropdown-menu-shadow"> \
                        <div class="bg-dark text-white pl-2" style="font-weight: 700; line-height: 2.5rem">View Options</div> \
                        <div> \
                            <a href="#!" data-view="0" class="dropdown-item" data-bind="class: historyViewMode() == 0 ? `active` : ``, click: changeHistoryViewMode">24Hrs</a> \
                            <a href="#!" data-view="1" class="dropdown-item" data-bind="class: historyViewMode() == 1 ? `active` : ``, click: changeHistoryViewMode">48Hrs</a> \
                            <a href="#!" data-view="2" class="dropdown-item" data-bind="class: historyViewMode() == 2 ? `active` : ``, click: changeHistoryViewMode">72Hrs</a> \
                        </div> \
                </div> \
            </div> \
            <div data-bind="attr: {id: containerId}" class="d-flex flex-fill" style="color: silver; overflow-y: auto;"> \
                <table class="dig-table dark"> \
                    <thead class="header" style="font-weight: bold; min-width: 100%; border-bottom: silver 1px solid;"> \
                        <td class="dig-col flex-fill pr-2" style="width: 100%; min-width: 180px;" data-bind="text: inmateLabel()"></td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 100px; max-width: 100px;" data-bind="text: identifierLabel()"></td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 160px; max-width: 160px;">Entered</td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 160px; max-width: 160px;">Exited</td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 110px; max-width: 110px;">Duration</td> \
                        <td class="dig-col" style="text-align: left; min-width: 140px; max-width: 140px;">Unit</td> \
                    </thead> \
                    <!-- ko foreach: sortedHistory --> \
                    <tbody style="font-size: 1.2rem; line-height: 1.3rem; height: 32px;"> \
                        <tr class="summary no-hover"> \
                            <td class="dig-col flex-fill pr-2" style="width: 100%; min-width: 180px; text-overflow: ellipsis;" data-bind="text: displayInmateName"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 100px; max-width: 100px;" data-bind="text: inmateIdentifier"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 160px; max-width: 160px;" data-bind="text: displayEnteredTime"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 160px; max-width: 160px;" data-bind="text: displayExitedTime"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 110px; max-width: 110px;" data-bind="text: displayDuration"></td> \
                            <td class="dig-col" style="text-align: left; min-width: 140px; max-width: 140px; text-overflow: ellipsis;" data-bind="text: displayDetailedDorm"></td> \
                        </tr> \
                    </tbody> \
                    <!-- /ko --> \
                    <tbody style="font-size: 1.2rem; line-height: 1.3rem;"> \
                        <tr class="summary no-hover"> \
                            <td class="dig-col flex-fill pr-2" style="width: 100%; min-width: 180px; text-overflow: ellipsis;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 100px; max-width: 100px;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 160px; max-width: 160px;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 160px; max-width: 160px;"></td> \
                            <td class="dig-col pr-2" style="text-align: left; min-width: 110px; max-width: 110px;"></td> \
                            <td class="dig-col" style="text-align: left; min-width: 140px; max-width: 140px; text-overflow: ellipsis;"></td> \
                        </tr> \
                    </tbody> \
                </table> \
            </div> \
        </div>'
})