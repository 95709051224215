﻿import ko = require("knockout")

export interface ViewModelItem {
    itemSelected?(sender): void;
    activated?(): void;
}

export abstract class ViewModelItem {
    //
    //  Properties
    //
    public view

    hasValidators: ko.Observable<boolean> = ko.observable(false)
    isSelected: ko.Observable<boolean> = ko.observable(false)
    allowSelect: ko.Observable<boolean> = ko.observable(true)

    public mapConfig = {
        key: (item) => ko.utils.unwrapObservable(item.getId()),
        observe: [],
        ignore: [],
        copy: []
    }

    //
    //  Methods
    //
    public abstract compare(compareTo, sort: string, direction: number): number
    public abstract itemDescription(sort: string): string
    public abstract isMatch(viewModel: any): boolean
    public abstract getId(): number

    toggle = () => {
        const e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        if (this.allowSelect()) {
            this.isSelected(!this.isSelected());

            if (this.view?.itemSelected !== undefined && typeof (this.view?.itemSelected) === 'function') {
                this.view.itemSelected(this);
            }
        }
    }

    detailRowClass: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let result = "";

        if (typeof this.getId == 'function' && typeof this.view?.activeId == 'function' ) {
            result = this.getId() == this.view.activeId()
                ? 'active'
                : (this.view?.options.detail.enabled ?? false) ? 'no-bold' : '';

            if (this.view.options.detail.expandAllRows) {
                result = "active all simple";
            }
        }
        return result;
    })
}
