﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")

/*import * as $ from 'jquery'*/
import $ from 'jquery'

import { CommonViewModel } from "../Common"
import { LoadingIndicator } from "../LoadingIndicator"
import { InmateDialogModel } from "../Inmates/InmateDialog"

import { RollCallInmate } from "./RollCallInmate"

const common: CommonViewModel = globalThis.DIG.Common

export class RollCallsViewModel {
    inmates: ko.ObservableArray<RollCallInmate> = ko.observableArray([])

    loading: LoadingIndicator = new LoadingIndicator($('#rollCallView'))

    inmateDialog: InmateDialogModel = new InmateDialogModel({
        isReadOnly: false,
        showAlarms: false,
        showConfiguration: true,
        allowEdit: true
    });

    rollCallId: ko.Observable<number> = ko.observable<number>(0)
    deviceCount: ko.Observable<number> = ko.observable<number>(0)
    reportedCount: ko.Observable<number> = ko.observable<number>(0)
    durationMinutes: ko.Observable<number> = ko.observable<number>(0)
    startTime: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(moment.utc())
    stopTime: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(null)
    nextRollCallTime: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(null)

    previousRollCallId: ko.Observable<number> = ko.observable(0)
    previousStartTime: ko.Observable<string> = ko.observable('')
    nextRollCallId: ko.Observable<number> = ko.observable(0)
    nextStartTime: ko.Observable<string> = ko.observable('')

    percentComplete: ko.Observable<number> = ko.observable<number>(0)
    remaining: ko.Observable<string> = ko.observable<string>('')
    statusClass: ko.Observable<string> = ko.observable<string>('bg-secondary');
    statusTextColor: ko.Observable<string> = ko.observable<string>('#fff');

    lastRefreshTime: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(null)
    bumpTime: ko.Observable<boolean> = ko.observable<boolean>(false)
    changed: ko.Observable<boolean> = ko.observable<boolean>(false)
    timerPaused = false
    refreshTimer: number
    nextRefreshTime: moment.Moment

    displayStartTime: ko.PureComputed<string> = ko.pureComputed(() => common.formatTime(this.startTime()))
    displayStopTime: ko.PureComputed<string> = ko.pureComputed(() => common.formatTime(this.stopTime()))
    displayNextTime: ko.PureComputed<string> = ko.pureComputed(() => this.nextRollCallTime() !== null ? common.formatTime(this.nextRollCallTime(), undefined, undefined, false) : '')

    displayStatusText: ko.PureComputed<string> = ko.pureComputed(() => `${this.percentComplete()}% (${this.reportedCount()} of ${this.deviceCount()})`)

    displayLastRefreshTime: ko.Computed<string> = ko.computed<string>(() => {
        return this.lastRefreshTime() !== null
            ? this.lastRefreshTime().format('MMMM Do YYYY, h:mm:ss A')
            : '';
    })

    pending: ko.PureComputed<RollCallInmate[]> = ko.pureComputed(() => {
        const temp = this.changed();
        return ko.utils.arrayFilter(this.inmates(), (i: RollCallInmate) => !i.hasReported()).sort(this.sortLastEventTime);
    })

    reported: ko.PureComputed<RollCallInmate[]> = ko.pureComputed(() => {
        const temp = this.changed();
        return ko.utils.arrayFilter(this.inmates(), (i: RollCallInmate) => i.hasReported()).sort(this.sortReportedTime);
    })

    lastReportedTimestamp = () => {
        const time = this.reported().length === 0
            ? this.startTime()
            : this.reported().slice(-1)[0].reportedTimestamp();

        return moment(time).toISOString();
    }

    duration: ko.PureComputed<string> = ko.pureComputed(() => {
        const temp = this.bumpTime();

        let elapsed: moment.Moment = moment.utc(0);
        const start = moment.utc(this.startTime());
        const current = moment().utc();
        let status = 'bg-silver';

        if (this.stopTime() === null) {
            const diff = moment.duration(current.diff(start)).as('milliseconds');
            elapsed = moment.utc(diff);

            //TODO: Instead of setting remaining and stoptime if < 0, it should probably call the server for a data refresh
            const remaining = (this.durationMinutes() * 60000) - diff;
            if (remaining > 0) {
                this.remaining(moment.utc(remaining).format('HH:mm:ss'));
            } else {
                this.remaining('');
                this.stopTime(moment.utc(this.startTime()).add(this.durationMinutes(), 'minute'));
            }
        } else {
            const stop = moment.utc(this.stopTime());
            elapsed = moment.utc(moment.duration(stop.diff(start)).as('milliseconds'));

            this.remaining('');

            status = this.reportedCount() < this.deviceCount()
                ? 'bg-danger'
                : 'bg-success';
        }

        this.statusClass(status);
        this.statusTextColor(status === 'bg-warning' || status === 'bg-silver' ? '#000' : '#fff');

        return elapsed.format('HH:mm:ss');
    })

    constructor() {
        common.rollCallStarted = this.getLastRollCall;
    }

    init = () => {
        ko.applyBindings(this);
        this.getLastRollCall();
    }

    getLastRollCall = () => {
        //
        //  Show the loading indicator immediately
        //
        this.loading.show(0);

        $.get('/api/rollcall/last')
            .done(data => this.setRollCallData(data))
            .fail((request, textStatus, error) => alert(error))
            .always(() => this.loading.hide());
    }

    refresh = () => {
        this.bumpTime(!this.bumpTime());

        if (!this.timerPaused && moment.utc().diff(this.nextRefreshTime) >= 0) {
            this.timerPaused = true;

            //
            //  Allow the default delay to occur first
            //
            this.loading.show();

            $.get(`/api/rollcall/${this.rollCallId()}/refresh`, { since: this.lastReportedTimestamp() })
                .done(data => this.refreshData(data))
                .fail((request, textStatus, error) => alert(error))
                .always(() => this.loading.hide());
        }
    }

    setRollCallData = (data) => {
        //
        //  Remove the existing timer if we have one
        //
        try { clearInterval(this.refreshTimer) } catch { /* */ }

        if (data !== null) {
            //
            //  Map roll call summary data
            //
            mapping.fromJS(data, { ignore: ['inmates', 'previous', 'next'] }, this);

            //
            //  Map inmates
            //
            this.inmates = mapping.fromJS(data.inmates, {
                key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
                create: (inmate) => new RollCallInmate(inmate.data, this)
            });

            //
            //  Map next and previous
            //
            if (data.previous !== null) {
                this.previousRollCallId(data.previous.key);
                this.previousStartTime(data.previous.value);
            } else {
                this.previousRollCallId(0);
            }

            if (data.next !== null) {
                this.nextRollCallId(data.next.key);
                this.nextStartTime(data.next.value);
            } else {
                this.nextRollCallId(0);
            }

            //
            //  Handle refresh stuff
            //
            this.lastRefreshTime(moment());

            if (this.stopTime() === null) {
                this.nextRefreshTime = moment.utc().add(5, 'seconds');
                this.refreshTimer = window.setInterval(this.refresh, 1000);
                this.timerPaused = false;
            }

            this.changed(!this.changed());
        }
    }

    refreshData = (data) => {
        this.deviceCount(data.deviceCount);
        this.reportedCount(data.reportedCount);
        this.stopTime(data.stopTime);
        this.percentComplete(data.percentComplete);

        data.changes.forEach((inmateData) => {
            const inmate: RollCallInmate = this.findInmate(inmateData.key);

            if (inmate.reportedTimestamp() === null) {
                const listItem: JQuery = $(`#pi_${inmate.inmateId()}`);
                if (listItem !== null) {
                    const flyer = listItem.clone();
                    flyer.addClass('flyer');

                    const bodyOffset: JQueryCoordinates = $($('.body-content')[0]).offset();
                    const parent: any = listItem[0].parentElement;
                    let fadeDuration = 400;

                    let left: number = bodyOffset.left + 10;

                    const maxTop: number = parent.clientHeight + parent.offsetTop + bodyOffset.top;
                    let top: number = bodyOffset.top + listItem.position().top + 10;

                    if (top > maxTop) {
                        top = maxTop;
                        left += left / 2;
                        fadeDuration = 0;
                    }

                    const destLeft: number = $('#reportedList').offset().left;
                    let destTop: number = parent.offsetTop + bodyOffset.top;
                    destTop += (maxTop - destTop) / 2;

                    const table = $($.parseHTML(`<table id="ft_${inmate.inmateId()}" style="position: absolute; display: table-row;"></table>`));
                    table.css({ top: top, left: left, 'background-color': 'steelblue', opacity: 0.0, width: parent.clientWidth });

                    table.append(flyer);
                    $('body').append(table[0]);

                    table.delay(Math.ceil(Math.random() * 500))
                        .animate({ opacity: 1.0 }, fadeDuration, 'swing')
                        .animate({ top: destTop, left: destLeft, opacity: 0.0 }, 900, 'swing', () => {
                            flyer.remove();
                            table.remove();

                            inmate.reportedTimestamp(inmateData.value);
                        });
                } else {
                    //
                    //  Can't seem to find the object, just mark it
                    //
                    inmate.reportedTimestamp(inmateData.value);
                }
            }
        });

        if (data.changes.length > 0) {
            this.changed(!this.changed());
        }

        this.lastRefreshTime(moment());

        if (this.stopTime() !== null) {
            clearInterval(this.refreshTimer);
        } else {
            this.nextRefreshTime = moment.utc().add(5, 'seconds');
            this.timerPaused = false;
        }
    }

    findInmate = (inmateId: number) => {
        return ko.utils.arrayFirst<RollCallInmate>(this.inmates(), (inmate: RollCallInmate) => inmate.inmateId() == inmateId);
    }

    dataFor = (inmate) => ko.dataFor(inmate)

    sortReportedTime = (a: RollCallInmate, b: RollCallInmate) => {
        let aDate: any = moment(a.reportedTimestamp());
        let bDate: any = moment(b.reportedTimestamp());
        return aDate - bDate;
    }

    sortLastEventTime = (a: RollCallInmate, b: RollCallInmate) => {
        let aDate: any = moment(a.lastEventTimestamp());
        let bDate: any = moment(b.lastEventTimestamp());
        return aDate - bDate;
    }

    afterRenderReported = () => {
        this.scrollToBottom($('#reportedList'));
    }

    scrollToBottom = (list: JQuery) => {
        //
        //  Using setTimeout to allow the final record to render before the scroll
        //      occurs. Without this it will always scroll one up from the end.
        //
        setTimeout(() => {
            list.scrollTop(list[0].scrollHeight);
        }, 1);
    }

    refreshNow = () => {
        this.loading.show(0);
        this.nextRefreshTime = moment();
        this.refresh();
    }

    next = () => {
        this.loading.show(0);

        $.get(`/api/rollcall/${this.nextRollCallId()}`)
            .done(data => this.setRollCallData(data))
            .fail((request, textStatus, error) => alert(error))
            .always(() => this.loading.hide());
    }

    previous = () => {
        this.loading.show(0);

        $.get(`/api/rollcall/${this.previousRollCallId()}`)
            .done(data => this.setRollCallData(data))
            .fail((request, textStatus, error) => alert(error))
            .always(() => this.loading.hide());
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.RollCalls === undefined) {
    globalThis.DIG.RollCalls = () => { /* */ };
}

globalThis.DIG.RollCalls.ViewModel = RollCallsViewModel