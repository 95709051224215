﻿import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"
import { Dialogs } from "../Dialogs"

const common: CommonViewModel = globalThis.DIG.Common

export class AlarmHistoryItem {
    view: any
    alarmId: number
    customEventDescription: string
    eventType: number
    alarmTimestamp: string
    isDismissed: boolean //ko.Observable<boolean>

    constructor(data: any, view: any) {
        this.view = view;

        mapping.fromJS(data, {
            copy: ['alarmId', 'eventType', 'customEventDescription', 'alarmTimestamp', 'isDismissed']
        }, this);
    }

    displayAlarmTime: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.alarmTimestamp);
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    });

    displayAlarmTimePlain: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.alarmTimestamp);
        return time.format('M/D/YYYY h:mm:ss A');
    });

    public edit = () => this.view?.alarmDialog?.edit(this.alarmId);

}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Alarms === undefined) {
    globalThis.DIG.Alarms = () => { };
}
