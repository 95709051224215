﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")

import Loading = require("../LoadingIndicator")
import { DashboardAlarm } from "./DashboardAlarm"
import { AlarmDialogModel } from "../Alarms/AlarmDialog"

export class UTCList {
    utcs: any = ko.observableArray<DashboardAlarm>([])
    loading: Loading.LoadingIndicator

    //
    //  Paging properties
    //
    page: ko.Observable<number> = ko.observable(-1);
    direction: ko.Observable<number> = ko.observable(-1);
    startIndex: ko.Observable<number> = ko.observable(-1);
    endIndex: ko.Observable<number> = ko.observable(-1);
    pageSize: ko.Observable<string> = ko.observable('20');
    sort: ko.Observable<string> = ko.observable('alarmTimestamp');

    alarmDialog: AlarmDialogModel = new AlarmDialogModel({
        allowDismiss: true
    })

    constructor(container: any) {
        this.loading = new Loading.LoadingIndicator(container);
    }

    recordCount: ko.PureComputed<number> = ko.pureComputed(() => {
        return this.sorted().length;
    });

    pageCount: ko.PureComputed<number> = ko.pureComputed(() => {
        return Math.ceil(this.recordCount() / parseInt(this.pageSize()));
    });

    sorted: ko.Computed<any> = ko.computed(() => {
        var pageSizeRead = this.pageSize();
        var sortRead = this.sort();
        var directionRead = this.direction();

        this.page(0);

        return this.utcs().sort((a, b) => {
            let aDate: any = moment(a[this.sort()]);
            let bDate: any = moment(b[this.sort()]);
            return (aDate - bDate) * this.direction();
        });
    });

    dataPage: ko.PureComputed<any> = ko.pureComputed(() => {
        let page: number = this.page();
        let pageSize: number = parseInt(this.pageSize());

        let startIndex: number = page * pageSize;
        let endIndex: number = startIndex + pageSize;

        this.startIndex(startIndex < 0 ? 0 : Math.min(startIndex + 1, this.sorted().length));
        this.endIndex(Math.min(endIndex, this.sorted().length));

        return this.sorted().slice(startIndex, endIndex);
    });

    getUTCs() {
        this.loading.show();

        $.get('/api/alarm/utcs')
            .done(results => this.setUTCs(results))
            .fail((a, b, c) => { console.error("UTCList::getUTCs()", a, b, c) })
            .always(_ => this.loading.hide());
    };

    setUTCs = (utcs) => {
        const utcMap = {
            key: (utc) => ko.utils.unwrapObservable(utc.alarmId),
            create: (utc) => new DashboardAlarm(utc.data, this)
        };

        this.utcs = mapping.fromJS(utcs, utcMap);
        this.sort.valueHasMutated();
    };

    allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.page() < this.pageCount() - 1;
    });

    allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.page() > 0;
    });

    previous = () => {
        if (this.allowPrevious()) {
            this.page(this.page() - 1);
        }
    };

    next = () => {
        if (this.allowNext()) {
            this.page(this.page() + 1);
        }
    };

    dataFor = (alarm) => ko.dataFor(alarm);
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Dashboard === undefined) {
    globalThis.DIG.Dashboard = () => { };
}

globalThis.DIG.Dashboard.UTCList = UTCList;