﻿import moment = require("moment-timezone")
import { isNullOrUndefined } from "util"

declare global {
    interface String {
        toMoment(inputFormat?: string): moment.Moment;
    }

    module moment {
        export interface Moment {
            compare(compareTo: moment.Moment): number;
            default(defaultValue: moment.Moment | string): moment.Moment;
            cap(defaultValue: moment.Moment | string): moment.Moment;
        }
    }

    interface Array<T> {
        getByEntity(entityTypeId: number, entityId: number): T;
    }
}

String.prototype.toMoment = function (this: string, inputFormat?: string): moment.Moment {
    let value = this;

    if (isNullOrUndefined(value) || value.trim() === '') {
        value = '0001-01-01T00:00:00+00:00'
    }

    return isNullOrUndefined(inputFormat)
        ? moment(value)
        : moment(value, inputFormat);
}

moment.fn.compare = function (this: moment.Moment | string, compareTo: moment.Moment | string): number {
    let result = 0;
    let aDate: moment.Moment = moment(this);
    let bDate: moment.Moment = moment(compareTo);

    if (isNullOrUndefined(this) || !aDate.isValid) {
        aDate = moment('0001-01-01T00:00:00+00:00');
    }

    if (isNullOrUndefined(compareTo) || !bDate.isValid) {
        bDate = moment('0001-01-01T00:00:00+00:00');
    }

    if (!aDate.isSame(compareTo)) {
        result = aDate.isAfter(compareTo)
            ? 1
            : -1;
    }

    return result;
}

moment.fn.default = function (this: moment.Moment, defaultValue: moment.Moment | string): moment.Moment {
    return (isNullOrUndefined(this) || !this.isValid)
        ? moment(defaultValue).clone()
        : this;
}

moment.fn.cap = function (this: moment.Moment, cap: moment.Moment | string): moment.Moment {
    return this.isAfter(cap)
        ? moment(cap).clone()
        : this;
}

Array.prototype.getByEntity = function (entityTypeId: number, entityId: number) {
    const result = this.find(item => item.entityType == entityTypeId && item.entityId == entityId);
    return result;
}
