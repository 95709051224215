﻿import ko = require('knockout')
import mapping = require('knockout.mapping')

import { AlarmEvent } from "./AlarmEvent"
import { Dialogs } from "../Dialogs"

export class AlarmInmate {
    view //: any
    alarm: AlarmEvent
    alarmId: ko.Observable<number>
    deviceId: ko.Observable<number>
    inmateId: ko.Observable<number>
    lastName: ko.Observable<string>
    firstName: ko.Observable<string>
    location: ko.Observable<string>
    inmateIdentifier: ko.Observable<string>
    startEvent: AlarmEvent
    clearEvent: AlarmEvent

    constructor(data, alarm, view) {
        this.alarm = alarm;
        this.view = view;

        mapping.fromJS(data, {}, this);
    }

    displayName: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let prettyLocation = '';
        if (this.location() != undefined && this.location().length > 0) {
            prettyLocation = ` (${this.location()})`;
        }

        return this.view.sort == undefined || this.view.sort() == 'firstName'
            ? `${this.firstName()} ${this.lastName()}${prettyLocation}`
            : `${this.lastName()}, ${this.firstName()}${prettyLocation}`;
    });

    displayNameNoLocation: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        
        return this.view.sort == undefined || this.view.sort() == 'firstName'
            ? `${this.firstName()} ${this.lastName()}`
            : `${this.lastName()}, ${this.firstName()}`;
    });

    imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)

    editInmate = () => {
        event.stopPropagation();
        Dialogs.editInmate(this.inmateId());
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Alarms ??= () => { /* */ };
globalThis.DIG.Alarms.AlarmInmate = AlarmInmate;
