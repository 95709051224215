﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")
import { BeaconGroup } from './BeaconGroup';

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class BeaconGroupViewModel {

    beaconGroups: ko.ObservableArray<BeaconGroup> = ko.observableArray<BeaconGroup>([])
    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    beaconGroupData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.beaconGroups();
    });

    fetchBeaconGroupData = () => {
        $.get(`/api/facility/beacon/list`)
            .done(results => this.setBeaconGroupData(results))
            .fail((a, b, c) => { console.error("BeaconGroup::fetchData()", a, b, c) })
    }

    setBeaconGroupData = (data) => {
        const presentMap = {
            //key: (bzDt) => ko.utils.unwrapObservable(bzDt.facilityBeaconGroupId),
            create: (bzDt) => new BeaconGroup(bzDt.data)
        };
        this.beaconGroups = mapping.fromJS(data, presentMap);

        this.bump(!this.bump());
    }

    init = () => {
        ko.applyBindings(this);

        this.fetchBeaconGroupData();
    }
}
globalThis.DIG ??= () => { /* */ };
globalThis.DIG.BeaconGroup ??= () => { /* */ };
globalThis.DIG.BeaconGroup.ViewModel = BeaconGroupViewModel;