﻿import Chart = require("chart.js")
import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { CommonViewModel } from "../Common"
import { TimeIntervalTypes } from "../TimeIntervalTypes"
import { LoadingIndicator } from "../LoadingIndicator"
import { EventTypeEnum } from "../EventTypes"

const common: CommonViewModel = globalThis.DIG.Common;

ko.components.register('AlarmChartWidget', {
    viewModel: function (params) {
        this.eventType = common.getEventTypeById(params.eventType || EventTypeEnum.Undefined);
        this.view = TimeIntervalTypes[params.view] || TimeIntervalTypes.Month;

        this.containerId = common.uniqueId();
        this.canvasId = common.uniqueId();
        this.when = ko.observable<moment.Moment>(common.FacilityEndDate.clone());
        this.loading = new LoadingIndicator($(`#${this.containerId}`));

        this.description = ko.computed(() => {
            return this.eventType !== undefined
                ? `${this.eventType.displayDescription()}s`
                : '';
        });

        this.displayDate = ko.pureComputed(() => {
            let date = '';

            switch (this.view) {
                case TimeIntervalTypes.Day: date = this.when().clone().format('MMM Do, YYYY'); break;
                default: date = this.when().clone().format('MMMM YYYY'); break;
            }

            return date;
        });

        this.setData = (data, animate?: boolean, date?: moment.Moment) => {
            if (date !== undefined) {
                this.when(date);
            }

            this.chart?.destroy();
            this.chart = this._newChart();
            this.chart.data = data;

            if (animate === undefined || animate) {
                this.chart.update();
            } else {
                this.chart.update({ duration: 0 });
            }
        };

        this.changeDate = (date) => {
            this.loading.show();

            $.ajax({
                url: '/api/alarm/dashboardchart',
                cache: false,
                method: 'POST',
                data: {
                    eventType: this.eventType.id,
                    intervalType: this.view,
                    when: date.format()
                },
                success: (results) => this.setData(results, true, date),
                error: (a, b, c) => { console.error("AlarmChartWidget::changeDate()", date, a, b, c); },
                complete: _ => this.loading.hide()
            });
        };

        this.refresh = () => {
            this.changeDate(common.FacilityEndDate.clone());
        }

        this.intervalString = () => {
            return $.isNumeric(this.view)
                ? TimeIntervalTypes[this.view]
                : this.view;
        }
        this.allowPrevious = ko.pureComputed<boolean>(() => {
            return this.when().clone().startOf(this.intervalString()).isAfter(common.FacilityStartDate);
        });

        this.previous = () => {
            if (this.allowPrevious()) {
                this.changeDate(this.when().add(-1, this.intervalString()));
            }
        }

        this.allowNext = ko.pureComputed<boolean>(() => {
            return this.when().clone().endOf(this.intervalString()).isBefore(common.FacilityEndDate);
        });

        this.next = () => {
            if (this.allowNext()) {
                this.changeDate(this.when().add(1, this.intervalString()));
            }
        };

        this._newChart = () => {
            return new Chart(this.canvasId, {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        label: `${this.eventType.description}s`,
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 1
                    }]
                },
                options: {
                    legend: { display: false },
                    scales: {
                        xAxes: [{
                            ticks: {
                                maxTicksLimit: 16,
                                minRotation: 0,
                                maxRotation: 0,
                                autoSkipPadding: 4
                            },
                            gridLines: {
                                display: false
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 10,
                                stepSize: 1.0
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            title: (items, data: any) => {
                                return moment(`${data.dataKey.slice(-6, -2)}-${data.dataKey.slice(-2)}-01`)
                                    .add(items[0].index, 'day')
                                    .format('MMMM Do, YYYY');
                            },
                            label: (item) => item.value
                        }
                    }
                }
            });
        }

        this.refresh();
    },

    template:
        '<div class="py-1 px-2 d-flex" style="background-color: royalblue; color: white; font-weight: bold;"> \
                <span data-bind="text: description"></span> \
                <span class="mx-auto" data-bind="text: displayDate"></span> \
                <span><a href="#" data-bind="click: previous"><i class="fas fa-angle-left" data-bind="class: allowPrevious() ? \'text-white\' : \'text-white-50\'"></i></a></span> \
                <span class="ml-2"><a href="#" data-bind="click: next"><i class="fas fa-angle-right" data-bind="class: allowNext() ? \'text-white\' : \'text-white-50\'"></i></a></span> \
                <span class="ml-2"><a href="#" data-bind="click: refresh"><i class="fas fa-sync-alt text-white"></i></a></span> \
            </div> \
            <div data-bind="attr: {id: containerId}" class="p-2" style="background-color: white;"> \
                <canvas data-bind= "attr: {id: canvasId}" width="400" height="209"></canvas> \
            </div> \
        </div>'
})