﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"
//import { InmatePresenceRcDialog } from "../Inmates/InmatePresenceRcDialog"
//import { InmateAssignmentRcDialog } from "../Inmates/InmateAssignmentRcDialog"
import { Dialogs, InmateAssignmentRcDialogDelegates, InmatePresenceRcDialogDelegates } from "../Dialogs"
import { Headcount } from "./Headcount"

const common: CommonViewModel = globalThis.DIG.Common

export class HeadcountInmate {

    onSave: Function = null;


    //view: any // view model where this class is displayed
    headcount: Headcount

    facilityBuildingId: ko.Observable<number>
    inmateId: ko.Observable<number>
    presenceTypeId: ko.Observable<number>
    presenceDescription: ko.Observable<string>
    exclude: ko.Observable<boolean>
    firstName: ko.Observable<string>
    lastName: ko.Observable<string>
    inmateIdentifier: ko.Observable<string>
    dormDescription: ko.Observable<string>
    block: ko.Observable<string>
    bunk: ko.Observable<string>
    lastDetectedTimestamp: moment.Moment = ''.toMoment()
    lastCallInTimestamp: moment.Moment = ''.toMoment()

    //rcAssignmentDialogOptions: InmateAssignmentRcDialogDelegates = {
    //    onSave: null
    //}

    //rcPresenceDialogOptions: InmatePresenceRcDialogDelegates = {
    //    onSave: null
    //}

    constructor(data: object, headcount: Headcount) {
        //this.view = view;
        this.headcount = headcount;

        mapping.fromJS(data, {}, this);
        this.lastDetectedTimestamp = moment(data["lastDetectedTimestamp"]);
        this.lastCallInTimestamp = moment(data["lastCallInTimestamp"]);

        //if (view) {
        //    this.rcAssignmentDialogOptions.onSave = this.view.afterRCDeviceChanged;
        //    this.rcPresenceDialogOptions.onSave = this.view.afterRCPresenceChanged;
        //}

        //this.rcAssignmentDialogOptions.onSave = this.localAfterRCDeviceChanged;
        //this.rcPresenceDialogOptions.onSave = this.localAfterRCPresenceChanged;

        //this.onSave = this.headcount.onChildSave
    }

    displayInmateName: ko.Computed<string> = ko.computed<string>(() => this.lastName() + ', ' + this.firstName())

    displayInmateDorm: ko.Computed<string> = ko.computed<string>(() => {

        if (this.headcount.isBuilding()) {

            let dorm = `${common.customStrings.find(e => e.customStringType == 42).description}: ${this.headcount.title()}`; //42 is dorm

            if (this.facilityBuildingId() > 0) {
                if (this.block() != null) {
                    dorm += `-${this.block()}`
                }
                if (this.bunk() != null) {
                    dorm += `-${this.bunk()}`
                }
            }

            return dorm;
        }
        else {
            return `${this.headcount.title()}`
        }

    });

    displayLastReportedTimestamp: ko.Computed<string> = ko.computed<string>(() => {

        if ( (!this.lastDetectedTimestamp.isValid()) && (!this.lastCallInTimestamp.isValid()) ) {
            return '';
        }

        if (!this.lastCallInTimestamp.isValid()) {
            this.lastCallInTimestamp = moment.utc("0001-01-01");
        }

        if (!this.lastDetectedTimestamp.isValid()) {
            this.lastDetectedTimestamp = moment.utc("0001-01-01");
        }

        if (this.lastDetectedTimestamp.isAfter(this.lastCallInTimestamp)) {
            return `${common.toFacilityTime(this.lastDetectedTimestamp).format('M/D/YYYY')} <span style="white-space: nowrap;">${common.toFacilityTime(this.lastDetectedTimestamp).format('h:mm:ss A')}</span>`
        }
        else {
            return `${common.toFacilityTime(this.lastCallInTimestamp).format('M/D/YYYY')} <span style="white-space: nowrap;">${common.toFacilityTime(this.lastCallInTimestamp).format('h:mm:ss A')}</span>`
        }
    });

    imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)

    changeDeviceClick = () => {
        //Dialogs.rcInmateAsssignment(this.inmateId(), this.rcAssignmentDialogOptions);

        Dialogs.rcInmateAsssignment(this.inmateId(), Dialogs.inmateAssignmentDialogDelegates);
    }

    changePresenceClick = () => {
        Dialogs.rcInmatePresence(this.inmateId(), Dialogs.inmatePresenceDialogDelegates);
    }

    //localAfterRCDeviceChanged = (inmateId, newDeviceId) => {
    //    console.log("DeviceChanged");
    //}

    //localAfterRCPresenceChanged = (inmateId, newPresence, newDeviceId) => {
    //    console.log("Presence changed");
    //    this._onSave(inmateId, newDeviceId);
    //}

    contextMenuItems: ko.ObservableArray = ko.observableArray([
        { text: "Assignment/Unassignment", action: this.changeDeviceClick },
        
        { text: "Change Presence", action: this.changePresenceClick },
    ]);

    //private _onSave = (inmateId: number, deviceId) => {
    //    if (this.onSave) {
    //        this.onSave(inmateId, deviceId);
    //    }
    //}

}
