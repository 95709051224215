﻿import ko = require("knockout")
import mapping = require("knockout.mapping")

import { isNullOrUndefined } from "util"
import { EntitySwitch } from "./EntitySwitch"
import { EntityType, CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class SwitchEntityDialogModel {
    //
    //  Events
    //
    onChange: Function = null

    //
    // Properties
    //
    allowContractSelection: boolean = true;
    showContracts: boolean = true;
    showFacilities: boolean = true;
    allEntities: Array<EntitySwitch> = new Array<EntitySwitch>(0);
    selectedEntity: EntitySwitch = null;
    selectedEntityDescription: ko.Observable<string> = ko.observable("")
    hasSelectedEntity: ko.Observable<boolean> = ko.observable(false)

    dialogTitle: ko.Computed<string> = ko.computed((): string => {

        let title = "Select ";
        if (this.showContracts && this.allowContractSelection) {
            title += "Contract";
        }
        if (this.showContracts && this.allowContractSelection && this.showFacilities) {
            title += " or "
        }
        if (this.showFacilities) {
            title += "Facility";
        }

        return title;
    })

    showNestedLayout: ko.Computed<boolean> = ko.computed(() => {
        return this.showContracts && this.showFacilities;
    })

    hasSelectedContract: ko.Computed<boolean> = ko.computed(() => {
        return this.hasSelectedEntity() && this.selectedEntity.entityType == EntityType.Contract ;
    })

    hasSelectedFacility: ko.Computed<boolean> = ko.computed(() => {
        return this.hasSelectedEntity() && this.selectedEntity.entityType == EntityType.Facility;
    })

    displayEntities = () => {

        // this assumes that the entites are stored in allEntities as a parent child, with contract as the parent.
        // with contract being at the parent level just send the full list
        if (this.showNestedLayout() || this.showContracts) {
            return this.allEntities
        }
        else {

            // for facility only get the children.
            let facilityList = new Array<EntitySwitch>(0);

            this.allEntities.forEach(parent => {
                if (!isNullOrUndefined(parent.children)) {
                    parent.children.forEach(child => {
                        facilityList.push(child);

                    });
                }
            });

            return facilityList
        }
    }

    collapseAll = (leaveOpenId: number) => {
        this.allEntities.filter(x => x.expanded() && x.entityId != leaveOpenId).forEach(y => y.expanded(false));
    }

    showDialog = () => {
        if ($('#switchEntityDiv').length === 0) {
            $.get('/settings/SwitchEntity')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'switchEntityDiv';
                    $('body').append(div);

                    $('#switchEntityDiv').html(results);
                    this._fetchFacilityAndContracts().then(() => {
                        $('#switchEntityModal').ready(this._showDialog);
                    });
                })
                .fail((request, textStatus, error) => {
                    console.error(error);
                });
        }
        else {
            //this._showDialog();
            this._clearSelectedElements();
            this._fetchFacilityAndContracts().then(() => {
                this._showDialog();
            });
        }
    }

    setSelected = (entity: EntitySwitch) => {
        
        if (entity !== null && ((this.allowContractSelection && entity.entityType == EntityType.Contract) || entity.entityType == EntityType.Facility)) {

            this.selectedEntity = entity;
            this.selectedEntityDescription(this.selectedEntity.description);
            this.hasSelectedEntity(false); // seems to require a toggle of the observable or selected type computed results remain fixed. 
            this.hasSelectedEntity(true);
        }
        else {
            this.selectedEntityDescription("");
            this.hasSelectedEntity(false);
        }
    }

    switchEntity = () => {
        if (this.selectedEntity !== null) {
            //common.clearSessionData();
            globalThis.DIG.Common.clearSessionData();
            this.selectedEntity.save();
        }
        
    }

    private _fetchFacilityAndContracts = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/user/entities`,
                cache: false
            })
                .done(data => {

                    this.allEntities = new Array<EntitySwitch>(0);

                    data.forEach(contract => {

                        let facilities: Array<EntitySwitch> = new Array<EntitySwitch>();

                        contract.children.forEach(facility => {
                            facilities.push(new EntitySwitch(EntityType.Facility, facility.entityId, facility.description, null, this, facility.isOnline));
                        });

                        this.allEntities.push(new EntitySwitch(EntityType.Contract, contract.entityId, contract.description, facilities, this, false));
                        
                    });

                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('User::Entities_List', request, textStatus, error);
                    reject();
                })
        })
    };

    private _clearSelectedElements = () => {
        this.selectedEntity = null;
        this.selectedEntityDescription("");
        this.hasSelectedEntity(false);
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {

            $(':input').attr('data-lpignore', 'true');
            this._dialogInitialized = true;

        } else {
            ko.cleanNode($('#switchEntityModal')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#switchEntityModal')[0]);

        window.eval('$("#switchEntityModal").modal("show")');
    }
}