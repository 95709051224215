﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class FacilityBuilding {
    
    facilityBuildingId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')
    isDeviceRequired: ko.Observable<boolean> = ko.observable(true)
    forceUnassignmentReasonId: ko.Observable<number> = ko.observable(-1)
    displayOrder: ko.Observable<number> = ko.observable(0)

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
        }
    }

    static noBulding = (): FacilityBuilding => {
        let noBuliding = new FacilityBuilding();
        noBuliding.facilityBuildingId(0);
        noBuliding.description('');
        noBuliding.isDeviceRequired(true);
        noBuliding.forceUnassignmentReasonId(-1);
        noBuliding.displayOrder(0);

        return noBuliding;
    }

    

}
