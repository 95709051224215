﻿import ko = require("knockout")
import { isatty } from "tty";

export class GenericEntity {
    id: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')

    constructor(id: number, description: string) {
        this.id(id);
        this.description(description);
    }
}

export class GenericEntityWithStatus {
    id: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')
    isActive: ko.Observable<boolean> = ko.observable<boolean>(false)

    constructor(id: number, description: string, isActive: boolean) {
        this.id(id);
        this.description(description);
        this.isActive(isActive);
    }
}
