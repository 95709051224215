﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from 'util';
import Common = require("../Common")
import moment = require("moment-timezone")

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class MealSchedule {
    
    rfSessionId: number

    description: string
    line2Description: string
    dayFoundDescription: string
    mealStartMinutesFromMidnight: number
    mealEndMinutesFromMidnight: number
    foundOnDate: moment.Moment = ''.toMoment()
    
    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {
                copy: ['rfSessionId', 'description', 'line2Description', 'dayFoundDescription', 'mealStartMinutesFromMidnight', 'mealEndMinutesFromMidnight']
            }, this);

            if (!isNullOrUndefined(data["foundOnDate"])) {
                this.foundOnDate = moment(data["foundOnDate"]);
            }
        }
    }

    mealIdentifier: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.foundOnDate.format('YYYYMMDD') + this.mealStartMinutesFromMidnight.toString().padStart(4, '0') + this.rfSessionId;
    });

    titleDescription: ko.PureComputed<string> = ko.pureComputed(() => {
        return `${this.description} ${this.dayFoundDescription}`;
    });

}
