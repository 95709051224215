﻿import ko = require("knockout")
import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common;

ko.components.register('EventTypeDropdown', {
    viewModel: function (params) {
        this.showAlarmTypes = params.showAlarmTypes || true;
        this.showInfoTypes = params.showInfoTypes || false;
        this.typeDescription = params.typeDescription || 'Alarm'

        this.eventTypes = common.getEventTypes('description')

        this.alarmTypes = this.showAlarmTypes
            ? common.getAlarmEventTypes('description')
            : null

        this.infoTypes = this.showInfoTypes
            ? common.getInfoEventTypes('description')
            : null

        this.selected = []

        if (params.selected?.length ?? 0 === 0) {
            if (this.showAlarmTypes) this.alarmTypes.forEach(type => this.selected.push(type.id.toString()));
            if (this.showInfoTypes) this.infoTypes.forEach(type => this.selected.push(type.id.toString()));
        } else {
            this.selected = params.selected;
        }

        this.allAlarms = true;
        this.allInfo = true;

        this.eventTypesSelectedText = ko.pureComputed<string>(() => {
            const eventTypes = this.selected();

            let result: string = eventTypes.length > 1
                ? `${eventTypes.length} ${this.typeDescription} Types Selected`
                : `No ${this.typeDescription} Types Selected`;

            this.allAlarms = (this.showAlarmTypes && this.alarmTypes.every(type => this.selected.indexOf(type.id.toString()) >= 0));
            this.allInfo = (this.showInfoTypes && this.infoTypes.every(type => this.selected.indexOf(type.id.toString()) >= 0));

            if (eventTypes.length === 1) {
                result = common.getEventTypeById(parseInt(eventTypes[0])).displayDescription();
            } else if ((this.showAlarmTypes && this.allAlarms && this.showInfoTypes && this.allInfo)
                || (this.showAlarmTypes && this.allAlarms && !this.showInfoTypes)
                || (this.showInfoTypes && this.allInfo && !this.showAlarmTypes)) {
                result = `All ${ this.typeDescription } Types`;
            }

            return result;
        })

        this.toggleEventTypes = (a, b) => {
            const eventType = b.target.dataset.eventtype;

            switch (eventType) {
                case 'A': // Alarms
                    if (this.allAlarms) {
                        // Remove
                        this.alarmTypes.forEach(type => this.selected.remove(type.id.toString()));
                    }
                    else {
                        // Add
                        this.alarmTypes.forEach(type => this.selected.push(type.id.toString()));
                    }
                    this.allAlarms = !this.allAlarms;
                    break;

                case 'I': // Info
                    if (this.allInfo) {
                        // Remove
                        this.infoTypes.forEach(type => this.selected.remove(type.id.toString()));
                    }
                    else {
                        // Add
                        this.infoTypes.forEach(type => this.selected.push(type.id.toString()));
                    }
                    this.allInfo = !this.allInfo;
                    break;
            }
        }
    },
    template:
        '<div class="input-group input-group-sm dropdown-filter" style="margin-top: -1px;">\
            <input type="text" data-bind="value: eventTypesSelectedText" class="form-control form-control-sm input-group-text bg-white text-left" readonly />\
            <div class="input-group-append">\
                <div class="btn btn-sm btn-primary text-white dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;"></div>\
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm allow-focus" style="padding-top: 0px; width: max-content;">\
                    <table><tr>\
                        <td class="px-0" data-bind="visible: showAlarmTypes" style="vertical-align: top;">\
                            <h5 class="bg-primary text-white font-weight-bold px-2" data-eventtype="A" data-bind="click: toggleEventTypes" style="line-height: 2rem; cursor: pointer;">Alarms</h5>\
                            <div data-bind="foreach: alarmTypes" style="width: max-content; margin-top: -4px;">\
                                <div class="custom-control custom-checkbox mx-2">\
                                    <input type="checkbox" class="custom-control-input" data-bind="attr: { id: `set${id}`, value: id }, checked: $parent.selected" />\
                                    <label class="custom-control-label pr-1" data-bind="attr: { for: `set${id}` }">\
                                        <span data-bind="text: description"></span>\
                                    </label>\
                                </div>\
                            </div>\
                        </td>\
                        <td class="px-0" data-bind="visible: showInfoTypes, class: showAlarmTypes && showInfoTypes ? `dropdown-column-border-left` : ``" style="vertical-align: top;">\
                            <h5 class="bg-primary text-white font-weight-bold px-2" data-eventtype="I" data-bind="click: toggleEventTypes" style="line-height: 2rem; cursor: pointer;">Information</h5>\
                            <div data-bind="foreach: infoTypes" style="width: max-content; margin-top: -4px;">\
                                <div class="custom-control custom-checkbox mx-2">\
                                    <input type="checkbox" class="custom-control-input" data-bind="attr: { id: `set${id}`, value: id }, checked: $parent.selected" />\
                                    <label class="custom-control-label pr-1" data-bind="attr: { for: `set${id}` }">\
                                        <span data-bind="text: description"></span>\
                                    </label>\
                                </div>\
                            </div>\
                        </td>\
                    </tr></table>\
                </div>\
            </div>\
        </div>'
})