﻿import mapping = require('knockout.mapping')
import moment = require("moment-timezone")
import { GenericEntity } from '../GenericEntity'

import { Dialogs } from "../Dialogs"

const common = globalThis.DIG.Common;

export class DeviceAssignment {
    deviceId: number = 0
    assignedDate: moment.Moment
    removedDate: moment.Moment
    lastEventTimestamp: moment.Moment
    unassignmentReason: string

    //
    //  These fields are only available when ByDevice is used
    //
    facility: GenericEntity
    inmate: GenericEntity
    inmateIdentifier: string

    constructor(data: object) {
        mapping.fromJS(data, {
            copy: [
                'deviceId', 'assignedDate', 'removedDate', 'lastEventTimestamp',
                'unassignmentReason', 'inmateIdentifier', 'facility', 'inmate' 
            ]
        }, this);
    }

    displayAssignedDate = (): string => {
        const time = common.toFacilityTime(this.assignedDate);
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    }

    displayRemovedDate = (): string => {
        const time = common.toFacilityTime(this.removedDate);
        return this.removedDate.toString() != '0001-01-01T00:00:00+00:00'
            ? `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`
            : '';
    }

    displayReason = (): string => this.unassignmentReason ?? "";

    public editInmate = (e) => {
        Dialogs.editInmate(e.inmateId);
    };
}