﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")
import { ConfigurationSetting } from '../Configuration/ConfigurationSetting';
import { ViewModelItem } from "../ViewModelItem"
import { Locator } from './Locator';
import { Dialogs } from "../Dialogs"
import { ZoneTypeEnum } from './ZoneTypes';
import { Placement } from './Placement';

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class Zone extends ViewModelItem {
    hasDetail: ko.Observable<boolean> = ko.observable(false)
    hasConfiguration: ko.Observable<boolean> = ko.observable(false)
    hasLocators: ko.Observable<boolean> = ko.observable(false)
    
    facilityBeaconGroupId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')
    displayColor: ko.Observable<string> = ko.observable('')
    graceSeconds: ko.Observable<number> = ko.observable(0)
    facilityBuildingId: ko.Observable<number> = ko.observable(0)
    shape: ko.Observable<string> = ko.observable('')
    zoneType: ko.Observable<ZoneTypeEnum> = ko.observable(ZoneTypeEnum.LivingUnit)
    devicesCount: ko.Observable<number> = ko.observable(0)
    mutedDevicesCount: ko.Observable<number> = ko.observable(0)
    devicesNotReportingCount: ko.Observable<number> = ko.observable(0)
    searchString: ko.Observable<string> = ko.observable('')

    configuration: ko.ObservableArray<ConfigurationSetting> = ko.observableArray<ConfigurationSetting>([])
    placements: ko.ObservableArray<Placement> = ko.observableArray<Placement>([])

    constructor(data?: object, view?: object) {
        super()

        // view would be the zoneDialog.
        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('Zone Id')
            };
        }

        if (data) {
            mapping.fromJS(data, this.mapConfig, this);
        }
    }

    static getById = async (zoneId: number, dialogView?: any): Promise<Zone> => {
        const result = new Zone(null, dialogView);
        result.facilityBeaconGroupId(zoneId);
        await result.getDetail();
        return result;
    }

    displayZoneStatus: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let displayStr = this.devicesCount() + " Device"
        displayStr += this.devicesCount() != 1 ? "s" : "";

        if (this.mutedDevicesCount() > 0) {
            displayStr += ", " + this.mutedDevicesCount() + " Muted";
        }

        if (this.devicesNotReportingCount() > 0) {
            displayStr += ", " + this.devicesNotReportingCount() + " Not Reporting";
        }

        return displayStr;
    })

    editZone = () => {
        if (this.facilityBeaconGroupId() !== 0 && this.view?.zoneDialog) {
            this.view.zoneDialog.edit(this.facilityBeaconGroupId());
        } else {
            Dialogs.editZone(this.facilityBeaconGroupId());
        }
    }

    getDetail = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/facility/beacon/${this.facilityBeaconGroupId()}`,
                cache: false
            })
                .done(data => {
                    this.setDetail(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Zone::getDetail', request, textStatus, error);
                    reject();
                })
        })
    };

    setDetail = (detail) => {

        mapping.fromJS(detail, this.mapConfig, this);
        if (detail.facilityBuildingId == null) {
            this.facilityBuildingId(0);
        }

        this.hasDetail(true);
    };

    toFormData = (includeConfiguration: boolean = true): object => {
        const data = {
            facilityBeaconGroupId: this.facilityBeaconGroupId(),
            description: this.description(),
            displayColor: this.displayColor(),
            graceSeconds: this.graceSeconds(),
            facilityBuildingId: this.facilityBuildingId(),
            shape: this.shape(),
            zoneType: this.zoneType()
        }

        //if (this.building && this.building.id() !== undefined && parseInt(this.building.id().toString()) !== 0) {
        //    data['facilityBuildingId'] = this.building.id()
        //}

        const formData = common.objectToFormData(data);

        return formData;
    }

    getConfiguration = async (protocolVersion: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            let qString = "";
            if (protocolVersion != null) {
                qString = "?protocolVersion=" + protocolVersion;
            }
            $.get({
                url: `/api/configuration/Zone/${this.facilityBeaconGroupId()}${qString}`,
                cache: false
            })
                .done(data => {
                    this.setConfiguration(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Zone::getConfiguration', request, textStatus, error);
                    reject();
                })
        })
    };

    private setConfiguration = (data) => {
        const configurationMap = {
            key: (setting) => ko.utils.unwrapObservable(setting.configurationSettingId),
            create: (setting) => new ConfigurationSetting(setting.data, this)
        };

        this.configuration = mapping.fromJS(data, configurationMap);
        this.hasConfiguration(true);
    };

    configToFormData = (): object => {
        //
        //  Today, this is not the place to assign an inmate (later perhaps)
        //
        const data = {
            configRestores: this._restoredConfigurations()
        }

        ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.needsUpdate())
            .forEach((value, index, array) => data[`cfg_${array[index].configurationSettingId()}`] = array[index].editValue());

        return common.objectToFormData(data);
    }

    private _restoredConfigurations: ko.PureComputed<string> = ko.pureComputed(() => {
        let result = '';
        ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.isRestored())
            .forEach((setting) => result += (result !== '' ? ',' : '') + setting.configurationSettingId().toString());
        return result;
    })

    getPlacements = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/facility/beacon/${this.facilityBeaconGroupId()}/placements`,
                cache: false
            })
                .done(data => {
                    this.setPlacements(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Zone::getPlacements', request, textStatus, error);
                    reject();
                })
        })
    };

    private setPlacements = (data) => {
        const configurationMap = {
            key: (locator) => ko.utils.unwrapObservable(locator.facilityBeaconGroupDeviceId),
            create: (locator) => new Placement(locator.data, this.facilityBeaconGroupId(), this.view)
        };

        this.placements = mapping.fromJS(data, configurationMap);
        this.hasLocators(true);
    };

    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');

            result = regexName1.test(this.description())
                || regexName1.test(this.searchString());
        }

        return result;
    }

    public compare(compareTo, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'zone id':
                result = (this.facilityBeaconGroupId() - compareTo.facilityBeaconGroupId()) * direction;
                break;

            case 'description':
                result = this.description().localeCompare(compareTo.description()) * direction;
                break;

            case 'status':
                result = this.displayZoneStatus().localeCompare(compareTo.displayZoneStatus()) * direction;
                break;
            
        }

        return result;
    }

    public itemDescription(sort: string): string {
        switch (sort.toLowerCase()) {
            case 'zone id': sort = 'facilityBeaconGroupId'; break;
            case 'description': sort = 'description'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public getId = (): number => this.facilityBeaconGroupId();

}
