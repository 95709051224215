﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")


import { LoadingIndicator } from "../LoadingIndicator"
import { RollCallSchedule } from "./RollCallSchedule"
import { CommonViewModel } from "../Common"

let common: CommonViewModel = globalThis.DIG.Common

export class RollCallSchedulesViewModel {
    schedules: any = ko.observableArray<RollCallSchedule>([])
    loading: LoadingIndicator = new LoadingIndicator($('#rollCallSchedulesView'))
    changed: ko.Observable<boolean> = ko.observable<boolean>(false)
    lastRefreshTime: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(null)

    displayLastRefreshTime: ko.Computed<string> = ko.computed<string>(() => {
        return this.lastRefreshTime() !== null
            ? this.lastRefreshTime().format('MMMM Do YYYY, h:mm:ss A')
            : '';
    })

    dataPage: ko.PureComputed<any> = ko.pureComputed(() => {
        var temp = this.changed();
        return this.schedules().sort((a: RollCallSchedule, b: RollCallSchedule) => a.startEpoch() - b.startEpoch());
    })

    init = () => {
        ko.applyBindings(this);
        this.getRollCallSchedules();
    }

    getRollCallSchedules = () => {
        //
        //  Show the loading indicator immediately
        //
        this.loading.show(0);

        $.get('/api/rollcall/schedules')
            .done(data => this.setRollCallScheduleData(data))
            .fail((request, textStatus, error) => alert(error))
            .always(() => this.loading.hide());
    }

    setRollCallScheduleData = (data) => {
        if (data !== null) {
            const scheduleMap = {
                key: (schedule) => ko.utils.unwrapObservable(schedule.rollCallScheduleId),
                create: (schedule) => new RollCallSchedule(schedule.data, self)
            };

            this.schedules = mapping.fromJS(data, scheduleMap);

            //
            //  Handle refresh stuff
            //
            this.lastRefreshTime(moment());

            //
            //  Make things update
            //
            this.changed(!this.changed());
        }
    }

    checkValue = (row) => {
        //const today = common.toFacilityTime(moment.utc()).startOf('day');
        //let schedule: RollCallSchedule = this.dataFor(row);
        //const newTime = today.add(common.parseTimeAsDayEpoch(row.value), 'seconds');
        //alert(schedule.displayRollCallTime() + " - " + newTime.toString());

        let schedule: RollCallSchedule = this.dataFor(row);

        if (row.value.trim().length !== 0) {
            schedule.startEpoch(common.parseTimeAsDayEpoch(row.value));
        } else {
            this.schedules.remove(schedule);
        }

        this.changed(!this.changed());
    }

    addValue = (input) => {
        if (input.value.trim().length !== 0) {
            this.schedules.mappedCreate({
                entityType: 5,
                entityId: 0,
                startEpoch: common.parseTimeAsDayEpoch(input.value) 
            });
            input.value = '';
            input.focus();
            this.changed(!this.changed());
        }
    }

    saveChanges = () => {
        //
        //  Show the loading indicator immediately
        //
        this.loading.show(0);

        var schedules = mapping.toJS(this.schedules);

        $.post('/api/rollcall/schedules', { schedules: schedules })
            .done(() => common.toast('success', 'Schedules saved', 'Roll Call Schedules'))
            .fail((request, textStatus, error) => alert(error))
            .always(() => this.loading.hide());
    }

    find = (startEpoch: number) => {
        return ko.utils.arrayFirst<RollCallSchedule>(this.schedules(), (schedule: RollCallSchedule) => schedule.startEpoch() === startEpoch);
    }

    dataFor = (schedule) => ko.dataFor(schedule)
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.RollCallSchedules === undefined) {
    globalThis.DIG.RollCallSchedules = () => { };
}

globalThis.DIG.RollCallSchedules.ViewModel = RollCallSchedulesViewModel