﻿import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { Dialogs, InmateDialogOptions } from "../Dialogs"
import { } from "../Extensions"
import ko = require('knockout')
import mapping = require("knockout.mapping")

import { CommonViewModel } from "../Common"
import { InmatesImportDetail } from "./InmatesImportDetail"
import { InmatesImport } from "./InmatesImport"
const common: CommonViewModel = globalThis.DIG.Common

export class InmatesImportViewModel extends ViewModelBase<InmatesImportDetail> {
    options: ViewModelBaseOptions = {
        settingPrefix: "InmatesImport",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: true,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: false, //fb change to avoid initial details load
        autoRefresh: {
            show: false,
            enabled: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: true,
        },
        sortFields: ['Last Name', 'First Name', 'Inmate Id', 'Operation', 'Completed', 'Notes'],
        pageSizeOptions: [1000],
        pageActions: [],
        selectionActions: [],
        rightClickActions: []
    }

    // properties for screen layoud
    displayUploadSection: ko.Observable<boolean> = ko.observable<boolean>(false);
    hasDetailsLoaded: ko.Observable<boolean> = ko.observable(false);
    showChangesOnly: ko.Observable<boolean> = ko.observable(true);


    autoLoadDetailsForId: number = 0; //used post upload to go back and fetch the recently uploaded import file
    selectedImportId: ko.Observable<number> = ko.observable(1); //changes to latest when binding
    
    importSession: InmatesImport = null; //session for which the rowdetails are loaded.

    latestImportSessions: ko.ObservableArray<InmatesImport> = ko.observableArray<InmatesImport>([]);  //list of items in dropdown.

    //needed for base.
    protected createItem = (data?: object, view?: object): InmatesImportDetail => new InmatesImportDetail(data, view)

    latestImportSessionsData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.latestImportSessions();
    })

    //
    // helpers display section at the top of the screen.
    //
    isImportComplete: ko.Computed<boolean> = ko.computed<boolean>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return selectedSession.isExecuted;
        }
        else {
            return false;
        }
    })

    hasError: ko.Computed<boolean> = ko.computed<boolean>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return !common.isNullOrWhitespace(selectedSession.executionSummary.globalError);
        }
        else {
            return false;
        }
    })

    allowImport: ko.Computed<boolean> = ko.computed<boolean>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return (!selectedSession.isExecuted) && this.hasDetailsLoaded() && (common.isNullOrWhitespace(selectedSession.executionSummary.globalError));
        }
        else {
            return false;
        }
    })

    displayFilename: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return selectedSession.filename;
        }
        else {
            return "";
        }
    })

    displayUploadedDateTime: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return common.toFacilityTime(selectedSession.uploadTimestamp).format("M/D/YYYY h:mm:ss A");
        }
        else {
            return "";
        }
    })

    displayUploadedBy: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return selectedSession.uploadUserName;
        }
        else {
            return "";
        }
    })

    displayUploadedByInfo: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return selectedSession.uploadUserName + " " + common.toFacilityTime(selectedSession.uploadTimestamp).format("M/D/YYYY h:mm:ss A");
        }
        else {
            return "";
        }
    })

    displayImportedDateTime: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            if (selectedSession.isExecuted) {
                return common.toFacilityTime(selectedSession.executedTimestamp).format("M/D/YYYY h:mm:ss A");
            }
            else {
                return "Not Imported";
            }
        }
        else {
            return "";
        }

    })

    displayImportedBy: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            if (selectedSession.isExecuted) {
                return selectedSession.executedUserName;
            }
            else {
                return "Not Imported";
            }
        }
        else {
            return "";

        }
    })

    displayImportedInfo: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            if (selectedSession.isExecuted) {
                return selectedSession.executedUserName + " " + common.toFacilityTime(selectedSession.executedTimestamp).format("M/D/YYYY h:mm:ss A");
            }
            else {
                return "Not Imported";
            }
        }
        else {
            return "";
        }

    })


    displayExecutionMessage: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return selectedSession.executionSummary.globalError;
        }
        else {
            return "";
        }
    })

    displayInsertSummary: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return `${selectedSession.executionSummary.insertedRows} of ${selectedSession.executionSummary.insertRowsTotal}`;
        }
        else {
            return "";
        }
    })

    displayUpdateSummary: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return `${selectedSession.executionSummary.updatedRows} of ${selectedSession.executionSummary.updateRowsTotal}`;
        }
        else {
            return "";
        }
    })

    displayRemoveSummary: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return `${selectedSession.executionSummary.removedRows} of ${selectedSession.executionSummary.removeRowsTotal}`;
        }
        else {
            return "";
        }
    })

    displayUnchangedSummary: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return `${selectedSession.executionSummary.unchangedRowsTotal}`;
        }
        else {
            return "";
        }
    })

    displayTotalSummary: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        let selectedSession = this.latestImportSessions().find(x => x.inmateImportId == this.selectedImportId())

        if (selectedSession != null) {
            return `${selectedSession.executionSummary.totalRows}`;
        }
        else {
            return "";
        }
    })



    //
    //  Fetching Data to populate the dropdown list
    //
    protected fetchLatestList = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get(`/api/inmate/import/list`)
                .done(results => {
                    this.setLocalListData(results);

                    if (this.autoLoadDetailsForId != 0) {
                        this.selectedImportId(this.autoLoadDetailsForId);

                        this.autoLoadDetailsForId = 0;
                        this.loadData();
                    }

                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("InmatesImportViewModel::fetchLatestList()", request, textStatus, error);
                    reject();
                })
        });
    }

    protected setLocalListData = (data: InmatesImport[]) => {
        var map = {
            create: (importItem) => new InmatesImport(importItem.data)
        };

        this.latestImportSessions = mapping.fromJS(data, map);

        this.bump(!this.bump());
    }

    

    //
    //  Fetching data (details) used in the grid. []
    //
    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get(`/api/inmate/import/select/${this.selectedImportId()}`)
                .done(results => {
                    this.setLocalDetailData(results);
                    resolve(results.importDetails);
                })
                .fail((request, textStatus, error) => {
                    console.error("InmatesImportViewModel::fetchDetailsData()", request, textStatus, error);
                    reject();
                })
        });
    }

    protected setLocalDetailData = (data: InmatesImport) => {
        this.importSession = new InmatesImport(data);
        this.hasDetailsLoaded(true);
    }


    //
    //  Perform the import operation, this also sends the rows the user wants to skip in the operation.
    //
    protected performImport = async (): Promise<object> => {
        return new Promise((resolve, reject) => {

            common.toast('success', `Operation will take some time`, 'Starting Import');

            // too large for most imports.
            //let jsonPayload = ko.toJSON(this.importSession);


            //$.post(`/api/inmate/import/go`, JSON.stringify(this.buildApprovalPayload()))
            //    .done(results => {
            //        resolve(results.importDetails);
            //    })
            //    .fail((request, textStatus, error) => {
            //        console.error("InmatesImportViewModel::fetchData()", request, textStatus, error);
            //        reject();
            //    })

            this.loading.show();

            $.ajax({
                url: '/api/inmate/import/go',
                method: 'POST',
                data: JSON.stringify(this.buildApprovalPayload()),
                cache: false,
                contentType: 'application/json; charset=utf-8',
                dataType: 'json'
            })
                .done(results => {
                    
                    this.loadData();
                    this.fetchLatestList();

                    this.loading.hide();

                    common.toast('success', `Results are loading`, 'Import Complete');

                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("InmatesImportViewModel::performImport()", request, textStatus, error);
                    common.toast('error', `Import Failed`, 'Import Failed');
                    reject();
                })

        });
    }

    // Used by the AddNew user click
    onAddNew = () => {
        this.displayUploadSection(true);
    }

    // Used by the Cancel button in the upload file display.
    closeUpload = () => {
        this.displayUploadSection(false);
    }

    //
    //  Sending only the rows the user declined, 
    //  the original approach was creating too large of a payload.
    //
    private buildApprovalPayload = (): object => {
        
        let payload: any = {};

        payload.inmateImportId = this.importSession.inmateImportId;

        //payload.inmateApprovals = this.importSession.importDetails.map(function (rowDetail) {
        //    return {
        //        RowIndex: rowDetail.rowIndex,
        //        Approved: rowDetail.userAccepted
        //    }
        //});

        //this.importSession.importDetails.forEach((x) => {
        //    if (x.userAccepted() == false) {
        //        declinedRowIndex.push(x.rowIndex());
        //    }
        //});

        let declinedRowIndex: number[] = [];

        this.data().forEach((x) => {
            if (x.userAccepted() == false) {
                declinedRowIndex.push(x.rowIndex());
            }
        });

        payload.declinedRowIndex = declinedRowIndex;
        return payload;
    }

    //
    //  Event when clicking the Upload button.
    //
    protected uploadFile = () => {
        this.loading.show();

        common.toast('success', `Upload can take some time`, 'Upload Starting');

        let formData = new FormData();
        if (($('#importFile')[0] as any).files.length !== 0) {
            formData.append('photo', ($('#importFile')[0] as any).files[0]);
        }

        $.ajax({
            url: '/api/inmate/import',
            method: 'POST',
            data: formData,
            cache: false,
            //contentType: 'multipart/form-data',
            contentType: false,
            processData: false,
        })
            .done(results => {

                    
                this.autoLoadDetailsForId = results.inmateImportId;

                this.fetchLatestList();

                this.loading.hide();
                this.displayUploadSection(false);

                common.toast('success', `File uploaded, loading results`, 'Upload Complete');

                //resolve(results);
            })
            .fail((request, textStatus, error) => {
                console.error("InmatesImportViewModel::uploadFile()", request, textStatus, error);
                common.toast('error', `File Uploaded Failed`, 'Failed Upload');
                //reject();
            })

    }

    //
    //  event when the dropdown selection has changed.
    //
    private _selectedChanged = () => {
        this.bump(!this.bump());
        this.data([]);
        this.hasDetailsLoaded(false);
    }

    public constructor() {
        super();

        this.fetchLatestList();
        this.selectedImportId.subscribe(this._selectedChanged);
    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.InmatesImport ??= () => { /* */ };
globalThis.DIG.InmatesImport.ViewModel = InmatesImportViewModel