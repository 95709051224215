﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")
import Common = require("../Common")

import { TimeIntervalTypes } from "../TimeIntervalTypes"
import { LoadingIndicator } from "../LoadingIndicator"
import { Dialogs } from "../Dialogs"

const common: Common.CommonViewModel = globalThis.DIG.Common;

ko.components.register('MissedHeadcountWidget', {
    viewModel: function (params) {
        this.view = TimeIntervalTypes[params.view] || TimeIntervalTypes.Hour;

        this.containerId = common.uniqueId();
        this.when = ko.observable<moment.Moment>(common.toFacilityTime(moment()).cap(common.FacilityEndDate).startOf('hour'));
        this.inmates = ko.observableArray([]);
        this.loading = new LoadingIndicator($(`#${this.containerId}`));
        this.noResultsClass = ko.observable<string>('d-none');
        this.sort = ko.observable<string>('deviceId');
        this.direction = ko.observable<number>(-1);

        this.timerPaused = true;
        this.refreshTimer = 1000;
        this.refreshIntervalSeconds = 60;
        this.nextRefreshTime = moment().add(this.refreshIntervalSeconds, "seconds");

        this.description = ko.computed(() => {
            return 'Missed Headcount';
        });

        this.displayDate = ko.pureComputed(() => {
            let date = '';

            const current: moment.Moment = common.toFacilityTime(moment()).add(1, "hour").startOf(this.intervalString());

            if (this.when().clone().startOf(this.intervalString()).isSame(current)) {
                date = 'Current Hour';
            } else {
                switch (this.view) {
                    case TimeIntervalTypes.Day: date = this.when().clone().format('MMM Do, YYYY'); break;
                    default: date = this.when().clone().format('MMM Do - hA'); break;
                }
            }

            return date;
        });

        this.inmatePluralLabel = (): string => {
            return common.customStrings.find(e => e.customStringType == 41).description; //41 is label_inmate consider making enum
        };

        this.emptyListLabel = (): string => {
            return "All " + this.inmatePluralLabel() + " Reported"
        };

        this.sorted = ko.computed(() => {
            var sortRead = this.sort();
            var directionRead = this.direction();

            //return this.inmates().sort((a, b) => {
            //    switch (sortRead) {
            //        case 'deviceId':
            //            return a[sortRead] - b[sortRead] * directionRead;
                    
            //        default:
            //            return a[sortRead].localeCompare(b[sortRead]) * directionRead;
            //    }
            //});

            return this.inmates();
        });

        this.changeDate = (date) => {
            this.loading.show();

            const day = date.format('YYYYMMDD');
            const hour = date.format('H');

            $.get(`/api/rollcall/hourly/${day}/${hour}`)
                .done(results => this.setData(results, date))
                .fail((a, b, c) => { console.error("MissedHeadcountWidget::changeDate()", a, b, c) })
                .always(_ => this.loading.hide());
        };

        this.setData = (data, date) => {
            if (date !== undefined) {
                this.when(date);
            }
            
            const map = {
                key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
                //create: (inmate) => new DashboardAlarm(inmate.data, this)
            };

            this.inmates = mapping.fromJS(data, map);

            this.noResultsClass(this.inmates().length === 0 ? 'd-flex' : 'd-none');

            this.sort.valueHasMutated();
        };

        this.autoRefreshChanged = (newValue) => {
            if (newValue) {

                //
                //  Create timer
                //
                this.nextRefreshTime = moment.utc().add(this.refreshIntervalSeconds, 'seconds');
                this.refreshTimer = window.setInterval(this.autoRefresh, 15000);
                this.timerPaused = false;

            } else {
                //
                //  Kill timer (if exists)
                //
                try { window.clearInterval(this.refreshTimer) } catch { /* */ }
                this.timerPaused = true;
            }
        }

        this.autoRefresh = () => {
            if (!this.timerPaused) {
                if (moment.utc().isAfter(this.nextRefreshTime)) {
                    this.nextRefreshTime = moment.utc().add(this.refreshIntervalSeconds, 'seconds');
                    this.refresh();
                }
            }
        }

        this.refresh = () => {
            this.changeDate(common.toFacilityTime(moment()).add(1, "hour").cap(common.FacilityEndDate).endOf('hour'));
        }

        this.intervalString = () => {
            return $.isNumeric(this.view)
                ? TimeIntervalTypes[this.view]
                : this.view;
        }

        this.allowPrevious = ko.pureComputed<boolean>(() => {
            return this.when().clone().startOf(this.intervalString()).isAfter(common.FacilityStartDate);
        });

        this.previous = () => {
            if (this.allowPrevious()) {
                this.changeDate(this.when().add(-1, this.intervalString()));
            }
        }

        this.allowNext = ko.pureComputed<boolean>(() => {
            const maxTime: moment.Moment = moment.min(common.toFacilityTime(moment()), common.FacilityEndDate).endOf(this.intervalString());
            let allow: boolean = this.when().clone().endOf(this.intervalString()).isSameOrBefore(maxTime);

            // only auto-refresh when looking at the 'latest' data, stop autorefresh when viewing historical data.
            this.autoRefreshChanged(!allow); 

            return allow;
        });

        this.next = () => {
            if (this.allowNext()) {
                this.changeDate(this.when().add(1, this.intervalString()));
            }
        };

        this.editInmate = (e) => {
            Dialogs.editInmate(e.inmateId());
        };

        this.changeDeviceClick = (data: any, event) => {
            Dialogs.rcInmateAsssignment(data.inmateId());
        }

        this.changePresenceClick = (data: any, event) => {
            Dialogs.rcInmatePresence(data.inmateId());
        }


        this.contextMenuItems = ko.observableArray([
            { text: "Assignment/Unassignment", action: this.changeDeviceClick },

            { text: "Change Presence", action: this.changePresenceClick },
        ]);

        this.refresh();

    },

    template:
        '<div class="py-1 px-2 d-flex" style="background-color: royalblue; color: white; font-weight: bold;"> \
            <span data-bind="text: description"></span> \
            <span class="mx-auto" data-bind="text: displayDate"></span> \
            <span><a href="#" data-bind="click: previous"><i class="fas fa-angle-left" data-bind="class: allowPrevious() ? \'text-white\' : \'text-white-50\'"></i></a></span> \
            <span class="ml-2"><a href="#" data-bind="click: next"><i class="fas fa-angle-right" data-bind="class: allowNext() ? \'text-white\' : \'text-white-50\'"></i></a></span> \
            <span class="ml-2"><a href="#" data-bind="click: refresh"><i class="fas fa-sync-alt text-white"></i></a></span> \
        </div> \
        <div data-bind="attr: {id: containerId}" class="d-flex" style="background-color: white; position: absolute; left: 0.5rem; top: 28px; right: 0; bottom: 0; overflow-y: auto;"> \
            <table class="dig-table flex-fill"> \
                <thead class="header" style="font-weight: bold;height: 26px;min-width: 100%;"> \
                    <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;">Device Id</td> \
                    <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;">Inmate Id</td> \
                    <td class="dig-col flex-fill" style="width: 100%; min-width: 150px;">Inmate</td> \
                </thead> \
                <tbody class="simpleBody" data-bind="foreach: sorted" style="height: 0px;"> \
                    <tr class="summary no-hover"> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;" data-bind="text: deviceId"></td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;" data-bind="text: inmateIdentifier"></td> \
                        <td class="dig-col flex-fill" style="width: 100%; min-width: 150px; text-overflow: ellipsis;"><span data-bind="text: lastName"></span>, <span data-bind="text: firstName"></span><a href="#" data-bind="click: $parent.editInmate, contextMenu: $parent.contextMenuItems "> <i class="far fa-address-card hover-link"></i></a></td> \
                    </tr> \
                </tbody> \
                <tbody> \
                    <tr class="no-hover">\
                        <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;"></td> \
                        <td class="dig-col pr-2" style="text-align: left; min-width: 90px; max-width: 90px;"></td> \
                        <td class="dig-col flex-fill" style="width: 100%; min-width: 150px; text-overflow: ellipsis;"></td> \
                    </tr> \
                </tbody> \
                <div data-bind="class: noResultsClass" style="background-color: gainsboro;position: absolute;top: 0;bottom: 0;right: 0;left: 0; display: none;"> \
                    <span style="font: 24px Arial;color: black;text-align: center;" class="m-auto"><span data-bind="text: emptyListLabel()"></span><br /><span data-bind="text: displayDate"></span></span> \
                </div> \
            </table> \
        </div>'
})