﻿import { DeviceTransfer } from "./DeviceTransfer"

import { CommonViewModel } from "../Common"
import ko = require("knockout");
import { strict } from "assert";

const common: CommonViewModel = globalThis.DIG.Common

export class DeviceTransferViewModel {

    //unassign: ko.Observable<boolean> = ko.observable<boolean>(false); // auto-unassigning from warehouse (facility 10 only)
    deviceTransfers: any = ko.observableArray<DeviceTransfer>([]);

    constructor() {

        ko.applyBindings(this);
    }

    sorted: ko.Computed = ko.computed(() => {
        
        return this.deviceTransfers().sort(function (a, b) {
            var sortResult = (a.failed() - b.failed()) * -1;

            if (sortResult === 0) {
                return a.deviceId() - b.deviceId();
            }

            return sortResult;
        });
    })
     
    inputDevices = () => {

        // clear textarea (removed the clear for now, it's convenient to fix item in list that wasn't copied right)
        //$("#inputDeviceIds").val(''); 

        window.eval('$("#inputTransferDevicesModal").modal("show")');
    }

    parse = () => {
        this.deviceTransfers.removeAll()

        let tagIdsText = String($("#inputDeviceIds").val());

        let splitIds: string[];
        if (tagIdsText.indexOf(",") > 0) {
            splitIds = tagIdsText.split(",");
        }
        else {
            splitIds = tagIdsText.split(/\r?\n/);
        }

        if (splitIds.length > 0) {
            splitIds.forEach(strId => {
                if (strId != "" && (parseInt(strId) > 0)) {
                    this.deviceTransfers.push(new DeviceTransfer(parseInt(strId)));
                }
                
            });
        }

        this.deviceTransfers.valueHasMutated();

        window.eval('$("#inputTransferDevicesModal").modal("hide")');
    }


    transfer = () => {
        for (let i = 0; i < this.deviceTransfers().length; i++) {
            $.post('/api/device/transfer', {
                deviceId: this.deviceTransfers()[i].deviceId()
                //unassign: this.unassign()
            })
                .done(result => this.displayResult(result))
                .fail((request, textStatus, error) => {
                    common.toast('error', textStatus, 'Device Transfer');
                    console.error('deviceTransfer', request, textStatus, error);
                });
        }
    }

    displayResult = (transferResult: DeviceTransfer) => {
        // properties don't come in as observables
        let deviceRow = this.deviceTransfers().find(item => item.deviceId() == transferResult.deviceId);
        deviceRow.message(transferResult.message);
        deviceRow.failed(transferResult.failed)
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.DeviceTransfer === undefined) {
    globalThis.DIG.DeviceTransfer = () => { };
}

globalThis.DIG.DeviceTransfer.ViewModel = DeviceTransferViewModel