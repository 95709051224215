﻿export class TimeZones {
    static "Eastern Standard Time" = "America/New_York";
    static "Central Standard Time" = "America/Chicago";
    static "Mountain Standard Time" = "America/Denver";
    static "Pacific Standard Time" = "America/Los_Angeles";
}

//  ==================================
//  Setup globalThis object
//  ==================================
if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

globalThis.DIG.TimeZones = TimeZones;