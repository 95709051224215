﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from 'util';
import Common = require("../Common")
import { PlacementDevice } from './PlacementDevice';

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class Placement {
    view: any // view should be ZoneDialog
    parentId:ko.Observable<number> = ko.observable(0)
    placementId: ko.Observable<number> = ko.observable(0)
    entityId: ko.Observable<number> = ko.observable(0)
    entityType: ko.Observable<Common.EntityType> = ko.observable(Common.EntityType.Zone)
    //deviceId: ko.Observable<number> = ko.observable(0)
    createdTimestamp: moment.Moment = ''.toMoment()
    removedTimestamp: moment.Moment = ''.toMoment()
    description: ko.Observable<string> = ko.observable('')
    originalDescription: string = ''
    comment: ko.Observable<string> = ko.observable('')
    originalComment: string = ''
    placementDevices: ko.ObservableArray<PlacementDevice> = ko.observableArray<PlacementDevice>([])
    //isSelected: ko.Observable<boolean> = ko.observable<boolean>(false)

    constructor(data?: object, parentId?:number, view?: object) {
        this.view = view; // view should be ZoneDialog

        if (data) {
            mapping.fromJS(data, { ignore: ['devices'] }, this);
        }

        if (parentId != null) {
            this.parentId(parentId);
        }

        var childMap = {
            create: (inObj) => new PlacementDevice(inObj.data, view)
        };

        if (!isNullOrUndefined(data["devices"])) {
            this.placementDevices = (mapping.fromJS(data["devices"], childMap));
        }
        else {
            this.placementDevices = null;
        }

        this.originalComment = this.comment();
        this.originalDescription = this.description();
    }

    hasCommentChanged: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => {
        return (this.comment() != this.originalComment
                    || this.description() != this.originalDescription);
    })

    protected savePlacementChanges(): Promise<object> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: '/api/facility/placement', 
                method: 'PUT',
                data: {
                    placementId: this.placementId(),
                    description: this.description(),
                    comment: this.comment()
                },
                cache: false,
                //contentType: false,
                //processData: false,
            })
                .done(results => {
                    if (results) {
                        this.originalComment = this.comment();
                        this.comment.valueHasMutated();
                        this.originalDescription = this.description();
                        this.description.valueHasMutated();
                    }
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Locator::savePlacementChanges()", request, textStatus, error);
                    reject();
                })
        });

        //return new Promise((resolve, reject) => {

        //    $.post('/api/facility/beacon/device', {
        //        facilityBeaconGroupDeviceId: this.facilityBeaconGroupDeviceId(),
        //        description: this.comment()
        //    })
        //        .done(results => {
        //            resolve(results);
        //        })
        //        .fail((request, textStatus, error) => {
        //            console.error("Locator::saveLocatorChanges()", request, textStatus, error);
        //            reject();
        //        })
        //});
    }

    public removePlacement(): Promise<object> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: '/api/facility/placement',
                method: 'DELETE',
                data: {
                    placementId: this.placementId()
                },
                cache: false,
            })
                .done(results => {
                    // view should be ZoneDialog
                    if (this.view) {
                        this.view.placementsChanged(this.parentId());
                    }
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Locator::removePlacement()", request, textStatus, error);
                    reject();
                })
        });
    }

    //sendCommand = (action: number): Promise<void> => {
    //    return new Promise((resolve, reject) => {

    //        $.ajax({
    //            url: `/api/device/${this.deviceId()}/action/${action}`,
    //            method: 'GET',
    //            cache: false,
    //        })
    //            .done(results => {
    //                // view should be ZoneDialog
    //                //if (this.view) {
    //                //    this.view.locatorsChanged(this.facilityBeaconGroupId());
    //                //}
    //                resolve();
    //            })
    //            .fail((request, textStatus, error) => {
    //                console.error("Locator::sendCommand()", request, textStatus, error);
    //                reject();
    //            })
    //    });
    //}

    //sendDFU = (advertiseSeconds: number): Promise<void> => {
    //    return new Promise((resolve, reject) => {

    //        $.ajax({
    //            url: `/api/device/${this.deviceId()}/dfu/${advertiseSeconds}`,
    //            method: 'GET',
    //            cache: false,
    //        })
    //            .done(results => {
    //                resolve();
    //            })
    //            .fail((request, textStatus, error) => {
    //                console.error("Locator::sendDFU()", request, textStatus, error);
    //                reject();
    //            })
    //    });
    //}

}
