﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import Common = require("../Common")
import { Dialogs, InmateAssignmentRcDialogDelegates, InmateDialogOptions, InmatePresenceRcDialogDelegates } from "../Dialogs"
import { LoadingIndicator } from "../LoadingIndicator"

import { InmateDormPresence } from "../Inmates/InmateDormPresence"

const common: Common.CommonViewModel = globalThis.DIG.Common;

ko.components.register('DormResidentsWidget', {
    viewModel: function (params) {
        //globalThis.widget = this;

        this.dialogOptions = {
            isReadOnly: false,
            showAlarms: true,
            showConfiguration: false,
            showTemp: true,
            showLocation: true,
            allowEdit: true,
            onSave: null,
            onShown: null,
            onHidden: null,
            onDeactivate: null
        }

        this.containerId = common.uniqueId();
        this.loading = new LoadingIndicator($(`#${this.containerId}`));
        this.facilityBuildingId = params.buildingId || 0;
        this.overflow = params.overflow || 'clip';
        this.columns = params.columns || 2;
        if (params.showBanner === undefined) {
            this.showBanner = true;
        }
        else {
            this.showBanner = params.showBanner;
        }
        

        this.inmates = ko.observableArray<InmateDormPresence>([]);
        this.bump = ko.observable<boolean>(false);
        this.sort = ko.observable<string>('inmateName');

        this.sorted = ko.computed(() => {
            var bumpRead = this.bump();
            //return this.inmates(); //.sort((a, b) => a.whenEntered.compare(b.whenEntered));

            var sortRead = this.sort();
            var directionRead = 1;//this.direction();

            return this.inmates().sort((a, b) => {
                switch (sortRead) {
                    case 'inmateIdentifier':
                        return a.inmateIdentifier().localeCompare(b.inmateIdentifier()) * directionRead;

                    case 'inmateName':
                        return a.inmateName().localeCompare(b.inmateName()) * directionRead;

                    case 'displayBlockAndBunk':
                        return a.displayBlockAndBunk().localeCompare(b.displayBlockAndBunk()) * directionRead;

                    case 'displayLocation':
                        return a.displayLocation().localeCompare(b.displayLocation()) * directionRead;

                    default:
                        return a.inmateName().localeCompare(b.inmateName()) * directionRead;
                }
            });

        });

        this.changeSort = (a, b) => {
            this.sort(b.target.dataset.view);
            this.bump(!this.bump());
        }

        this.residentsTitle = (): string => {

            if (this.sorted().length > 0) {
                let total = this.sorted().length;
                let present = ko.utils.arrayFilter(this.sorted(), (item: InmateDormPresence) => item.isPresent()).length;

                return `Residents (${present} / ${total})`;
            }
            else {
                return "Residents";
            }
        }

        this.sortedClass = (listItem): string => {
            if (listItem == this.sort()) {
                return "dropdown-item active";
            }
            else {
                return "dropdown-item";
            }
        }

        this.widgetClass = ko.computed(() => 'neutral')
        //{
        //    var bumpRead = this.bump();
        //    return this.inmates().length == 0 ? 'status-good' : 'status-bad'
        //});

        this.inmateClass = (index): string => {
            const width: number = Math.floor(12 / this.columns);
            return ((index() + 1) % this.columns) == 0 ? `col-${width} pt-1 px-0` : `col-${width} pb-0 pl-0 pr-1 pt-1`;
        }

        this.fetchData = () => {
            this.loading.show();

            $.get(`/api/dashboard/location/${this.facilityBuildingId}/dorm`)
                .done(results => this.setData(results))
                .fail((a, b, c) => { console.error("DormResidentWidget::fetchData()", a, b, c) })
                .always(_ => this.loading.hide());
        };

        this.setData = (data) => {
            const presentMap = {
                key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
                create: (inmate) => new InmateDormPresence(inmate.data)
            };
            this.inmates = mapping.fromJS(data, presentMap);

            this.bump(!this.bump());
        };

        this.fetchData();

        this.editInmate = (data: InmateDormPresence, event) => {
            //event.stopPropagation();
            Dialogs.editInmate(data.inmateId(), this.dialogOptions);
        }

        this.changeDeviceClick = (data: InmateDormPresence, event) => {
            let rcAssignmentDialogOptions: InmateAssignmentRcDialogDelegates = {
                onSave: null
            }
            rcAssignmentDialogOptions.onSave = this.afterRCDeviceChanged;

            Dialogs.rcInmateAsssignment(data.inmateId(), rcAssignmentDialogOptions);
        }

        this.changePresenceClick = (data: InmateDormPresence, event) => {
            let rcPresenceDialogOptions: InmatePresenceRcDialogDelegates = {
                onSave: null
            }
            rcPresenceDialogOptions.onSave = this.afterRCPresenceChanged;

            Dialogs.rcInmatePresence(data.inmateId(), rcPresenceDialogOptions);
        }

        this.afterRCDeviceChanged = (inmateId, newDeviceId) => {
            //event.stopPropagation();
            console.log("Widget After afterRCDeviceChanged");
            this.fetchData();
        }

        this.afterRCPresenceChanged = (inmateId, newPresence, newDeviceId) => {
            //event.stopPropagation();
            console.log("Widget After afterRCPresenceChanged");
            this.fetchData();
        }

        this.contextMenuItems = ko.observableArray([
            { text: "Assignment/Unassignment", action: this.changeDeviceClick },

            { text: "Change Presence", action: this.changePresenceClick },
        ]);

        window.setInterval(() => this.fetchData(), 30000);
    },

    template:
        '<div class="dw d-flex flex-column" style="position: absolute; top: 15px; left: 9px; right: 0px; bottom: 0px;"> \
            <div data-bind="class: widgetClass, visible: showBanner" class="widget-banner px-2" style="text-align: center; font-size: 1.6rem; font-weight: 900;"> \
                <div data-bind="html: residentsTitle()"></div> \
                <a href="#!" role="button" data-toggle="dropdown" style="position: absolute; top: 3px; right: 15px; font-size: 1.5rem;" ><i class="fas fa-sort-alt text-white"></i></a> \
                <div class="dropdown-menu dropdown-menu-right bg-silver"> \
                    <a href="#" data-view="inmateName" data-bind="click: changeSort, class: sortedClass(`inmateName`)" >Name</a> \
                    <a href="#" data-view="inmateIdentifier" data-bind="click: changeSort, class: sortedClass(`inmateIdentifier`)" >Inmate ID</a> \
                    <a href="#" data-view="displayBlockAndBunk" data-bind="click: changeSort, class: sortedClass(`displayBlockAndBunk`)" >Room</a> \
                    <a href="#" data-view="displayLocation" data-bind="click: changeSort, class: sortedClass(`displayLocation`)" >Location</a> \
                </div> \
            </div> \
            <div data-bind="foreach: sorted" class="flex-fill flex-grow-0 d-flex flex-wrap residents" style="font-weight: 600; overflow-y: auto;"> \
                <div data-bind="class: $parent.inmateClass($index), click: $parent.editInmate, contextMenu: $parent.contextMenuItems " style="cursor: pointer;"> \
                    <div class="py-1 pl-1 pr-2 flex-column" data-bind="class: tileBackgroundColor()"> \
                        <div data-bind="text: inmateName" class="inmate" style="overflow: clip; white-space: nowrap;"></div> \
                        <div class="p-0 d-flex"> \
                            <div class="mr-2" style="overflow: clip" data-bind="text: inmateIdentifier"></div> \
                            <div class="ml-auto" style="text-align: right; overflow: clip;" data-bind="text: displayBlockAndBunk"></div> \
                        </div>\
                        <div class="p-0 d-flex"> \
                            <div class="location" style="text-align: right; overflow: clip;" data-bind="text: displayLocation"></div> &nbsp; \
                            <div class="ml-auto" style="text-align: right; overflow: clip;" data-bind="text: displayAlarm"></div> \
                        </div>\
                    </div> \
                </div> \
            </div> \
        </div>'
})