﻿import ko = require("knockout")
import mapping = require("knockout.mapping")

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class ChangePasswordDialogModel {
    //
    //  Events
    //
    onShown: Function = null
    onHidden: Function = null

    //
    //  Properties
    //
    userId: number = 0
    error: ko.Observable<string> = ko.observable<string>("")
    showError: ko.Computed<boolean> = ko.computed<boolean>(() => this.error().length > 0)

    password: ko.Observable<string> = ko.observable<string>("").extend({
        rateLimit: {
            timeout: 250,
            method: 'notifyWhenChangesStop'
        }
    })

    retype: ko.Observable<string> = ko.observable<string>("").extend({
        rateLimit: {
            timeout: 250,
            method: 'notifyWhenChangesStop'
        }
    })

    allowCancel: ko.Observable<boolean> = ko.observable<boolean>(true)

    allowSubmit: ko.Computed<boolean> = ko.computed<boolean>(() => {
        let valid: boolean = false;
        let error: string = "";

        var p1: string = this.password().trim();
        var p2: string = this.retype().trim();

        if (p1.length > 0 && p2.length > 0) {
            //
            //  We have a password at least, assume valid. rules below will invalidate if necessary
            //
            valid = true;

            //
            //  Validate complexity rules
            //
            if (p1.length < 6) {
                valid = false;
                error += 'Password must be at least 6 characters.<br>';
            }

            //
            //  Check passwords match
            //
            if (p1 !== p2) {
                valid = false;
                error += 'Password and Retype Password must match.<br>';
            };
        }

        //
        //  Update error message
        //
        this.error(error);

        return valid
    });

    //
    //  Methods
    //
    constructor() {
    }

    cancel = () => {
        this._hideDialog();
    }

    submit = () => {
        if (this.allowSubmit()) {
            const request = {
                userId: this.userId,
                password: this.password()
            };

            $.post('/api/user/password', request)
                .done(() => {
                    this._hideDialog();
                    common.toast('success', 'Your password has been updated.');
                })
                .fail(a => {
                    common.toast('error', 'An error occurred while updating your password.');
                });
        }
    }

    showDialog = () => {
        if ($('#_changePasswordDiv_').length === 0) {
            $.get('/account/changepassworddialog')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = '_changePasswordDiv_';
                    div.innerHTML = results;

                    $('.body-content')[0].appendChild(div);

                    $('#changePasswordModal').ready(this._showDialog);
                });
        }
        else {
            this.password('');
            this.retype('');
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            this._setupKeyBindings();

            this._dialogInitialized = true;
        } else {
            ko.cleanNode($('#_changePasswordDiv_')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#_changePasswordDiv_')[0]);

        window.eval('$("#changePasswordModal").modal("show")');

        this._onShown();
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        window.eval('$("#changePasswordModal").modal("hide")');
        this._onHidden();
    }

    private _onShown = () => {
        if (this.onShown) {
            this.onShown();
        }
    }

    private _onHidden = () => {
        if (this.onHidden) {
            this.onHidden();
        }
    }

    private _setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 27: //ESC
                        if (this.allowCancel()) {
                            this.hideDialog();
                        }
                        e.preventDefault();
                        break;
                }
            }
        });
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

globalThis.DIG.ChangePasswordDialog = ChangePasswordDialogModel
