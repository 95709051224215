﻿import ko = require("knockout")

import { CommonViewModel } from "../Common"

let common: CommonViewModel = globalThis.DIG.Common

export class LoginViewModel {
    loginForm: JQuery
    errors: any = ko.observableArray<string>([])

    init = (loginForm: JQuery) => {
        ko.applyBindings(this);
        this.loginForm = loginForm; 
    }

    login = () => {
        $.post("/account/login", this.loginForm.serialize())
            .done((redirect) => {
                common.navigate(redirect, false);
            })
            .fail((a, b, c) => {
                if (a.status === 400) {
                    alert(a.responseJSON[''].toString());
                } else {
                    alert("A server error has occurred.")
                }
            });
    }
};

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

globalThis.DIG.Account = () => { /* */ };
globalThis.DIG.Account.Login = LoginViewModel;