﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateCrewInfo {
    inmateId: ko.Observable<number> = ko.observable(0)
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    lastEventTimestamp: moment.Moment = ''.toMoment()

    
    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.lastEventTimestamp = moment(data["lastEventTimestamp"]);
        }
    }

    displayLastTimestamp: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        if (this.lastEventTimestamp.isValid()) {
            return `${common.toFacilityTime(this.lastEventTimestamp).format('M/D/YYYY')} <span style="white-space: nowrap;">${common.toFacilityTime(this.lastEventTimestamp).format('h:mm:ss A')}</span>`
        }
        else {
            return "";
        }
        //return `${this.lastSeen.format('M/D/YYYY')} <span style="white-space: nowrap;">${this.lastSeen.format('h:mm:ss A')}</span>`
        
    })

    displayLastTimeOnly: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        if (this.lastEventTimestamp.isValid()) {
            return `${common.toFacilityTime(this.lastEventTimestamp).format('h:mm:ss A')}`
        }
        else {
            return "";
        }
        //return `${this.lastSeen.format('M/D/YYYY')} <span style="white-space: nowrap;">${this.lastSeen.format('h:mm:ss A')}</span>`

    })

    inmateName: ko.Computed<string> = ko.computed<string>(() => this.lastName() + ', ' + this.firstName())

    imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)
}
