﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class RollCallInmate {
    view: any

    inmateId: ko.Observable<number> = ko.observable(0)
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    deviceId: ko.Observable<number> = ko.observable(0)
    reportedTimestamp: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(null)
    lastEventTimestamp: ko.Observable<moment.Moment> = ko.observable<moment.Moment>(null)

    constructor(data: any, view: any) {
        this.view = view;
        mapping.fromJS(data, {}, this);
    }

    displayReportedTimestamp: ko.Computed<string> = ko.computed(() => {
        return this.reportedTimestamp() !== null
            ? common.toFacilityTime(this.reportedTimestamp()).format('M/D/YYYY h:mm:ss A')
            : '';
    });

    displayLastEventTimestamp: ko.Computed<string> = ko.computed(() => {
        return this.lastEventTimestamp() !== null
            ? common.toFacilityTime(this.lastEventTimestamp()).format('M/D/YYYY h:mm:ss A')
            : '';
    });

    displayName: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.view.sort === undefined || this.view.sort() === 'lastName'
            ? `${this.lastName()}, ${this.firstName()}`
            : `${this.firstName()} ${this.lastName()}`;
    });

    displayNameSimple: ko.PureComputed<string> = ko.pureComputed(() => `${this.lastName()}, ${this.firstName()}`);

    hasReported: ko.Computed<boolean> = ko.computed(() => this.reportedTimestamp() !== null)

    viewInmate = () => {
        this.view.inmateDialog.edit(this.inmateId());
    }
}
