﻿import { CommonViewModel } from "../Common"
const common: CommonViewModel = globalThis.DIG.Common

import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from "util"

import { ViewModelItem } from "../ViewModelItem"
import { DeviceAssignment } from "../Devices/DeviceAssignment"
import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"
import { Dialogs } from "../Dialogs"

export enum InmateImportOperationType {
    Unchanged = 0,
    Insert = 1,
    UpdateReactivate = 2,
    Update = 3,
    UpdateAndUnassign = 4,
    Remove = 5,
    RemoveAndUnassign = 6,
}

export class InmatesImportDetail extends ViewModelItem {
    inmateId: ko.Observable<number> = ko.observable(0)
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    middleName: ko.Observable<string> = ko.observable('')
    operation: ko.Observable<InmateImportOperationType> = ko.observable()

    userAccepted: ko.Observable<boolean> = ko.observable()
    allowOperation: ko.Observable<boolean> = ko.observable()
    success: ko.Observable<boolean> = ko.observable()
    operationNotes: ko.Observable<string> = ko.observable()
    rowIndex: ko.Observable<number> = ko.observable()
    
    bump: ko.Observable<boolean> = ko.observable(true);

    
    constructor(data?: object, view?: object) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('operation')
            };
        }

        if (data) {
            mapping.fromJS(data, this.mapConfig, this);

            
        }
    }

    displayName: ko.PureComputed<string> = ko.pureComputed(() => {
        const last: string = this.lastName();
        const first: string = this.firstName();

        return this.view?.sort === undefined || this.view.sort().toLowerCase() == 'first name'
            ? `${first} ${last}`
            : `${last}, ${first}`;
    });

    displayOperation: ko.PureComputed<string> = ko.pureComputed(() => {

        switch (this.operation()) {
            case InmateImportOperationType.Unchanged: {
                return "No Changes";
            }
            case InmateImportOperationType.Insert: {
                return "Create Inmate";
            }
            case InmateImportOperationType.UpdateReactivate: {
                return "Update and Reactivate";
            }
            case InmateImportOperationType.Update: {
                return "Update Inmate";
            }
            case InmateImportOperationType.UpdateAndUnassign: {
                return "Update and Unassign";
            }
            case InmateImportOperationType.Remove: {
                return "Deactivate Inmate";
            }
            case InmateImportOperationType.RemoveAndUnassign: {
                return "Unassign and Deactivate";
            }
            default: {
                return "";
            }
        }
    });


    userAcceptedToggle = () => {
        const e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

        this.userAccepted(!this.userAccepted());

        //if (this.view?.itemSelected !== undefined && typeof (this.view?.itemSelected) === 'function') {
        //    this.view.itemSelected(this);
        //}
    }

    

    //getDetail = async (): Promise<boolean> => {
    //    return new Promise((resolve, reject) => {
    //        $.get({
    //            url: `/api/inmate/${this.inmateId()}`,
    //            cache: false,
    //        })
    //            .done(data => {
    //                this.setDetail(data);
    //                resolve(true);
    //            })
    //            .fail((request, textStatus, error) => {
    //                console.error('Inmate::getDetail', request, textStatus, error);
    //                reject();
    //            });
    //    })
    //};

    //setDetail = (detail) => {
    //    mapping.fromJS(detail, this.mapConfig, this);

    //    if (!this.building) this.building = {
    //        id: ko.observable<number>(0),
    //        description: ko.observable<string>('')
    //    }

    //    if ((this.deviceId() ?? 0) !== 0) {
    //        this.deviceDescription(this.deviceId().toString());
    //    } else {
    //        this.deviceId(0);
    //        this.deviceDescription('Unassigned');
    //    }

    //    if (!isNullOrUndefined(detail["lastEventTimestamp"])) {
    //        this.lastEventTimestamp = moment(detail["lastEventTimestamp"])
    //    }
    //    else {
    //        this.lastEventTimestamp = ''.toMoment();
    //    }

    //    if (!isNullOrUndefined(detail["deviceAssignmentDate"])) {
    //        this.deviceAssignmentDate = moment(detail["deviceAssignmentDate"])
    //    }
    //    else {
    //        this.deviceAssignmentDate = ''.toMoment();
    //    }

    //    this.hasDetail(true);
    //};

    //public activated = () => {
    //    this.edit();
    //}

    clone = (): object => mapping.toJS(this, {
        ignore: ['clone', 'view'],
        
    });

    toFormData = (includeConfiguration: boolean = true): object => {
        const data = {
            inmateId: this.inmateId(),
            inmateIdentifier: this.inmateIdentifier(),
            firstName: this.firstName(),
            middleName: this.middleName(),
            lastName: this.lastName()
        }

        const formData = common.objectToFormData(data);

        return formData;
    }

    

    //static getById = async (inmateId: number): Promise<InmatesImportDetail> => {
    //    return new Promise((resolve) => {
    //        const result = new Inmate();
    //        result.inmateId(inmateId);
    //        result.getDetail().then(() => resolve(result));
    //    })
    //}

    public compare(compareTo, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'last name':
                result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                if (result === 0) {
                    result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                }
                break;

            case 'first name':
                result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                if (result === 0) {
                    result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                }
                break;

            case 'inmate id':
                result = this.inmateIdentifier().localeCompare(compareTo.inmateIdentifier()) * direction;
                break;

            case 'operation':
                result = (this.operation() - compareTo.operation()) * direction;
                break;

            case 'success':
                result = (this.operation() - compareTo.operation()) * direction;
                break;

            case 'operationNotes':
                result = (this.operation() - compareTo.operation()) * direction;
                break;

        }

        return result;
    }

    public itemDescription(sort: string): string {
        switch (sort.toLowerCase()) {
            case 'inmateIdentifier': sort = 'inmateIdentifier'; break;
            case 'last name': sort = 'displayName'; break;
            case 'first name': sort = 'displayName'; break;
            case 'operation': sort = 'operation'; break;
            case 'success': sort = 'success'; break;
            case 'notes': sort = 'operationNotes'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');

            result = (regexName1.test(this.firstName()) && regexName2.test(this.lastName()))
                || (regexName1.test(this.lastName()) && regexName2.test(this.firstName()))
                || regexName1.test(this.inmateIdentifier()) //regexId.test(this.inmateIdentifier())
        }

        const showChangesOnly = this.view?.showChangesOnly() ? this.operation() != InmateImportOperationType.Unchanged : true;

        return result && showChangesOnly;
    }

    //public edit = () => {
    //    if (this.view?.inmateDialog?.edit !== undefined && typeof (this.view?.inmateDialog?.edit) === 'function') {
    //        this.view?.inmateDialog?.edit(this.inmateId());
    //    } else {
    //        Dialogs.editInmate(this.inmateId());
    //    }
    //}

    public getId = (): number => this.rowIndex();
}
