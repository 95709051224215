﻿import ko = require("knockout")
import mapping = require("knockout.mapping")

import { CommonViewModel } from "../Common"
import { FindDialogOptions } from "../Dialogs"

const common: CommonViewModel = globalThis.DIG.Common

export class FindDialogModel {
    //
    //  Events
    //
    onShown: Function = null
    onHidden: Function = null

    //
    //  Properties
    //
    options: FindDialogOptions
    filter: string = ''

    //
    //  Methods
    //
    constructor(e?: FindDialogOptions) {
        this.options = e ?? new FindDialogOptions()
    }

    search = () => {
        $.get('')
    }


    showDialog = (options) => {
        if (options) {
            if (options.showContracts) this.options.showContracts = options.showContracts;
            if (options.showDevices) this.options.showDevices = options.showDevices;
            if (options.showFacilities) this.options.showFacilities = options.showFacilities;
            if (options.showInmates) this.options.showInmates = options.showInmates;
        }

        if ($('#_findDiv_').length === 0) {
            $.get('/shared/finddialog')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = '_findDiv_';
                    div.innerHTML = results;

                    $('.body-content')[0].appendChild(div);

                    $('#findModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            this._setupKeyBindings();

            this._dialogInitialized = true;
        } else {
            ko.cleanNode($('#_findDiv_')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#_findDiv_')[0]);

        window.eval('$("#findModal").modal("show")');

        this._onShown();
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        window.eval('$("#findModal").modal("hide")');
        this._onHidden();
    }

    private _onShown = () => {
        if (this.onShown) {
            this.onShown();
        }
    }

    private _onHidden = () => {
        if (this.onHidden) {
            this.onHidden();
        }
    }

    private _setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 27: //ESC
                        this.hideDialog();
                        e.preventDefault();
                        break;
                }
            }
        });
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

globalThis.DIG.FindDialog = FindDialogModel
