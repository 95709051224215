﻿import { warn } from "console";
import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { CommonViewModel } from "../Common"
import { Dialogs, InmateDialogOptions } from "../Dialogs";
import { InmateCrewInfo } from "../Inmates/InmateCrewInfo";
import { LoadingIndicator } from "../LoadingIndicator";

const common: CommonViewModel = globalThis.DIG.Common

export class CrewStatusViewModel {
    constructor() {
    }

    dialogOptions: InmateDialogOptions = {
        isReadOnly: false,
        showAlarms: true,
        showConfiguration: false,
        showTemp: true,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onHidden: null,
        onDeactivate: null
    }

    loading = new LoadingIndicator($('#inmateTiles'));
    inmates = ko.observableArray<InmateCrewInfo>([]);

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    displayDate: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('MMMM Do, YYYY');
    });

    displayTime: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return common.toFacilityTime(moment.utc()).format('h:mm A');
    });

    allInmatesSorted = ko.computed(() => {
        var bumpRead = this.bump();

        var sortRead = "inmateName"; //this.sort();
        var directionRead = 1;//this.direction();

        return this.inmates().sort((a, b) => {
            var result = 0;

            if (result == 0) {
                result = a.lastName().localeCompare(b.lastName()) * directionRead;

                if (result == 0) {
                    result = a.firstName().localeCompare(b.firstName()) * directionRead;
                }
            }

            return result;
        });

    });
    
    fetchAttendanceData = () => {
        this.loading.show();

        let path = `/api/dashboard/crew`;
       
        $.get(path)
            .done(results => this.setAttendanceData(results))
            .fail((a, b, c) => { console.error("MealMonitor::fetchAttendanceData()", a, b, c) })
            .always(_ => this.loading.hide());
    }

    setAttendanceData = (data) => {
        const presentMap = {
            key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmate) => new InmateCrewInfo(inmate.data)
        };
        this.inmates = mapping.fromJS(data, presentMap);

        this.bump(!this.bump());
    }

    columns = 4;
    inmateClass = (index): string => {
        const width: number = Math.floor(12 / this.columns);
        return ((index() + 1) % this.columns) == 0 ? `col-${width} pt-1 px-0` : `col-${width} pb-0 pl-0 pr-1 pt-1`;
    }

    borderColorClass = (lastCallin: moment.Moment): string => {
        let goodInterval: number = 300;
        let warningInterval: number = 600;


        if (!lastCallin.isValid()) {
            return "neutral";
        }
        else if (lastCallin.isAfter(moment().utc().subtract(goodInterval, 'seconds'))) {
            return "good";
        }
        else if (lastCallin.isAfter(moment().utc().subtract(warningInterval, 'seconds'))) {
            return "warning";
        }
        else {
            return "danger";
        }

    }


    editInmate = (data: InmateCrewInfo, event) => {
        //event.stopPropagation();
        Dialogs.editInmate(data.inmateId(), this.dialogOptions);
    }



    init = () => {
        
        ko.applyBindings(this);

        this.fetchAttendanceData();

        window.setInterval(() => this.fetchAttendanceData(), 30000);

    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dashboard ??= () => { /* */ };
globalThis.DIG.Dashboard.CrewStatus ??= () => { /* */ };
globalThis.DIG.Dashboard.CrewStatus.ViewModel = CrewStatusViewModel;