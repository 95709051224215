﻿import ko = require("knockout")
import { GenericEntity } from "../GenericEntity"
import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common;

ko.components.register('GenericSelectDropdown', {
    viewModel: function (params) {
        this.componentId = common.uniqueId()
        this.typeDescription = params.typeDescription || 'Entity'

        this.data = params.data

        this.selected = params.selected;

        // i don't know why on 1 line it always go to else...
        //      because you deleted the ?? 0
        let cc = params.selected()?.length;
        if (cc === 0) {
            this.data().forEach(type => this.selected.push(type.id().toString()));
        } else {
            this.selected = params.selected;
        }

        this.eventTypesSelectedText = ko.pureComputed<string>(() => {
            const eventTypes = this.selected();

            let result: string = eventTypes.length > 1
                ? `${eventTypes.length} ${this.typeDescription} Selected`
                : `No ${this.typeDescription} Selected`;

            this.allData = this.data().every(type => eventTypes.indexOf(type.id().toString()) >= 0);

            if (eventTypes.length === 1) {
                result = this.data().find(i => i.id() == eventTypes[0]).description(); //common.getEventTypeById(parseInt(eventTypes[0])).displayDescription();
            } else if (this.allData) {
                result = `All ${ this.typeDescription }s `;
            }

            return result;
        })

        this.toggleEventTypes = (a, b) => {

            if (this.allData) {
                // Remove
                this.selected.removeAll();
            }
            else {
                // Clear all selection before adding all.
                this.selected.removeAll();
                // Add
                this.data().forEach(type => this.selected.push(type.id().toString()));
            }
            
        }
    },
    template:
        '<div class="input-group input-group-sm dropdown-filter">\
            <input type="text" data-bind="value: eventTypesSelectedText" class="form-control form-control-sm input-group-text bg-white text-left" readonly />\
            <div class="input-group-append">\
                <div class="btn btn-sm btn-primary text-white dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;"></div>\
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm allow-focus" style="padding-top: 0px; width: 175px;">\
                    <table width="100%"><tr>\
                        <td class="px-0" style="vertical-align: top;">\
                            <h5 class="bg-primary text-white font-weight-bold px-2" data-eventtype="A" data-bind="click: toggleEventTypes, text: typeDescription" style="line-height: 2rem; cursor: pointer;"></h5>\
                            <div data-bind="foreach: data" style="width: max-content; margin-top: -4px;">\
                                <div class="custom-control custom-checkbox mx-2">\
                                    <input type="checkbox" class="custom-control-input" data-bind="attr: { id: `${$parent.componentId}${id()}`, value: id }, checked: $parent.selected" />\
                                    <label class="custom-control-label pr-1" data-bind="attr: { for: `${$parent.componentId}${id()}` }">\
                                        <span data-bind="text: description"></span>\
                                    </label>\
                                </div>\
                            </div>\
                        </td>\
                    </tr></table>\
                </div>\
            </div>\
        </div>'
})