﻿import mapping = require('knockout.mapping')
import moment = require("moment-timezone")
import { GenericEntity } from '../GenericEntity'

import { Dialogs } from "../Dialogs"
import { Staff } from './Staff';
import { isNullOrUndefined } from 'util';
import ko from 'knockout';

const common = globalThis.DIG.Common;

export class StaffDeviceAssignment {
    staffDeviceAssignmentId: ko.Observable<number> = ko.observable(0)
    deviceId: ko.Observable<number> = ko.observable(0)
    staffId: ko.Observable<number> = ko.observable(0)
    assignedDate: moment.Moment
    removedDate: moment.Moment

    staff: ko.Observable<Staff> = ko.observable<Staff>()
    
    //
    //  These fields are only available when ByDevice is used
    //
    facility: GenericEntity
    inmate: GenericEntity
    inmateIdentifier: string

    constructor(data: object) {
        mapping.fromJS(data, {
            //copy: [
            //    'staffDeviceAssignmentId', 'deviceId', 'staffId', 'assignedDate', 'removedDate' 
            //],
            ignore: ['staff']
        }, this);

        var childMap = {
            create: (inObj) => new Staff(inObj.data)
        };

        if (!isNullOrUndefined(data["staff"])) {
            //this.staff = ko.utils.unwrapObservable(mapping.fromJS(data["staff"], childMap));
            this.staff(mapping.fromJS(data["staff"], childMap));
        }
        else {
            this.staff(null);
        }
    }

    displayAssignedDate = (): string => {
        const time = common.toFacilityTime(this.assignedDate);
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    }

    displayRemovedDate = (): string => {
        const time = common.toFacilityTime(this.removedDate);
        return this.removedDate.toString() != '0001-01-01T00:00:00+00:00'
            ? `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`
            : '';
    }


}