﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")


import { CommonViewModel } from "../Common"
import { Dialogs, InmateDialogOptions } from "../Dialogs"
import { InmateAlarm } from "../Inmates/InmateAlarm"
import { Headcount } from "../RollCalls/Headcount"
import { HeadcountInmate } from "../RollCalls/HeadcountInmate"

const common: CommonViewModel = globalThis.DIG.Common

export class SecurityCenterViewModel {

    inmateAlarms: ko.ObservableArray<InmateAlarm> = ko.observableArray<InmateAlarm>([])
    headcounts: ko.ObservableArray<Headcount> = ko.observableArray<Headcount>([])
    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    dialogOptions: InmateDialogOptions = {
        isReadOnly: false,
        showAlarms: true,
        showConfiguration: false,
        showTemp: true,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onHidden: null,
        onDeactivate: null
    }

    constructor() {
        Dialogs.createStaticAssignmentDelegates(this.dialogStaticDeviceAssignFct);
        Dialogs.createStaticPresenceDelegates(this.dialogStaticPresenceFct);
    }

    alarmData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.inmateAlarms();
    });

    alarmSorted: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        //return this.inmateAlarms();

        return this.inmateAlarms().sort((a, b) => {
            let result = a.processStatusId() - b.processStatusId();

            if (result === 0) {
                let aDate: any = a.alarmTimestamp;
                let bDate: any = b.alarmTimestamp;
                result =  (aDate - bDate) * -1; // latest date at the top
            }

            return result;
        });
    });

    newAlarmData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.inmateAlarms().filter(x => x.processStatusId() != 2)
    });

    dispatchedAlarmData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.inmateAlarms().filter(x => x.processStatusId() == 2)
    });

    populationOnSiteData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.headcounts().filter(x => x.isBuilding() == true);
    });

    populationLongTermOffSiteData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.headcounts().filter(x => x.isBuilding() == false);
    });

    offGroundsData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        let onGroundInmates: HeadcountInmate[] = [];
        this.headcounts().forEach(headCountItem => {

            if (headCountItem.isTracked() && headCountItem.isBuilding()) {
                headCountItem.relevantInmates().forEach(missingItem => {
                    if (missingItem.exclude() === true) {
                        onGroundInmates.push(missingItem);
                    }
                })
            }
        })
        return onGroundInmates;
    });

    onGroundsData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        let onGroundInmates: HeadcountInmate[] = [];
        this.headcounts().forEach(headCountItem => {

            if (headCountItem.isTracked() && headCountItem.isBuilding()) {
                headCountItem.relevantInmates().forEach(missingItem => {
                    if (missingItem.exclude() === false) {
                        onGroundInmates.push(missingItem);
                    }
                })
            }
        })
        return onGroundInmates;
    });

    unmonitoredInmates: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        let onGroundInmates: HeadcountInmate[] = [];

        //sort mutates the original collection, sorted does not and returns regular array.
        let sortedHeadcountGroups = this.headcounts.sorted((a, b) => {
            let result = 0;
            if (a.isBuilding() !== b.isBuilding()) {
                result = a.isBuilding() ? -1 : 1;
            }
            
            if (result === 0) {
                result = a.title().localeCompare(b.title());
            }
            return result;
        });

        sortedHeadcountGroups.forEach(headCountItem => {

            if (!headCountItem.isTracked()) {
                headCountItem.relevantInmates().forEach(missingItem => {
                    onGroundInmates.push(missingItem);
                })
            }
        })

        return onGroundInmates;
    });

    onGroundsHasData: ko.PureComputed<boolean> = ko.pureComputed(() => {
        if (this.onGroundsData().length == 0) {
            return false;
        }
        else {
            return true;
        }
    });

    displayDate: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return globalThis.DIG.Common.toFacilityTime(moment.utc()).format('MMMM Do, YYYY');
    });

    displayTime: ko.PureComputed<string> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return globalThis.DIG.Common.toFacilityTime(moment.utc()).format('h:mm A');
    });

    sumOnSite = (field: string): number => {
        let total = 0;
        this.headcounts().forEach(x => {
            if (x.isBuilding()) {
                total += x[field]();
            }
        });
        return total;
    }

    totalOnSiteAssigned: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumOnSite('assignedCount');
    });

    totalOnSiteExcluded: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumOnSite('excludedCount');
    });

    totalOnSiteTentative: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumOnSite('tentativeCount');
    });

    totalOnSiteActual: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumOnSite('actualCount');
    });

    allDormsTracked: ko.PureComputed<boolean> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.headcounts().every(x => x.isTracked() == true && x.isBuilding() == true);
    });

    sumAll = (field: string): number => {
        let total = 0;
        this.headcounts().forEach(x => 
                total += x[field]()
            );
        return total;
    }

    totalAllAssigned: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumAll('assignedCount');
    });

    totalAllExcluded: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumAll('excludedCount');
    });

    totalAllTentative: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumAll('tentativeCount');
    });

    totalAllActual: ko.PureComputed<number> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.sumAll('actualCount');
    });

    hasLongTermLeave: ko.PureComputed<boolean> = ko.pureComputed(() => {
        var bumpRead = this.bump();
        return this.headcounts().some(x => x.isTracked() == false && x.isBuilding() == false);
    });

    fetchPopulationData = () => {
        //loading.show();

        $.get('/api/rollcall/headcount')
            .done(results => this.setPopulationData(results))
            .fail((a, b, c) => { console.error("SecurityCenter::fetchData()", a, b, c) })
        // .always(_ => this.loading.hide());
    }

    setPopulationData = (data) => {
        //const presentMap = {
        //    //key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
        //    create: (gPop) => new GeneralPopulation(gPop.data)
        //};
        //this.generalPopulation = mapping.fromJS(data, presentMap);


        var map = {
            create: (dorm) => new Headcount(dorm.data)
        };

        this.headcounts = mapping.fromJS(data, map);

        this.bump(!this.bump());
    }

    fetchAlarmData = () => {
        //loading.show();

        $.get(`/api/dashboard/alarm/inmateAlarms`)
            .done(results => this.setAlarmData(results))
            .fail((a, b, c) => { console.error("SecurityCenter::fetchData()", a, b, c) })
        // .always(_ => this.loading.hide());
    }

    setAlarmData = (data) => {
       

        const presentMap = {
            //key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmateAlarm) => new InmateAlarm(inmateAlarm.data)
        };
        this.inmateAlarms = mapping.fromJS(data, presentMap);

        this.bump(!this.bump());
    }

    onAlarmReceived = () => {
        this.fetchAlarmData();
    }

    init = () => {
        common.inmateRFEventOccurred = this.inmateRFEventOccurred;

        this.dialogOptions.onSave = this._inmateSaved;

        ko.applyBindings(this);

        this.fetchPopulationData();
        this.fetchAlarmData();


        // need to add a refresh to pull data, not just refresh the computed values
        window.setTimeout(() => window.setInterval(() => this.bump(!this.bump()), 60000), 61000 - (moment().seconds() * 1000));

        // backup for signalR
        window.setInterval(() => this.fetchAlarmData(), 60000);
        window.setInterval(() => this.fetchPopulationData(), 120000);

        globalThis.DIG.Common.newAlarm = this.onAlarmReceived;
    }

    inmateRFEventOccurred = (inmateId, RFZoneId) => {
        this.fetchPopulationData();
    }

    _inmateSaved = (inmateId, results) => {

        common.toast("success", "Inmate Data Saved", 'Update Inmate');
        this.fetchPopulationData();
        this.fetchAlarmData();
        
    }

    editInmate = (data: HeadcountInmate, event) => {
        //event.stopPropagation();
        Dialogs.editInmate(data.inmateId(), this.dialogOptions);
    }

    //afterRCDeviceChanged = (inmateId, newDeviceId) => {
    //    this.fetchPopulationData();
    //}

    //afterRCPresenceChanged = (inmateId, newPresence, newDeviceId) => {
    //    this.fetchPopulationData();
    //}

    dialogStaticDeviceAssignFct = (inmateId, newDeviceId) => {
        console.log("Received function from static Dialogs" + inmateId + " " + newDeviceId);
        this.fetchPopulationData();
        
    }

    dialogStaticPresenceFct = (inmateId: number, presenceId: number, deviceId: number) => {
        console.log("Received function from static Dialogs" + inmateId + " " + presenceId + " " + deviceId);
        this.fetchPopulationData();

    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.SecurityCenter ??= () => { /* */ };
globalThis.DIG.SecurityCenter.ViewModel = SecurityCenterViewModel;