﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class DeviceTransfer {

    deviceId: ko.Observable<number> = ko.observable(0);
    message: ko.Observable<string> = ko.observable('');
    failed: ko.Observable<boolean> = ko.observable(false);

    constructor(id:number) {
        this.deviceId(id);

    }

}
