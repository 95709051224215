﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from 'util';
import { EntityType } from '../Common';
import Common = require("../Common")
import { GenericEntity, GenericEntityWithStatus } from '../GenericEntity';

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class Association {
    view: any // view should be UserDialog
    userId: number = 0
    userAssociationId: number = 0
    associatedToType: EntityType = EntityType.Unknown;
    associatedTo: GenericEntity = new GenericEntity(0, "")
    jobTitle: GenericEntity = new GenericEntity(0, "")
    isActive: boolean = false
    
    constructor(data?: object, userId?: number, view?: any) {

        if (view) {
            this.view = view;
        }

        if (data) {
            //mapping.fromJS(data, { 'copy': ["userAssociationId", "associatedToType", "associatedTo", "jobTitle", "isActive"] }, this);
            mapping.fromJS(data, { 'copy': ["userAssociationId", "associatedToType",  "isActive"] }, this);
        }

        this.userId = userId;

    }
    

}


