﻿import ko = require("knockout")
import $ = require('jquery')
import mapping = require("knockout.mapping")

import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"

import { CommonViewModel, DeviceType, EntityType } from "../Common"
import { Zone } from "./Zone"
import { EntitySelectViewModel } from "../Components/EntitySelectDropdown"
import { FacilityBuilding } from "../Facility/FacilityBuilding"
import { Validation } from "../ValidationExtenders"
import { ZoneType } from "./ZoneTypes"
import { PlacementDevice } from "./PlacementDevice"
import { Placement } from "./Placement"
import { FirmwareVersion } from "../Firmware/FirmwareVersion"

const common: CommonViewModel = globalThis.DIG.Common

export class ZoneDialogModel {
    //
    //  Events
    //
    onSave: Function = null
    //onCancel: Function = null
    //onLocatorsChanges: Function = null;

    //
    //  Properties
    //
    zone: ko.Observable<Zone> = ko.observable(null)
    blagSelector: EntitySelectViewModel = null
    configProtocolVersion: ko.Observable<number> = ko.observable(5) //default protocol 5

    //currentTab: ko.Observable<string> = ko.observable('general')
    isReadOnly: ko.Observable<boolean> = ko.observable(true)
    showAlarms: ko.Observable<boolean> = ko.observable(false)
    showConfiguration: ko.Observable<boolean> = ko.observable(true)
    showAssignments: ko.Observable<boolean> = ko.observable(true)

    deviceToVerify: ko.Observable<number> = ko.observable(0);
    deviceToVerifyString: ko.Observable<string> = ko.observable('');
    deviceToAddMessage: ko.Observable<string> = ko.observable('Select a device to add.');

    placementToEdit: ko.Observable<Placement> = ko.observable();

    showAddBlag: ko.Observable<boolean> = ko.observable(false);
    locatorToRemove: PlacementDevice = null;

    isEditingGeneral: ko.Observable<boolean> = ko.observable(false)
    isEditingConfig: ko.Observable<boolean> = ko.observable(false)

    facilityBuildings: ko.ObservableArray<FacilityBuilding> = ko.observableArray<FacilityBuilding>()
    facilityZoneTypes: ko.ObservableArray<ZoneType> = ko.observableArray<ZoneType>()
    availableFirmware: ko.ObservableArray<FirmwareVersion> = ko.observableArray<FirmwareVersion>()
    
    selectedFirmwareVersion: ko.Observable<number> = ko.observable<number>(0)
    isFirmwareForced: ko.Observable<boolean> = ko.observable<boolean>(false)

    isAllDevicesSelected: ko.Observable<boolean> = ko.observable<boolean>(false)

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    allowEdit: ko.Observable<boolean> = ko.observable(true)

    isReadOnlyDefault = true

    dialogTitle: ko.Computed<string> = ko.computed((): string => {
        return this.zone()
            ? `${this.zone().description()}`
            : 'Zone Dialog - Not Initialized';
    })

    isEditing: ko.Computed<boolean> = ko.computed((): boolean => {
        return !this.isReadOnly();
    })

    showEditButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.isReadOnly() && this.allowEdit());
    })

    hasZoneErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        return false;
    })

    hasConfigErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        
        return false;
    })

    hasErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        return this.hasZoneErrors()
            || this.hasConfigErrors();
    })

    displayFacilityBuildingDesciption: ko.Computed<string> = ko.computed((): string => {
        var bump = this.bump();
        var building = this.facilityBuildings().find(x => x.facilityBuildingId() == this.zone().facilityBuildingId())
        if (building) {
            return building.description();
        }
        else {
            return "";
        }

    })

    facilityBuildingsData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.facilityBuildings();
    })

    availableFirmwareData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.availableFirmware();
    })

    displayFacilityZoneTypeDesciption: ko.Computed<string> = ko.computed((): string => {
        var bump = this.bump();
        var zoneType = this.facilityZoneTypes().find(x => x.zoneType() == this.zone().zoneType())
        if (zoneType) {
            return zoneType.description();
        }
        else {
            return "";
        }

    })

    displayBlagRemovalMessage: ko.Computed<string> = ko.computed((): string => {
        var bump = this.bump();

        if (this.locatorToRemove != null) {
            return `Are you sure you want to remove locator ${this.locatorToRemove.deviceId()}.`;
        }
        else {
            return "";
        }

    })

    facilityZoneTypesData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.facilityZoneTypes();
    })

    // not used, just trying this out to fix cancel issue.
    displayConfiguration: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.zone()) {
            return this.zone().configuration();
        }
        else {
            return null;
        }

    })

    //
    //  Methods
    //
    constructor(options) {
        if (options.isReadOnly !== undefined) this.isReadOnlyDefault = options.isReadOnly;
        if (options.showAlarms !== undefined) this.showAlarms(options.showAlarms);
        if (options.showConfiguration !== undefined) this.showConfiguration(options.showConfiguration);
        if (options.showAssignments !== undefined) this.showAssignments(options.showAssignments);
        if (options.allowEdit !== undefined) this.allowEdit(options.allowEdit);

        this.blagSelector = new EntitySelectViewModel({
            entityType: EntityType.Device,
            subTypes: [DeviceType.ILinkLocator],
            noSelectionDescription: "",
            autoSelectSingle: true
        });
    }

    edit = async (zoneId?: number): Promise<void> => {
       
        return new Promise((resolve, reject) => {
            this._getZone(zoneId)
                .then(() => this._getPlacements())
                .then(() => this._getConfiguration())
                .then(() => {
                    this._addValidators();
                    this.showDialog();
                    resolve()
                })
        })
    }

    placementsChanged = () => {

        this._getPlacements().then(() => {
            this.zone.valueHasMutated();
            this.zone().placements.valueHasMutated();
            this.zone.valueHasMutated();
        });
        common.toast('success', `${this.zone().description()} was updated.`, 'Update Zone Locators');

    }


    private _getZone = (zoneId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (zoneId) {
                //this.isReadOnly(this.isReadOnlyDefault);
                Zone.getById(zoneId, this)
                    .then(zone => {
                        this.zone(zone)
                        this.isEditingConfig(false);
                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                //this.isReadOnly(false);
                this.isEditingGeneral(true);
                this.isEditingConfig(false);
                this.zone(new Zone());
                resolve();
            }
        })
    }

    private _getPlacements = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.showAssignments()) {
                this.zone().getPlacements().then(() => resolve(), rejectReason => reject(rejectReason));
            } else {
                resolve();
            }
        })
    }


    private _getConfiguration = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.showConfiguration()) {
                this.zone().getConfiguration(this.configProtocolVersion().toString()).then(() => resolve(), rejectReason => reject(rejectReason));
            } else {
                resolve();
            }
        })
    }

    private _addValidators = () => {
        if (!this.zone().hasValidators()) {
            this.zone().description = Validation.addLength(this.zone().description, { min: 2, max: 100, fieldName: 'Description' });
            
            for (let index = 0; index < this.zone().configuration().length; index++) {
                this.zone().configuration()[index].addValidator();
            }

            this.zone().hasValidators(true);

            //this.zone().graceSeconds.subscribe(this._nameChanged);
        }
    }

    showDialog = () => {
        if ($('#zoneEditorDiv').length === 0) {
            this._getFacilityBuildings()
                .then(() => this._getAvailableFirmware())
                .then(() => this._getFacilityZoneTypes())
                .then(() => this._getDialog())
                .then(() => $('#zoneEditorDiv').ready(this._showDialog));
        }
        else {
            this._showDialog();

        }
    }

    private _getDialog = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get('/zones/fullscreeneditor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'zoneEditorDiv';
                    div.className = 'editor'
                    div.style.cssText = 'position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 10; display: none';
                    $('.body-content')[0].appendChild(div);
                    $('#zoneEditorDiv').html(results);
                    resolve();
                })
                .fail((a, b, c) => {
                    console.error("zoneEditorDiv::_getDialog", a, b, c);
                    reject();
                });
        });
    }


    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');


            this._setupKeyBindings();

            this._dialogInitialized = true;

            window.eval(`$(document).popover({               
                html: true,
                container: 'body',
                boundary: 'window',
                placement: 'top',
                selector: '.validationTip',
                trigger: 'hover focus',
                template: '<div class="popover" role="tooltip"><div class="popover-body validation"></div></div>'
            })`);

        } else {
            ko.cleanNode($('#zoneEditorDiv')[0]);
        }

        this.isEditingGeneral((this.zone().facilityBeaconGroupId() ?? 0) == 0);

        //this._getFacilityBuildings();

        this.deviceToVerify(0);
        this.deviceToVerifyString('');
        this.deviceToAddMessage('Select a device to add.');

        ko.applyBindingsToDescendants(this, $('#zoneEditorDiv')[0]);

        this.blagSelector.init(this.deviceToVerify, this.deviceToVerifyString);

        //window.eval('$("[data-toggle=confirmation]").confirmation({rootSelector:"[data-toggle=confirmation]"});');

        //this._onBeforeShow();

        $('#zoneEditorDiv').addClass('show');

        
        //this._onShown();



        this.deviceToVerify.subscribe(newValue => {
            this._verifyBlagAssignment();
        });

        this.isAllDevicesSelected.subscribe(_ => {
            this.toggleSelectedDevices();
        });

        //this.inmate().building.id.subscribe(newValue => {
        //    const dorm = this.facilityBuildings().find(x => x.facilityBuildingId() == this.inmate().building.id());

        //    if (dorm !== undefined) {
        //        this.inmate().building.description(dorm.description());
        //    }
        //});

        
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        //this._onBeforeHide();
        $('#zoneEditorDiv').removeClass('show');
        //this._onHidden();
    }

    unlockForm = () => {
        this.isReadOnly(false);
    }

    editGeneral = () => {
        this.isEditingGeneral(true);
    }

    saveGeneral = () => {
        this.isEditingGeneral(false);
        this._save();
    }

    //cancelGeneral = () => {
    //    this._getZone(this.zone().facilityBeaconGroupId())
    //        .then(() => this._getLocators())
    //        .then(() => this._getConfiguration());
    //    this.isEditingGeneral(false);
    //}

    cancelGeneral = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            let zoneId = this.zone().facilityBeaconGroupId();
            this._getZone(zoneId)
                .then(() => this._getPlacements())
                .then(() => this._getConfiguration())
                .then(() => {
                    this._addValidators();
                    //this.showDialog();
                    this.isEditingGeneral(false);
                    this.zone.valueHasMutated();
                    this.zone().placements.valueHasMutated();
                    this.zone().configuration.valueHasMutated();
                    resolve();
                })
        });
    }

    editConfig = () => {
        this.isEditingConfig(true);
    }

    saveConfig = () => {
        this.isEditingConfig(false);
        this._saveConfig();
    }

    cancelConfig = () => {
        this.isEditingConfig(false);
        this.zone().configuration().forEach((config) => {
            if (config.needsUpdate()) {
                config.undoChange();
            }
        });

    }

    changeProtocolVersion = (a, b) => {
        const id: number = parseInt(b.target.dataset.view);
        this.configProtocolVersion(id);

        this.zone().hasValidators(false);
        this.zone().getConfiguration(this.configProtocolVersion().toString())
            .then(() => {
                this._addValidators();
                this.zone.valueHasMutated();
            })

    }

    //cancelConfig = async (): Promise<void> => {
    //    return new Promise((resolve, reject) => {
    //        this._getConfiguration().then(() => {

    //            this.isEditingConfig(false);

                
    //            //this.zone.valueHasMutated();
    //            this.zone().configuration.valueHasMutated();
    //            this.zone.valueHasMutated();

    //            this._addValidators();

    //            resolve();
    //        })
    //    });
    //}

    private _onSave = (zoneId: number) => {
        if (this.onSave) {
            this.onSave(zoneId);
        }
    }

    //private _onCancel = () => {
    //    if (this.onCancel) {
    //        this.onCancel();
    //    }
    //}

    restoreValue = (setting) => {
        ConfigurationSetting.dataFor(setting).restore();
    }

    protected addPlacement(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: '/api/facility/placement',
                method: 'POST',
                data: {
                    zoneId: this.zone().facilityBeaconGroupId()
                },
                cache: false,
            })
                .done(results => {
                        
                    common.toast('success', `Placement Added`, 'Updated Zone');

                    this._getPlacements().then(() => {
                        this.zone.valueHasMutated();
                        this.zone().placements.valueHasMutated();
                        this.zone.valueHasMutated();
                    });

                    this.blagSelector.deselect();
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("ZoneDialog::addPlacement()", request, textStatus, error);
                    reject();
                })
            
        });
    }

    protected assignLocator(): Promise<boolean> {
        let placementId = this.placementToEdit().placementId();

        return new Promise((resolve, reject) => {

            if (this.deviceToVerify() != 0) {
                $.ajax({
                    url: '/api/facility/placement/device',
                    method: 'POST',
                    data: {
                        placementId: placementId,
                        deviceId: this.deviceToVerify()
                    },
                    cache: false,
                })
                    .done(results => {
                        this.showAddBlag(false);
                        this.deviceToAddMessage('Select a device to add.');

                        common.toast('success', `${this.deviceToVerify()} was added.`, 'Update Zone Locators');

                        this._getPlacements().then(() => {
                            this.zone.valueHasMutated();
                            this.zone().placements.valueHasMutated();
                            this.zone.valueHasMutated();
                        });

                        this.blagSelector.deselect();
                        resolve(results);
                    })
                    .fail((request, textStatus, error) => {
                        console.error("ZoneDialog::assignLocator()", request, textStatus, error);
                        reject();
                    })
            }
            else {
                this.deviceToAddMessage("Please select a device first");
                this.showAddBlag(false);
                resolve(false);
            }

        });
    }


    sendEnableDFU = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {

            let total = 0;

            for (let i = 0; i < this.zone().placements().length; i++) {
                
                for (let j = 0; j < this.zone().placements()[i].placementDevices().length; j++) {

                    if (this.zone().placements()[i].placementDevices()[j].isSelected()) {
                        this.zone().placements()[i].placementDevices()[j].sendDFU(3600);
                        total++;

                        // reset the checkbox
                        this.zone().placements()[i].placementDevices()[j].isSelected(false)
                    }
                }
            }

            // reset all selected
            this.isAllDevicesSelected(false);

            common.toast('success', `Command sent to ${total} device(s).`, 'Command Sent');
            resolve(true);

        });
    }

    sendLocateCommand = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {

            let total = 0;

            for (let i = 0; i < this.zone().placements().length; i++) {

                for (let j = 0; j < this.zone().placements()[i].placementDevices().length; j++) {

                    if (this.zone().placements()[i].placementDevices()[j].isSelected()) {
                        this.zone().placements()[i].placementDevices()[j].locate(900);
                        total++;

                        // reset the checkbox
                        this.zone().placements()[i].placementDevices()[j].isSelected(false)
                    }
                }
            }

            // reset all selected
            this.isAllDevicesSelected(false);

            common.toast('success', `15 minute Locate Command sent to ${total} device(s).`, 'Command Sent');
            resolve(true);

        });
    }

    allowConfig = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {

            let total = 0;

            for (let i = 0; i < this.zone().placements().length; i++) {

                for (let j = 0; j < this.zone().placements()[i].placementDevices().length; j++) {

                    if (this.zone().placements()[i].placementDevices()[j].isSelected()) {
                        this.zone().placements()[i].placementDevices()[j].toggleSendConfig(true);
                        total++;

                        // reset the checkbox
                        this.zone().placements()[i].placementDevices()[j].isSelected(false)
                    }
                }
            }

            // reset all selected
            this.isAllDevicesSelected(false);

            common.toast('success', `Allowing config for ${total} device(s).`, 'Command Sent');
            resolve(true);

        });
    }

    blockConfig = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {

            let total = 0;

            for (let i = 0; i < this.zone().placements().length; i++) {

                for (let j = 0; j < this.zone().placements()[i].placementDevices().length; j++) {

                    if (this.zone().placements()[i].placementDevices()[j].isSelected()) {
                        this.zone().placements()[i].placementDevices()[j].toggleSendConfig(false);
                        total++;

                        // reset the checkbox
                        this.zone().placements()[i].placementDevices()[j].isSelected(false)
                    }
                }
            }

            // reset all selected
            this.isAllDevicesSelected(false);

            common.toast('success', `Blocking config for ${total} device(s).`, 'Command Sent');
            resolve(true);

        });
    }

    private toggleSelectedDevices = () => {

        for (let i = 0; i < this.zone().placements().length; i++) {

            for (let j = 0; j < this.zone().placements()[i].placementDevices().length; j++) {

                this.zone().placements()[i].placementDevices()[j].isSelected(this.isAllDevicesSelected())
            }
        }
    };

    private _save(): Promise<object> {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/facility/beacon`,
                method: this.zone().facilityBeaconGroupId() === 0 ? 'POST' : 'PUT',
                data: this.zone().toFormData(false),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {

                    common.toast('success', `${this.zone().description()} was updated.`, 'Saved Zone');

                    if (this.zone().facilityBeaconGroupId() == 0) {
                        this.zone().facilityBeaconGroupId(parseInt(results));
                    }

                    this._onSave(this.zone().facilityBeaconGroupId());
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Save Zone General", request, textStatus, error);
                    reject();
                });
        });

    }

    private _verifyBlagAssignment = (): boolean => {

        if (this.deviceToVerify() > 0) {

            $.ajax({
                url: `/api/facility/beacon/device/${this.deviceToVerify()}/verifyassignment/${this.zone().facilityBeaconGroupId()}`,
                method: 'GET',
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {

                    this.deviceToAddMessage(results.message);
                    this.showAddBlag(results.allowContinue);

                })
                .fail((request, textStatus, error) => {
                    console.error("Blag Assignment Validation Failed", request, textStatus, error);

                });
        }
        return true;
    }

    private _saveConfig = () => {
        if (!this.hasErrors()) {
            let qString = "";
            if (this.configProtocolVersion() != null) {
                qString = "?protocolVersion=" + this.configProtocolVersion().toString();
            }
            $.ajax({
                url: `/api/facility/beacon/${this.zone().facilityBeaconGroupId()}/configuration${qString}`,
                method: 'PUT',
                data: this.zone().configToFormData(),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {
                    //this._hideDialog();
                    //this._onSave(this.device().deviceId, results);
                    common.toast('success', `${this.zone().description()} was updated.`, 'Update Zone Confguration');
                })
                .fail((request, textStatus, error) => {
                    console.error("Save Zone Configuration", request, textStatus, error);
                });
        }
    }

    showAddBlagModal = (data: Placement, event) => {

        this.placementToEdit(data);

        //this.bump(!this.bump());
        window.eval('$("#addBlagModal").modal("show")');
    }

    addBlagConfirmed = () => {
        this.assignLocator()
            .then(() => {
                this._onSave(this.zone().facilityBeaconGroupId());
                window.eval('$("#addBlagModal").modal("hide")');
            });
    }

    showRemoveBlagModal = (data: PlacementDevice, event) => {
        this.locatorToRemove = data;

        this.bump(!this.bump());
        window.eval('$("#removeBlagModal").modal("show")');
    }

    removeBlagConfirmed = () => {
        this.locatorToRemove.removePlacementDevice()
            .then(() => {
                this._onSave(this.zone().facilityBeaconGroupId());
                window.eval('$("#removeBlagModal").modal("hide")');
            });
    }

    showRemovePlacementModal = (data: Placement, event) => {
        this.placementToEdit(data);

        //this.bump(!this.bump());
        window.eval('$("#removePlacementModal").modal("show")');
    }

    removePlacementConfirmed = () => {
        this.placementToEdit().removePlacement();

        this._onSave(this.zone().facilityBeaconGroupId());
        window.eval('$("#removePlacementModal").modal("hide")');
    }

    showFirmwareModal = (event) => {

        window.eval('$("#loadFirmwareModal").modal("show")');
    }

    loadFirmwareConfirmed = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {

            let total = 0;

            for (let i = 0; i < this.zone().placements().length; i++) {

                for (let j = 0; j < this.zone().placements()[i].placementDevices().length; j++) {

                    if (this.zone().placements()[i].placementDevices()[j].isSelected()) {
                        this.zone().placements()[i].placementDevices()[j].firmwareUpdate(this.selectedFirmwareVersion(), this.isFirmwareForced());
                        total++;

                        // reset the checkbox
                        this.zone().placements()[i].placementDevices()[j].isSelected(false)
                    }
                }
            }

            // reset all selected
            this.isAllDevicesSelected(false);

            common.toast('success', `Firmware Load sent to ${total} device(s).`, 'Command Sent');
            window.eval('$("#loadFirmwareModal").modal("hide")');

            resolve(true);

        });
    }

    private _getFacilityBuildings = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sessionStorage.getItem("facilityBuildings")) {
                //alert("from Session");

                let results = sessionStorage.getItem("facilityBuildings");
                let resultOjbect = JSON.parse(results);
                this._setFacilityBuildings(resultOjbect);
                resolve();
            }
            else {

                //alert("api call");

                const path = `/api/facility/building/list`;
                $.get(path)
                    .done(results => {
                        sessionStorage.setItem("facilityBuildings", JSON.stringify(results));
                        this._setFacilityBuildings(results);
                        resolve();
                    })

                    .fail((a, b, c) => {
                        console.error("_getFacilityBuildings::fetchData()", a, b, c);
                        reject();
                    })
            }
        })
    }

    private _setFacilityBuildings = (data?: object) => {
        var map = {
            create: (dorm) => new FacilityBuilding(dorm.data)
        };
        this.facilityBuildings = mapping.fromJS(data, map);
        this.bump(!this.bump());
    }

    private _getFacilityZoneTypes = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sessionStorage.getItem("facilityZoneTypes")) {
                
                let results = sessionStorage.getItem("facilityZoneTypes");
                let resultOjbect = JSON.parse(results);
                this._setFacilityZoneTypes(resultOjbect);
                resolve();
            }
            else {

                const path = `/api/facility/zonetype/list`;
                $.get(path)
                    .done(results => {
                        sessionStorage.setItem("facilityZoneTypes", JSON.stringify(results));
                        this._setFacilityZoneTypes(results);
                        resolve();
                    })

                    .fail((a, b, c) => {
                        console.error("_getFacilityZoneTypes::fetchData()", a, b, c);
                        reject();
                    })
            }
        })
    }

    private _setFacilityZoneTypes = (data?: object) => {
        var map = {
            create: (item) => new ZoneType(item.data)
        };
        this.facilityZoneTypes = mapping.fromJS(data, map);
        this.bump(!this.bump());
    }

    private _getAvailableFirmware = (): Promise<void> => {
        return new Promise((resolve, reject) => {

            $.ajax({
                url: '/api/firmware/search',
                method: 'POST',
                data: {
                    deviceType: DeviceType.ILinkLocator,
                    firmwareType: "NRF",
                    dfuOnly: true

                },
                cache: false,
            })
                .done(results => {

                    this._setAvailableFirmware(results);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error("_getAvailableFirmware::fetchData()", request, textStatus, error);
                    reject();
                })
            
        })
    }

    private _setAvailableFirmware = (data?: object) => {
        var map = {
            create: (dorm) => new FirmwareVersion(dorm.data)
        };
        this.availableFirmware = mapping.fromJS(data, map);
        this.bump(!this.bump());
    }

    private _setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 27: //ESC
                        this.hideDialog();
                        e.preventDefault();
                        break;
                }
            }
        });
    }

    //doAction = () => {
    //    const action = $(event.target).data('action');
    //    $.get(`/api/device/${this.device().deviceId}/action/${action}`)
    //        .then(() => common.toast('success', 'Action has been queued', 'Device Actions'))
    //        .fail(() => common.toast('error', 'Action was not queued', 'Device Actions'))
    //}
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Zones ??= () => { /* */ };
globalThis.DIG.Zones.ZoneDialog = ZoneDialogModel
