﻿import ko = require("knockout")
import $ = require('jquery')
import mapping = require("knockout.mapping")

import { CommonViewModel, DeviceType, EntityType } from "../Common"
import { EntitySelectViewModel } from "../Components/EntitySelectDropdown"
import { StaffDevice } from "./StaffDevice"
const common: CommonViewModel = globalThis.DIG.Common


/////////////////////////////////////// This displays the data and calls the "save" method but that's it. Need to add the add new Staff option too

export class StaffDeviceDialogModel {
    //
    //  Events
    //
    onSave: Function = null
    //onCancel: Function = null
    //onLocatorsChanges: Function = null;

    //
    //  Properties
    //
    staffDevice: ko.Observable<StaffDevice> = ko.observable(null);
    staffSelector: EntitySelectViewModel = null;

    assignedStaffId: ko.Observable<number> = ko.observable(0);
    assignedStaffDescription: ko.Observable<string> = ko.observable('');

    newStaffFirstname: ko.Observable<string> = ko.observable('');
    newStaffLastname: ko.Observable<string> = ko.observable('');
    newStaffComment: ko.Observable<string> = ko.observable('');

    
    isReadOnly: ko.Observable<boolean> = ko.observable(true);
    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    allowEdit: ko.Observable<boolean> = ko.observable(true)

    isReadOnlyDefault = true

    dialogTitle: ko.Computed<string> = ko.computed((): string => {
        return this.staffDevice()
            ? `${this.staffDevice().deviceId}`
            : 'Staff Device Dialog - Not Initialized';
    })

    
    showAddNew: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        return this.assignedStaffId() == -1;
    })

    hasAssignmentChanged: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        let originalAssignment: number = 0;

        if (this.staffDevice().assignment() != null) {
            originalAssignment = this.staffDevice().assignment().staffId();
        }

        return this.assignedStaffId() != originalAssignment;
    })

   
    //
    //  Methods
    //
    constructor(options) {
        if (options.isReadOnly !== undefined) this.isReadOnlyDefault = options.isReadOnly;
        if (options.allowEdit !== undefined) this.allowEdit(options.allowEdit);

        this.staffSelector = new EntitySelectViewModel({
            entityType: EntityType.Staff,
            subTypes: null,
            showNoSelection: true,
            noSelectionDescription: "Unassigned",
            autoSelectSingle: false,
            maxHeight: 220,
            showAddNew: true,
            addNewId: -1,
            addNewDescription: "Create New"
        });
    }

    edit = async (deviceId?: number): Promise<void> => {
       
        return new Promise((resolve, reject) => {
            this._getStaffDevice(deviceId)
                .then(() => this._initializeEntityControl())
                .then(() => {
                    this.showDialog();
                    resolve();
                })
        })
    }

    private _initializeEntityControl = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.staffDevice().assignment() != null) {
                this.assignedStaffId(this.staffDevice().assignment().staffId());
                this.assignedStaffDescription(this.staffDevice().assignment().staff().lastName() + ", " + this.staffDevice().assignment().staff().firstName());
            }
            else {
                this.assignedStaffId(0);
                this.assignedStaffDescription("");
            }
            resolve();
        })
    }

    private _getStaffDevice = (deviceId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (deviceId) {
                //this.isReadOnly(this.isReadOnlyDefault);
                StaffDevice.getById(deviceId, this)
                    .then(device => {
                        this.staffDevice(device)
                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                resolve();
            }
        })
    }

    showDialog = () => {
        if ($('#staffDeviceEditorDiv').length === 0) {
            $.get('/staffDevices/editor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'staffDeviceEditorDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#staffDeviceEditorDiv').html(results);
                    $('#staffDeviceEditorModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }

    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {

            $(':input').attr('data-lpignore', 'true');

            this._setupKeyBindings();
            this._dialogInitialized = true;

        } else {
            ko.cleanNode($('#staffDeviceEditorDiv')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#staffDeviceEditorDiv')[0]);
        this.staffSelector.init(this.assignedStaffId, this.assignedStaffDescription);
        window.eval('$("#staffDeviceEditorModal").modal("show")');
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        //this._onBeforeHide();
        //$('#staffDeviceEditorDiv').removeClass('show');
        window.eval('$("#staffDeviceEditorModal").modal("hide")');
        //this._onHidden();
    }

    saveAssignment = () => {
        //this.isEditingGeneral(false);
        this._save();
        this._hideDialog();
    }

    private _onSave = (deviceId: number, deviceDetails) => {
        if (this.onSave) {
            this.onSave(deviceId, deviceDetails);
        }
    }

    private _save(): Promise<object> {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/staff/device`,
                method: 'PUT',
                data: {
                    deviceId: this.staffDevice().deviceId,
                    staffId: this.assignedStaffId(),
                    firstname: this.newStaffFirstname(),
                    lastname: this.newStaffLastname(),
                    comment: this.newStaffComment()
                },
                cache: false
            })
                .done(results => {

                    common.toast('success', `${this.staffDevice().deviceId()} was updated.`, 'Saved Assignment');

                    this._onSave(this.staffDevice().deviceId(), results);
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Save Assignment", request, textStatus, error);
                    reject();
                });
        });

    }

    private _setupKeyBindings = () => {
        $(document).keydown((e: any) => {
            if ($('.dropdown.show').length === 0) {
                e = e || window.event;

                switch (e.keyCode) {
                    case 27: //ESC
                        this.hideDialog();
                        e.preventDefault();
                        break;
                }
            }
        });
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Staff ??= () => { /* */ };
globalThis.DIG.Staff.StaffDeviceDialog = StaffDeviceDialogModel
