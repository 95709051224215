﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")

import Loading = require("../LoadingIndicator")
import { Alarm } from "../Alarms/Alarm"
import { CommonViewModel } from "../Common"
import { AlarmDialogOptions, Dialogs } from "../Dialogs"

const common: CommonViewModel = globalThis.DIG.Common;

export class CurrentAlarms {
    alarms: any = ko.observableArray<Alarm>([])
    loading: Loading.LoadingIndicator

    //
    //  Paging properties
    //
    page: ko.Observable<number> = ko.observable(-1);
    direction: ko.Observable<number> = ko.observable(-1);
    startIndex: ko.Observable<number> = ko.observable(-1);
    endIndex: ko.Observable<number> = ko.observable(-1);
    pageSize: ko.Observable<string> = ko.observable('20');
    sort: ko.Observable<string> = ko.observable('alarmTimestamp');

    dialogOptions: AlarmDialogOptions = {
        onDismissed: (alarmId) => {
            this.removeById(alarmId)
        },
        onSave: undefined,
        onShown: undefined,
        onHidden: undefined
    }

    constructor(container: any) {
        this.loading = new Loading.LoadingIndicator(container);

        common.newAlarm = (alarm) => {
            this.getAlarms();
        };
    }

    recordCount: ko.PureComputed<number> = ko.pureComputed(() => {
        return this.sorted().length;
    });

    pageCount: ko.PureComputed<number> = ko.pureComputed(() => {
        return Math.ceil(this.recordCount() / parseInt(this.pageSize()));
    });
     
    sorted: ko.Computed<any> = ko.computed(() => {
        var pageSizeRead = this.pageSize();
        var sortRead = this.sort();
        var directionRead = this.direction();

        this.page(0);

        return this.alarms().sort((a, b) => {
            let aDate: any = moment(a[this.sort()]);
            let bDate: any = moment(b[this.sort()]);
            return (aDate - bDate) * this.direction();
        });
    });

    dataPage: ko.PureComputed<any> = ko.pureComputed(() => {
        return this.sorted();

        //let page: number = this.page();
        //let pageSize: number = parseInt(this.pageSize());

        //let startIndex: number = page * pageSize;
        //let endIndex: number = startIndex + pageSize;

        //this.startIndex(startIndex < 0 ? 0 : Math.min(startIndex + 1, this.sorted().length));
        //this.endIndex(Math.min(endIndex, this.sorted().length));

        //return this.sorted().slice(startIndex, endIndex);
    });

    getAlarms() {
        this.loading.show();

        $.ajax({
            url: '/api/alarm/open',
            cache: false,
            method: 'GET',
            success: (results) => {
                this.setAlarms(results);
            },
            error: (a, b, c) => { console.error("CurrentAlarms::getAlarms()", a, b, c); },
            complete: _ => this.loading.hide()
        });
    };

    setAlarms = (alarms) => {
        var alarmMap = {
            key: (alarm) => ko.utils.unwrapObservable(alarm.alarmId),
            create: (alarm) => new Alarm(alarm.data, this)
        };

        this.alarms = mapping.fromJS(alarms, alarmMap);
        this.sort.valueHasMutated();
    };

    protected find(id: number) {
        return ko.utils.arrayFirst(this.alarms(), (item) => item.getId() === id);
    }

    removeById = (id: number): boolean => {
        let result = false;
        const alarm = this.find(id);

        if (alarm) {
            this.alarms.remove(alarm);
            result = true;
        }

        return result;
    }

    editAlarm = (alarmId: number) => Dialogs.editAlarm(alarmId, this.dialogOptions);

    //allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
    //    return this.page() < this.pageCount() - 1;
    //});

    //allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
    //    return this.page() > 0;
    //});

    //previous = () => {
    //    if (this.allowPrevious()) {
    //        this.page(this.page() - 1);
    //    }
    //};

    //next = () => {
    //    if (this.allowNext()) {
    //        this.page(this.page() + 1);
    //    }
    //};

    dataFor = (alarm) => ko.dataFor(alarm);
}

globalThis.DIG ??= () => { };
globalThis.DIG.Dashboard ??= () => { };
globalThis.DIG.Dashboard.PastAlarms = CurrentAlarms;