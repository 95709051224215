﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import moment = require('moment-timezone')

import { isNullOrUndefined } from 'util'
import { CommonViewModel } from '../Common'
import { AlarmDetection } from './AlarmDetection'

const common: CommonViewModel = globalThis.DIG.Common

export class AlarmZone {
    alarmId: number
    zoneId: number
    description: string
    //detectedby: { [key: string]: moment.Moment }
    detections: AlarmDetection[]


    constructor(data) {

        var map = {
            'copy': ["alarmId", "zoneId", "description", "detections"]
        }

        mapping.fromJS(data, map, this);

        var childMap = {
            create: (inObj) => new AlarmDetection(inObj.data)
        };

        if (!isNullOrUndefined(data["detections"])) {
            this.detections = ko.utils.unwrapObservable(mapping.fromJS(data["detections"], childMap));
        }
        else {
            this.detections = null;
        }

        //mapping.fromJS(data, {}, this);
    }

    detectedBlagIds = (): string => {
        let ids = ""
        this.detections.forEach((blag) => {
            ids += blag.deviceId.toString() + ", ";
        });

        ids = ids.substring(0, ids.length - 2); // remove comma

        return ids;
    };

    latestDetectedTime = (): string => {
        let moments = this.detections.map(d => moment(d.timestamp)), maxDate = moment.max(moments)

        return common.toFacilityTime(maxDate).format('h:mm:ss A');
    };

}