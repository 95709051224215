﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { CommonViewModel, DeviceType, EntityType } from "../Common"
import { EntitySelectViewModel } from "../Components/EntitySelectDropdown"
import { Inmate } from "../Inmates/Inmate"
import { GenericEntity } from "../GenericEntity"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateAssignmentRcDialog {

    //
    //  Events
    //
    onSave: Function = null

    //
    //  Properties
    //
    unassignReasons: ko.ObservableArray<GenericEntity> = ko.observableArray<GenericEntity>()
    tagSelector: EntitySelectViewModel = null;
    inmate: ko.Observable<Inmate> = ko.observable(null);
    unassignmentReasonId: ko.Observable<number> = ko.observable();
    currentAssignmentDetails: ko.Observable<string> = ko.observable('')
    originalDeviceId: number = 0;

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    

    displayInmateName: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.inmate() == null) {
            return "";
        }
        else {
            return `${this.inmate().lastName()}, ${this.inmate().firstName()}`;
        }
    });

    displayIdentifier: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.inmate() == null) {
            return "";
        }
        else {
            return this.inmate().inmateIdentifier();
        }
    });

    displayImageSource: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.inmate() == null) {
            return "";
        }
        else {
            return this.inmate().imageSource();
        }
    });

    displayUnassignReason: ko.Computed<boolean> = ko.computed<boolean>(() => {
        var bump = this.bump();

        if (this.inmate() == null || this.originalDeviceId == 0) {
            return false;
        }
        else {
            return this.inmate().deviceId() != this.originalDeviceId
        }
    });


    private _getInmate = (inmateId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (inmateId) {
                Inmate.getById(inmateId)
                    .then(inmate => {
                        this.inmate(inmate)

                        this.originalDeviceId = this.inmate().deviceId();
                        this.tagSelector.init(this.inmate().deviceId, this.inmate().deviceDescription);
                        ko.applyBindingsToDescendants(this, $('#assignmentRCModal')[0]);
                        
                        this.inmate().deviceId.subscribe(newValue => {
                            this._verifyTagAssignment();
                        });

                        this.bump(!this.bump());

                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                this.inmate(new Inmate());
                resolve();
            }
        })
    }

    private _verifyTagAssignment = (): boolean => {
        $.ajax({
            url: `/api/device/${this.inmate().deviceId()}/verifyAssignment?inmateId=${this.inmate().inmateId()}`,
            method: 'GET',
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(results => {
                if (results.displayPrompt) {
                    this._showInmateTransfer(results);
                }
                else {
                    this.currentAssignmentDetails('');
                }
            })
            .fail((request, textStatus, error) => {
                console.error("Device Validation Failed", request, textStatus, error);

            });

        return true;
    }

    private _showInmateTransfer = (result) => {

        let message = null;
        if (result.currentOwnerName != null) {
            message = `This device currently belongs to ${result.currentOwnerName}`;
        }

        if (result.assignedInmateLastName != null) {
            if (message == null) {
                message = `Device ${this.inmate().deviceId()} is currently assigned to ${result.assignedInmateLastName}, ${result.assignedInmateFirstName} (${result.assignedInmateIdentifier}). <br /> Do you want to remove it from ${result.assignedInmateLastName}, ${result.assignedInmateFirstName} and assign it to ${this.inmate().lastName()}, ${this.inmate().firstName()}?`;
            }
            else {
                message += ` and is assigned to ${result.assignedInmateLastName}, ${result.assignedInmateFirstName} (${result.assignedInmateIdentifier})`;
            }
        }

        this.currentAssignmentDetails(message);
    }


    protected saveDeviceAssignment = () => {

        $.ajax({
            url: '/api/inmate/general',
            method: this.inmate().inmateId() === 0 ? 'POST' : 'PUT',
            data: this._packageData(),
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(results => {
                this._tagSwapComplete(this.inmate().inmateId(), results);
            })
            .fail((request, textStatus, error) => {
                console.error("InmateAssignmentRcDialog::saveDeviceAssignment()", request, textStatus, error);

            });

    }

    protected closeDeviceAssignment = () => {
        this._hideDialog();
    }

    
    inmateId: number = 0;
    showDialog = (inmateId: number) => {
        this.inmateId = inmateId;

        if ($('#easyAssignmentDiv').length === 0) {
            $.get('/inmates/AssignmentRC')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'easyAssignmentDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#easyAssignmentDiv').html(results);
                    $('#assignmentRCModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
       
        if (!this._dialogInitialized) {
            this._dialogInitialized = true;
        }
        else {
            ko.cleanNode($('#assignmentRCModal')[0]);
        }

        window.eval('$("#assignmentRCModal").modal("show")');

        this._getInmate(this.inmateId);
        this.currentAssignmentDetails("");
    }

    private _hideDialog = () => {
        window.eval('$("#assignmentRCModal").modal("hide")');
    }

    constructor(options) {

        this.tagSelector = new EntitySelectViewModel({
            entityType: EntityType.Device,
            subTypes: [DeviceType.Tag],
            showNoSelection: true,
            noSelectionDescription: "Unassigned",
            autoSelectSingle: true
        });
    }

    _tagSwapComplete(inmateId, results) {
        common.toast("success", "Assignment Complete", 'Update Inmate');

        this._onSave(this.inmateId, this.inmate().deviceId());

        this._hideDialog();
    }

    private _packageData = (): FormData => {
        let formData = <FormData>this.inmate().toFormData(false);
        formData.append('unassignmentReasonId', this.unassignmentReasonId().toString());

        return formData;
    }

    private _onSave = (inmateId: number, deviceId) => {
        if (this.onSave) {
            this.onSave(inmateId, deviceId);
        }
    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.InmateAssignmentRcDialog ??= () => { /* */ };
globalThis.DIG.InmateAssignmentRcDialog.ViewModel = InmateAssignmentRcDialog;