﻿export class ChartExtenders {
    public static RangePlugin = {
        renderRange: function (chart) {
            const ctx = chart.chart.ctx;
            const xaxis = chart.scales['x-axis-0'];
            const yaxis = chart.scales['y-axis-0'];

            const median = chart.config.data.median;
            const offset = chart.config.data.medianOffset;

            const medianPos = yaxis.getPixelForValue(median);
            const rangeBegin = yaxis.getPixelForValue(median + offset);
            const rangeEnd = yaxis.getPixelForValue(median - offset);

            ctx.save();

            ctx.fillStyle = 'rgba(0, 255, 0, 0.3)';
            ctx.fillRect(xaxis.left, Math.min(rangeBegin, rangeEnd), xaxis.right - xaxis.left, Math.max(rangeBegin, rangeEnd) - Math.min(rangeBegin, rangeEnd));

            ctx.fillStyle = "#000000";
            ctx.textAlign = 'right';

            ctx.fillText('+2.5°', 45, rangeBegin + 2);
            ctx.fillText('-2.5°', 45, rangeEnd + 2);
            ctx.fillText('Nominal', 45, medianPos + 2);

            ctx.beginPath();
            ctx.fillStyle = "#000055";
            ctx.lineWidth = 4;
            ctx.setLineDash([10, 10]);
            ctx.moveTo(49, medianPos);
            ctx.lineTo(xaxis.right, medianPos);
            ctx.stroke();

            ctx.restore();
        },

        beforeDatasetsDraw: function (chart, easing) {
            if (chart.config.data.median) {
                this.renderRange(chart);
            }
        }
    }

    public static NoDataPlugin = {
        afterDraw: function(chart) {
            if (chart.data.datasets.length === 0
                || chart.data.datasets[0].length === 0
                || chart.data.datasets[0].data.length === 0) {

                var ctx = chart.chart.ctx;
                var width = chart.chart.width;
                var height = chart.chart.height

                chart.clear();

                ctx.save();
                ctx.fillStyle = "gainsboro";
                ctx.fillRect(0, 0, width, height);

                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = "#000000";
                ctx.font = "24px Arial";
                ctx.fillText('No data to display', width / 2, height / 2);
                ctx.restore();
            }
        }
    }
}