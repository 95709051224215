﻿import $ = require('jquery')
import ko = require("knockout")

import { CommonViewModel, EntityType } from "../Common"
import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { Dialogs, DeviceDialogOptions } from "../Dialogs"
import { Device } from "./Device"
import { GenericEntity } from '../GenericEntity'

const common: CommonViewModel = globalThis.DIG.Common;

export class DevicesViewModel extends ViewModelBase<Device> {
    static runTestResultReport = (): any => {
        const data = common.objectToFormData({
            deviceId: 0,
            testTimeStart: '1/1/2021',
            testTimeEnd: '12/31/3000',
        });

        common.postBinaryRequest('/api/report/testresults', data);
    }

    options: ViewModelBaseOptions = {
        settingPrefix: "Devices",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: false,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            enabled: false,
            show: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Device Id', 'Type', 'L21 Version', 'R35 Version', 'Battery', 'Assignment', 'Inmate Id', 'Location', 'Last Event'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [{
            description: '<u>T</u>est Results',
            class: 'bg-dark',                           
            callback: DevicesViewModel.runTestResultReport,
            altKey: 'T'
        }],
        selectionActions: [],
        rightClickActions: []
    }

    dialogOptions: DeviceDialogOptions = {
        isReadOnly: true,
        showAlarms: true,
        showCommands: true,
        showConfiguration: true,
        showRemoteDebug: false,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onCancel: null
    }

    deviceTypeAll: ko.ObservableArray<GenericEntity> = ko.observableArray<GenericEntity>([]) // values set in init in order to use customStrings
    deviceTypesFilter: ko.Observable<string> = ko.observable('')
    deviceTypeSelected: ko.ObservableArray<string> = ko.observableArray<string>([])

    protected createItem = (data?: object, view?: object) => new Device(data, view)

    onClick = (id) => {
        Dialogs.editDevice(id, this.dialogOptions);
    }

    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get('/api/device')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("DevicesViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    filterData = (): Device[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: Device) => item.isMatch(filterText, this.deviceTypeSelected()));
    }

    //inmateDialog: InmateDialogModel = new InmateDialogModel({
    //    isReadOnly: false,
    //    showAlarms: false,
    //    showConfiguration: true,
    //    allowEdit: true
    //});

    //deviceDialog: DeviceDialogModel = new DeviceDialogModel({
    //    isReadOnly: false,
    //    showAlarms: false,
    //    showConfiguration: true,
    //    showAssignments: true,
    //    allowEdit: true
    //});

    public constructor() {
        super();
    }

    initChild = () => {
        common.inmateLocationChanged = this.inmateLocationChanged;

        this.deviceTypeAll = ko.observableArray<GenericEntity>([
            new GenericEntity(1, common.customStrings.getByEntity(EntityType.Device, 1).shortDescription),
            new GenericEntity(2, common.customStrings.getByEntity(EntityType.Device, 2).shortDescription),
            new GenericEntity(4, common.customStrings.getByEntity(EntityType.Device, 4).shortDescription),
            new GenericEntity(5, common.customStrings.getByEntity(EntityType.Device, 5).shortDescription)
        ])

        // these are not saved for the time being so give all options when page loads / refreshes.
        this.deviceTypesFilter("1|2|4|5");

        if (this.deviceTypesFilter() != '') {
            this.deviceTypesFilter().split("|").forEach(s => this.deviceTypeSelected.push(s));
        }
    }

    inmateLocationChanged = (inmateId, location) => {
        const device = this.data().find(e => e.inmateId == inmateId);

        if (device) {
            device.location(location);
        }
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Devices === undefined) {
    globalThis.DIG.Devices = () => { /* */ };
}

globalThis.DIG.Devices.ViewModel = DevicesViewModel