﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"
import { ViewModelItem } from "../ViewModelItem"
import { Dialogs } from "../Dialogs"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateLocationInfo extends ViewModelItem {
    inmateId: ko.Observable<number> = ko.observable(0)
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    imageSource: ko.Observable<string> = ko.observable<string>('/images/photoUnavailable.jpg')
    isAuthorized: ko.Observable<boolean> = ko.observable<boolean>(false)
    inmateBuilding = { id: ko.observable<number>(0), description: ko.observable<string>('') }
    block: ko.Observable<string> = ko.observable<string>('')
    bunk: ko.Observable<string> = ko.observable<string>('')
    whenEntered: moment.Moment = ''.toMoment()
    whenExited: moment.Moment = ''.toMoment()
    durationSeconds: ko.Observable<number> = ko.observable(0)
    totalDurationSeconds: ko.Observable<number> = ko.observable(0)

    //displayBuilding: ko.Computed<string> = ko.computed<string>(() => this.inmateBuilding?.description() ?? '')
    displayInmateName: ko.PureComputed<string> = ko.pureComputed<string>(() => `${this.lastName()}, ${this.firstName()}`)
    displayEnteredTime: ko.PureComputed<string> = ko.pureComputed<string>(() => common.toFacilityTime(this.whenEntered).format('M/DD/YYYY h:mm A'))
    displayExitedTime: ko.PureComputed<string> = ko.pureComputed<string>(() => common.toFacilityTime(this.whenExited).format('M/DD/YYYY h:mm A'))

    displayDuration: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let hours: number = Math.floor(this.durationSeconds() / 60 / 60);
        let minutes: number = Math.round( (this.durationSeconds() / 60) % (60) );

        if (hours > 0) {
            return `${hours}h ${minutes}m`;
        }
        else {
            return `${minutes}m`;
        }
    })

    displayTotalDuration: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let hours: number = Math.floor(this.totalDurationSeconds() / 60 / 60);
        let minutes: number = Math.round((this.totalDurationSeconds() / 60) % (60));

        if (hours > 0) {
            return `${hours}h ${minutes}m`;
        }
        else {
            return `${minutes}m`;
        }
    })

    displayDetailedDorm: ko.PureComputed<string> = ko.pureComputed(() => {

        if (this.inmateBuilding == null || this.inmateBuilding.id() == 0) {
            return "";
        }
        else {
            let dorm = this.inmateBuilding.description();

            if (this.block() != null && this.block() != "") {
                dorm += "-" + this.block();
            }

            if (this.bunk() != null && this.bunk() != "") {
                dorm += "-" + this.bunk();
            }

            return dorm;
        }
    })

    constructor(data?: object) {
        super();

        if (data) {
            mapping.fromJS(data, {}, this);
            this.whenEntered = moment(data["whenEntered"]);
            this.whenExited = moment(data["whenExited"]);
            this.imageSource(`/api/inmate/thumbnail/${this.inmateId()}`);
        }
    }

    static getById = async (zoneId: number): Promise<InmateLocationInfo> => {
        // this object is only used in a list as a whole, details are never fetched.
        return null;
    }

    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');

            result = (regexName1.test(this.firstName()) && regexName2.test(this.lastName()))
                || (regexName1.test(this.lastName()) && regexName2.test(this.firstName()))
                || (regexName1.test(this.inmateIdentifier()));
        }

        return result;
    }

    public compare(compareTo, sort: string, direction: number): number {
        let result = null;
        let aDate, bDate;

        switch (sort.toLowerCase()) {
            case 'inmate identifier':
                result = this.inmateIdentifier().localeCompare(compareTo.inmateIdentifier()) * direction;
                break;

            case 'inmate name':
                result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                if (result === 0) {
                    result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                }
                break;

            case 'enter':
                aDate = moment(this.whenEntered);
                bDate = moment(compareTo.whenEntered);
                result = (aDate - bDate) * direction;
                break;

            case 'exit':
                aDate = moment(this.whenExited);
                bDate = moment(compareTo.whenExited);
                result = (aDate - bDate) * direction;
                break;

            case 'duration':
                result = ((this.durationSeconds() ?? 0) - (compareTo.durationSeconds() ?? 0)) * direction;
                break;

            case 'total duration':
                result = ((this.totalDurationSeconds() ?? 0) - (compareTo.totalDurationSeconds() ?? 0)) * direction;
                break;

            case 'inmate building':
                result = this.displayDetailedDorm().localeCompare(compareTo.displayDetailedDorm()) * direction;

        }

        return result;
    }

    public itemDescription(sort: string): string {
        switch (sort.toLowerCase()) {
            case 'zone id': sort = 'facilityBeaconGroupId'; break;
            case 'description': sort = 'description'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public getId = (): number => this.inmateId(); //incorrect but again, not used currently, controller needs to return the realId

    public editInmate = (e) => {
        Dialogs.editInmate(e.inmateId());
    };
}
