﻿import ko = require("knockout")

export class TagsReported {
    description: ko.Observable<string> = ko.observable('')
    itemClass: ko.Observable<string> = ko.observable('')
    hours: ko.Observable<number> = ko.observable(0)

    link: ko.Observable<string> = ko.observable('')

    constructor(data: any) {
        this.description(`${data.data.label}<br />${data.data.data[0]} / ${data.data.data[1]}`)
        this.itemClass(`btn ${data.data.backgroundColor} ${data.data.borderColor}`)
        this.hours(parseInt(data.data.data[2]))
        this.link(`viewModel.rollCall.showSummaryDetail(${this.hours()});`);
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Dashboard === undefined) {
    globalThis.DIG.Dashboard = () => { };
}

globalThis.DIG.Dashboard.TagsReported = TagsReported;
