﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { EventTypeEnum } from "../EventTypes"

const common = globalThis.DIG.Common;

export class AlarmEvent {
    view: any
    inmate: any

    eventId: ko.Observable<number> = ko.observable(0)
    deviceId: ko.Observable<number> = ko.observable(0)
    inmateId: ko.Observable<number> = ko.observable(0)
    eventType: ko.Observable<number> = ko.observable(EventTypeEnum.Undefined)
    currentStatus: ko.Observable<number> = ko.observable(0)
    isAlarmEvent: ko.Observable<boolean> = ko.observable(false)
    isClearEvent: ko.Observable<boolean> = ko.observable(false)
    isInfoEvent: ko.Observable<boolean> = ko.observable(false)
    eventTimestamp: ko.Observable<string> = ko.observable('')
    storedTimestamp: ko.Observable<string> = ko.observable('')
    sTimer: ko.Observable<number> = ko.observable(0)
    gatewayRssi: ko.Observable<number> = ko.observable(0)
    frameCounter: ko.Observable<number> = ko.observable(0)
    eventData: ko.ObservableArray<any> = ko.observableArray<any>()

    constructor(data: any, inmate: any, view: any) {
        this.view = view;
        this.inmate = inmate;

        mapping.fromJS(data, {}, this);
    }

    displayEventTime: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.eventTimestamp());
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    })

    displayEventTimePlain: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.eventTimestamp());
        return time.format('M/D/YYYY h:mm:ss A');
    })

    displayStoredTime: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.storedTimestamp());
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    })

    displayStoredTimePlain: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.storedTimestamp());
        return time.format('M/D/YYYY h:mm:ss A');
    })

    displayStoredTimeDiff: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.storedTimestamp());
        let diff = moment(this.storedTimestamp()).diff(moment(this.eventTimestamp()), 's', true);

        return `${time.format('M/D/YYYY h:mm:ss A')} (${diff}s)`;
    })

    eventTypeDescription: ko.Computed<string> = ko.computed<string>(() =>
        common.getEventTypeById(this.eventType()).description)

    typeIcon: ko.Computed<string> = ko.computed(() => {
        return this.isAlarmEvent()
            ? 'fas fa-bell-school alarm-icon'
            : this.isClearEvent()
                ? 'fal fa-bell-school-slash'
                : 'fal fa-info';
    })
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Alarms ??= () => { /* */ };
globalThis.DIG.Alarms.AlarmEvent = AlarmEvent;