﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import { CommonViewModel, DeviceType, EntityType } from "../Common"
import { Inmate } from "../Inmates/Inmate"
import { InmatePresence } from "../Facility/InmatePresence"

const common: CommonViewModel = globalThis.DIG.Common

export class InmatePresenceRcDialog {

    //
    //  Events
    //
    onSave: Function = null

    //
    //  Properties
    //
    inmate: ko.Observable<Inmate> = ko.observable(null);
    inmatePresence: ko.ObservableArray<InmatePresence> = ko.observableArray<InmatePresence>()
    originalPresence = { inmatePresenceTypeId: ko.observable<number>(0), description: ko.observable<string>('') }
    originalDeviceId: number = 0;

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    

    displayInmateName: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.inmate() == null) {
            return "";
        }
        else {
            return `${this.inmate().lastName()}, ${this.inmate().firstName()}`;
        }
    });

    displayIdentifier: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.inmate() == null) {
            return "";
        }
        else {
            return this.inmate().inmateIdentifier();
        }
    });

    displayImageSource: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        if (this.inmate() == null) {
            return "";
        }
        else {
            return this.inmate().imageSource();
        }
    });

    displayPresenceWarning: ko.Computed<boolean> = ko.computed<boolean>(() => {
        var bump = this.bump();

        if (this.inmate() != null) {
            let presence = this.inmatePresence().find(x => x.inmatePresenceTypeId() == this.inmate().presence.inmatePresenceTypeId());

            if (presence !== undefined && presence.forceUnassignmentReasonId() > 0) {

                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    });

    displayWarntingText: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();

        if (this.displayPresenceWarning()) {

            if (this.inmate().deviceId() > 0) {
                return `Device ${this.inmate().deviceId()} will be unassigned.`;
            }
            else {
                return "Presence unmonitored.";
            }
        }
        else {
            return "";
        }
    });

    inmatePresenceData: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.inmatePresence();
    })

    private _getFacilityInmatePresence = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (sessionStorage.getItem("facilityInmatePresence")) {

                let results = sessionStorage.getItem("facilityInmatePresence");
                let resultOjbect = JSON.parse(results);
                this._setFacilityInmatePresence(resultOjbect);
                resolve();
            }
            else {
                const path = `/api/facility/inmatePresenceList`;
                $.get(path)
                    .done(results => {
                        sessionStorage.setItem("facilityInmatePresence", JSON.stringify(results));
                        this._setFacilityInmatePresence(results);
                        resolve();
                    })

                    .fail((a, b, c) => {
                        console.error("_getFacilityInmatePresence::fetchData()", a, b, c);
                        reject();
                    })
            }
        })
    }

    private _setFacilityInmatePresence = (data?: object) => {
        var map = {
            create: (dorm) => new InmatePresence(dorm.data)
        };
        this.inmatePresence = mapping.fromJS(data, map);
        this.bump(!this.bump());
    }

    private _getInmate = (inmateId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (inmateId) {
                Inmate.getById(inmateId)
                    .then(inmate => {
                        this.inmate(inmate)

                        this.originalPresence = this.inmate().presence;
                        this.originalDeviceId = this.inmate().deviceId();
                        ko.applyBindingsToDescendants(this, $('#presenceRCModal')[0]);
                       
                        this.bump(!this.bump());

                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                this.inmate(new Inmate());
                resolve();
            }
        })
    }

    protected savePresenceAssignment = () => {

        $.ajax({
            url: '/api/inmate/general',
            method: this.inmate().inmateId() === 0 ? 'POST' : 'PUT',
            data: this._packageData(),
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(results => {
                this._tagSwapComplete(this.inmate().inmateId(), results);
            })
            .fail((request, textStatus, error) => {
                console.error("InmatePresenceRcDialog::savePresenceAssignment()", request, textStatus, error);

            });

    }

    protected closePresenceDialog = () => {
        this._hideDialog();
    }

    
    inmateId: number = 0;
    showDialog = (inmateId: number) => {
        this.inmateId = inmateId;
        this._getFacilityInmatePresence();

        if ($('#easyPresenceDiv').length === 0) {
            $.get('/inmates/PresenceRC')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'easyPresenceDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#easyPresenceDiv').html(results);
                    $('#presenceRCModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
       
        if (!this._dialogInitialized) {
            this._dialogInitialized = true;
        }
        else {
            ko.cleanNode($('#presenceRCModal')[0]);
        }

        window.eval('$("#presenceRCModal").modal("show")');

        this._getInmate(this.inmateId);
    }

    private _hideDialog = () => {
        window.eval('$("#presenceRCModal").modal("hide")');
    }

    constructor() {

    }

    _tagSwapComplete(inmateId, results) {
        common.toast("success", "Presence Changed", 'Update Inmate');

        this._onSave(this.inmateId, this.inmate().presence.inmatePresenceTypeId(), this.inmate().deviceId());

        this._hideDialog();
    }

    private _packageData = (): FormData => {

        if (this.displayPresenceWarning() && this.inmate().deviceId() > 0) {
            this.inmate().deviceId(0);
        }

        let formData = <FormData>this.inmate().toFormData(false);

        if (this.displayPresenceWarning()) {
            let presence = this.inmatePresence().find(x => x.inmatePresenceTypeId() == this.inmate().presence.inmatePresenceTypeId());

            formData.append('unassignmentReasonId', presence.forceUnassignmentReasonId().toString());
        }
       
        return formData;
    }

    private _onSave = (inmateId: number, presenceId: number, deviceId: number) => {
        if (this.onSave) {
            this.onSave(inmateId, deviceId);
        }
    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.InmatePresenceRcDialog ??= () => { /* */ };
globalThis.DIG.InmatePresenceRcDialog.ViewModel = InmatePresenceRcDialog;