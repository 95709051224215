﻿import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { Inmate } from "./Inmate"
import { Dialogs, InmateAssignmentRcDialogDelegates, InmateDialogOptions, InmatePresenceRcDialogDelegates } from "../Dialogs"
import { } from "../Extensions"
import ko = require('knockout')

import { CommonViewModel } from "../Common"
const common: CommonViewModel = globalThis.DIG.Common

export class InmatesViewModel extends ViewModelBase<Inmate> {

    openRighClickDeviceAssignment = () => {
        //this._easyAssignmentDialog.showDialog(this.rightClickItemId);
        Dialogs.rcInmateAsssignment(this.rightClickItemId, this.rcAssignmentDialogOptions);
    }

    openRighClickInmatePresence = () => {
        //this._easyPresenceDialog.showDialog(this.rightClickItemId);
        Dialogs.rcInmatePresence(this.rightClickItemId, this.rcAssignmentDialogOptions);
    }

    options: ViewModelBaseOptions = {
        settingPrefix: "Inmates",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: true,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            show: false,
            enabled: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Last Name', 'First Name', 'Inmate Id', 'Tag Id', 'Assigned Date', 'Dorm', 'Location', 'Last Event'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [],
        selectionActions: [],
        rightClickActions: [{ description: 'Assignment/Unassignment', class: '', callback: this.openRighClickDeviceAssignment, altKey: '' },
            { description: 'Change Presence', class: '', callback: this.openRighClickInmatePresence, altKey: '' }]
    }

    dialogOptions: InmateDialogOptions = {
        isReadOnly: false,
        showAlarms: true,
        showConfiguration: true,
        showTemp: true,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onHidden: null,
        onDeactivate: null
    }

    rcAssignmentDialogOptions: InmateAssignmentRcDialogDelegates = {
        onSave: null
    }

    rcPresenceDialogOptions: InmatePresenceRcDialogDelegates = {
        onSave: null
    }

    //private _easyAssignmentDialog: InmateAssignmentRcDialog;
    //private _easyPresenceDialog: InmatePresenceRcDialog;
    private _showUnassigned: string = null;
    showUnassigned: ko.Observable<boolean> = ko.observable(true)
    
    //TODO: Rename "Commands" to "Actions"

    private permissions: Array<string>;
    protected setPermissions = (value: string): void =>
    {
        this.permissions = value.split("|");
        this.dialogOptions.showConfiguration = this.permissions.includes('mon-inmate-configuration');
    }

    protected createItem = (data?: object, view?: object): Inmate => new Inmate(data, view)

    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get('/api/inmate')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("InmatesViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    removeById = (id: number): boolean => {
        let result = false;
        const inmate = this.find(id);

        if (inmate) {
            this.data.remove(inmate);
            common.toast('success', `${inmate.firstName()} ${inmate.lastName()} was deactivated.`, 'Deactivate Inmate');
            result = true;
        }

        return result;
    }

    onAddNew = () => {
        Dialogs.editInmate(0, this.dialogOptions);
    }

    onClick = (id) => {
        Dialogs.editInmate(id, this.dialogOptions);
    }

    onAddRemoveMenuClick = (id) => {
        console.info(id);


    }

    public constructor() {
        super();
        this.dialogOptions.onSave = this._inmateSaved;
        this.dialogOptions.onDeactivate = this._inmateDeactivated;

        this.rcAssignmentDialogOptions.onSave = this.afterRCDeviceChanged;
        this.rcPresenceDialogOptions.onSave = this.afterRCPresenceChanged;
        //this._easyAssignmentDialog = new InmateAssignmentRcDialog(this);
        //this._easyPresenceDialog = new InmatePresenceRcDialog(this);

        //$("#assignmentRightClick").on("click", (e) => {
        //    console.info("assignmentMenuClicked--" + e.pageX + ":" + e.pageY);


        //    console.info(document.elementFromPoint(e.pageX, e.pageY));

        //    console.info(this.dataFor(document.elementFromPoint(e.pageX, e.pageY)));


        //    console.info(this.rightClickItemId);

        //    this._easyAssignmentDialog.showDialog(this.rightClickItemId);

        //});


    }

    initChild = () => {
        common.inmateLocationChanged = this.inmateLocationChanged;

        this._showUnassigned = this.showUnassigned().toString();
        this.showUnassigned.subscribe(newValue => {
            if (this._showUnassigned !== null && newValue.toString() !== this._showUnassigned) {
                this.saveSetting('ShowUnassigned', newValue);
            }
            this._showUnassigned = newValue.toString();
        });
    }

    _inmateSaved = (inmateId, results) => {
        const inmate = this.find(inmateId);

        if (inmate) {
            inmate.setDetail(results);
            common.toast('success', `${inmate.firstName()} ${inmate.lastName()} was updated.`, 'Update Inmate');
        } else {
            //const newInmate = this.data.mappedCreate(results);
            const newInmate = this.insertItem(results);
            newInmate.hasDetail(true);
            common.toast("success", `${newInmate.firstName()} ${newInmate.lastName()} was added.`, 'Add Inmate');
        }
    }

    _inmateDeactivated = (inmateId) => {
        //TODO: When we can view deactivated inmates, we shouldn't remove it from the list.
        this.removeById(inmateId); 
    }

    inmateLocationChanged = (inmateId, location) => {
        const inmate = this.find(inmateId);

        if (inmate) {
            inmate.location(location);
        }
    }

    afterRCDeviceChanged = (inmateId, newDeviceId) => {
        for (let inmt of this.data()) {
            if (inmt.inmateId() == inmateId) {
                inmt.deviceId(newDeviceId);
                break;
            }
        }
    }

    afterRCPresenceChanged = (inmateId, newPresence, newDeviceId) => {
        for (let inmt of this.data()) {
            if (inmt.inmateId() == inmateId) {
                inmt.deviceId(newDeviceId);
                break;
            }
        }
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Inmates ??= () => { /* */ };
globalThis.DIG.Inmates.ViewModel = InmatesViewModel