﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import moment = require('moment-timezone')

import { isNullOrUndefined } from 'util'

export class InmatesImportSummary {
    totalRows: number
    insertedRows: number
    insertRowsTotal: number
    updatedRows: number
    updateRowsTotal: number
    unchangedRowsTotal: number
    removedRows: number
    removeRowsTotal: number
    globalError: string
    success: boolean

    

    constructor(data) {

        var map = {
            'copy': ["totalRows", "insertedRows", "insertRowsTotal", "updatedRows", "updateRowsTotal", "unchangedRowsTotal", "removedRows", "removeRowsTotal", "globalError", "success"]
        }

        mapping.fromJS(data, map, this);
    }
}