﻿import signalR = require("@aspnet/signalr")
import ko = require("knockout")
import moment = require("moment-timezone")
import toastr = require("toastr")
//import { ApplicationInsights } from "@microsoft/applicationinsights-web"

import "./ValidationExtenders"
import { EventType, EventTypeEnum, EventTypes } from "./EventTypes"
import { CustomString } from "./CustomString"
import { isNullOrUndefined } from "util"
import { SwitchEntityDialogModel } from "./SwitchEntity/SwitchEntityDialog"
//import { Dialogs } from "./Dialogs"
import { } from "./Extensions"

//  ==================================
//  Setup globalThis object
//  ==================================
globalThis.DIG ??= () => { /* */ };
globalThis.DIG.regexTime = RegExp('t.*[-+z]', 'i');

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": 1000,
    "hideDuration": 1000,
    "timeOut": 10000,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

export enum EntityType {
    Unknown = 0,        //for null binding
    User = 1,
    Role = 2,
    Contract = 3,
    Agency = 4,
    Facility = 5,
    Inmate = 6,
    Event = 7,
    Alarm = 8,
    Device = 9,
    EventType = 10,
    Zone = 11,
    Contact = 12,
    Environment = 13,
    RFZone = 14,
    Staff = 15
}

export enum DeviceType {
    Unknown = 0,
    Tag = 1,
    ILinkLocator = 2,
    Gateway = 3,
    OfficerSafetyDevice = 4,
    OSDnRF = 5,
}

export class CommonViewModel {
    private _page: string
    private _signalHub: signalR.HubConnection
    private _eventTypes: EventTypes
    private _switchEntityDialog: SwitchEntityDialogModel;
    private _customStrings: Array<CustomString>
    private _facilityStartDate: moment.Moment
    private _facilityEndDate: moment.Moment
    private _isDemoMode: boolean = false

    public facilityTimeZone: string
    public entityType: string

    longTimezoneNames = {
        EST: 'Eastern Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
    }

    constructor() {
        this._switchEntityDialog = new SwitchEntityDialogModel();
        this._facilityStartDate = moment().startOf('day');
        this._facilityEndDate = moment().endOf('day');
    }

    get isDemoMode(): boolean {
        return this._isDemoMode;
    }

    set isDemoMode(value: boolean) {
        this._isDemoMode = value;
    }

    get page(): string {
        return this._page;
    }

    set page(value: string) {
        this._page = value;
        this.setupSignalR();
    }

    get eventTypes(): EventTypes {
        if (isNullOrUndefined(this._eventTypes)) {
            this._eventTypes = new EventTypes();
        }

        return this._eventTypes;
    }

    get customStrings(): Array<CustomString> {
        return this._customStrings;
    }

    setCustomStrings = (value: string) => {
        this._customStrings = JSON.parse(value);
        this._customStrings.forEach((element) => {
            if (element.entityType == EntityType.EventType) {
                this.eventTypes.getEventTypeById(element.entityId).description = element.description;
            }
        });
    }

    get FacilityStartDate(): any {
        return this._facilityStartDate.default(this.toFacilityTime(moment()).startOf('day'));
    }

    set FacilityStartDate(value: any) {
        this._facilityStartDate = this.toFacilityTime(moment.unix(value));
    }

    get FacilityEndDate(): any {
        const maxDay = this.toFacilityTime(moment()).endOf('day');
        return moment.min(maxDay, this._facilityEndDate.default(maxDay));
    }

    set FacilityEndDate(value: any) {
        this._facilityEndDate = this.toFacilityTime(moment.unix(value)).endOf('day');
    }

    getEventTypes = (sortField?: string): EventType[] => this.eventTypes.getEventTypes(sortField);
    getAlarmEventTypes = (sortField?: string): EventType[] => this.eventTypes.getAlarmEventTypes(sortField);
    getInfoEventTypes = (sortField?: string): EventType[] => this.eventTypes.getInfoEventTypes(sortField);
    getEventTypeById = (id: EventTypeEnum): EventType => this.eventTypes.getEventTypeById(id);

    toFacilityTime = (time: string | moment.Moment | ko.Observable<string> | ko.Observable<moment.Moment>, timezone?: string): moment.Moment => {
        if (ko.isObservable(time)) {
            time = time();
        }

        if (typeof (time) === "string") {
            if (!globalThis.DIG.regexTime.test(time)) {
                time += 'Z';
            }
        }

        if (isNullOrUndefined(timezone) || timezone.trim() === '') {
            timezone = this.facilityTimeZone;
        }

        return moment.tz(time, timezone);
    }

    switchEntity = () => {
        this._switchEntityDialog.showDialog();
    }

    refreshPermissions = () => {
        $.get('/api/user/refresh')
            .done(() => this.navigate('', false))
            .fail((request, textStatus, error) => {
                console.error(error);
            });
    }

    setupSignalR() {
        this._signalHub = new signalR.HubConnectionBuilder()
            .withUrl(`/monitor/hub?page=${this.page}`)
            .build();

        this._signalHub.onclose((e) => {
            window.setTimeout(() => { this._signalHub.start(); }, 30000);
        });

        this._signalHub
            .start()
            .then(() => {
                if (this.page !== 'alarms') {
                    this._signalHub.on('newAlarm', this.announceAlarm);
                }

                if (this.page !== 'rollcall') {
                    this._signalHub.on('rollCallStarted', this.announceRollCall);
                }
            });
    }

    private announceAlarm = (alarm) => {
        if (alarm.eventType != EventTypeEnum.AlarmPanicButton) {
            const message = `<table><tr><td ${this.isDemoMode ? 'demo' : ''}><img src="/api/inmate/thumbnail/${alarm.inmateId}" style="width: 110px; border: 1px black solid;"></td><td valign="top" style="padding-left: 10px; padding-top: 0px;"><span class="toast-alarm-inmatename">${alarm.inmateName}</span><br /><span class="toast-alarm-info">${alarm.inmateIdentifier}<br />${this.eventTypes.getEventTypeById(alarm.eventType).description}<br />${this.toFacilityTime(alarm.eventTime).format('h:mm:ss A')}</span></td></tr></table>`;
            toastr["error"](message, '', { iconClass: 'toast-alarm' });
        }
    }

    private announceRollCall = () => {
        toastr['success']('Roll Call Started');
    }

    set newAlarm(method) {
        this._signalHub.on('newAlarm', method);
    }

    set refreshDashboard(method) {
        this._signalHub.on('refreshDashboard', method);
    }

    set rollCallStarted(method) {
        this._signalHub.on('rollCallStarted', method);
    }

    set inmateLocationChanged(method) {
        this._signalHub.on('inmateLocationChanged', method);
    }

    set inmateRFEventOccurred(method) {
        this._signalHub.on('RFScan', method);
    }

    parseTimeAsDayEpoch = (timeString: string): number => {
        let result = 0;
        let hour = 0;

        timeString = timeString.toUpperCase();

        if (timeString.includes('AM') || timeString.includes('PM')) {
            if (timeString.includes('PM')) {
                hour = 12;
            }
            timeString = timeString.replace('AM', '').replace('PM', '');
        }

        const parts: string[] = timeString.includes(':')
            ? timeString.split(':')
            : [timeString];

        if (parts.length >= 2) {
            result = ((parseInt(parts[0]) + hour) * 3600)  // hour
                + (parseInt(parts[1]) * 60);
        } else if (parts.length === 1) {
            result = ((parseInt(parts[0]) + hour) * 3600); // hour
        } else {
            throw "Invalid time format.. use hh:mm"
        }

        return result;
    }

    formatTime = (time: moment.Moment, plain?: boolean, allowWrap?: boolean, adjustTimeZone?: boolean): string => {
        if (plain === undefined) {
            plain = false;
        }

        if (allowWrap === undefined) {
            allowWrap = true;
        }

        if (adjustTimeZone === undefined) {
            adjustTimeZone = true;
        }

        const facilityTime = moment.tz(time, adjustTimeZone ? this.facilityTimeZone : 'UTC');
        const datePart: string = facilityTime.format('M/D/YYYY');
        const timePart: string = facilityTime.format('h:mm:ss A');

        return plain
            ? `${datePart} ${timePart}`
            : allowWrap
                ? `${datePart} <span style="white-space: nowrap;">${timePart}</span>`
                : `<span style="white-space: nowrap;">${datePart} ${timePart}</span>`;
    }

    public navigate(url, newWindow: boolean) {
        if (isNullOrUndefined(newWindow) || newWindow === false) {
            window.location = url;
        } else {
            window.open(url);
        }
    }

    public expandMenu(menu: string) {
        const theMenu = `.${menu}-menu`;
        $(theMenu).toggleClass('expanded');
    }

    //
    //  Filename parsing and file saving taken from https://stackoverflow.com/questions/16086162/handle-file-download-from-ajax-post
    //
    public postBinaryRequest = (url: string, data: FormData) => {
        const progressBar = $('.page-progress')
        const request = new XMLHttpRequest();

        request.open("POST", url, true);
        request.responseType = "arraybuffer";
        request.onload = (event) => {
            if (request.response) {
                let filename = "";
                const disposition = request.getResponseHeader('Content-Disposition');

                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }

                const bytes = new Uint8Array(request.response);
                const blob = new Blob([bytes]);

                const downloadUrl = (window.URL || window.webkitURL).createObjectURL(blob);

                let a = document.createElement("a");

                if (typeof a.download === 'undefined') {
                    window.location.href = downloadUrl;
                } else {
                    a.href = downloadUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                }

                setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                if (progressBar.length != 0) $(progressBar[0]).toggleClass('d-none', true);
            }
        }

        if (progressBar.length != 0) $(progressBar[0]).toggleClass('d-none', false);

        request.send(data);
    }

    //
    //  Taken From: https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data
    //      Expanded to handle arrays (Only 1 layer deep right now)
    //
    public objectToFormData = (object): FormData => {
        const result = new FormData();

        for (const key in object) {
            if ($.isArray(object[key])) {
                for (let index = 0; index < object[key].length; index++) {
                    result.append(`${key}[]`, object[key][index]);
                }
            } else {
                result.append(key, object[key]);
            }
        }

        return result;
    }

    //
    //  Taken From: https://www.newline.co/books/beginners-guide-to-typescript/generating-unique-ids
    //
    public uniqueId = (): string => {
        const dateStr = Date
            .now()
            .toString(36); // convert num to base 36 and stringify

        const randomStr = Math
            .random()
            .toString(36)
            .substring(2, 8); // start at index 2 to skip decimal point

        return `${dateStr}-${randomStr}`;
    }

    public toast = (type: ToastrType, message: string, title?: string, options?: ToastrOptions) => {
        toastr[type](message, title, options);
    }

    public statusLedPath(value: string | number | ko.Observable<string> | ko.Observable<number>) {
        let path: string;

        if (ko.isObservable(value)) {
            value = value();
        }

        switch (value.toString().toLowerCase()) {
            case '0':
            case 'critical':
                path = '/images/led_red_on.png';
                break;

            case '1':
            case 'warning':
                path = '/images/led_yellow_on.png';
                break;

            case '2':
            case 'normal':
                path = '/images/led_green_on.png';
                break;

            default:
                path = '/images/led_gray.png';
        }

        return path;        
    }

    isNullOrWhitespace = (value: string): boolean => (isNullOrUndefined(value) || value.trim().length === 0)

    public clearSessionData = () => {
        sessionStorage.clear();
    }
}

//const aiKey: string = ($('#aiKey')[0] as HTMLInputElement).value;
//if ((aiKey ?? '').length != 0) {
//    const appInsights = new ApplicationInsights({
//        config: {
//            instrumentationKey: ($('#aiKey')[0] as HTMLInputElement).value,
//            loggingLevelConsole: 2,
//            autoTrackPageVisitTime: true
//        }
//    });
//    appInsights.loadAppInsights();
//    appInsights.trackPageView();
//}

globalThis.DIG.Common = new CommonViewModel();
