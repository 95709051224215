﻿import moment = require("moment-timezone")
import ko = require("knockout")

import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { CommonViewModel, EntityType } from "../Common"
import { EntitySelectViewModel } from "../Components/EntitySelectDropdown"

import { Event } from "./Event"

import { GenericEntity } from "../GenericEntity"

const common: CommonViewModel = globalThis.DIG.Common;

export class EventViewerViewModel extends ViewModelBase<Event> {
    getExcel = () => this._getExcel();

    options: ViewModelBaseOptions = {
        settingPrefix: "EV",
        allowSelection: true,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: false,
        detail: {
            enabled: true,
            nextRow: false,
            right: true,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        autoRefresh: {
            enabled: false,
            show: true,
            intervalSeconds: 300
        },
        loadDataOnInit: false,
        flashNewRows: false,
        showExportExcel: true,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: true,
            esc: false
        },
        templateSections: {
            queryParameters: true,
        },
        sortFields: ['Device', 'Inmate', 'Event Timestamp', 'Stored Timestamp', 'STimer', 'Event Type', 'Channel', 'GW RSSI', 'Category'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [{ description: "Export", callback: this.getExcel, altKey: 'X', class: 'bg-success' }],
        selectionActions: [],
        rightClickActions: []
    }

    //inmateDialog: InmateDialogModel = new InmateDialogModel({
    //    isReadOnly: false,
    //    showAlarms: false,
    //    showConfiguration: true,
    //    allowEdit: true
    //});

    //
    //  Query properties
    //
    dateRangeMode: ko.Observable<number> = ko.observable(1)
    startDate: ko.Observable<string> = ko.observable('')
    endDate: ko.Observable<string> = ko.observable('')
    inmateFilterId: ko.Observable<number> = ko.observable(0);
    inmateFilterDescription: ko.Observable<string> = ko.observable('')
    deviceId: ko.Observable<string> = ko.observable('')
    inmateSelector: EntitySelectViewModel = null
    deviceTypeAll: ko.ObservableArray<GenericEntity> = ko.observableArray<GenericEntity>([]) // values set in init in order to use customStrings
    deviceTypesFilter: ko.Observable<string> = ko.observable('')
    deviceTypeSelected: ko.ObservableArray<string> = ko.observableArray<string>([])

    eventTypesFilter = ko.observableArray(ko.utils.arrayMap(common.getEventTypes('description'), (e) => e.id.toString())).extend({
        rateLimit: {
            timeout: 50,
            method: 'notifyWhenChangesStop'
        }
    })

    protected createItem = (data?: object, view?: object): Event => new Event(data, view)

    protected fetchData(): Promise<object> {
        return new Promise((resolve, reject) => {
            //
            //  Doing this seemingly pointless step to update the start and end date values
            //  before executing the query
            //
            this.calculateDates();

            $.post('/api/event/search', {
                dateRangeMode: this.dateRangeMode(),
                startDate: this.startDate(),
                endDate: this.endDate(),
                inmateId: this.inmateFilterId(),
                deviceId: this.deviceId(),
                deviceTypes: this.deviceTypeSelected().join("|")
            })
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("EventViewerViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    filterData = (): Event[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: Event) => item.isMatch(filterText, this.eventTypesFilter()));
    }

    initChild = () => {
        this.mapConfig.observe = ['eventId'];
        this.mapConfig.ignore = [];
        this.mapConfig.copy = ['eventType', 'deviceId', 'inmateId', 'isAlarmEvent', 'isClearEvent', 'isInfoEvent', 'sTimer', 'gatewayRssi', 'frameCounter', 'loraChannel', 'eventTimestamp', 'storedTimestamp', 'inmateFirstName', 'inmateLastName', 'eventData', 'currentStatus', 'lowBatteryStatus', 'inRollCallStatus'];

        this.deviceTypeAll = ko.observableArray<GenericEntity>([
            new GenericEntity(1, common.customStrings.getByEntity(EntityType.Device, 1).shortDescription),
            new GenericEntity(2, common.customStrings.getByEntity(EntityType.Device, 2).shortDescription),
            new GenericEntity(4, common.customStrings.getByEntity(EntityType.Device, 4).shortDescription),
            new GenericEntity(5, common.customStrings.getByEntity(EntityType.Device, 5).shortDescription),
        ])

        if (this.deviceTypesFilter() != '') {
            this.deviceTypesFilter().split("|").forEach(s => this.deviceTypeSelected.push(s));
        }
    }

    afterBinding = () => {
        //flatpickr('.datetime', { enableTime: true, dateFormat: 'n/d/Y h:i K', allowInput: true }) //maxDate: moment().format('M/d/yyyy HH:mm') 'today' })
        this.inmateSelector.init(this.inmateFilterId, this.inmateFilterDescription)
    }

    onEnter = (e) => {
        if ($(e.target).parents('.do-enter').length === 1) {
            this.loadData();
        }
    }

    constructor() {
        super();

        this.inmateSelector = new EntitySelectViewModel({
            entityType: EntityType.Inmate,
            noSelectionDescription: "All Inmates",
            autoSelectSingle: true
        });
    }

    displayDateRangeMode: ko.Computed<string> = ko.computed(() => {
        let text: string = 'Unknown';

        switch (this.dateRangeMode()) {
            case 0: text = 'Custom'; break;
            case 1: text = 'Today'; break;
            case 2: text = 'Yesterday'; break;
            case 3: text = 'Last 60 Minutes'; break;
            case 4: text = 'Last 24 Hours'; break;
            case 5: text = 'Last 7 Days'; break;
            case 6: text = 'Last 30 Days'; break;
        }

        return text;
    })

    setDateRange = (a, b) => {
        this.dateRangeMode(parseInt(b.target.dataset.mode));
        this.calculateDates();
    }

    startDateValid: ko.Observable<boolean> = ko.observable(true)
    startDateClass: ko.Computed<string> = ko.computed(() => {
        return this.dateRangeMode() != 0
            ? 'bg-silver'
            : this.startDateValid() ? 'bg-white' : 'bg-danger text-white'
    })

    parseStartDate = () => {
        if (!common.isNullOrWhitespace(this.startDate())) {
            let date: moment.Moment = moment(this.startDate());

            this.startDateValid(date.isValid())
            if (date.isValid()) {
                this.startDate(date.format('MM/DD/YYYY hh:mm A'));
            }
        } else {
            this.startDateValid(false);
        }
    }

    endDateValid: ko.Observable<boolean> = ko.observable(true)
    endDateClass: ko.Computed<string> = ko.computed(() => {
        return this.dateRangeMode() != 0
            ? 'bg-silver'
            : this.endDateValid() ? 'bg-white' : 'bg-danger text-white'
    })

    parseEndDate = () => {
        if (!common.isNullOrWhitespace(this.endDate())) {
            let date: moment.Moment = moment(this.endDate());

            this.endDateValid(date.isValid())
            if (date.isValid()) {
                this.endDate(date.format('MM/DD/YYYY hh:mm A'));
            }
        } else {
            this.endDateValid(false);
        }
    }

    calculateDates = () => {
        let refDate: moment.Moment;

        switch (this.dateRangeMode()) {
            case 0: //  Custom - Don't change the values
                break;

            case 1: //  Today
                refDate = common.toFacilityTime(moment().utc());
                this.startDate(refDate.startOf('day').format('MM/DD/YYYY hh:mm A'));
                this.endDate(refDate.endOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            case 2: //  Yesterday
                refDate = common.toFacilityTime(moment().utc()).subtract(1, 'day');
                this.startDate(refDate.startOf('day').format('MM/DD/YYYY hh:mm A'));
                this.endDate(refDate.endOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            case 3: //  Last 60 Minutes
                refDate = common.toFacilityTime(moment().utc());
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(60, 'minutes').format('MM/DD/YYYY hh:mm A'));
                break;

            case 4: //  Last 24 Hours
                refDate = common.toFacilityTime(moment().utc());
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(24, 'hours').format('MM/DD/YYYY hh:mm A'));
                break;

            case 5: //  Last 7 Days
                refDate = common.toFacilityTime(moment().utc()).endOf('day');
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(7, 'day').startOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            case 6: //  Last 30 Days
                refDate = common.toFacilityTime(moment().utc()).endOf('day');
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(30, 'day').startOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            default:
                // Not implemented
                break;
        }
    }

    _getExcel = () => {
        //
        //  ** In some modes (eg. last 60 minutes) this could give results different to 
        //      what was displayed
        //
        this.calculateDates();

        const data = common.objectToFormData({
            deviceId: this.deviceId(),
            inmateId: this.inmateFilterId(),
            startTime: this.startDate(),
            endTime: this.endDate(),
            eventTypes: this.eventTypesFilter(),
            deviceTypes: this.deviceTypeSelected().join("|")
        });

        common.postBinaryRequest('/api/report/debug/detailv2', data);
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.EventViewer ??= () => { /* */ };
globalThis.DIG.EventViewer.ViewModel = EventViewerViewModel