﻿import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"
import { Dialogs } from "../Dialogs"

const common: CommonViewModel = globalThis.DIG.Common

export class DashboardAlarm {
    view: any
    alarmId: number
    inmateId: number
    inmateName: string
    eventType: number
    alarmTimestamp: string

    constructor(data: any, view: any) {
        this.view = view;

        mapping.fromJS(data, {
            copy: ['alarmId', 'inmateId', 'inmateName', 'eventType', 'alarmTimestamp']
        }, this);
    }

    displayAlarmTime: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.alarmTimestamp);
        return `${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`;
    });

    displayAlarmTimePlain: ko.Computed<string> = ko.computed<string>(() => {
        let time = common.toFacilityTime(this.alarmTimestamp);
        return time.format('M/D/YYYY h:mm:ss A');
    });

    eventTypeDescription: ko.Computed<string> = ko.computed<string>(() =>
        common.getEventTypeById(this.eventType).description);

    public edit = () => this.view?.alarmDialog?.edit(this.alarmId);

    public editInmate = () => {
        event.stopPropagation();
        Dialogs.editInmate(this.inmateId);
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Alarms === undefined) {
    globalThis.DIG.Alarms = () => { };
}

globalThis.DIG.Alarms.DashboarAlarm = DashboardAlarm;
