﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { ViewModelItem } from "../ViewModelItem"
import { CommonViewModel, EntityType } from "../Common"
import { Association } from "./Association"
import { isNullOrUndefined } from "util"
import { SecurityGroup } from "./SecurityGroup"
import { UserGroup } from "./UserGroup"

const common: CommonViewModel = globalThis.DIG.Common

export class User extends ViewModelItem {
    hasDetail: ko.Observable<boolean> = ko.observable(false)

    userAssociationId: ko.Observable<number> = ko.observable(0)
    userId: ko.Observable<number> = ko.observable(0);
    userName: ko.Observable<string> = ko.observable('');
    firstName: ko.Observable<string> = ko.observable('');
    lastName: ko.Observable<string> = ko.observable('');
    emailAddress: ko.Observable<string> = ko.observable('');
    phoneNumber: ko.Observable<string> = ko.observable('');
    isActive: ko.Observable<boolean> = ko.observable(false);
    isLocked: ko.Observable<boolean> = ko.observable(false);
    loginFailureCount: ko.Observable<number> = ko.observable(0);
    groups: ko.ObservableArray<UserGroup> = ko.observableArray();
    imageSource: ko.Observable<string> = ko.observable('/images/photoUnavailable.jpg');

    associations: Array<Association>

    selectedContractJobTitleId: ko.Observable<number> = ko.observable(0);
    selectedContractJobTitleDescription: ko.Observable<string> = ko.observable("");
    selectedContractJobTitleAssociationType: ko.Observable<EntityType> = ko.observable(EntityType.Facility);

    selectedContractId: ko.Observable<number> = ko.observable(0);
    selectedFacilityId: ko.Observable<number> = ko.observable(0);


    bump: ko.Observable<boolean> = ko.observable<boolean>(false);


    constructor(data?, view?) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('last name')
            };
        }

        this.mapConfig.ignore = ['associations'];


        if (data) {

            this.setDetail(data);
            
        }

        
    }


    clone = (): object => mapping.toJS(this, {
        ignore: ['clone', 'view'],
        include: ['photo', 'hasPhoto']
    });

 
    _getUserDetail = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/user/${this.userId()}`,
                cache: false
            })
                .done(data => {
                    //this.setDetail(data);
                    resolve(data);
                })
                .fail((request, textStatus, error) => {
                    console.error('User::getDetail', request, textStatus, error);
                    reject();
                });
        })
    };

    _getAssociation = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/user/${this.userId()}/association`,
                cache: false
            })
                .done(data => {
                    //this.setDetail(data);
                    resolve(data);
                })
                .fail((request, textStatus, error) => {
                    console.error('User::getDetail', request, textStatus, error);
                    reject();
                });
        })
    };

    getGroups = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/user/${this.userId()}/group`,
                cache: false
            })
                .done(data => {
                    this.setGroups(data);
                    resolve(data);
                })
                .fail((request, textStatus, error) => {
                    console.error('User::getGroups', request, textStatus, error);
                    reject();
                });
        })
    };

    getDetail = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            this._getUserDetail()
                .then((userData) => {
                    this.setDetail(userData);
                    return this._getAssociation();
                })
                .then((associationData) => {
                    this.setAssociationDetail(associationData);
                    resolve();
                });
        })
    };

    
    setDetail = (detail) => {
        mapping.fromJS(detail, this.mapConfig, this);


        var childMap = {
            create: (inObj) => new Association(inObj.data, this.userId(), this.view)
        };

        if (!isNullOrUndefined(detail["associations"])) {
            this.associations = ko.utils.unwrapObservable( (mapping.fromJS(detail["associations"], childMap)));
        }


        for (let index = 0; index < this.associations.length; index++) {

            if (this.associations[index].associatedToType == EntityType.Facility
                && this.associations[index].associatedTo.id() == this.selectedFacilityId()) {

                this.selectedContractJobTitleId(this.associations[index].jobTitle.id());
                this.selectedContractJobTitleDescription(this.associations[index].jobTitle.description());

                this.userAssociationId(this.associations[index].userAssociationId);

                break;
            }

            if (this.associations[index].associatedToType == EntityType.Contract
                && this.associations[index].associatedTo.id() == this.selectedContractId()) {

                this.selectedContractJobTitleId(this.associations[index].jobTitle.id());
                this.selectedContractJobTitleDescription(this.associations[index].jobTitle.description());
                this.selectedContractJobTitleAssociationType(EntityType.Contract);

                this.userAssociationId(this.associations[index].userAssociationId);

                break;
            }
        }

        if (!isNullOrUndefined(detail["groups"])) {
            this.setGroups(detail["groups"]);
        }

        this.hasDetail(true);
    };

    setGroups = (data) => {

        var childMapGroup = {
            create: (inObj) => new UserGroup(inObj.data, this.userId(), this.view)
        };

        if (!isNullOrUndefined(data)) {
            this.groups = mapping.fromJS(data, childMapGroup);
        }

        this.bump(!this.bump());
    }


    setAssociationDetail = (associationData) => {
        //mapping.fromJS(detail, this.mapConfig, this);

        // temporary, eventually this will be a bigger list (possibly tree view layout)
        //if (associationData != null) {
        //    this.userAssociationId(associationData.userAssociationId);

        //    this.jobTitle = { id: ko.observable<number>(associationData.contractJobTitleId), description: ko.observable<string>(associationData.description) };

        //    this.jobTitleEntityType(associationData.EntityType)
        //}

        //this.hasDetail(true);
    };

    displayName: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.view.sort === undefined || this.view.sort() === 'lastName'
            ? `${this.lastName()}, ${this.firstName()}`
            : `${this.firstName()} ${this.lastName()}`;
    });

    relevantGroups: ko.PureComputed = ko.pureComputed(() => {
        var bump = this.bump();

        return this.groups().filter(x => 
            (x.parentType == EntityType.Contract && x.parent.id() == this.selectedContractId())
            || (x.parentType == EntityType.Facility && x.parent.id() == this.selectedFacilityId()))

    });

    statusLevel: ko.PureComputed<string> = ko.pureComputed(() => {
        let status = "normal";

        if (!this.isActive()) {
            status = "disabled";
        }
        //} else if (this.isLockedOut()) {
        //    status = "critical";
        //} else if (this.loginFailedCount() > 0) {
        //    status = "warning";
        //}

        return common.statusLedPath(status);
    });

    static getById = async (userAssociationId: number): Promise<User> => {
        return new Promise((resolve, reject) => {
            const result = new User();
            //result.userAssociationId(userAssociationId);
            result.userId(userAssociationId);
            result.getDetail().then(() => {
                resolve(result);
            }, rejectReason => {
                console.error('User::GetById', rejectReason);
                reject(rejectReason);
            })
        })
    }

    public compare(compareTo: any, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'last name':
                result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                if (result === 0) {
                    result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                }
                break;

            case 'first name':
                result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                if (result === 0) {
                    result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                }
                break;

            
        }

        return result;
    }

    public itemDescription(sort: string): string {
        let value = '';

        switch (sort.toLowerCase()) {
            case 'last name': value = this.displayName(); break;
            case 'first name': value = this.displayName(); break;
        }

        return value;
    }

    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');
            const regexId = new RegExp('^\\D*' + text1, 'i');

            // temporary until we get the nicer user screen.
            result = (regexName1.test(this.firstName()) && regexName2.test(this.lastName()))
                || (regexName1.test(this.lastName()) && regexName2.test(this.firstName()))
                || (regexName1.test(this.emailAddress()));
        }

        return result;
    }

    toFormData = (): object => {
        const data = {
            userId: this.userId(),
            userName: this.userName(),
            firstName: this.firstName(),
            lastName: this.lastName(),
            emailAddress: this.emailAddress() == null ? '' : this.emailAddress(),
            phoneNumber: this.phoneNumber() == null ? '' : this.phoneNumber(),
            contractJobTitleId: this.selectedContractJobTitleId() == null ? '' : this.selectedContractJobTitleId(),
            userAssociationId: this.userAssociationId()
        }

        const formData = common.objectToFormData(data);

        let userPhotoElement = $('#userPhotoFile')[0];

        if (userPhotoElement != null && (userPhotoElement as any).files.length !== 0) {
            formData.append('photo', (userPhotoElement as any).files[0], 'photo');
        }

        return formData;
    }

    public edit = () => this.view?.userDialog?.edit(this.userId());

    public getId = (): number => this.userId();
}
