﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import moment = require('moment-timezone')

import { EventTypeEnum } from "../EventTypes"
import { CommonViewModel } from "../Common"
import { AlarmInmate } from "./AlarmInmate"
import { TimeZones } from '../TimeZones'
import { ViewModelItem } from '../ViewModelItem'
import { Dialogs } from "../Dialogs"
import { isNullOrUndefined } from 'util'
import { AlarmZone } from './AlarmZone'
import { AlarmEntity } from './AlarmEntity'

const common: CommonViewModel = globalThis.DIG.Common

export class Alarm extends ViewModelItem {
    hasDetail: ko.Observable<boolean> = ko.observable(false)
    inmates: ko.ObservableArray<AlarmInmate> = ko.observableArray<AlarmInmate>([])
    entities: ko.ObservableArray<AlarmEntity> = ko.observableArray<AlarmEntity>([])
    zones: ko.ObservableArray<AlarmZone> = ko.observableArray<AlarmZone>([])

    alarmId: number
    dismissedComment: ko.Observable<string> = ko.observable<string>('')
    flash: ko.Observable<boolean> = ko.observable<boolean>(false)
    duration: moment.Duration = moment.duration(0);

    eventType = 0
    alarmTimestamp = ''
    facility = { id: 0, description: '' }
    timeZoneId = ''

    constructor(data?, view?) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('Alarm Time')
            };
        }

        this.mapConfig.copy = ['alarmId', 'eventType', 'alarmTimestamp', 'facility.id', 'facility.description', 'timeZoneId'];
        this.mapConfig.ignore = ['inmates', 'zones', 'entities'];

        if (data) {
            this.setDetail(data);

            // Case Tamper
            if (this.eventType == EventTypeEnum.AlarmCaseTamper
                || this.eventType == EventTypeEnum.AlarmUtc) {
                this.allowSelect(false);
            }
        }
    }

    displayName: ko.Observable<string> = ko.observable<string>('')
    displayAlarmTime: ko.Observable<string> = ko.observable<string>('')
    displayAlarmTimePlain: ko.Observable<string> = ko.observable<string>('')
    eventTypeDescription: ko.Observable<string> = ko.observable<string>('')
    primaryLocation: ko.Observable<string> = ko.observable<string>('')

    durationWithUnits: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        if (this.duration == null) {
            return "In Progress";
        }
        else {
            if (this.duration.asMinutes() < 1) {
                return "1 Min";
            }
            return Math.round(this.duration.asMinutes()) + " Min";
        }
    });

    showDuration: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => {

        if (this.eventType == EventTypeEnum.AlarmCellDetect) {
            return true;
        }
        else {
            return false;
        }
    });

    attentionClass = (): string => {
        return this.eventType === EventTypeEnum.AlarmPanicButton && (this.getStartEventValue('panicButton_Mode') ?? '2') == '1'
            ? 'attention'
            : '';
    }

    alarmClass = (columnType?: number | string, className?: string): string => {
        let show: boolean = true;

        if (common.isNullOrWhitespace(className)) {
            switch (this.eventType) {
                case EventTypeEnum.AlarmPanicButton:
                    className = (this.getStartEventValue('panicButton_Mode') ?? '2') == '1'
                        ? 'critical'
                        : 'normal';
                    break;

                case EventTypeEnum.AlarmLowBattery:
                    className = 'warning';
                    break;

                default:
                    className = 'critical';
                    break;
            }
        }

        if (!isNullOrUndefined(columnType)) {
            show = typeof (columnType) == "number"
                ? (this.eventType == columnType)
                : columnType.indexOf(`.${this.eventType}.`) < 0;
        }

        return show ? className : 'hide';
    }

    ledImageSource: ko.Computed<string> = ko.computed<string>(() => {
        const color: string = this.eventType === EventTypeEnum.AlarmLowBattery ? 'yellow' : 'red';
        return `/images/led_${color}_on.png`;
    })

    facilityName = (): string => this.facility?.description ?? '';

    displayClass: ko.Computed<string> = ko.computed<string>(() => this.flash() ? 'flash-row' : '')

    hasInmateDetails: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => {
        if (this.inmates().length > 0) {
            return true;
        }
        else {
            return false;
        }
    })

    hasEntityDetails: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => {
        if (this.entities().length > 0) {
            return true;
        }
        else {
            return false;
        }
    })

    hasZoneDetails: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => {
        if (this.zones().length > 0) {
            return true;
        }
        else {
            return false;
        }
    })

    getStartEventValue = (eventDataType: string): string => {
        if (this.inmates().length > 0) {
            return this.inmates()[0].startEvent.eventData[eventDataType]();
        }
        else {
            return this.entities()[0].startEvent.eventData[eventDataType]();
        }
    }

    getDetail = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/alarm/${this.alarmId}`,
                cache: false
            })
                .done(data => {
                    this.setDetail(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Alarm::getDetail', request, textStatus, error);
                    reject();
                });
        })
    };

    setDetail = (detail) => {
        //
        //  Map Alarm data
        //
        mapping.fromJS(detail, this.mapConfig, this);

        //
        //  set observables
        //
        if (detail.primaryLocation !== null && detail.primaryLocation !== undefined && detail.primaryLocation.trim().length > 0) {
            this.primaryLocation(detail.primaryLocation);
        }
        else {
            this.primaryLocation('');
        }

        if (detail.duration != null) {
            this.duration = moment.duration(detail.duration);
        }
        else {
            this.duration = null;
        }

        //
        //  Map Inmates
        //
        const inmateMap = {
            key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmate) => new AlarmInmate(inmate.data, this, this.view)
        };

        this.inmates = mapping.fromJS(detail.inmates, inmateMap);
        this.hasDetail(true);


        //
        //  Map Entities
        //
        const entityMap = {
            key: (entity) => ko.utils.unwrapObservable(entity.entityId),
            create: (entity) => new AlarmEntity(entity.data, this, this.view)
        };

        this.entities = mapping.fromJS(detail.entities, entityMap);


        //
        //  Map Zones
        //
        const zoneMap = {
            key: (zone) => ko.utils.unwrapObservable(zone.zoneId),
            create: (zone) => new AlarmZone(zone.data)
        };

        this.zones = mapping.fromJS(detail.zones, zoneMap);

        //
        //  Build Name
        //
        const names: Array<string> = [];
        for (let index = 0; index < this.inmates().length; index++) {
            names.push(this.inmates()[index].displayName());
        }
        for (let index = 0; index < this.entities().length; index++) {
            names.push(this.entities()[index].displayName());
        }
        this.displayName(names.join('; '));
      
        //
        //  Build time displays
        //
        let tz: string = null;
        if (this.timeZoneId !== undefined && this.timeZoneId.trim().length > 0) {
            tz = TimeZones[this.timeZoneId];
        }

        const time = common.toFacilityTime(this.alarmTimestamp, tz);
        this.displayAlarmTime(`${time.format('M/D/YYYY')} <span style="white-space: nowrap;">${time.format('h:mm:ss A')}</span>`);
        this.displayAlarmTimePlain(time.format('M/D/YYYY h:mm:ss A'));

        //
        //  Lookup Event Description
        //
        this.eventTypeDescription(this.eventType !== 0 ? common.getEventTypeById(this.eventType).description : '');
    };

    dismiss = async (): Promise<boolean> => {
        let result = false;

        await $.ajax({
            url: '/api/alarm',
            method: 'PUT',
            data: this.toFormData(),
            cache: false,
            contentType: false,
            processData: false,
        })
            .done(() => {
                result = true;
            })
            .fail((request, textStatus, error) => {
                console.error("Alarm::dismiss", request, textStatus, error);
            });

        return result;
    }

    is = (eventType: EventTypeEnum): boolean => this.eventType === eventType;

    //select = () => {
    //    const e = window.event;
    //    e.cancelBubble = true;
    //    if (e.stopPropagation) e.stopPropagation();

    //    if (this.view?.activeId) {
    //        this.view.activeId(this.alarmId);
    //    }

    //    if (this.view?.onSelectionChanged) {
    //        this.view.onSelectionChanged(this)
    //    }
    //}

    toFormData = (): object => {
        const data = {
            alarmId: this.alarmId,
            notes: this.dismissedComment(),
            facilityId: this.facility?.id ?? 0
        }

        return common.objectToFormData(data);
    }

    static getById = async (alarmId: number): Promise<Alarm> => {
        return new Promise((resolve, reject) => {
            const result = new Alarm();
            result.alarmId = alarmId;
            result.getDetail().then(() => {
                resolve(result);
            }, rejectReason => {
                console.error('Alarm::GetById', rejectReason);
                reject(rejectReason);
            })
        })
    }

    public compare(compareTo: any, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'alarm time':
                let aDate: any = moment(this.alarmTimestamp);
                let bDate: any = moment(compareTo.alarmTimestamp);
                result = (aDate - bDate) * direction;
                break;

            case 'facility':
                result = this.facility.description.localeCompare(compareTo.facility.description) * direction;
                break;

            case 'alarm type':
            case 'event type':
                result = this.eventTypeDescription().localeCompare(compareTo.eventTypeDescription()) * direction;
                break;

            case 'inmate name':
            case 'last name':
                result = this.inmates()[0].lastName().localeCompare(compareTo.inmates()[0].lastName()) * direction;
                if (result === 0) {
                    result = this.inmates()[0].firstName().localeCompare(compareTo.inmates()[0].firstName()) * direction;
                }
                break;

            case 'first name':
                result = this.inmates()[0].firstName().localeCompare(compareTo.inmates()[0].firstName()) * direction;
                if (result === 0) {
                    result = this.inmates()[0].lastName().localeCompare(compareTo.inmates()[0].lastName()) * direction;
                }
                break;

            case 'assignment':
                result = this.displayName().localeCompare(compareTo.displayName()) * direction;
                break;

            case 'location':
                result = this.primaryLocation().localeCompare(compareTo.primaryLocation()) * direction;
                break;

            case 'duration':
                result = ((this.duration != null ? this.duration.asSeconds() : -1) - (compareTo.duration != null ? compareTo.duration.asSeconds() : -1)) * direction;
                break;
        }

        return result;
    }

    public itemDescription = (sort: string): string => {
        switch (sort.toLowerCase()) {
            case 'alarm type': sort = 'eventTypeDescription'; break;
            case 'event type': sort = 'eventTypeDescription'; break;
            case 'inmate name': sort = 'displayName'; break;
            case 'last name': sort = 'displayName'; break;
            case 'first name': sort = 'displayName'; break;
            case 'assignment': sort = 'displayName'; break;
            case 'alarm time': sort = 'displayAlarmTimePlain'; break;
            case 'facility': sort = 'facilityName'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public isMatch(filter: string, eventTypes?: string[]): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');
            const regexId = new RegExp('^\\D*' + text1, 'i');

            result = false;
            this.inmates().forEach((inmate) => {
                if ((regexName1.test(inmate.firstName()) && regexName2.test(inmate.lastName()))
                    || (regexName1.test(inmate.lastName()) && regexName2.test(inmate.firstName()))
                    || regexId.test(inmate.inmateIdentifier())) {

                    result = true;
                }
            });

            if (!result) {
                result = (regexName1.test(this.primaryLocation()));
            }
        }

        if (result && (eventTypes ?? []).length > 0) {
            result = eventTypes.indexOf(this.eventType.toString()) > -1;
        }

        return result;
    }

    public getId = (): number => this.alarmId;

    public selected(): void {
        if (!this.hasDetail()) {
            this.getDetail();
        }
    }

    public editWithParent = () => this.view.editAlarm(this.alarmId);
    public edit = () => Dialogs.editAlarm(this.alarmId);
    public editInmate = () => this.inmates()[0].editInmate();

    changeDeviceClick = () => {
        Dialogs.rcInmateAsssignment(this.inmates()[0].inmateId());
    }

    changePresenceClick = () => {
        Dialogs.rcInmatePresence(this.inmates()[0].inmateId());
    }

    contextMenuItems: ko.ObservableArray = ko.observableArray([
        { text: "Assignment/Unassignment", action: this.changeDeviceClick },

        { text: "Change Presence", action: this.changePresenceClick },
    ]);
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Alarms ??= () => { /* */ };
globalThis.DIG.Alarms.Alarm = Alarm;
