﻿import mapping = require('knockout.mapping')
import moment = require("moment-timezone")
import { GenericEntity } from '../GenericEntity'

import { Dialogs } from "../Dialogs"
import ko from 'knockout';

const common = globalThis.DIG.Common;

export class Staff {
    staffId: ko.Observable<number> = ko.observable(0)
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    phoneNumber: ko.Observable<string> = ko.observable('')
    emailAddress: ko.Observable<string> = ko.observable('')
    comment: ko.Observable<string> = ko.observable('')
    
    constructor(data: object) {
        mapping.fromJS(data, {
            //copy: [
            //    'staffId', 'firstName', 'lastName', 'phoneNumber', 'emailAddress', 'comment'
            //]
        }, this);
    }

}