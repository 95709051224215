﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import { CommonViewModel, EntityType, DeviceType } from "../Common"
import { GenericEntity } from "../GenericEntity";

const common: CommonViewModel = globalThis.DIG.Common;

export class EntitySelectViewModel {
    componentId: string = common.uniqueId()
    component: any = null

    entityType: EntityType
    subTypes: []
    noSelectionDescription: string
    showNoSelection: boolean
    preloadList: boolean
    autoSelectSingle: boolean
    maxHeight: number = 298
    value: ko.Observable<number> = ko.observable<number>(0)
    description: ko.Observable<string> = ko.observable<string>('')
    initial: GenericEntity = null
    showAddNew: boolean
    addNewId: number
    addNewDescription: string

    data: ko.ObservableArray<any> = ko.observableArray<any>([])
    bump: ko.Observable<boolean> = ko.observable<boolean>(false)
    selected: ko.Observable<any> = ko.observable<any>(null)
    toggleState: boolean = false

    filter: ko.Observable<string> = ko.observable<string>('')
        .extend({
            rateLimit: {
                timeout: 500,
                method: 'notifyWhenChangesStop'
            }
        })

    public constructor(params) {
        this.entityType = params.entityType || EntityType.Inmate;
        this.subTypes = params.subTypes || [];
        this.noSelectionDescription = params.noSelectionDescription || 'No Selection';
        this.showNoSelection = params.showNoSelection === undefined ? true : params.showNoSelection;
        this.preloadList = params.preloadList || false;
        this.autoSelectSingle = params.autoSelectSingle || false;
        this.maxHeight = params.maxHeight || 298;
        this.showAddNew = params.showAddNew === undefined ? false : params.showAddNew;
        this.addNewId = params.addNewId || -1;
        this.addNewDescription = params.addNewDescription || 'Add New';


        this.filter.subscribe(value => {
            this.fetchData(value);
        });
    }

    fetchData = (value: string) => {
        const params = {
            t: this.entityType,
            s: this.subTypes.join('|'),
            f: value
        };

        $.post('/api/utility/suggest', params)
            .then(data => this.setData(data))
            .fail(_ => { })
            .always(_ => { })
    }

    setData = (data) => {
        this.data = mapping.fromJS(data);
        this.bump(!this.bump());

        if (this.autoSelectSingle) {
            if (data != null && data.length == 1) {
                this.select(this.data()[0]);
            }
        }
    }

    private _firstInit = true;
    public init = (value: ko.Observable<number>, description: ko.Observable<string>) => {
        this.value = value;
        this.description = description;

        if (value() == 0) {
            this.description(this.noSelectionDescription);
        }

        this.initial = new GenericEntity(value(), this.description());

        //
        //  Reset everything (this cleans up the control for the next inmate)
        //
        this.filter('');
        this.selected(null);
        this.closeDropdown();

        //
        //  Add an oob click test to close the selector
        //
        if (this._firstInit) {
            this._firstInit = false;
            document.addEventListener('click', (event) => {
                if (this.component == null) { this.component = document.querySelector(`#${this.componentId}`); }
                const containsControl = event.composedPath().includes(document.querySelector(`#${this.componentId}`))
                if (this.toggleState && !containsControl) { this.closeDropdown(); }
            });
        }

        if (this.preloadList) {
            this.fetchData("");
        }
    }

    results = ko.computed(() => {
        var bump = this.bump();
        return this.data();
    });

    select = (e) => {
        this.closeDropdown();
        this.selected(e);
        this.value(e.displayItem.id());
        this.description(e.displayItem.description());
    };

    deselect = () => {
        this.closeDropdown();
        this.selected(null);
        this.value(0);
        this.description(this.noSelectionDescription);
    };

    revert = () => {
        this.closeDropdown();
        this.selected(null);
        this.value(this.initial.id());
        this.description(this.initial.description());
    };

    addNew = () => {
        this.closeDropdown();
        this.selected(null);
        this.value(this.addNewId);
        this.description(this.addNewDescription);
    };

    closeDropdown = () => {
        this.toggleState = false;
        $(`#${this.componentId}`).find('.dropdown-menu').removeClass('show');
        $(`#${this.componentId}`).find('.input-group-append').removeClass('show');
    };

    openDropdown = () => {
        this.toggleState = true;
        $(`#${this.componentId}`).find('.dropdown-menu').addClass('show');
        $(`#${this.componentId}`).find('.input-group-append').addClass('show');
        eval(`$("#${this.componentId}").find(':text')[1].focus()`);
    }

    toggleDropdown = () => {
        if (this.toggleState) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }

    borderStyle = () => {
        if (this.addNew) {
            return "border-top: 0px solid gainsboro; height: 3px;";
        }
        else {
            "border-top: 1px solid gainsboro; height: 3px;"
        }
    }
}

ko.components.register('EntitySelectDropdown', {
    viewModel: function (params) { return params.instance; },
    template:
        '<div data-bind="attr: {id: componentId}" class="input-group input-group-sm dropdown-filter">\
            <input type="text" data-bind="value: description, event: { click: openDropdown }" class="form-control form-control-sm input-group-text bg-white text-left" readonly />\
            <div class="input-group-append"> \
                <div class="btn btn-sm btn-primary text-white dropdown-toggle" data-bind="event: { click: toggleDropdown }" style="cursor: pointer;"></div>\
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-sm allow-focus py-0" style="width: 100%;">\
                    <div class="d-flex flex-column" data-bind="style: {\'max-height\': (maxHeight + \'px\')}">\
                        <div class="d-flex">\
                            <input type="text" data-bind="value: filter, valueUpdate: \'afterkeydown\'" class="form-control form-control-sm input-group-text bg-white text-left m-2" />\
                        </div>\
                        <div class="flex-fill" style="overflow-y: auto;" data-bind="foreach: results">\
                            <a class="dropdown-item" href="#!" data-bind="text: displayItem.description, click: $parent.select"></a>\
                        </div>\
                        <div data-bind="visible: showAddNew" style="border-top: 1px solid gainsboro; height: 3px;"></div>\
                        <a class="dropdown-item" href="#!" data-bind="text: addNewDescription, click: addNew, visible: showAddNew"></a>\
                        <div data-bind="visible: showNoSelection, style=borderStyle()"></div>\
                        <a class="dropdown-item" href="#!" data-bind="text: noSelectionDescription, click: deselect, visible: showNoSelection"></a>\
                    </div>\
                </div>\
            </div>\
        </div>'
})