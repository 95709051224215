﻿import ko = require('knockout');

//
//  String Length
//
export type LengthValidationOptions = {
    min: number;
    max: number;
    fieldName: string;
    overrideMessage?: string;
    isRequired?: boolean;
}

ko.extenders.length = (target, options: LengthValidationOptions) => {
    target.hasError = ko.observable<boolean>();
    target.validationMessage = ko.observable<string>();

    function validate(newValue: string) {
        newValue = $.trim(newValue);

        if (options.isRequired || newValue.length > 0) {

            const hasError: boolean = (newValue.length < options.min || newValue.length > options.max);
            let message = '';

            if (hasError) {
                if (options.overrideMessage) {
                    message = options.overrideMessage;
                } else {
                    message = (options.min === 0)
                        ? `${options.fieldName} must be no longer than ${options.max} characters`
                        : `${options.fieldName} must be between ${options.min} and ${options.max} character${options.max > 1 ? 's' : ''}`
                }
            }

            target.hasError(hasError);
            target.validationMessage(message);
        }
        else {
            target.hasError(false);
            target.validationMessage("");
        }
    }

    validate(target());
    target.subscribe(validate);

    return target;
}

//
//  Numeric range
//
export class RangeValidationOptions {
    fieldName: string;
    overrideMessage?: string;

    min: number;
    max: number;
    default?: number;

    constructor(fieldName: string, minValue: number | string, maxValue: number | string, defaultValue?: number | string, overrideMessage?: string) {
        this.fieldName = fieldName;
        this.overrideMessage = overrideMessage;

        this.min = parseFloat(minValue.toString());
        this.max = parseFloat(maxValue.toString());

        if (defaultValue) {
            this.default = parseFloat(defaultValue.toString());
        }
    }
}

ko.extenders.range = (target, options: RangeValidationOptions) => {
    target.hasError = ko.observable<boolean>();
    target.validationMessage = ko.observable<string>();

    function validate(newValue: string) {
        const value = parseFloat(newValue);
        let message = '';

        const hasError: boolean = (value < options.min || value > options.max);

        if (hasError) {
            if (options.overrideMessage) {
                message = options.overrideMessage;
            } else {
                message = `${options.fieldName} must be between ${options.min} and ${options.max}.`;

                if (options.default !== null) {
                    message += ` (Default = ${options.default})`;
                }
            }
        }

        target.hasError(hasError);
        target.validationMessage(message);
    }

    validate(target());
    target.subscribe(validate);

    return target;
}


//
//  String Regex
//
export type RegExValidationOptions = {
    regEx: any; // needs to be passed in as "regEx: new RegExp(...)"
    fieldName: string;
    overrideMessage?: string;
    isRequired?: boolean;
}

ko.extenders.regEx = (target, options: RegExValidationOptions) => {
    target.hasError = ko.observable<boolean>();
    target.validationMessage = ko.observable<string>();

    function validate(newValue: string) {
        newValue = $.trim(newValue);

        if (options.isRequired || newValue.length > 0) {

            let testResult = options.regEx.test(newValue); 

            const hasError: boolean = (options.isRequired && newValue.length == 0) || !testResult;
            let message = '';

            if (hasError) {
                if (options.overrideMessage) {
                    message = options.overrideMessage;
                } else {
                    message = `${options.fieldName} did not match validation pattern.`;
                }
            }

            target.hasError(hasError);
            target.validationMessage(message);
        }
        else {
            target.hasError(false);
            target.validationMessage("");
        }
    }

    validate(target());
    target.subscribe(validate);

    return target;
}


//
//  Validation Class
//      Holds the static "add" methods
//
export class Validation {
    public static addLength = (item: ko.Observable<string>, options: LengthValidationOptions) => {
        let value: string = item();

        if (value === undefined || value === null || value === 'null') {
            value = '';
        }

        //console.info('Add Length', value);

        // @ts-ignore
        return ko.observable(value).extend({ length: options })
    }

    public static addRange = (item: ko.Observable<number>, options: RangeValidationOptions) => {
        let value: number = item();

        if (value === undefined || value === null) {
            value = 0;
        }

        //console.info(`Add Range - ${options.fieldName}`, value);

        // @ts-ignore
        return ko.observable(value).extend({ range: options })
    }

    public static addRangeS = (item: ko.Observable<string>, options: RangeValidationOptions) => {
        let value: number = parseFloat(item().toString());

        if (value === undefined || value === null) {
            value = 0;
        }

        //console.info(`Add Range - ${options.fieldName}`, value);

        // @ts-ignore
        return ko.observable(value.toString()).extend({ range: options })
    }

    public static addRegEx = (item: ko.Observable<string>, options: RegExValidationOptions) => {
        let value: string = item();

        if (value === undefined || value === null || value === 'null') {
            value = '';
        }

        //console.info('Add Length', value);

        // @ts-ignore
        return ko.observable(value).extend({ regEx: options })
    }
}
