﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from 'util';
import Common = require("../Common")
import { GenericEntity, GenericEntityWithStatus } from '../GenericEntity';
import { SecurityGroupPermission } from './SecurityGroupPermission';

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class SecurityGroup {
    view: any // view should be UserDialog
    securityGroupId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable("")
    permissions: ko.ObservableArray<SecurityGroupPermission> = ko.observableArray()
    

    //isChecked: ko.Observable<boolean> = ko.observable(false) //temporary while using checkboxes

    constructor(data?: object, userId?: number, view?: object) {
        this.view = view; // view should be UserDialog

        if (data) {
            mapping.fromJS(data, { ignore: ['permissions' ] }, this);
        }

        var optionsMap = {
            create: (inObj) => new SecurityGroupPermission(inObj.data)
        };

        if (!isNullOrUndefined(data["permissions"])) {
            this.permissions = (mapping.fromJS(data["permissions"], optionsMap));
        }
        else {
            this.permissions = null;
        }

    }

}
