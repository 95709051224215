﻿import ko = require('knockout')
import mapping = require('knockout.mapping')

import { AlarmEvent } from "./AlarmEvent"
import { Dialogs } from "../Dialogs"
import { EntityType } from '../Common'

export class AlarmEntity {
    view //: any
    alarm: AlarmEvent
    alarmId: ko.Observable<number>
    deviceId: ko.Observable<number>
    entityType: ko.Observable<EntityType>
    entityId: ko.Observable<number>
    nameB: ko.Observable<string>
    nameA: ko.Observable<string>
    location: ko.Observable<string>
    identifier: ko.Observable<string>
    startEvent: AlarmEvent
    clearEvent: AlarmEvent

    constructor(data, alarm, view) {
        this.alarm = alarm;
        this.view = view;

        mapping.fromJS(data, {}, this);
    }

    displayName: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        let prettyLocation = '';
        if (this.location() != undefined && this.location().length > 0) {
            prettyLocation = ` (${this.location()})`;
        }

        if (this.entityType() == EntityType.Unknown) {
            return `Device ${this.deviceId()}${prettyLocation}`
        }
        else {
            return this.view.sort == undefined || this.view.sort() == 'firstName'
                ? `${this.nameA()} ${this.nameB()}${prettyLocation}`
                : `${this.nameB()}, ${this.nameA()}${prettyLocation}`;
        }
    });

    displayNameNoLocation: ko.PureComputed<string> = ko.pureComputed<string>(() => {
        
        return this.view.sort == undefined || this.view.sort() == 'firstName'
            ? `${this.nameA()} ${this.nameB()}`
            : `${this.nameB()}, ${this.nameA()}`;
    });

    //imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)

    //editInmate = () => {
    //    event.stopPropagation();
    //    Dialogs.editInmate(this.inmateId());
    //}
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Alarms ??= () => { /* */ };
globalThis.DIG.Alarms.AlarmEntity = AlarmEntity;
