﻿import ko = require("knockout")

import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { CommonViewModel } from "../Common"
import { Dialogs, AlarmDialogOptions } from "../Dialogs"
import { Alarm } from "./Alarm"

const common: CommonViewModel = globalThis.DIG.Common

export interface AlarmsViewModel {
    showDismissModal: () => void;
}

export class AlarmsViewModel extends ViewModelBase<Alarm> {
    _alarmDismissed = (alarmId: number) => this.removeAlarm(alarmId);
    getPdf = () => this._getPdf();

    showDismissModal = () => {
        window.eval('$("#dismissModal").modal("show")');
    }

    options: ViewModelBaseOptions = {
        settingPrefix: "Alarms",
        allowSelection: false,
        allowMultipleSelections: true,
        clearSelectionsOnPageChange: true,
        showAddNew: false,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            enabled: true,
            show: true,
            intervalSeconds: 60
        },
        flashNewRows: true,
        showExportExcel: false,
        showExportPDF: true,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Alarm Time', 'Alarm Type', 'Assignment'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [{ description: "Work Sheet", callback: this.getPdf, altKey: 'X', class: 'bg-success' }],
        selectionActions: [{ description: 'Dismiss Selected', class: 'bg-success', callback: this.showDismissModal, altKey: 'd' }],
        rightClickActions: []
    }

    dialogOptions: AlarmDialogOptions = {
        onShown: null,
        onSave: null,
        onHidden: null,
        onDismissed: null
    }

    protected createItem = (data?: object, view?: object): Alarm => new Alarm(data, view)

    protected fetchData(): Promise<object> {
        return new Promise((resolve, reject) => {
            $.get('/api/alarm/open')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("AlarmsViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    filterData = (): Alarm[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: Alarm) => item.isMatch(filterText, this.alarmTypesFilter()));
    }

    isDismissVisible: ko.Observable<boolean> = ko.observable(false)
    dismissedComment: ko.Observable<string> = ko.observable('')

    alarmTypes = ko.observableArray(common.getAlarmEventTypes('description'))
    alarmTypesFilter = ko.observableArray(ko.utils.arrayMap(this.alarmTypes(), (e) => e.id.toString())).extend({
        rateLimit: {
            timeout: 75,
            method: 'notifyWhenChangesStop'
        }
    })

    onAlarmReceived = () => {
        if (this.autoRefresh()) {
            this.loadData();
        } else {
            this.updateAvailable(true);
        }
    }

    addAlarm(alarm) {
        this.data.mappedCreate(alarm);
    }

    removeAlarm(alarmId: number, silent = false) {
        const alarm = ko.utils.arrayFirst<Alarm>(this.data(), (alarm: Alarm) => alarm.alarmId === alarmId);
        if (alarm) {
            this.data.remove(alarm);
            if (!(silent ?? false)) {
                common.toast('success', `Alarm was dismissed.`, 'Dismiss Alarm');
            }
        }
    }

    dismissSelected = (): void => {
        const request = {
            dismissedComment: this.dismissedComment(),
            alarms: []
        }

        this.loading.show();

        ko.utils.arrayFilter<Alarm>(this.data(), (alarm) => alarm.isSelected())
            .forEach(alarm => request.alarms.push(alarm.alarmId));

        $.post('/api/alarm/dismissmany', request)
            .done((result) => {
                result.successful.forEach(alarmId => ko.utils.arrayRemoveItem<Alarm>(this.data(), this.find(alarmId)));
                this.data.valueHasMutated();
                this.itemSelected();

                if (result.failureCount === 0) {
                    common.toast('success', `${result.successful.length} alarms dismissed`, 'Dismiss Selected Alarms');
                }
                else {
                    common.toast('error', `${result.successful.length} of ${result.requestedCount} selected alarms dismissed.`, 'Dismiss Selected Alarms');
                }
            })
            .fail((request, textStatus, error) => {
                console.error('AlarmsViewModel::dismissSelected', request, textStatus, error);
            })
            .always(_ => {
                window.eval('$("#dismissModal").modal("hide")');
                this.loading.hide();
            });
    }

    constructor() {
        super()
        this.dialogOptions.onDismissed = this._alarmDismissed;
    }

    initChild = () => {
        common.newAlarm = this.onAlarmReceived;
    }

    onClick = (id) => {
        Dialogs.editAlarm(id, this.dialogOptions);
    }

    _getPdf = () => {
        common.postBinaryRequest(`/api/report/alarm/tagSwaps`, null);
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Alarms ??= () => { /* */ };
globalThis.DIG.Alarms.ViewModel = AlarmsViewModel