﻿import $ = require('jquery')
import ko = require("knockout")

import { CommonViewModel, EntityType } from "../Common"
import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { Dialogs, DeviceDialogOptions } from "../Dialogs"
import { StaffDevice } from './StaffDevice'

const common: CommonViewModel = globalThis.DIG.Common;

export class StaffDeviceViewModel extends ViewModelBase<StaffDevice> {
    
    options: ViewModelBaseOptions = {
        settingPrefix: "StaffDevices",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: false,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            enabled: true,
            show: false,
            intervalSeconds: 90
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Device Id', 'Assignment', 'Location', 'Battery Status'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [],
        selectionActions: [],
        rightClickActions: []
    }

    dialogOptions: DeviceDialogOptions = {
        isReadOnly: true,
        showAlarms: true,
        showCommands: true,
        showConfiguration: true,
        showRemoteDebug: false,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onCancel: null
    }

    protected createItem = (data?: object, view?: object) => new StaffDevice(data, view)

    onClick = (id) => {
        Dialogs.editStaffDevice(id, this.dialogOptions);
    }

    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get('/api/staff/device/list')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("StaffDeviceViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    filterData = (): StaffDevice[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: StaffDevice) => item.isMatch(filterText));
    }

    public constructor() {
        super();
        this.dialogOptions.onSave = this._assignmentSaved;

    }

    //initChild = () => {
       
    //}

    _assignmentSaved = (deviceId, results) => {
        //const staffDevice = this.find(deviceId);

        this.loadData();
    }

    // TODO: use push notification for battery level updates or location changes.
    //batteryLevelChanged = (deviceId, staffDevice) => {
    //    const device = this.data().find(e => e.deviceId == deviceId);

    //    if (device) {
    //        device(staffDevice);
    //    }
    //}
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.StaffDevices === undefined) {
    globalThis.DIG.StaffDevices = () => { /* */ };
}

globalThis.DIG.StaffDevices.ViewModel = StaffDeviceViewModel