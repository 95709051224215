﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class InmatePresence {
    
    inmatePresenceTypeId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable('')
    includeInHeadcount: ko.Observable<boolean> = ko.observable(true)
    showOnScoreCard: ko.Observable<boolean> = ko.observable(false)
    forceUnassignmentReasonId: ko.Observable<number> = ko.observable(-1)
    displayOrder: ko.Observable<number> = ko.observable(-1)

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
        }
    }

}
