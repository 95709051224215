﻿import ko = require("knockout")
import moment = require("moment-timezone")
import Chart = require("chart.js")

import { RollCallChart } from "./RollCallChart"
import { CurrentAlarms } from "./CurrentAlarms"
import { AlarmDialogModel } from "../Alarms/AlarmDialog"

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

Chart.defaults.global.maintainAspectRatio = false;
Chart.defaults.global.responsive = true;

export class DashboardViewModel {
    rollCall: RollCallChart = new RollCallChart(
        $('#chartRollCall'),
        //$('#chartRollCallCurrent'),
        $('#rollCall')
    )

    currentAlarms: CurrentAlarms = new CurrentAlarms($('#currentAlarms > div.alarm-table'));

    constructor() {
    }

    init = () => {
        ko.applyBindings(this);

        this.rollCall.refresh();
        this.currentAlarms.getAlarms();

        globalThis.DIG.Common.refreshDashboard = this.refreshDashboard;
    }

    refreshDashboard = (section, data) => {
        section = section.toLowerCase();
        let keyParts: string[] = section.split('.');

        switch (keyParts[0]) {
            case 'rollcall':
                this.rollCall.setData(data, false);
                break;

            case 'alarms-past24hours':
                this.currentAlarms.getAlarms();
                break;

            default:
                console.log(`Unknown message received (${section})`);
                break;
        }
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dashboard ??= () => { /* */ };
globalThis.DIG.Dashboard.ViewModel = DashboardViewModel;