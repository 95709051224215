﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class GeneralPopulation {
    livingUnit: ko.Observable<string> = ko.observable('')
    zoneId: ko.Observable<number> = ko.observable(0)
    assigned: ko.Observable<number> = ko.observable(0)
    offGrounds: ko.Observable<number> = ko.observable(0)
    tentative: ko.Observable<number> = ko.observable(0)
    actual: ko.Observable<number> = ko.observable(0)

    isCountMissmatch: ko.Computed<boolean> = ko.computed<boolean>(() => this.tentative() != this.actual())

    displayZoneName: ko.Computed<string> = ko.computed<string>(() => {
        if (this.livingUnit() == "") {
            return "No Dorm Assigned";
        }
        else {
            return this.livingUnit();
        }
    })

    showZoneRedirect: ko.Computed<boolean> = ko.computed<boolean>(() => this.zoneId() != 0)

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
        }
    }

    public navigateToMonitor = () => {
        common.navigate(`/monitor/dorm?lid=${this.zoneId()}`, true);
    }
}
