﻿export class LoadingIndicator {
    private _parent: any | undefined = undefined;

    public constructor(parent: any) {
        this._parent = parent;
    }

    public show(delay?: number) {
        if (delay === undefined) delay = 500;

        this._parent.append('<div class="loading loading-backdrop" data-max-opacity="0.5" style="opacity: 0;"></div><div class="loading loading-spinner d-flex" data-max-opacity="1.0" style="opacity: 0"><img src="/images/Spinner.svg" class="loading m-auto" data-max-opacity="1.0" style="width: 100%; max-width: 100px; opacity: 0;" /></div>');
        this._parent.find('.loading')
            .each((index, element) => {
                $(element)
                    .delay(delay)
                    .animate({ opacity: $(element).data('max-opacity') }, 250, 'swing');
            });
    }

    public hide() {
        this._parent.find('.loading').animate({ opacity: 0.0 }, 250, 'swing');
        this._parent.find('.loading').remove();
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.LoadingIndicator = LoadingIndicator;