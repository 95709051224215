﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import moment = require('moment-timezone')

import { isNullOrUndefined } from 'util'

export class AlarmDetection {

    deviceId: number
    timestamp: moment.Moment

    constructor(data) {

        var map = {
            'copy': ["deviceId"]
        }

        mapping.fromJS(data, map, this);

        if (!isNullOrUndefined(data["timestamp"])) {
            this.timestamp = moment(data["timestamp"])
        }
        else {
            this.timestamp = ''.toMoment();
        }

        //mapping.fromJS(data, {}, this);
    }
}