﻿import { AlarmDialogModel } from "./Alarms/AlarmDialog"
import { PanicDialogModel } from "./Alarms/PanicDialog"
import { InmateDialogModel } from "./Inmates/InmateDialog"
import { DeviceDialogModel } from "./Devices/DeviceDialog"
import { FindDialogModel } from "./Components/FindDialog"
import { ChangePasswordDialogModel } from "./Components/ChangePasswordDialog"
import { ZoneDialogModel } from "./Zones/ZoneDialog"
import { UserDialogModel } from "./Users/UserDialog"
import { StaffDeviceDialogModel } from "./Staff/StaffDeviceDialog"
import { InmateAssignmentRcDialog } from "./Inmates/InmateAssignmentRcDialog"
import { InmatePresenceRcDialog } from "./Inmates/InmatePresenceRcDialog"

export class AlarmDialogOptions {
    onSave: Function = null
    onShown: Function = null
    onHidden: Function = null
    onDismissed: Function = null
}

export class PanicDialogOptions {
    allowDismiss: boolean = false;
    onShown: Function = null
    onHidden: Function = null
}

export class InmateDialogOptions {
    isReadOnly: boolean = false;
    showAlarms: boolean = true;
    showConfiguration: boolean = true;
    showTemp: boolean = true;
    showLocation: boolean = true;
    allowEdit: boolean = true;
    onSave: Function = null;
    onShown: Function = null;
    onHidden: Function = null;
    onDeactivate: Function = null;
}

export class DeviceDialogOptions {
    isReadOnly: boolean = false;
    showAlarms: boolean = true;
    showConfiguration: boolean = true;
    showLocation: boolean = true;
    showCommands: boolean = true;
    showRemoteDebug: boolean = true;
    allowEdit: boolean = true;
    onSave: Function = null;
    onShown: Function = null;
    //onHidden: Function = null;

    onCancel: Function = null;
}

export class ZoneDialogOptions {
    isReadOnly: boolean = false;
    showConfiguration: boolean = false;
    showLocators: boolean = true;
    //showCommands: boolean = true;
    allowEdit: boolean = true;
    onSave: Function = null;
    onShown: Function = null;
    //onLocatorsChanged: Function = null;
    //onHidden: Function = null;

    //onCancel: Function = null;
}

export class StaffDeviceDialogOptions {
    isReadOnly: boolean = false;
    allowEdit: boolean = true;
    onSave: Function = null;
    onShown: Function = null;
    //onHidden: Function = null;
    onCancel: Function = null;
}

export class UserDialogOptions {
    isReadOnly: boolean = false;
    showPermissions: boolean = false;
    allowEdit: boolean = true;
    onSave: Function = null;
    onShown: Function = null;
    //onHidden: Function = null;
    //onCancel: Function = null;
}

export class FindDialogOptions {
    showInmates: boolean = true;
    showDevices: boolean = true;
    showContracts: boolean = false;
    showFacilities: boolean = true;
}

//
//  Right click Options and Delegates
//
export class InmateAssignmentRcDialogDelegates {
    onSave: Function = null;
}

export class InmatePresenceRcDialogDelegates {
    onSave: Function = null;
}

export interface Dialogs {
    editAlarm(alarmId: number, options?: AlarmDialogOptions);
    editDevice(deviceId: number, options?: DeviceDialogOptions);
    editInmate(inmateId: number, options?: InmateDialogOptions);
    showFindDialog(options?: FindDialogOptions);
    showPanicDialog(data: any);
    initPanic(options?: PanicDialogOptions);
    changePassword();
}

export abstract class Dialogs {
    static _alarmDialog: AlarmDialogModel;
    static _deviceDialog: DeviceDialogModel;
    static _zoneDialog: ZoneDialogModel;
    static _staffDeviceDialog: StaffDeviceDialogModel;
    static _findDialog: FindDialogModel;
    static _inmateDialog: InmateDialogModel;
    static _panicDialog: PanicDialogModel;
    static _changePassword: ChangePasswordDialogModel;
    static _userDialog: UserDialogModel;

    // right click dialogs
    static _rcInmateAssignmentDialog: InmateAssignmentRcDialog;
    static _rcInmatePresenceDialog: InmatePresenceRcDialog;

    // global dialog delegates
    public static inmateAssignmentDialogDelegates: InmateAssignmentRcDialogDelegates;
    public static inmatePresenceDialogDelegates: InmatePresenceRcDialogDelegates;

    public static initPanic(options?: PanicDialogOptions) {
        (this._panicDialog ??= new PanicDialogModel(options)).init();
    }

    private static hideBodyContent() {
        $('.body-content').css('display', 'none !important');
    }

    private static showBodyContent() {
        $('.body-content').css('display', '');
    }

    public static editInmate(inmateId: number, options?: InmateDialogOptions) {
        if (!this._inmateDialog) {
            this._inmateDialog = new InmateDialogModel({});
            this._inmateDialog.onBeforeHide = this.showBodyContent;
            this._inmateDialog.onBeforeShow = this.hideBodyContent;
        }

        if (options) {
            if (options.isReadOnly !== undefined) this._inmateDialog.isReadOnlyDefault = options.isReadOnly;
            if (options.showAlarms !== undefined) this._inmateDialog.showAlarms(options.showAlarms);
            if (options.showTemp !== undefined) this._inmateDialog.showTemp(options.showTemp);
            if (options.showLocation !== undefined) this._inmateDialog.showLocation(options.showLocation);
            if (options.showConfiguration !== undefined) this._inmateDialog.showConfiguration(options.showConfiguration);
            if (options.allowEdit !== undefined) this._inmateDialog.allowEdit(options.allowEdit);
            if (options.onSave !== undefined) this._inmateDialog.onSave = options.onSave;
            if (options.onShown !== undefined) this._inmateDialog.onShown = options.onShown;
            if (options.onHidden !== undefined) this._inmateDialog.onHidden = options.onHidden;
            if (options.onDeactivate !== undefined) this._inmateDialog.onDeactivate = options.onDeactivate;
        }
        
        this._inmateDialog.edit(inmateId);
    }

    public static editAlarm(alarmId: number, options?: AlarmDialogOptions) {
        this._alarmDialog ??= new AlarmDialogModel({});

        if (options) {
            if (options.onShown !== undefined) this._alarmDialog.onShown = options.onShown;
            if (options.onSave !== undefined) this._alarmDialog.onSave = options.onSave;
            if (options.onHidden !== undefined) this._alarmDialog.onHidden = options.onHidden;
            if (options.onDismissed !== undefined) this._alarmDialog.onDismissed = options.onDismissed;
        }

        this._alarmDialog.edit(alarmId);
    }

    public static editDevice(deviceId: number, options?: DeviceDialogOptions) {
        this._deviceDialog ??= new DeviceDialogModel({});

        if (options) {
            if (options.isReadOnly !== undefined) this._deviceDialog.isReadOnlyDefault = options.isReadOnly;
            if (options.showAlarms !== undefined) this._deviceDialog.showAlarms(options.showAlarms);
            //if (options.showLocation !== undefined) this._deviceDialog.showLocation(options.showLocation);
            if (options.showConfiguration !== undefined) this._deviceDialog.showConfiguration(options.showConfiguration);
            if (options.showRemoteDebug !== undefined) this._deviceDialog.showRemoteDebug(options.showRemoteDebug);
            //if (options.showCommands !== undefined) this._deviceDialog.showCommands(options.showCommands);
            if (options.allowEdit !== undefined) this._deviceDialog.allowEdit(options.allowEdit);
            if (options.onSave !== undefined) this._deviceDialog.onSave = options.onSave;
            if (options.onCancel !== undefined) this._deviceDialog.onCancel = options.onCancel;
        }

        this._deviceDialog.edit(deviceId);
    }

    public static editZone(zoneId: number, options?: ZoneDialogOptions) {
        this._zoneDialog ??= new ZoneDialogModel({});

        if (options) {
            if (options.isReadOnly !== undefined) this._zoneDialog.isReadOnlyDefault = options.isReadOnly;
            if (options.showConfiguration !== undefined) this._zoneDialog.showConfiguration(options.showConfiguration);
            if (options.allowEdit !== undefined) this._zoneDialog.allowEdit(options.allowEdit);
            if (options.onSave !== undefined) this._zoneDialog.onSave = options.onSave;
            //if (options.onLocatorsChanged !== undefined) this._zoneDialog.onLocatorsChanges = options.onLocatorsChanged;
        }

        this._zoneDialog.edit(zoneId);
    }

    public static editStaffDevice(deviceId: number, options?: StaffDeviceDialogOptions) {
        this._staffDeviceDialog ??= new StaffDeviceDialogModel({});

        if (options) {
            if (options.isReadOnly !== undefined) this._staffDeviceDialog.isReadOnlyDefault = options.isReadOnly;
            if (options.allowEdit !== undefined) this._staffDeviceDialog.allowEdit(options.allowEdit);
            if (options.onSave !== undefined) this._staffDeviceDialog.onSave = options.onSave;
        }

        this._staffDeviceDialog.edit(deviceId);
    }

    public static showFindDialog(options?: FindDialogOptions) {
        (this._findDialog ??= new FindDialogModel(options)).showDialog(options);
    }

    public static changePassword() {
        (this._changePassword ??= new ChangePasswordDialogModel()).showDialog();
    }

    public static showPanicDialog(data: any) {
        this._panicDialog.showDialog(data);
    }

    public static editUser(userId: number, options?: UserDialogOptions) {
        this._userDialog ??= new UserDialogModel({});

        if (options) {
            if (options.isReadOnly !== undefined) this._userDialog.isReadOnly(options.isReadOnly);
            if (options.showPermissions !== undefined) this._userDialog.showPermissions(options.showPermissions);
            if (options.allowEdit !== undefined) this._userDialog.allowEdit(options.allowEdit);
            if (options.onSave !== undefined) this._userDialog.onSave = options.onSave;
        }

        this._userDialog.edit(userId);
    }


    //
    //  Right Click Dialogs
    //
    public static rcInmateAsssignment(inmateId: number, options?: InmateAssignmentRcDialogDelegates) {
        if (!this._rcInmateAssignmentDialog) {
            this._rcInmateAssignmentDialog = new InmateAssignmentRcDialog(null);
        }

        if (options) {
            if (options.onSave !== undefined) this._rcInmateAssignmentDialog.onSave = options.onSave;
        }

        this._rcInmateAssignmentDialog.showDialog(inmateId);
    }

    public static rcInmatePresence(inmateId: number, options?: InmatePresenceRcDialogDelegates) {
        if (!this._rcInmatePresenceDialog) {
            this._rcInmatePresenceDialog = new InmatePresenceRcDialog();
        }

        if (options) {
            if (options.onSave !== undefined) this._rcInmatePresenceDialog.onSave = options.onSave;
        }

        this._rcInmatePresenceDialog.showDialog(inmateId);
    }

    public static createStaticAssignmentDelegates(onSaveFct: Function) {
        this.inmateAssignmentDialogDelegates = {
            onSave: null
        }

        this.inmateAssignmentDialogDelegates.onSave = onSaveFct
    }

    public static createStaticPresenceDelegates(onSaveFct: Function) {
        this.inmatePresenceDialogDelegates = {
            onSave: null
        }

        this.inmatePresenceDialogDelegates.onSave = onSaveFct
    }

}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Dialogs = Dialogs;