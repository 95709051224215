﻿export enum TimeIntervalTypes {
    QuarterHour = 0,
    HalfHour = 1,
    Hour = 2,
    Day = 3,
    DayOfWeek = 4,
    Week = 5,
    Month = 6,
    Quarter = 7,
    Year = 8
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

globalThis.DIG.TimeIntervalTypes = TimeIntervalTypes