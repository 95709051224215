﻿import { CommonViewModel } from "../Common"
const common: CommonViewModel = globalThis.DIG.Common

import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from "util"

import { ViewModelItem } from "../ViewModelItem"
import { DeviceAssignment } from "../Devices/DeviceAssignment"
import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"
import { Dialogs } from "../Dialogs"

export class Inmate extends ViewModelItem {
    hasDetail: ko.Observable<boolean> = ko.observable(false)
    hasConfiguration: ko.Observable<boolean> = ko.observable(false)
    hasAssignments: ko.Observable<boolean> = ko.observable(false)
    hasTemp: ko.Observable<boolean> = ko.observable(false)

    inmateId: ko.Observable<number> = ko.observable(0)
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    middleName: ko.Observable<string> = ko.observable('')
    timeZoneId: ko.Observable<string> = ko.observable('')
    contract = { description: ko.observable('') }
    agency = { description: ko.observable('') }
    facility = { description: ko.observable('') }
    securityLevel = { id: ko.observable<number>(0), description: ko.observable<string>('') }
    building = { id: ko.observable<number>(0), description: ko.observable<string>('') }
    block: ko.Observable<string> = ko.observable('')
    bunk: ko.Observable<string> = ko.observable('')
    deviceId: ko.Observable<number> = ko.observable(0)
    deviceDescription: ko.Observable<string> = ko.observable('Unassigned')
    deviceAssignmentDate: moment.Moment = ''.toMoment()
    imageSource: ko.Observable<string> = ko.observable('/images/photoUnavailable.jpg')
    lastEventTimestamp: moment.Moment = ''.toMoment()
    location: ko.Observable<string> = ko.observable<string>('')
    notes: ko.Observable<string> = ko.observable<string>('')
    presence = { inmatePresenceTypeId: ko.observable<number>(0), description: ko.observable<string>('') }
    demoFirstName: ko.Observable<string> = ko.observable('')
    demoLastName: ko.Observable<string> = ko.observable('')
    demoMiddleName: ko.Observable<string> = ko.observable('')
    hasGangAffiliation: ko.Observable<boolean> = ko.observable(false)
    hasGangAffiliationNumber: ko.Observable<string> = ko.observable('0')

    configuration: any = ko.observableArray<ConfigurationSetting>([])
    assignments: any = ko.observableArray<DeviceAssignment>([])

    bump: ko.Observable<boolean> = ko.observable(true);

    assignmentsBind: ko.Computed = ko.computed(() => {
        var bump = this.bump();
        return this.assignments();
    })

    constructor(data?: object, view?: object) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('last name')
            };
        }

        if (data) {
            mapping.fromJS(data, this.mapConfig, this);

            if (!isNullOrUndefined(data["lastEventTimestamp"])) {
                this.lastEventTimestamp = moment(data["lastEventTimestamp"])
            }
            else {
                this.lastEventTimestamp = ''.toMoment();
            }

            if (!isNullOrUndefined(data["deviceAssignmentDate"])) {
                this.deviceAssignmentDate = moment(data["deviceAssignmentDate"])
            }
            else {
                this.deviceAssignmentDate = ''.toMoment();
            }
        }

        this.hasGangAffiliationNumber.subscribe(newValue => {
            if (newValue == "1") {
                this.hasGangAffiliation(true);
            }
            else {
                this.hasGangAffiliation(false);
            }
        });
    }

    displayAssignedDate: ko.PureComputed<string> = ko.pureComputed(() => {
        let assigned: moment.Moment = moment(this.deviceAssignmentDate) || ''.toMoment();

        return assigned.isAfter(''.toMoment()) ?
            globalThis.DIG.Common.toFacilityTime(assigned).format('M/D/YYYY h:mm:ss A')
            : '';
    });

    displayLastEventTimestamp: ko.PureComputed<string> = ko.pureComputed(() => {
        let last: moment.Moment = moment(this.lastEventTimestamp) || ''.toMoment();

        return last.isAfter(''.toMoment()) ?
            globalThis.DIG.Common.toFacilityTime(last).format('M/D/YYYY h:mm:ss A')
            : '';
    });

    displayName: ko.PureComputed<string> = ko.pureComputed(() => {
        const last: string = this.lastName();
        const first: string = this.firstName();

        return this.view?.sort === undefined || this.view.sort().toLowerCase() == 'first name'
            ? `${first} ${last}`
            : `${last}, ${first}`;
    });

    displayDevice: ko.PureComputed<string> = ko.pureComputed(() => {
        if (this.deviceId() <= 0) {
           return "Unassigned";
        }
        else {
            return this.deviceId().toString();
        }
    });

    displayGangAffiliationBasic: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.hasGangAffiliation() ? "Yes" : "No";
    });

    displayDetailedDorm: ko.PureComputed<string> = ko.pureComputed(() => {

        if (this.building == null || this.building.id() == 0) {
            return "";
        }
        else {
            let dorm = this.building.description();

            if (this.block() != null && this.block() != "") {
                dorm += "-" + this.block();
            }

            if (this.bunk() != null && this.bunk() != "") {
                dorm += "-" + this.bunk();
            }

            return dorm;
        }
    });

    getDetail = async (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/inmate/${this.inmateId()}`,
                cache: false,
            })
                .done(data => {
                    this.setDetail(data);
                    resolve(true);
                })
                .fail((request, textStatus, error) => {
                    console.error('Inmate::getDetail', request, textStatus, error);
                    reject();
                });
        })
    };

    setDetail = (detail) => {
        mapping.fromJS(detail, this.mapConfig, this);

        if (!this.building) this.building = {
            id: ko.observable<number>(0),
            description: ko.observable<string>('')
        }

        if ((this.deviceId() ?? 0) !== 0) {
            this.deviceDescription(this.deviceId().toString());
        } else {
            this.deviceId(0);
            this.deviceDescription('Unassigned');
        }

        if (!isNullOrUndefined(detail["lastEventTimestamp"])) {
            this.lastEventTimestamp = moment(detail["lastEventTimestamp"])
        }
        else {
            this.lastEventTimestamp = ''.toMoment();
        }

        if (!isNullOrUndefined(detail["deviceAssignmentDate"])) {
            this.deviceAssignmentDate = moment(detail["deviceAssignmentDate"])
        }
        else {
            this.deviceAssignmentDate = ''.toMoment();
        }

        this.hasGangAffiliation() ? this.hasGangAffiliationNumber('1') : this.hasGangAffiliationNumber('0');

        this.hasDetail(true);
    };

    getConfiguration = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/configuration/inmate/${this.inmateId()}`,
                cache: false,
            })
                .done(data => {
                    this.setConfiguration(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Inmate::getConfiguration', request, textStatus, error);
                    reject();
                });
        })
    };

    private setConfiguration = (data) => {
        const configurationMap = {
            key: (setting) => ko.utils.unwrapObservable(setting.configurationSettingId),
            create: (setting) => new ConfigurationSetting(setting.data, this)
        };

        this.configuration = mapping.fromJS(data, configurationMap);
        this.hasConfiguration(true);
    };

    getAssignments = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/inmate/${this.inmateId()}/assignments`,
                cache: false
            })
                .done(data => {
                    this.setAssignments(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('Inmate::getAssignments', request, textStatus, error);
                    reject();
                })
        })
    };

    private setAssignments = (data) => {
        const configurationMap = {
            key: (assignment) => ko.utils.unwrapObservable(assignment.deviceAssignmentId),
            create: (assignment) => new DeviceAssignment(assignment.data)
        };

        this.assignments = mapping.fromJS(data, configurationMap);
        this.hasAssignments(true);

        this.bump(!this.bump());
    };

    scheduleAction = (a, b) => {
        const actionType = b.target.dataset.action;

        $.post(`api/inmate/${this.inmateId()}/action/${actionType}`)
            .done(() => common.toast('success', 'Action Queued', 'Device Actions'))
            .fail((request, textStatus, error) => {
                common.toast('error', textStatus, 'Device Actions');
                console.error('Inmate::scheduleAction', request, textStatus, error);
            });
    };

    pushConfiguration = () => {
        $.post(`api/inmate/${this.inmateId()}/action/pushconfig`)
            .done(() => common.toast('success', 'Configuration Download Queued', 'Push Configuration'))
            .fail((request, textStatus, error) => {
                common.toast('error', textStatus, 'Device Actions');
                console.error('Inmate::pushConfiguration', request, textStatus, error);
            });
    };

    public activated = () => {
        this.edit();
    }

    clone = (): object => mapping.toJS(this, {
        ignore: ['clone', 'view'],
        include: ['photo', 'hasPhoto']
    });

    toFormData = (includeConfiguration: boolean = true): object => {
        const data = {
            inmateId: this.inmateId(),
            inmateIdentifier: this.inmateIdentifier(),
            firstName: this.firstName(),
            middleName: this.middleName() == null ? '' : this.middleName(),
            lastName: this.lastName(),
            block: this.block() == null ? '' : this.block(),
            bunk: this.bunk() == null ? '' : this.bunk(),
            notes: this.notes() == null ? '' : this.notes(),
            tagId: this.deviceId(),
            hasGangAffiliation: this.hasGangAffiliation()
        }

        if (this.building && this.building.id() !== undefined && parseInt(this.building.id().toString()) !== 0) {
            data['facilityBuildingId'] = this.building.id()
        }

        if (this.presence && this.presence.inmatePresenceTypeId() !== undefined && parseInt(this.presence.inmatePresenceTypeId().toString()) !== 0) {
            data['inmatePresenceTypeId'] = this.presence.inmatePresenceTypeId()
        }

        if (this.securityLevel?.id() !== undefined && parseInt(this.securityLevel.id().toString()) !== 0) {
            data['contractSecurityLevelId'] = this.securityLevel.id()
        }

        if (includeConfiguration) {
            data['configRestores'] = this._restoredConfigurations()

            ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.needsUpdate())
                .forEach((value, index, array) => data[`cfg_${array[index].configurationSettingId()}`] = array[index].editValue());
        }

        const formData = common.objectToFormData(data);

        let inmatePhotoElement = $('#inmatePhotoFile')[0];

        if ( inmatePhotoElement != null && (inmatePhotoElement as any).files.length !== 0) {
            formData.append('photo', (inmatePhotoElement as any).files[0], 'photo');
        }

        return formData;
    }

    private _restoredConfigurations: ko.PureComputed<string> = ko.pureComputed(() => {
        let result = '';
        ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.isRestored())
            .forEach((setting) => result += (result !== '' ? ',' : '') + setting.configurationSettingId().toString());
        return result;
    })

    public closeConfigurationEditors = () => {
        ko.utils.arrayFilter(this.configuration(), (setting: ConfigurationSetting) => setting.isEditorActive())
            .forEach((setting) => setting.isEditorActive(false));
    }

    static getById = async (inmateId: number): Promise<Inmate> => {
        return new Promise((resolve) => {
            const result = new Inmate();
            result.inmateId(inmateId);
            result.getDetail().then(() => resolve(result));
        })
    }

    public compare(compareTo, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'last name':
                result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                if (result === 0) {
                    result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                }
                break;

            case 'first name':
                result = this.firstName().localeCompare(compareTo.firstName()) * direction;
                if (result === 0) {
                    result = this.lastName().localeCompare(compareTo.lastName()) * direction;
                }
                break;

            case 'inmate id':
                result = this.inmateIdentifier().localeCompare(compareTo.inmateIdentifier()) * direction;
                break;

            case 'last event':
                result = this.lastEventTimestamp.compare(compareTo.lastEventTimestamp) * direction;
                break;

            case 'tag id':
                result = ((this.deviceId() ?? 0) - (compareTo.deviceId() ?? 0)) * direction;
                break;

            case 'assigned date':
                result = this.deviceAssignmentDate.compare(compareTo.deviceAssignmentDate) * direction;
                break;

            case 'location':
                result = this.location().localeCompare(compareTo.location()) * direction;
                break;

            case 'dorm':
                result = this.displayDetailedDorm().localeCompare(compareTo.displayDetailedDorm()) * direction;
                break;
        }

        return result;
    }

    public itemDescription(sort: string): string {
        switch (sort.toLowerCase()) {
            case 'tag id': sort = 'displayDevice'; break;
            case 'last name': sort = 'displayName'; break;
            case 'first name': sort = 'displayName'; break;
            case 'inmate id': sort = 'inmateIdentifier'; break;
            case 'last event': sort = 'displayLastEventTimestamp'; break;
            case 'assigned date': sort = 'displayAssignedDate'; break;
            case 'location': sort = 'location'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');

            result = (regexName1.test(this.firstName()) && regexName2.test(this.lastName()))
                || (regexName1.test(this.lastName()) && regexName2.test(this.firstName()))
                || regexName1.test(this.inmateIdentifier()) //regexId.test(this.inmateIdentifier())
                || (this.deviceId() != null && regexName1.test(this.deviceId().toString())) //regexId.test(this.deviceId().toString()))
                || regexName1.test(this.location());
        }

        const showUnassigned = this.view?.showUnassigned() ? true : this.deviceId() > 0;

        return result && showUnassigned;
    }

    public edit = () => {
        if (this.view?.inmateDialog?.edit !== undefined && typeof (this.view?.inmateDialog?.edit) === 'function') {
            this.view?.inmateDialog?.edit(this.inmateId());
        } else {
            Dialogs.editInmate(this.inmateId());
        }
    }

    public getId = (): number => this.inmateId();
}
