﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel, EntityType } from "../Common"
import { EntitySelectViewModel } from '../Components/EntitySelectDropdown';
import { InmateLocationInfo } from '../Inmates/InmateLocationInfo';
import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"

const common: CommonViewModel = globalThis.DIG.Common;

export class PresenceSummaryViewModel extends ViewModelBase<InmateLocationInfo> {
    getPdf = () => this._getPdf();

    options: ViewModelBaseOptions = {
        settingPrefix: "PHS",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: false,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: false,
        autoRefresh: {
            enabled: false,
            show: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: true,
        allowMouseScroll: true,
        keyBindings: {
            enable: false,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: true,
        },
        sortFields: ['Inmate Identifier', 'Inmate Name'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [{ description: "Print", callback: this.getPdf, altKey: 'X', class: 'bg-success' }],
        selectionActions: [],
        rightClickActions: []
    }

    //
    //  Query properties
    //
    dateRangeMode: ko.Observable<number> = ko.observable(1)
    startDate: ko.Observable<string> = ko.observable('')
    endDate: ko.Observable<string> = ko.observable('')
    zoneSelector: EntitySelectViewModel = null

    zoneFilterId: ko.Observable<number> = ko.observable<number>(0);
    zoneFilterDescription: ko.Observable<string> = ko.observable('')

    protected createItem = (data?: object, view?: object) => new InmateLocationInfo(data)

    protected fetchData = async (): Promise<object> => {
        let unauthorizedOnly: boolean = false;

        this.calculateDates();

        this.saveSetting("ZoneIdFilter", this.zoneFilterId());
        this.saveSetting("DateFilter", `${this.dateRangeMode()}|${this.startDate()}|${this.endDate()}`);


        return new Promise((resolve, reject) => {
            $.get(`/api/dashboard/location/${this.zoneFilterId()}/accessSummary`, {
                unauthorizedonly: unauthorizedOnly,
                startTime: this.startDate(), //startTime.format('MM/DD/YYYY hh:mm A'),
                endTime: this.endDate() //endTime.format('MM/DD/YYYY hh:mm A')
            })
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("PresenceSummaryViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    filterData = (): InmateLocationInfo[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: InmateLocationInfo) => item.isMatch(filterText));
    }

    afterBinding = () => {
        this.zoneSelector.init(this.zoneFilterId, this.zoneFilterDescription)
    }

    onEnter = (e) => {
        if ($(e.target).parents('.do-enter').length === 1) {
            this.loadData();
        }
    }

    public constructor() {
        super();

        this.mapConfig = {
            //key: (inmate) => ko.utils.unwrapObservable(inmate.inmateId),
            create: (inmate) => new InmateLocationInfo(inmate.data),
            observe: [],
            ignore: [],
            copy: []
        };


        this.zoneSelector = new EntitySelectViewModel({
            entityType: EntityType.Zone,
            noSelectionDescription: "Select Zone",
            showNoSelection: false,
            preloadList: true
        });
    }

    //date range control stuff
    displayDateRangeMode: ko.Computed<string> = ko.computed(() => {
        let text: string = 'Unknown';

        switch (this.dateRangeMode()) {
            case 0: text = 'Custom'; break;
            case 1: text = 'Today'; break;
            case 2: text = 'Yesterday'; break;
            case 3: text = 'Last 60 Minutes'; break;
            case 4: text = 'Last 24 Hours'; break;
            case 5: text = 'Last 7 Days'; break;
            case 6: text = 'Last 30 Days'; break;
        }

        return text;
    })

    setDateRange = (a, b) => {
        this.dateRangeMode(parseInt(b.target.dataset.mode));
        this.calculateDates();
    }

    startDateValid: ko.Observable<boolean> = ko.observable(true)
    startDateClass: ko.Computed<string> = ko.computed(() => {
        return this.dateRangeMode() != 0
            ? 'bg-silver'
            : this.startDateValid() ? 'bg-white' : 'bg-danger text-white'
    })

    parseStartDate = () => {
        if (!common.isNullOrWhitespace(this.startDate())) {
            let date: moment.Moment = moment(this.startDate());

            this.startDateValid(date.isValid())
            if (date.isValid()) {
                this.startDate(date.format('MM/DD/YYYY hh:mm A'));
            }
        } else {
            this.startDateValid(false);
        }
    }

    endDateValid: ko.Observable<boolean> = ko.observable(true)
    endDateClass: ko.Computed<string> = ko.computed(() => {
        return this.dateRangeMode() != 0
            ? 'bg-silver'
            : this.endDateValid() ? 'bg-white' : 'bg-danger text-white'
    })

    parseEndDate = () => {
        if (!common.isNullOrWhitespace(this.endDate())) {
            let date: moment.Moment = moment(this.endDate());

            this.endDateValid(date.isValid())
            if (date.isValid()) {
                this.endDate(date.format('MM/DD/YYYY hh:mm A'));
            }
        } else {
            this.endDateValid(false);
        }
    }

    calculateDates = () => {
        let refDate: moment.Moment;

        switch (this.dateRangeMode()) {
            case 0: //  Custom - Don't change the values
                break;

            case 1: //  Today
                refDate = common.toFacilityTime(moment().utc());
                this.startDate(refDate.startOf('day').format('MM/DD/YYYY hh:mm A'));
                this.endDate(refDate.endOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            case 2: //  Yesterday
                refDate = common.toFacilityTime(moment().utc()).subtract(1, 'day');
                this.startDate(refDate.startOf('day').format('MM/DD/YYYY hh:mm A'));
                this.endDate(refDate.endOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            case 3: //  Last 60 Minutes
                refDate = common.toFacilityTime(moment().utc());
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(60, 'minutes').format('MM/DD/YYYY hh:mm A'));
                break;

            case 4: //  Last 24 Hours
                refDate = common.toFacilityTime(moment().utc());
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(24, 'hours').format('MM/DD/YYYY hh:mm A'));
                break;

            case 5: //  Last 7 Days
                refDate = common.toFacilityTime(moment().utc()).endOf('day');
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(7, 'day').startOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            case 6: //  Last 30 Days
                refDate = common.toFacilityTime(moment().utc()).endOf('day');
                this.endDate(refDate.format('MM/DD/YYYY hh:mm A'));
                this.startDate(refDate.subtract(30, 'day').startOf('day').format('MM/DD/YYYY hh:mm A'));
                break;

            default:
                // Not implemented
                break;
        }
    }
    //END: date range control stuff

    _getPdf = () => {
        
        this.calculateDates();

        let unauthorizedOnly: boolean = false;


        const data = common.objectToFormData({
            unauthorizedonly: unauthorizedOnly,
            startTime: this.startDate(), //startTime.format('MM/DD/YYYY hh:mm A'),
            endTime: this.endDate() //endTime.format('MM/DD/YYYY hh:mm A')
        });

        common.postBinaryRequest(`/api/report/location/${this.zoneFilterId()}/accessSummary`, data);
    }

}
globalThis.DIG ??= () => { /* */ };
globalThis.DIG.PresenceSummary ??= () => { /* */ };
globalThis.DIG.PresenceSummary.ViewModel = PresenceSummaryViewModel;