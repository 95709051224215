﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")

import { CommonViewModel, EntityType } from "../Common"
import { EntitySelectViewModel } from "../Components/EntitySelectDropdown"
import { Dialogs, InmateDialogOptions } from "../Dialogs"
import { isNullOrUndefined, isUndefined } from "util"
import { EntityMapLocation } from "./EntityMapLocation"

const common: CommonViewModel = globalThis.DIG.Common

export class FacilityMapViewModel {

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);
    entitySelector: EntitySelectViewModel = null
    entityFilterId: ko.Observable<number> = ko.observable(0);
    entityFilterDescription: ko.Observable<string> = ko.observable('')

    locationKey: ko.Observable<string> = ko.observable('')
    facilityBeaconGroupId: ko.Observable<number> = ko.observable<number>(0)
    facilityBeaconGroupDescription: ko.Observable<string> = ko.observable<string>('')

    showOccupants: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => this.facilityBeaconGroupId() !== 0)

    siteLocations: ko.ObservableArray = ko.observableArray([])

    highlightZoneId: ko.Observable<number> = ko.observable<number>(0);
    trackedEntity: EntityMapLocation = null;
    trackedEntityLoaded: ko.Observable<boolean> = ko.observable<boolean>(false);
    isSearchExpanded: boolean = false;
    autoTrack: boolean = false; //change the displayed DormResidents if the last thing to occur was searching for an inmate. As soon as the user clicks on a zone, stop auto tracking.


    dialogOptions: InmateDialogOptions = {
        isReadOnly: false,
        showAlarms: true,
        showConfiguration: false,
        showTemp: true,
        showLocation: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        onHidden: null,
        onDeactivate: null
    }

    trackedEntityName: ko.Computed<string> = ko.computed<string>(() => !this.trackedEntityLoaded() ? "" : this.trackedEntity.displayName() ?? '');

    trackedEntityIsInmate: ko.Computed<boolean> = ko.computed<boolean>(() => this.trackedEntityLoaded() && this.trackedEntity.isInmate());

    trackedEntityIdentifier: ko.Computed<string> = ko.computed<string>(() => !this.trackedEntityLoaded() ? "" : this.trackedEntity.identifier() ?? '');

    trackedInmateImageSource: ko.Computed<string> = ko.computed<string>(() => this.trackedEntityIsInmate() ? `/api/inmate/thumbnail/${this.trackedEntity.entityId() ?? 0}` : "");

    trackedLastLocation: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();
        return !this.trackedEntityLoaded() ? "" : this.trackedEntity.displayLastLocation() ?? ''
    });

    trackedLastDetectedTime: ko.Computed<string> = ko.computed<string>(() => {
        var bump = this.bump();
        return !this.trackedEntityLoaded() ? "" : this.trackedEntity.displayLastSeenTimestamp() ?? ''
    });

    displayTrackedEntity: ko.Computed<boolean> = ko.computed<boolean>(() => this.trackedEntityLoaded());

    public init = () => {
        this.fetchData();

        ko.applyBindings(this);

        this.entitySelector.init(this.entityFilterId, this.entityFilterDescription);
    }

    public toggleInmateSearch = () => {

        //triggers once when page loads.
        //$('.inmatesearch').toggleClass('wide');

        if (this.isSearchExpanded) {
            
            //$('.inmatesearch').addClass('wide');
            $('.inmatesearch').removeClass('closed');
            this.entitySelector.openDropdown();
        }
        else {
            //$('.inmatesearch').removeClass('wide');
            $('.inmatesearch').addClass('closed');
        }

        this.isSearchExpanded = !this.isSearchExpanded;
    };

    public stopTrack = (data: FacilityMapViewModel, event) => {
        event.stopPropagation();
        this.entityFilterId(0);
        this.entitySelector.deselect();
    };

    private fetchData = () => {
        $.get('api/facility/beacon/listmap')
            .done(results => this.setData(results))
            .fail((a, b, c) => { console.error("FacilityMapViewModel::fetchData()", a, b, c) });
    };

    private setData = (data) => {
        this.siteLocations(data);
    };

    public showDetail = (e) => {
        this.locationKey(e.locationKey);
        this.facilityBeaconGroupId(e.facilityBeaconGroupId);
        this.facilityBeaconGroupDescription(e.description);

        this.autoTrack = false;
    }

    public zoneClass = (e): string => {
        if (this.highlightZoneId() == e) {
            return "hover_group highlight";
        }
        else {
            return "hover_group";
        }

        //this.facilityBeaconGroupId(e.facilityBeaconGroupId);
        //this.facilityBeaconGroupDescription(e.description);
        //return "abc";
    }

    //public highlightZone = () => {
    //    let zoneId = 6;

    //    if (this.highlightZoneId() == zoneId) {
    //        this.highlightZoneId(0)
    //    }
    //    else {

    //        this.highlightZoneId(zoneId);
    //    }
    //}

    private findInmate = (switchDorm:boolean) => {

        if (this.entityFilterId() != 0) {

            if (switchDorm) {
                this.trackedEntityLoaded(false);
            }

            $.get(`api/device/${this.entityFilterId()}/currentlocation`)
                .done(results => this.setInmateLocationResult(results, switchDorm))
                .fail((a, b, c) => { console.error("FacilityMapViewModel::findInmate()", a, b, c) });
        }
        else {
            this.highlightZoneId(0);

            this.trackedEntityLoaded(false);
        }

    };

    private setInmateLocationResult = (data, switchDorm:boolean) => {
        this.highlightZoneId(data.zoneId);


        this.trackedEntity = new EntityMapLocation(data);  //mapping.fromJS(data, map);

        this.trackedEntityLoaded(true);


        if (switchDorm) {
            this.autoTrack = true;
        }
        
        if (this.autoTrack) {
            this.locationKey(data.locationKey);
            this.facilityBeaconGroupId(data.zoneId);
            this.facilityBeaconGroupDescription(data.zoneDescription);
        }

        this.bump(!this.bump());
    };

    editInmate = (data: FacilityMapViewModel, event) => {
        //event.stopPropagation();
        Dialogs.editInmate(data.trackedEntity.entityId(), this.dialogOptions);
    }

    constructor() {
        
        this.entitySelector = new EntitySelectViewModel({
            entityType: EntityType.Inmate,
            subTypes: ["map"],
            noSelectionDescription: "Search",
            activationDelay: 500
        });

        this.entityFilterId.subscribe(_ => {
            this.findInmate(true);
        });

        window.setInterval(() => this.findInmate(false), 15000);
    }
}

globalThis.DIG ??= () => { /* */ }
globalThis.DIG.FacilityMap ??= () => { /* */ }
globalThis.DIG.FacilityMap.ViewModel = FacilityMapViewModel