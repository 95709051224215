﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import moment = require('moment-timezone')

import { AlarmEvent } from "./AlarmEvent"
import { EventType } from '../EventTypes'
import { AlarmZone } from './AlarmZone'

import { isNullOrUndefined } from 'util'

export class AlarmNotification {
    alarmId: number
    inmateId: number
    inmateName: string
    inmateIdentifier:string
    eventType: EventType
    location: string
    eventTime: moment.Moment
    alarmData: { [key: string]: string }
    zones: AlarmZone[]

    constructor(data) {


        //TODO: figure out how to parse alarmData
        var map = {
            'copy': ["alarmId", "inmateId", "inmateName", "inmateIdentifier", "eventType", "location", "zones"]
        }

        mapping.fromJS(data, map, this);

        if (!isNullOrUndefined(data["eventTime"])) {
            this.eventTime = moment(data["eventTime"])
        }
        else {
            this.eventTime = ''.toMoment();
        }


        var zoneChildMap = {
            create: (inObj) => new AlarmZone(inObj.data)
        };

        if (!isNullOrUndefined(data["zones"])) {
            this.zones = ko.utils.unwrapObservable(mapping.fromJS(data["zones"], zoneChildMap));
        }
        else {
            this.zones = null;
        }

        //mapping.fromJS(data, {}, this);
    }
   
}