﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import moment = require("moment-timezone")

import Common = require("../Common")

import { TimeIntervalTypes } from "../TimeIntervalTypes"
import { LoadingIndicator } from "../LoadingIndicator"
import { EventTypeEnum } from "../EventTypes"
import { DashboardAlarm } from "../Dashboard/DashboardAlarm"

const common: Common.CommonViewModel = globalThis.DIG.Common;

ko.components.register('AlarmListWidget', {
    viewModel: function (params) {
        this.eventType = common.getEventTypeById(params.eventType || EventTypeEnum.Undefined);
        this.view = TimeIntervalTypes[params.view] || TimeIntervalTypes.Month;

        this.containerId = common.uniqueId();
        this.when = ko.observable<moment.Moment>(common.FacilityEndDate.clone());
        this.alarms = ko.observableArray<DashboardAlarm>([]);
        this.loading = new LoadingIndicator($(`#${this.containerId}`));
        this.sort = ko.observable<string>('alarmTimestamp');
        this.direction = ko.observable<number>(-1);

        this.description = ko.computed(() => {
            return this.eventType !== undefined
                ? `${this.eventType.displayDescription()}s`
                : '';
        });

        this.displayDate = ko.pureComputed(() => {
            let date = '';

            switch (this.view) {
                case TimeIntervalTypes.Day: date = this.when().clone().format('MMM Do, YYYY'); break;
                default: date = this.when().clone().format('MMMM YYYY'); break;
            }

            return date;
        });

        this.sorted = ko.computed(() => {
            var sortRead = this.sort();
            var directionRead = this.direction();

            //return this.alarms().sort((a, b) => {
            //    switch (sortRead) {
            //        case 'alarmTimestamp':
            //            let aDate: any = moment(a[sortRead]);
            //            let bDate: any = moment(b[sortRead]);
            //            return (aDate - bDate) * this.direction();

            //        default:
            //            return a[sortRead].localeCompare(b[sortRead]) * directionRead;
            //    }
            //});

            return this.alarms();
        });

        this.changeDate = (date) => {
            this.loading.show();

            const params = {
                eventType: this.eventType.id,
                intervalType: this.view,
                when: date.format()
            };

            //$.post('/api/alarm/widgetlist', params)
            $.get('/api/alarm/utcs')
                .done(results => this.setData(results, date))
                .fail((a, b, c) => { console.error("AlarmListWidget::changeDate()", a, b, c) })
                .always(_ => this.loading.hide());
        };

        this.setData = (data, date) => {
            if (date !== undefined) {
                this.when(date);
            }

            const map = {
                key: (alarm) => ko.utils.unwrapObservable(alarm.alarmId),
                create: (alarm) => new DashboardAlarm(alarm.data, this)
            };

            this.alarms = mapping.fromJS(data, map);
            this.sort.valueHasMutated();
        };

        this.refresh = () => {
            this.changeDate(common.FacilityEndDate.clone());
        }

        this.intervalString = () => {
            return $.isNumeric(this.view)
                ? TimeIntervalTypes[this.view]
                : this.view;
        }

        this.allowPrevious = ko.pureComputed<boolean>(() => {
            return this.when().clone().startOf(this.intervalString()).isAfter(common.FacilityStartDate);
        });

        this.previous = () => {
            if (this.allowPrevious()) {
                this.changeDate(this.when().add(-1, this.intervalString()));
            }
        }

        this.allowNext = ko.pureComputed<boolean>(() => {
            return this.when().clone().endOf(this.intervalString()).isBefore(common.FacilityEndDate);
        });

        this.next = () => {
            if (this.allowNext()) {
                this.changeDate(this.when().add(1, this.intervalString()));
            }
        };

        this.refresh();
    },

    template:
        '<div class="py-1 px-2 d-flex" style="background-color: royalblue; color: white; font-weight: bold;"> \
            <span data-bind="text: description"></span> \
            <span class="mx-auto" xdata-bind="text: displayDate"></span> \
            <!-- <span><a href="#" data-bind="click: previous"><i class="fas fa-angle-left" data-bind="class: allowPrevious() ? \'text-white\' : \'text-white-50\'"></i></a></span> \
            <span class="ml-2"><a href="#" data-bind="click: next"><i class="fas fa-angle-right" data-bind="class: allowNext() ? \'text-white\' : \'text-white-50\'"></i></a></span> --> \
            <span class="ml-2"><a href="#" data-bind="click: refresh"><i class="fas fa-sync-alt text-white"></i></a></span> \
        </div> \
        <div data-bind="attr: {id: containerId}" class="d-flex" style="background-color: white; position: absolute; left: 0.5rem; top: 28px; right: 0; bottom: 0;"> \
            <div class="dig-table d-flex flex-column flex-fill"> \
                <div class="d-flex text-white" style="font-weight: bold;height: 26px;min-width: 100%; background-color: darkslategray;"> \
                    <div class="dig-table-col pr-2" style="text-align: left; min-width: 165px; max-width: 165px;">Alarm Time</div> \
                    <div class="dig-table-col flex-fill" style="min-width: 150px;">Inmate</div> \
                </div> \
                <div class="dig-table-body" data-bind="foreach: sorted"> \
                    <div class="d-flex dig-table-row-summary" xdata-bind="click: edit"> \
                        <div class="dig-table-col pr-2" style="text-align: left; min-width: 165px; max-width: 165px;" data-bind="html: displayAlarmTime()"></div> \
                        <div class="dig-table-col flex-fill" style="width: 0px; min-width: 150px; text-overflow: ellipsis;"><span data-bind="text: inmateName"></span><a href="#" data-bind="visible: inmateId !== 0, click: editInmate"> <i class="far fa-address-card hover-link"></i></a></div> \
                    </div> \
                </div> \
                <div data-bind="visible: sorted().length === 0" style="background-color: gainsboro;position: absolute;top: 0;bottom: 0;right: 0;left: 0;" class="d-flex"> \
                    <span style="font: 24px Arial;color: black;text-align: center;" class="m-auto">No data to display</span> \
                </div> \
            </div> \
        </div>'
})