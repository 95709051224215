﻿import ko = require("knockout")
import $ = require('jquery')

import { Device } from "./Device"
import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"

import { CommonViewModel, DeviceType } from "../Common"
const common: CommonViewModel = globalThis.DIG.Common

export class DeviceDialogModel {
    //
    //  Events
    //
    onSave: Function = null
    onCancel: Function = null

    //
    //  Properties
    //
    device: ko.Observable<Device> = ko.observable(null)

    currentTab: ko.Observable<string> = ko.observable('general')
    isReadOnly: ko.Observable<boolean> = ko.observable(true)
    showAlarms: ko.Observable<boolean> = ko.observable(false)
    showConfiguration: ko.Observable<boolean> = ko.observable(true)
    showAssignments: ko.Observable<boolean> = ko.observable(true)

    allowEdit: ko.Observable<boolean> = ko.observable(true)

    isReadOnlyDefault = true

    dialogTitle: ko.Computed<string> = ko.computed((): string => {
        return this.device()
            ? `${this.device().deviceType.description} ${this.device().deviceId}`
            : 'Device Dialog - Not Initialized';
    })

    isEditing: ko.Computed<boolean> = ko.computed((): boolean => {
        return !this.isReadOnly();
    })

    showEditButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.isReadOnly() && this.allowEdit());
    })

    showR35ResetButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.device && this.device() != null && this.device().deviceType.id == DeviceType.Tag);
    })

    showDebugButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.device && this.device() != null && this.device().deviceType.id == DeviceType.ILinkLocator);
    })

    showLocateButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.device && this.device() != null && this.device().deviceType.id == DeviceType.ILinkLocator);
    })

    hasDeviceErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        //if (this.inmate && this.inmate().hasValidators()) {
        //    return (this.inmate().inmateIdentifier as any).hasError()
        //        || (this.inmate().firstName as any).hasError()
        //        || (this.inmate().middleName as any).hasError()
        //        || (this.inmate().lastName as any).hasError()
        //}
        //else {
        //    return this.allowEdit();
        //}
        return false;
    })

    hasConfigErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        if (this.device && this.device().hasValidators()) {
            let configHasError = false;

            try {
                configHasError = ko.utils.arrayFilter(this.device().configuration(), (i) => i.editValue.hasError()).length > 0;
            } catch { /* */ }

            return configHasError;
        }
        else {
            return this.allowEdit();
        }
    })

    hasErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        return this.hasDeviceErrors()
            || this.hasConfigErrors();
    })

    //
    //  Methods
    //
    constructor(options) {
        if (options.isReadOnly !== undefined) this.isReadOnlyDefault = options.isReadOnly;
        if (options.showAlarms !== undefined) this.showAlarms(options.showAlarms);
        if (options.showConfiguration !== undefined) this.showConfiguration(options.showConfiguration);
        if (options.showAssignments !== undefined) this.showAssignments(options.showAssignments);
        if (options.allowEdit !== undefined) this.allowEdit(options.allowEdit);
    }

    edit = async (deviceId?: number): Promise<boolean> => {
        if (deviceId) {
            this.isReadOnly(this.isReadOnlyDefault);
            this.device(await Device.getById(deviceId));
        } else {
            this.isReadOnly(false);
            this.device(new Device());
        }

        if (this.showConfiguration()) {
            await this.device().getConfiguration();
        }

        if (this.showAssignments()) {
            await this.device().getAssignments();
        }

        if (this.allowEdit()) {
            this._addValidators();
        }

        this.showDialog();

        return true;
    }

    private _addValidators = () => {
        if (!this.device().hasValidators()) {
            for (let index = 0; index < this.device().configuration().length; index++) {
                this.device().configuration()[index].addValidator();
            }

            this.device().hasValidators(true);
        }
    }

    showDialog = () => {
        if ($('#deviceEditorDiv').length === 0) {
            $.get('/devices/editor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'deviceEditorDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#deviceEditorDiv').html(results);
                    $('#deviceEditorModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            $('#deviceEditForm #navDevice .nav-link').on('click', this._tabChanged);
            $('#saveDevice').on('click', this._save);

            this._dialogInitialized = true;
        } else {
            ko.cleanNode($('#deviceEditorModal')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#deviceEditorModal')[0]);

        window.eval('$("#dev_tab_gen").trigger("click")');
        window.eval('$("#deviceEditorModal").modal("show")');

        this.device().shouldSendConfig.subscribe(newValue => {
            this.device().toggleSendConfig();
        });
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        window.eval('$("#deviceEditorModal").modal("hide")');
    }

    unlockForm = () => {
        this.isReadOnly(false);
    }

    private _onSave = (deviceId: number, deviceDetails) => {
        if (this.onSave) {
            this.onSave(deviceId, deviceDetails);
        }
    }

    private _onCancel = () => {
        if (this.onCancel) {
            this.onCancel();
        }
    }

    restoreValue = (setting) => {
        ConfigurationSetting.dataFor(setting).restore();
    }

    private _save = () => {
        if (!this.hasErrors()) {
            $.ajax({
                url: `/api/device/${this.device().deviceId}`,
                method: 'PUT',
                data: this.device().toFormData(),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {
                    this._hideDialog();
                    this._onSave(this.device().deviceId, results);
                })
                .fail((request, textStatus, error) => {
                    console.error("Save Device Failed", request, textStatus, error);
                });
        }
    }

    private _tabChanged = (e) => {
        //const tab = $(e.currentTarget).attr("aria-controls");
    }

    doAction = () => {
        const action = $(event.target).data('action');
        $.get(`/api/device/${this.device().deviceId}/action/${action}`)
            .then(() => common.toast('success', 'Action has been queued', 'Device Actions'))
            .fail(() => common.toast('error', 'Action was not queued', 'Device Actions'))
    }

    locateDevice = () => {
        
        $.get(`/api/device/${this.device().deviceId}/locate/900`)
            .then(() => common.toast('success', '15 Minute Location Command Queued', 'Device Actions'))
            .fail(() => common.toast('error', 'Action was not queued', 'Device Actions'))
    }

    stopLocateDevice = () => {

        $.get(`/api/device/${this.device().deviceId}/locate/0`)
            .then(() => common.toast('success', 'Stopping Locate', 'Device Actions'))
            .fail(() => common.toast('error', 'Action was not queued', 'Device Actions'))
    }
}

globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Devices ??= () => { /* */ };
globalThis.DIG.Devices.DeviceDialog = DeviceDialogModel
