﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from 'util';
import Common = require("../Common")
import { GenericEntity, GenericEntityWithStatus } from '../GenericEntity';

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class SecurityGroupPermission {
    //view: any // view should be UserDialog
    securityGroupPermissionId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable("")
    securableType: ko.Observable<number> = ko.observable(0);
    isAllowed: ko.Observable<boolean> = ko.observable(false)
    valueList: ko.ObservableArray<string> = ko.observableArray([]) 
    
    constructor(data?: object) {
        
        if (data) {
            mapping.fromJS(data, {}, this);
        }

    }

    displayDescription: ko.PureComputed<string> = ko.pureComputed(() => {

        if (this.isAllowed()) {
            return this.description();
        }
        else {
            return this.description() + " (Denied)";
        }

    });

    hasValueList: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return (this.securableType() == 1         //ValueList
            || this.securableType() == 3);  //EntitytypeMultple


    });
    
}


