﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

let common: CommonViewModel = globalThis.DIG.Common

export class RollCallSchedule {
    view: any

    rollCallScheduleId: ko.Observable<number> = ko.observable(0)
    entityType: ko.Observable<number> = ko.observable(0)
    //entity: ko.Observable<number> = ko.observable(0)
    startEpoch: ko.Observable<number> = ko.observable(0)

    rollCallTime: ko.Computed<moment.Moment> = ko.computed<moment.Moment>(() => {
        return common.toFacilityTime(moment.utc()).startOf('day').add(this.startEpoch(), 'second');
    })

    displayRollCallTime: ko.Computed<string> = ko.computed<string>(() => this.rollCallTime().format('h:mm A'))

    constructor(data: any, view: any) {
        this.view = view;
        mapping.fromJS(data, {}, this);
    }
}
