﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel, EntityType } from "../Common"
import { SwitchEntityDialogModel } from "./SwitchEntityDialog"

const common: CommonViewModel = globalThis.DIG.Common

export class EntitySwitch {
//    private contractId_SettingKey:string = "Mon-ContractId";
//    private facilityId_SettingKey:string = "Mon-FacilityId";

    entityType: EntityType
    entityId: number
    description: string
    children: Array<EntitySwitch>
    expanded: ko.Observable<boolean> = ko.observable(false)
    switchEntityDialog: SwitchEntityDialogModel
    isOnline: boolean

    constructor(entityType: EntityType, entityId: number, description: string, children: Array<EntitySwitch>, switchEntityDialog: SwitchEntityDialogModel, isOnline: boolean) {
        this.entityType = entityType;
        this.entityId = entityId;
        this.description = description;
        this.children = children;
        this.switchEntityDialog = switchEntityDialog;
        this.isOnline = (this.entityType === EntityType.Facility && isOnline);
    }

    //only works for facility currently
    save = () => {
        if (this.entityId !== 0) {

            if (this.entityType === EntityType.Facility) {

                $.post('/api/user/setting/Mon-FacilityId', {
                    settingValue: this.entityId
                })
                    .done(results => {
                        window.location.reload(); // true);
                    })
                    .fail((request, textStatus, error) => {
                        alert(error);
                    })
            }
            else if (this.entityType === EntityType.Contract) {
                $.post('/api/user/setting/Mon-ContractId', {
                    settingValue: this.entityId
                })
                    .done(results => {
                        window.location.reload(); // true);
                    })
                    .fail((request, textStatus, error) => {
                        alert(error);
                    })
            }
            else {
                alert('oops, what did you click?');
            }
        }
    }

    showChildren = (): boolean => {
        return this.expanded();
    }

    expandOrCollapseChildren = () => {
        this.expanded(!this.expanded());

        this.switchEntityDialog.collapseAll(this.entityId);

        if (this.expanded()) {
            this.selectEntity();
        }
    }

    selectEntity = () => {
        this.switchEntityDialog.setSelected(this);
    }
}