﻿import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel, EntityType } from "../Common"
import { Dialogs, ZoneDialogOptions } from '../Dialogs';
import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { Zone } from './Zone';

const common: CommonViewModel = globalThis.DIG.Common;

export class ZonesViewModel extends ViewModelBase<Zone> {

    options: ViewModelBaseOptions = {
        settingPrefix: "Zones",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: true,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            enabled: false,
            show: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Zone Id', 'Description', 'Status'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [],
        selectionActions: [],
        rightClickActions: []
    }

    dialogOptions: ZoneDialogOptions = {
        isReadOnly: false,
        showConfiguration: true,
        showLocators: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        //onLocatorsChanged: null,
        //onHidden: null,
        //onDeactivate: null
    }

    //zones: ko.ObservableArray<Zone> = ko.observableArray<Zone>([])
    //bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    protected createItem = (data?: object, view?: object) => new Zone(data, view)

    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get('/api/facility/beacon/list')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("ZonesViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    //fetchData = () => {
    //    $.get(`/api/facility/beacon/list`)
    //        .done(results => this.setZonesData(results))
    //        .fail((a, b, c) => { console.error("ZonesViewModel::fetchData()", a, b, c) })
    //}

    //setZonesData = (data) => {
    //    const presentMap = {
    //        //key: (bzDt) => ko.utils.unwrapObservable(bzDt.facilityBeaconGroupId),
    //        create: (bzDt) => new Zone(bzDt.data)
    //    };
    //    this.zones = mapping.fromJS(data, presentMap);

    //    this.bump(!this.bump());
    //}

    filterData = (): Zone[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: Zone) => item.isMatch(filterText));
    }

    onAddNew = () => {
        Dialogs.editZone(0, this.dialogOptions);
    }

    onClick = (id) => {
        Dialogs.editZone(id, this.dialogOptions);
    }

    public constructor() {
        super();
        this.dialogOptions.onSave = this._zonesChanged;
        //this.dialogOptions.onLocatorsChanged = this._locatorsChanged;
    }

    _zonesChanged = (zoneId) => {
        this.firstSearch = true; // supress the cache updated toast.
        this.refresh();
    }

    //init = () => {
    //    ko.applyBindings(this);

    //    this.fetchZonesData();
    //}
}
globalThis.DIG ??= () => { /* */ };
globalThis.DIG.Zones ??= () => { /* */ };
globalThis.DIG.Zones.ViewModel = ZonesViewModel;