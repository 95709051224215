﻿import { ViewModelBase, ViewModelBaseOptions } from "../ViewModelBase"
import { User } from "./User"

import { CommonViewModel } from "../Common"
import { Dialogs, UserDialogOptions } from "../Dialogs"
import ko from "knockout"

const common: CommonViewModel = globalThis.DIG.Common

export class UsersViewModel extends ViewModelBase<User> {
    options: ViewModelBaseOptions = {
        settingPrefix: "Users",
        allowSelection: false,
        allowMultipleSelections: false,
        clearSelectionsOnPageChange: true,
        showAddNew: true,
        detail: {
            enabled: false,
            nextRow: false,
            right: false,
            bottom: false,
            expandAllRows: false,
            detailSize: 0
        },
        loadDataOnInit: true,
        autoRefresh: {
            enabled: false,
            show: false,
            intervalSeconds: 0
        },
        flashNewRows: false,
        showExportExcel: false,
        showExportPDF: false,
        allowMouseScroll: true,
        keyBindings: {
            enable: true,
            enter: false,
            esc: false
        },
        templateSections: {
            queryParameters: false,
        },
        sortFields: ['Last Name'],
        pageSizeOptions: [10, 20, 50, 100],
        pageActions: [],
        selectionActions: [],
        rightClickActions: []
    }

    dialogOptions: UserDialogOptions = {
        isReadOnly: false,
        showPermissions: true,
        allowEdit: true,
        onSave: null,
        onShown: null,
        //onHidden: null,
    }

    

    protected createItem = (data?: object, view?: object): User => new User(data, view)

    protected fetchData = async (): Promise<object> => {
        return new Promise((resolve, reject) => {
            $.get('/api/user/list')
                .done(results => {
                    resolve(results);
                })
                .fail((request, textStatus, error) => {
                    console.error("UsersViewModel::fetchData()", request, textStatus, error);
                    reject();
                })
        });
    }

    //addUser = () => {
    //    $.get('/settings/users/associate')
    //        .done(results => {
    //            $('#associateUserDiv').html(results);
    //            window.eval('$("#userAssociateModal").modal("show")');
    //        })
    //        .fail((request, textStatus, error) => {
    //            console.error("UsersViewModel::addUser()", request, textStatus, error);
    //        })
    //};

    filterData = (): User[] => {
        const filterText = this.filter().trim();
        return ko.utils.arrayFilter(this.data(), (item: User) => item.isMatch(filterText));
    }

    onAddNew = () => {
        Dialogs.editUser(0, this.dialogOptions);
    }

    onClick = (id) => {
        Dialogs.editUser(id, this.dialogOptions);
    }

    constructor() {
        super();
        this.dialogOptions.onSave = this._usersChanged;
    }

    _usersChanged = () => {
        this.firstSearch = true; // supress the cache updated toast.
        this.refresh();
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Users === undefined) {
    globalThis.DIG.Users = () => { };
}

globalThis.DIG.Users.ViewModel = UsersViewModel