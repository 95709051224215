﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import Common = require("../Common")
import { HeadcountInmate } from "./HeadcountInmate"

const common: Common.CommonViewModel = globalThis.DIG.Common;

export class Headcount {
    //onChildSave: Function = null;

    //view: any // view model where this class is displayed

    locationKey: ko.Observable<string>
    facilityBuildingId: ko.Observable<number>
    zoneId: ko.Observable<number>
    title: ko.Observable<string>
    assignedCount: ko.Observable<number>
    excludedCount: ko.Observable<number>
    tentativeCount: ko.Observable<number>
    actualCount: ko.Observable<number>
    isTracked: ko.Observable<boolean>
    isBuilding: ko.Observable<boolean>
    relevantInmates: ko.ObservableArray<HeadcountInmate>

    isCountMissmatch: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() => this.tentativeCount() != this.actualCount())

    showMonitor: ko.PureComputed<boolean> = ko.pureComputed<boolean>(() =>
        (this.facilityBuildingId() == 0 && this.isBuilding())
        || (this.facilityBuildingId() != 0 && this.zoneId() != 0 && this.isTracked() && this.isBuilding())

    )

    constructor(data?: object) {
        //this.view = view;

        const map = {
            //copy: ['facilityBuildingId', 'dorm', 'assignedCount', 'excludedCount', 'tentativeCount', 'actualCount'],
            ignore: ['relevantInmates']
        };

        mapping.fromJS(data, map, this);

        const missingMap = {
            create: (inmate) => new HeadcountInmate(inmate.data, this)
        }

        this.relevantInmates = mapping.fromJS(data['relevantInmates'], missingMap);
    }

    public navigateToMonitor = () => {
        if (this.facilityBuildingId() == 0) {
            //common.navigate(`/monitor/nodorm?lid=${this.zoneId()}`, true);
            common.navigate(`/monitor/nodorm?lid=${this.locationKey()}`, true);
        }
        else {
            //common.navigate(`/monitor/dorm?lid=${this.zoneId()}`, true);
            common.navigate(`/monitor/dorm?lid=${this.locationKey()}`, true);
        }
    }

    //public onChildSave = (inmateId: number, deviceId) => {
    //    console.log("Parent Got Message" + this.title() + " " + inmateId);
    //}
}
