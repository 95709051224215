﻿import ko = require('knockout')
import mapping = require('knockout.mapping')
import { isNullOrUndefined } from 'util';
import Common = require("../Common")
import { GenericEntity, GenericEntityWithStatus } from '../GenericEntity';

const common: Common.CommonViewModel = globalThis.DIG.Common;


export class UserPermission {
    view: any // view should be UserDialog
    userId: ko.Observable<number> = ko.observable(0)
    securableId: ko.Observable<number> = ko.observable(0)
    description: ko.Observable<string> = ko.observable("")
    securableType: ko.Observable<number> = ko.observable(0)
    childPermissions: ko.ObservableArray
    isAllowed: ko.Observable<number> = ko.observable(0)
    isAllowedString: ko.Observable<string> = ko.observable("-1")
    allowedOptions: Array<GenericEntity> = [new GenericEntity(-1, "Inherited"), new GenericEntity(0, "Denied"), new GenericEntity(1, "Allowed") ]
    selectedOption: ko.Observable<number> = ko.observable(0)
    listOptions: ko.ObservableArray<GenericEntity> = ko.observableArray()
    entityOptions: ko.ObservableArray<GenericEntityWithStatus> = ko.observableArray()

    defaultIsAllowed: ko.Observable<number> = ko.observable(0)
    defaultListOption: ko.Observable<number> = ko.observable(0)
    defaultEntityOptions: ko.ObservableArray<GenericEntityWithStatus> = ko.observableArray()
    defaultSetBy: GenericEntity = new GenericEntity(0, "");

    originalIsAllowed: number = 0
    originalSelectedOption: number = 0
    originalEntityOptions: Array<GenericEntityWithStatus> = Array<GenericEntityWithStatus>()

    

    //isChecked: ko.Observable<boolean> = ko.observable(false) //temporary while using checkboxes

    constructor(data?: object, userId?: number, view?: object) {
        this.view = view; // view should be UserDialog

        if (data) {
            mapping.fromJS(data, { ignore: ['childPermissions', 'listOptions', 'entityOptions', 'defaultEntityOptions' ] }, this);
        }

        var optionsMap = {
            create: (inObj) => new GenericEntity(inObj.data.id, inObj.data.description)
        };

        if (!isNullOrUndefined(data["listOptions"])) {
            this.listOptions = (mapping.fromJS(data["listOptions"], optionsMap));
        }
        else {
            this.listOptions = null;
        }

        var entitiesMap = {
            create: (inObj) => new GenericEntityWithStatus(inObj.data.id, inObj.data.description, inObj.data.isActive)
        };

        if (!isNullOrUndefined(data["entityOptions"])) {
            this.entityOptions = (mapping.fromJS(data["entityOptions"], entitiesMap));
        }
        else {
            this.entityOptions = null;
        }

        if (!isNullOrUndefined(data["defaultEntityOptions"])) {
            this.defaultEntityOptions = (mapping.fromJS(data["defaultEntityOptions"], entitiesMap));
        }
        else {
            this.defaultEntityOptions = null;
        }

        var childMap = {
            create: (inObj) => new UserPermission(inObj.data, userId, this.view)
        };

        if (!isNullOrUndefined(data["childPermissions"])) {
            this.childPermissions = (mapping.fromJS(data["childPermissions"], childMap));
        }
        else {
            this.childPermissions = null;
        }



        this.userId(userId);

        this.originalIsAllowed = this.isAllowed();
        this.originalSelectedOption = this.selectedOption();

        if (this.entityOptions != null
            && this.entityOptions().length > 0) {
                this.entityOptions().forEach((x) => {
                    this.originalEntityOptions.push(new GenericEntityWithStatus(x.id(), x.description(), x.isActive()));

                    x.isActive.subscribe(_ => this.savePermission());
                });
            }


        // because knockout is dumb and can't work with non-string values for radio button values
        this.isAllowedString(this.isAllowed().toString());


        this.isAllowedString.subscribe(_ => this.isAllowed(+this.isAllowedString()));
        this.isAllowed.subscribe(_ => this.savePermission());
        this.selectedOption.subscribe(_ => this.savePermission());
    }

    protected savePermission(): Promise<object> {
        return new Promise((resolve, reject) => {

            
            let entityString = "";

            if (this.securableType() == 1 || this.securableType() == 2) {

                //Value List and EntityTypeSingle have a dropdown / single selection
                entityString = this.selectedOption().toString();
            }
            else if (this.securableType() == 3) {

                //EntityType multi select
                if (this.entityOptions != null
                    && this.entityOptions().length > 0) {

                    this.entityOptions().forEach((x) => {

                        if (x.isActive()) {
                            entityString += "|" + x.id();
                        }
                    })

                    if (entityString.length > 0) {
                        entityString += "|";
                    }
                }
            }

            if (this.userId() > 0) {
                $.ajax({
                    url: `/api/user/${this.userId()}/permission`,
                    method: 'PUT',
                    data: {
                        securableId: this.securableId(),
                        isAllowed: this.isAllowed(),
                        valuesList: entityString
                    },
                    cache: false,
                    //contentType: false,
                    //processData: false,
                })
                    .done(results => {

                        common.toast('success', `${this.description()} Updated`, 'Permission Updated');

                        // view should be ZoneDialog
                        if (this.view) {
                            this.view._getPermissions()
                                .then(() => this.view.buildPermissionTree());
                            
                        }

                        resolve(results);
                    })
                    .fail((request, textStatus, error) => {
                        console.error("UserPermission::savePermission()", request, textStatus, error);
                        reject();
                    })

            }
            else {
                resolve(null);
            }
            
        });
    }
    




}
