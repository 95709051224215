﻿import ko = require("knockout")
import $ = require('jquery')

import { FirmwareVersion } from "./FirmwareVersion"
import { DeviceType } from "../Common"

export class FirmwareDialogModel {
    //
    //  Events
    //
    onSave: Function = null
    onCancel: Function = null

    //
    //  Properties
    //
    firmware: ko.Observable<FirmwareVersion> = ko.observable(null)
    isReadOnly: ko.Observable<boolean> = ko.observable(true)
    isDisabled: ko.Observable<boolean> = ko.observable(true)
    allowEdit: ko.Observable<boolean> = ko.observable(true)

    isReadOnlyDefault = true

    dialogTitle: ko.Computed<string> = ko.computed((): string => {
        return this.firmware()?.description() ?? '' !== ''
            ? this.firmware().description()
            : 'New Firmware Version';
    })

    showEditButton: ko.Computed<boolean> = ko.computed((): boolean => {
        return (this.isReadOnly() && this.allowEdit());
    })

    isDescriptionDisabled: ko.Computed<boolean> = ko.computed((): boolean => {
        return this.isDisabled() || this.firmware().isRelease()
    })

    hasErrors: ko.PureComputed<boolean> = ko.pureComputed((): boolean => {
        return false;
    })

    //
    //  Methods
    //
    constructor(options) {
        if (options.isReadOnly !== undefined) this.isReadOnlyDefault = options.isReadOnly;
        if (options.allowEdit !== undefined) this.allowEdit(options.allowEdit);
    }

    private _getVersion = (firmwareVersionId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (firmwareVersionId) {
                this.isReadOnly(this.isReadOnlyDefault);
                FirmwareVersion.getById(firmwareVersionId)
                    .then(version => {
                        this.firmware(version);
                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                this.isReadOnly(false);
                this.firmware(new FirmwareVersion());
                resolve();
            }
        })
    }

    edit = (firmwareVersionId?: number): Promise<void> => {
        return new Promise((resolve) => {
            this._getVersion(firmwareVersionId)
                .then(() => {
                    this._addValidators();
                    this.showDialog();
                    resolve()
                });
        })
    };

    private _addValidators = () => {
        if (!this.firmware().hasValidators()) {
            this.firmware().hasValidators(true);
        }
    }

    showDialog = () => {
        if ($('#firmwareEditorDiv').length === 0) {
            $.get('/support/firmware/editor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'firmwareEditorDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#firmwareEditorDiv').html(results);
                    $('#firmwareEditorModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');

            $('#saveFirmware').on('click', this._save);
            $('#chooseFile').on('change', () => {
                const reader = new FileReader();

                reader.onload = async (file) => {
                    await this.parseFile(file.target.result);
                };

                reader.readAsText(($('#chooseFile')[0] as any).files[0]);
            });

            this._dialogInitialized = true;
        } else {
            ko.cleanNode($('#firmwareEditorModal')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#firmwareEditorModal')[0]);

        window.eval('$("#firmwareEditorModal").modal("show")');
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        window.eval('$("#firmwareEditorModal").modal("hide")');
    }

    unlockForm = () => {
        this.isReadOnly(false);
    }

    getFile = () => {
        const chooseFile: any = $('#chooseFile')[0];
        chooseFile.value = '';
        chooseFile.click();
    }

    private parseFile = (data: string | ArrayBuffer): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.post("/api/firmware/parse", { fileContents: data })
                .done((results) => { 
                    this.isDisabled(false);

                    this.firmware().isRelease(false);
                    this.firmware().majorVersion(results.majorVersion);
                    this.firmware().buildVersion(results.latestBuildVersion + 1);
                    this.firmware().deviceType(results.deviceType);
                    this.firmware().firmwareType(results.firmwareType);
                    this.firmware().buildTimestamp(results.buildTimestamp);
                    this.firmware().description(`${results.deviceType} ${results.firmwareType} v${results.majorVersion}.${results.latestBuildVersion + 1}`);
                    this.firmware().fileData(data as string);

                    resolve();
                })
                .fail(() => {
                    this.isDisabled(true);

                    this.firmware().isRelease(false);
                    this.firmware().majorVersion(0);
                    this.firmware().buildVersion(0);
                    this.firmware().isRelease(false);
                    this.firmware().deviceType(DeviceType.Unknown);
                    this.firmware().firmwareType('');
                    this.firmware().buildTimestamp(null);
                    this.firmware().description('');
                    this.firmware().fileData('');

                    reject();
                });
        })
    }

    private _onSave = (firmwareVersionId: number, firmwareDetails) => {
        if (this.onSave) {
            this.onSave(firmwareVersionId, firmwareDetails);
        }
    }

    private _onCancel = () => {
        if (this.onCancel) {
            this.onCancel();
        }
    }

    private _save = () => {
        if (!this.hasErrors()) {
            $.ajax({
                url: '/api/firmware',
                method: 'POST',
                data: this.firmware().toFormData(),
                cache: false,
                contentType: false,
                processData: false,
            })
                .done(results => {
                    this._hideDialog();
                    this._onSave(this.firmware().firmwareVersionId(), results);
                })
                .fail((request, textStatus, error) => {
                    console.error("FirmwareDialog::_save", request, textStatus, error);
                });
        }
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Firmware === undefined) {
    globalThis.DIG.Firmware = () => { /* */ };
}

globalThis.DIG.Firmware.FirmwareDialog = FirmwareDialogModel
