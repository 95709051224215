﻿import moment = require("moment-timezone")
import ko = require("knockout")
import ApexCharts from 'apexcharts'

import { CommonViewModel } from "../Common"
//import { LoadingIndicator } from "../LoadingIndicator"

const common: CommonViewModel = globalThis.DIG.Common

//TODO: Componentize

export class TemperatureChart {
    //private _canvas

    //loading: LoadingIndicator
    //chart: Chart
    apexChart: ApexCharts
    inmateId: number
    when: ko.Observable<moment.Moment> = ko.observable(common.toFacilityTime(moment()).startOf('day'));
    bump: ko.Observable<boolean> = ko.observable(false)

    title: ko.Computed<string> = ko.computed(() => this.when().format("MMM D, YYYY"));

    private chartConfiguration: ApexCharts.ApexOptions = {
        chart: {
            type: 'line',
            height: '100%',
            width: '100%'
        },
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 6,
            shape: 'circle'
        },
        series: [],
        noData: {
            text: 'Loading...'
        },
        yaxis: {
            labels: {
                show: false,

            }
        },
        grid: {
            padding: {
                left: 50
            }
        },
        tooltip: {
            enabled: false
        }

    };

    constructor(container: any, inmateId: number) {
        //this.loading = new LoadingIndicator(container);

        this.inmateId = inmateId;
        //this.when = ko.observable(moment().startOf('day'));

        this.apexChart = new ApexCharts(container, this.chartConfiguration);
        this.apexChart.render();

        this.refresh();
    }

    description: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.when !== undefined
            ? `Reported: ${this.when().format('M/D/YYYY')}`
            : '';
    });

    changeDay = (date) => {
        //this.loading.show();

        $.ajax({
            url: '/api/inmate/temperature',
            cache: false,
            method: 'POST',
            data: {
                inmateId: this.inmateId,
                when: date.format(),
                __RequestVerificationToken: $('[name=__RequestVerificationToken]').val()
            },
            success: (results) => this.setData(results, true, date),
            error: this.dataError,
            //complete: _ => this.loading.hide()
        });
    };

    setData = (data, animate?: boolean, date?: moment.Moment) => {
        if (date !== undefined) {
            this.when(date);
        }

        if (data.datasets.length > 0) {

            var medianLower = data.median - data.medianOffset;
            var medianUpper = data.median + data.medianOffset;

            var graphLower = medianLower - 1.5;
            var graphUpper = medianUpper + 1.5;

            var maxDataPoint = Math.max(...data.datasets[0].data); // spread synthax
            var minDataPoint = Math.min(...data.datasets[0].data);

            if (medianLower > minDataPoint) {
                graphLower = minDataPoint - 1.5;
            }

            if (medianUpper < maxDataPoint) {
                graphUpper = maxDataPoint + 1.5;
            }

            this.apexChart.updateSeries([{
                name: 'Temperatures',
                data: data.datasets[0].data
            }]);

            this.apexChart.updateOptions({
                xaxis: {
                    categories: data.labels
                },
                yaxis: {
                    min: graphLower,
                    max: graphUpper,
                    labels: {
                        show: false,

                    }
                },
                annotations: {
                    yaxis: [
                        {
                            y: medianLower,
                            y2: medianUpper,
                            borderColor: '#000',
                            fillColor: '#FEB019',
                            label: {
                                textAnchor: "start",
                                position: "left",
                            }
                        },
                        {
                            y: medianUpper,
                            borderColor: "#00E396",
                            borderWidth: 0,
                            strokeDashArray: 0,
                            label: {
                                text: "+2.5º",
                                textAnchor: "end",
                                position: "left",
                                offsetX: -10,
                                offsetY: 7,
                                borderWidth: 0

                            }
                        },
                        {
                            y: data.median,
                            borderColor: "#00E396",
                            borderWidth: 3,
                            strokeDashArray: 3,
                            label: {
                                text: "Nominal",
                                textAnchor: "end",
                                position: "left",
                                offsetX: -10,
                                offsetY: 7,
                                borderWidth: 0

                            }
                        },
                        {
                            y: medianLower,
                            borderColor: "#00E396",
                            borderWidth: 0,
                            strokeDashArray: 0,
                            label: {
                                text: "-2.5º",
                                textAnchor: "end",
                                position: "left",
                                offsetX: -10,
                                offsetY: 7,
                                borderWidth: 0

                            }
                        }
                    ]
                }
            });

        }
        else {

            this.apexChart.updateSeries([{
                name: 'Temperatures',
                data: []
            }])


            this.apexChart.updateOptions({
                xaxis: {
                    categories: []
                },
                noData: {
                    text: 'No Data'
                }
            });

        }

        this.bump(!this.bump());
    };

    dataError = (a, b, c) => {
        console.error('changeDay', a, b, c);
    }

    setInmate = (inmateId: number) => {
        this.inmateId = inmateId;
        this.refresh();
    }

    refresh = () => {
        this.changeDay(common.toFacilityTime(moment()).startOf('day'));
    };

    allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().isAfter(common.FacilityStartDate.startOf('day'));
    });

    previous = () => {
        this.changeDay(this.when().add(-1, 'day'));
    };

    allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
        const nextDay = parseInt(moment(this.when()).add(1, 'day').format("YYYYMMDD"));
        const thisDay = parseInt(moment().format("YYYYMMDD"));
        return nextDay <= thisDay;
    });

    next = () => {
        if (this.allowNext()) {
            this.changeDay(this.when().add(1, 'day'));
        }
    };

    today = () => {
        this.changeDay(common.toFacilityTime(moment()).startOf('day'));
    };
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Inmates === undefined) {
    globalThis.DIG.Inmates = () => { /* */ };
}

globalThis.DIG.Inmates.TemperatureChart = TemperatureChart;
