﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel, EntityType } from "../Common"
import { EventTypeEnum } from "../EventTypes"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateAlarm {
    alarmId: ko.Observable<number> = ko.observable(0)
    inmateId: ko.Observable<number> = ko.observable(0)
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    alarmLocation: ko.Observable<string> = ko.observable('')
    alarmTimestamp: moment.Moment = ''.toMoment()
    clearedTimestamp: moment.Moment = ''.toMoment()
    processStatusId: ko.Observable<number> = ko.observable(1)
    alarmType: ko.Observable<EventTypeEnum> = ko.observable()

    displayLocation: ko.Computed<string> = ko.computed<string>(() => this.alarmLocation() ?? '')

    displayInmateName: ko.Computed<string> = ko.computed<string>(() => this.lastName() + ', ' + this.firstName())

    displayAlarmTimestamp: ko.Computed<string> = ko.computed<string>(() => `${common.toFacilityTime(this.alarmTimestamp).format('M/D/YYYY')} <span style="white-space: nowrap;">${common.toFacilityTime(this.alarmTimestamp).format('h:mm:ss A')}</span>` )

    displayAlarmType: ko.Computed<string> = ko.computed<string>(() => {

        if (this.alarmType() != null) {
            return common.customStrings.getByEntity(EntityType.EventType, this.alarmType()).description;
        }
        else {
            return "";
        }
    })

    isDispatched: ko.Computed<boolean> = ko.computed<boolean>(() => this.processStatusId() == 2)

    imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)

    // not used, switched to function that returns actual color
    highlightAlarm: ko.Computed<boolean> = ko.computed<boolean>(() => {
        let highlightStart = moment.utc().add(-5, 'minute');
        let isAfter = this.alarmTimestamp.isAfter(highlightStart)
        return isAfter;
    })

    tileBackgroundColor: ko.Computed<string> = ko.computed<string>(() => {
        let highlightStart = moment.utc().add(-5, 'minute');
        let isNew = this.alarmTimestamp.isAfter(highlightStart)

        if (isNew) {
            if (this.processStatusId() == 2) { 
                // dispatched;
                return "green";
            }
            else {
                // new alarm
                return "firebrick";
            }
        }
        else {
            // old alarm
            if (this.processStatusId() == 2) {
                // dispatched;
                return "green";
            }
            else {
                // new alarm
                return "silver";
            }
        }

    })

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.alarmTimestamp = moment(data["alarmTimestamp"]);
            //this.imageSource(`/api/inmate/thumbnail/${this.inmateId()}`);
        }
    }

    toggleDispatched = async (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/alarm/${this.alarmId()}/toggleDispatch/${this.processStatusId() != 2 ? true : false}`,
                cache: false,
            })
                .done(data => {
                    //this.setDetail(data);
                    if (this.processStatusId() == 1) {
                        this.processStatusId(2);
                    }
                    else {
                        this.processStatusId(1);
                    }

                    resolve(true);
                })
                .fail((request, textStatus, error) => {
                    console.error('InmateAlarm::toggleDispatch', request, textStatus, error);
                    reject();
                });
        })
    };
}
