﻿import ko = require('knockout')
import mapping = require('knockout.mapping')

export enum ZoneTypeEnum {
    LivingUnit = 1,
    OffLimits = 2,
    CommonArea = 3
}

export class ZoneType {
    zoneType: ko.Observable<ZoneTypeEnum> = ko.observable(ZoneTypeEnum.LivingUnit)
    description: ko.Observable<string> = ko.observable('')
    isDorm: ko.Observable<boolean> = ko.observable(true)
    isRestricted: ko.Observable<boolean> = ko.observable(true)

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
        }
    }
}