﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateLocationPresence {
    inmateId: ko.Observable<number> = ko.observable(0)
    inmateName: ko.Observable<string> = ko.observable('')
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    imageSource: ko.Observable<string> = ko.observable<string>('/images/photoUnavailable.jpg')
    isAuthorized: ko.Observable<boolean> = ko.observable<boolean>(false)
    inmateBuilding = { id: ko.observable<number>(0), description: ko.observable<string>('') }
    whenEntered: moment.Moment = ''.toMoment()

    displayBuilding: ko.Computed<string> = ko.computed<string>(() => this.inmateBuilding?.description() ?? '')

    // best to use the function in the parent control since it will honor the columns parameter
    inmateClass = (index): string => ((index() + 1) % 4) == 0 ? 'col-3 pt-2 px-0' : 'col-3 pb-0 pl-0 pr-2 pt-2';

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.whenEntered = moment(data["whenEntered"]);
            this.imageSource(`/api/inmate/thumbnail/${this.inmateId()}`);
        }
    }
}
