﻿import moment = require("moment-timezone")
import ko = require("knockout")
import Chart = require("chart.js")

import { TimeIntervalTypes } from "../TimeIntervalTypes"
import { EventTypeEnum, EventType } from "../EventTypes"
import { LoadingIndicator } from "../LoadingIndicator"

const common = globalThis.DIG.Common;

export class MonthlyChart {
    loading: LoadingIndicator
    eventType: EventType
    chart: Chart
    when: ko.Observable<moment.Moment>
    lastUpdated: ko.Observable<moment.Moment>
    private _canvas

    constructor(canvas: any, container: any, eventTypeId: EventTypeEnum) {
        this.loading = new LoadingIndicator(container);
        this.eventType = common.getEventTypeById(eventTypeId);
        this.when = ko.observable(common.FacilityEndDate.clone());
        this.lastUpdated = ko.observable(moment().add(-1, 'day'));

        this._canvas = canvas;
    }

    description: ko.PureComputed<string> = ko.pureComputed(() => {
        return this.eventType !== undefined
            ? `${this.eventType.displayDescription()}s - ${this.when().clone().format('MMMM YYYY')}`
            : '';
    });

    setData = (data, animate?: boolean, date?: moment.Moment) => {
        if (date !== undefined) {
            this.when(date);
        }

        this.chart?.destroy();
        this.chart = this._newChart();
        this.chart.data = data;

        if (animate === undefined || animate) {
            this.chart.update();
        } else {
            this.chart.update({ duration: 0 });
        }
    };

    changeMonth = (date) => {
        this.loading.show();

        $.ajax({
            url: '/api/alarm/dashboardchart',
            cache: false,
            method: 'POST',
            data: {
                eventType: this.eventType.id,
                intervalType: TimeIntervalTypes.Month,
                when: date.format()
            },
            success: (results) => this.setData(results, true, date),
            error: (a, b, c) => { console.error("MonthlyChart::changeMonth()", date, a, b, c); },
            complete: _ => this.loading.hide()
        });
    };

    refresh = () => {
        this.changeMonth(common.FacilityEndDate.clone());
    }

    allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().clone().startOf('month').isAfter(common.FacilityStartDate);
    });

    previous = () => {
        if (this.allowPrevious()) {
            this.changeMonth(this.when().add(-1, 'month'));
        }
    }

    allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().clone().endOf('month').isBefore(common.FacilityEndDate);
    });

    next = () => {
        if (this.allowNext()) {
            this.changeMonth(this.when().add(1, 'month'));
        }
    };

    private _newChart = () => {
        return new Chart(this._canvas, {
            type: 'bar',
            data: {
                labels: [],
                datasets: [{
                    label: `${this.eventType.description}s`,
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1
                }]
            },
            options: {
                legend: { display: false },
                scales: {
                    xAxes: [{
                        ticks: {
                            maxTicksLimit: 16,
                            minRotation: 0,
                            maxRotation: 0,
                            autoSkipPadding: 4
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 10,
                            stepSize: 1.0
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: (items, data: any) => {
                            return moment(`${data.dataKey.slice(-6, -2)}-${data.dataKey.slice(-2)}-01`)
                                .add(items[0].index, 'day')
                                .format('MMMM Do, YYYY');
                        },
                        label: (item) => item.value
                    }
                }
            }
        });
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Dashboard === undefined) {
    globalThis.DIG.Dashboard = () => { /* */ };
}

globalThis.DIG.Dashboard.MonthlyChart = MonthlyChart;
