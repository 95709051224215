﻿import { CommonViewModel, DeviceType, EntityType } from "../Common"
const common: CommonViewModel = globalThis.DIG.Common

import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { ViewModelItem } from "../ViewModelItem"
import { ConfigurationSetting } from "../Configuration/ConfigurationSetting"
import { Dialogs } from "../Dialogs"
import { isNullOrUndefined } from "util"
import { StaffDeviceAssignment } from "./StaffDeviceAssignment"

export class StaffDevice extends ViewModelItem {
    deviceId: ko.Observable<number> = ko.observable(0)
    deviceType: ko.Observable<DeviceType> = ko.observable(DeviceType.Unknown) 
    location: ko.Observable<string> = ko.observable('')
    batteryStatus: ko.Observable<number> = ko.observable(0)
    
    assignment: ko.Observable<StaffDeviceAssignment> = ko.observable<StaffDeviceAssignment>()

    constructor(data?: object, view?: object) {
        super()

        if (view) {
            this.view = view;
        } else {
            this.view = {
                sort: ko.observable<string>('deviceId')
            };
        }

        this.setDetail(data);
        
    }

    displayAssignment: ko.PureComputed<string> = ko.pureComputed(() => {
        let display:string = "";

        if (this.assignment() != null) {
            display = this.assignment().staff().lastName() + ", " + this.assignment().staff().firstName();
        }

        return display;
    });

    displayType: ko.PureComputed<string> = ko.pureComputed(() => {
        return common.customStrings.find(e => e.entityType == EntityType.Device && e.entityId == this.deviceType().valueOf()).shortDescription;
    });

    displayBatteryIcon: ko.PureComputed<string> = ko.pureComputed(() => {
        if (this.batteryStatus() == 3) {
            return "<i class='fad fa-battery-full'></i>";
        }
        else if (this.batteryStatus() == 2) {
            return "<i class='fad fa-battery-half'></i>";
        }
        else if (this.batteryStatus() == 1) {
            return "<i class='fad fa-battery-quarter'></i>";
        }
        else if (this.batteryStatus() == 0) {
            return "<i class='fad fa-battery-slash'></i>";
        }
        else {
            return "<i class='fad fa-battery-slash'></i>";
        }
    });


    getDetail = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            $.get({
                url: `/api/staff/device/${this.deviceId()}`,
                cache: false
            })
                .done(data => {
                    this.setDetail(data);
                    resolve();
                })
                .fail((request, textStatus, error) => {
                    console.error('StaffDevice::getDetail', request, textStatus, error);
                    reject();
                })
        })
    };

    setDetail = (data) => {

        if (data != null) {
            //this.mapConfig.copy = ['deviceId', 'batteryStatus', 'deviceType'];
            this.mapConfig.ignore = ['assignment'];

            if (data) {
                mapping.fromJS(data, this.mapConfig, this);
            }

            var childMap = {
                create: (inObj) => new StaffDeviceAssignment(inObj.data)
            };

            if (!isNullOrUndefined(data["assignment"])) {
                //this.assignment = ko.utils.unwrapObservable(mapping.fromJS(data["assignment"], childMap));
                this.assignment(mapping.fromJS(data["assignment"], childMap));
            }
            else {
                this.assignment(null);
            }
        }
    };

    clone = (): object => mapping.toJS(this, {
        ignore: ['clone', 'view']
    });


    static getById = async (deviceId: number, dialogView?: any): Promise<StaffDevice> => {
        const result = new StaffDevice(null, dialogView);
        result.deviceId(deviceId);
        await result.getDetail();
        return result;
    }

    editDevice = () => {
        if (this.deviceId() !== 0 && this.view?.deviceDialog) {
            this.view.deviceDialog.edit(this.deviceId());
        } else {
            Dialogs.editDevice(this.deviceId());
        }
    }

    toFormData = (assignedStaffId: number = 0): object => {
        const data = {
            deviceId: this.deviceId(),
            assignedStaffId: assignedStaffId
        }

        const formData = common.objectToFormData(data);

        return formData;
    }

    public compare(compareTo, sort: string, direction: number): number {
        let result = null;

        switch (sort.toLowerCase()) {
            case 'device id':
                result = (this.deviceId() - compareTo.deviceId()) * direction;
                break;

            case 'assignment':
                result = this.displayAssignment().localeCompare(compareTo.displayAssignment()) * direction;
                break;

            case 'batteryStatus':
                result = (this.batteryStatus() - compareTo.batteryStatus()) * direction;
                break;

            case 'location':
                result = this.location().localeCompare(compareTo.location()) * direction;
                break;

           
        }

        return result;
    }
    
    public itemDescription(sort: string): string {
        switch (sort.toLowerCase()) {
            case 'device id': sort = 'deviceId'; break;
            case 'assignment': sort = 'displayAssignment'; break;
            case 'battery status': sort = 'batteryStatus'; break;
        }

        return typeof (this[sort]) === 'function'
            ? this[sort]()
            : this[sort]
    }

    public isMatch(filter: string): boolean {
        let result = true;

        if (filter !== '') {
            const items = filter.split(' ');
            let text1 = '';
            let text2 = '';

            for (let index = 0; index < items.length; index++) {
                if (items[index].trim() !== '') {
                    if (text1 === '') text1 = items[index].trim();
                    else if (text2 === '') text2 = items[index].trim();
                    else break;
                }
            }

            const regexName1 = new RegExp(text1, 'i');
            const regexName2 = new RegExp(text2, 'i');


            result = regexName1.test(this.deviceId().toString()) //regexId.test(this.deviceId.toString())
                || regexName1.test(this.displayAssignment());

        }

       
        return result;
    }

    public getId = (): number => this.deviceId();
}
