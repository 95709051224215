﻿import moment = require("moment-timezone")
import ko = require("knockout")
import mapping = require("knockout.mapping")

import { CommonViewModel } from "../Common"
import Loading = require("../LoadingIndicator")
import { AlarmHistoryItem } from "./AlarmHistoryItem"
import { AlarmDialogModel } from "../Alarms/AlarmDialog"

const common: CommonViewModel = globalThis.DIG.Common

export class AlarmHistoryList {
    alarms: ko.ObservableArray<AlarmHistoryItem> = ko.observableArray<AlarmHistoryItem>([])
    loading: Loading.LoadingIndicator
    inmateId: any = ko.observable<number>()
    

    rangeMode: ko.Observable<number> = ko.observable(0); //mode 0 - daily, mode 1 - weekly
    when: ko.Observable<moment.Moment> = ko.observable(moment());
    title: ko.Computed<string> = ko.computed(() => {
        let titleString = this.when().format("MMM D, YYYY");

        if (this.rangeMode() == 1) {
            let endTime = this.when().clone().endOf('week');

            if (endTime.format("MM") == this.when().format("MM")) {
                titleString = `${this.when().format("MMM D")} - ${endTime.format("D")}, ${this.when().format("YYYY")}`;
            }
            else {
                titleString = `${this.when().format("MMM D")} - ${endTime.format("MMM D")}, ${this.when().format("YYYY")}`;
            }
        }

        return titleString;
    });

    sort: ko.Observable<string> = ko.observable('alarmTimestamp');

    alarmDialog: AlarmDialogModel = new AlarmDialogModel({
        allowDismiss: true
    })

    constructor(container: any, inmateId: number) {
        this.loading = new Loading.LoadingIndicator(container);
        this.inmateId(inmateId);
        this.when(moment());
    }

    setInmate = (inmateId: number) => {
        this.inmateId(inmateId);
        this.when(moment());
        //this.getAlarms();
    }

    changeRangeMode = (a, b) => {
        const id: number = +b.target.dataset.view;  // using plus operator to cast to number, otherwise it treats it as string.
        this.rangeMode(id);

        this.getAlarms();
    }

    dataPage: ko.PureComputed<any> = ko.pureComputed(() => {

        var sortRead = this.sort();
        return this.alarms();
    });

    getAlarms() {
        this.loading.show();

        let startTime = common.toFacilityTime(this.when().clone()).startOf('day');
        let endTime = common.toFacilityTime(this.when().clone()).endOf('day');

        if (this.rangeMode() == 1) {
            startTime.startOf('week');
            endTime.endOf('week');
        }

        this.when(startTime);


        $.ajax({
            url: `/api/inmate/${this.inmateId()}/alarmHistory?start=${startTime.format()}&end=${endTime.format()}`,
            cache: false,
            method: 'GET',
            success: (results) => {
                this.setAlarms(results);
            },
            error: (a, b, c) => { console.error("InmateAlarms::getAlarmHistory()", a, b, c); },
            complete: _ => this.loading.hide()
        });
    };

    setAlarms = (alarms) => {
        var alarmMap = {
            key: (alarm) => ko.utils.unwrapObservable(alarm.alarmId),
            create: (alarm) => new AlarmHistoryItem(alarm.data, this)
        };

        this.alarms = mapping.fromJS(alarms, alarmMap);
        this.sort.valueHasMutated();
    };

    today = () => {
        this.when(moment());
        this.getAlarms();
    };

    allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().clone().add(1, this.rangeMode() == 1 ? 'weeks' : 'days').isBefore(moment());
    });

    allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().isAfter(moment().subtract(3, 'weeks'));
    });

    previous = () => {
        if (this.allowPrevious()) {
            this.when().subtract(1, this.rangeMode() == 1 ? 'weeks' : 'days'); //moment does in place replacement
            this.getAlarms();
        }
    };

    next = () => {
        if (this.allowNext()) {
            this.when().add(1, this.rangeMode() == 1 ? 'weeks' : 'days'); //moment does in place replacement
            this.getAlarms();
        }
    };

    dataFor = (alarm) => ko.dataFor(alarm);
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Inmates === undefined) {
    globalThis.DIG.Inmates = () => { /* */ };
}

globalThis.DIG.Inmates.AlarmHistoryList = AlarmHistoryList;