﻿import ko = require("knockout")
import mapping = require("knockout.mapping")
import $ = require('jquery')

import { Alarm } from "./Alarm"
import { EventTypeEnum } from "../EventTypes"

export class AlarmDialogModel {
    //
    //  Events
    //
    onSave: Function = null
    onShown: Function = null
    onHidden: Function = null
    onDismissed: Function = null

    //
    //  Properties
    //
    alarm: Alarm = null
    allowDismiss: ko.Observable<boolean> = ko.observable(true)

    bump: ko.Observable<boolean> = ko.observable<boolean>(false);

    dialogTitle: ko.Computed<string> = ko.computed((): string => {
        return this.alarm
            ? `${this.alarm.displayName()}`
            : 'Alarm Dialog - Not Initialized';
    })

    showDismissButton: ko.Computed<boolean> = ko.computed((): boolean => {
        var bump = this.bump();
        return (this.allowDismiss()
            && this.alarm != null
            && this.alarm.eventType != EventTypeEnum.AlarmCaseTamper
            && this.alarm.eventType != EventTypeEnum.AlarmUtc);
    })

    //
    //  Methods
    //
    constructor(options) {
        if (options.allowDismiss !== undefined) this.allowDismiss(options.allowDismiss);
    }

    edit = (alarmId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            this._getAlarm(alarmId)
                .then(() => {
                    this.bump(!this.bump());
                    this.showDialog();
                    resolve()
                })
        })
    };

    private _getAlarm = (alarmId?: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (alarmId) {
                Alarm.getById(alarmId)
                    .then(alarm => {
                        this.alarm = alarm
                        resolve();
                    }, rejectReason => reject(rejectReason));
            } else {
                this.alarm = new Alarm();
                resolve();
            }
        })
    }

    showDialog = () => {
        if ($('#alarmEditorDiv').length === 0) {
            $.get('/alarms/editor')
                .done((results) => {
                    const div: HTMLDivElement = document.createElement('div');
                    div.id = 'alarmEditorDiv';
                    $('.body-content')[0].appendChild(div);

                    $('#alarmEditorDiv').html(results);
                    $('#alarmEditorModal').ready(this._showDialog);
                });
        }
        else {
            this._showDialog();
        }
    }

    private _dialogInitialized = false;
    private _showDialog = () => {
        if (!this._dialogInitialized) {
            $(':input').attr('data-lpignore', 'true');
            this._dialogInitialized = true;
        } else {
            ko.cleanNode($('#alarmEditorModal')[0]);
        }

        ko.applyBindingsToDescendants(this, $('#alarmEditorModal')[0]);

        window.eval('$("#alarmEditorModal").modal("show")');
    }

    hideDialog = () => this._hideDialog();

    private _hideDialog = () => {
        window.eval('$("#alarmEditorModal").modal("hide")');
    }

    private _onDismiss = (alarmId: number) => {
        if (this.onDismissed) {
            this.onDismissed(alarmId);
        }
    }

    dismiss = async () => {
        if (await this.alarm.dismiss()) {
            this._hideDialog();
            this._onDismiss(this.alarm.alarmId);
        } else {
            alert('Error dismissing alarm');
        }
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { /* */ };
}

if (globalThis.DIG.Alarms === undefined) {
    globalThis.DIG.Alarms = () => { /* */ };
}

globalThis.DIG.Alarms.AlarmDialog = AlarmDialogModel
