﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateMapLocation {
    zoneId: ko.Observable<number> = ko.observable(0)
    zoneDescription: ko.Observable<string> = ko.observable('')
    inmateId: ko.Observable<number> = ko.observable(0)
    firstName: ko.Observable<string> = ko.observable('')
    lastName: ko.Observable<string> = ko.observable('')
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    isCurrent: ko.Observable<boolean> = ko.observable(false)
    lastSeen: moment.Moment = ''.toMoment()
    
    displayLastLocation: ko.Computed<string> = ko.computed<string>(() => {
        if (this.isCurrent()) {
            return this.zoneDescription();
        }
        else {
            if (this.zoneDescription() == null || this.zoneDescription() == "") {
                return "No Location Data";
            }
            else {
                return "Last Seen: " + this.zoneDescription();
            }
        }
    })

    displayInmateName: ko.Computed<string> = ko.computed<string>(() => this.lastName() + ', ' + this.firstName())

    // using pureComputed, without it, FacilityMapModels.ts trackedLastDetectedTime function assumed the value has not changed. Using bump in facility map didn't help.
    displayLastSeenTimestamp: ko.PureComputed<string> = ko.pureComputed<string>(() => {

        if (!this.isCurrent()) {
            if (this.lastSeen.isValid()) {
                return `${common.toFacilityTime(this.lastSeen).format('M/D/YYYY')} <span style="white-space: nowrap;">${common.toFacilityTime(this.lastSeen).format('h:mm:ss A')}</span>`
            }
            else {
                return "";
            }
            //return `${this.lastSeen.format('M/D/YYYY')} <span style="white-space: nowrap;">${this.lastSeen.format('h:mm:ss A')}</span>`
        }
        else {
            return "";
        }
    })

    imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.lastSeen = moment(data["lastSeen"]);
        }
    }

}
