﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')

import { CommonViewModel } from "../Common"

const common: CommonViewModel = globalThis.DIG.Common

export class InmateDormPresence {
    inmateId: ko.Observable<number> = ko.observable(0)
    inmateName: ko.Observable<string> = ko.observable('')
    inmateIdentifier: ko.Observable<string> = ko.observable('')
    block: ko.Observable<string> = ko.observable('')
    bunk: ko.Observable<string> = ko.observable('')
    isPresent: ko.Observable<boolean> = ko.observable<boolean>(false)
    isUtc: ko.Observable<boolean> = ko.observable<boolean>(false)
    isStrap: ko.Observable<boolean> = ko.observable<boolean>(false)
    includeInHeadcount: ko.Observable<boolean> = ko.observable<boolean>(false)
    presenceDescription: ko.Observable<string> = ko.observable('')
    currentLocation = { id: ko.observable<number>(0), description: ko.observable<string>('') }
    whenEntered: moment.Moment = ''.toMoment()

    displayLocation: ko.Computed<string> = ko.computed<string>(() => this.currentLocation?.description() ?? '')

    constructor(data?: object) {
        if (data) {
            mapping.fromJS(data, {}, this);
            this.whenEntered = moment(data["whenEntered"]);
        }
    }

    displayBlock: ko.Computed<string> = ko.computed<string>(() => "Block: " + this.block())
    displayBunk: ko.Computed<string> = ko.computed<string>(() => "Room: " + this.bunk())

    displayBlockAndBunk: ko.Computed<string> = ko.computed<string>(() => {
        if (this.block() != "" && this.bunk() != "") {
            return this.block() + "-" + this.bunk();
        }
        else if (this.block() != "") {
            return this.block();
        }
        else if (this.bunk() != "") {
            return this.bunk();
        }
        else {
            return "";
        }
    })

    tileBackgroundColor: ko.Computed<string> = ko.computed<string>(() => {

        if (!this.includeInHeadcount())
        {
            return "excused";
        }
        else if (this.isUtc() || this.isStrap()) {
            return "alarm";
        }
        else {
            if (this.isPresent()) {
                return "present";
            }
            else {
                return "absent";
            }
        }
    })

    displayAlarm: ko.Computed<string> = ko.computed<string>(() => {

        if (!this.includeInHeadcount()) {
            return this.presenceDescription();
        }
        if (this.isUtc()) {
            return common.customStrings.find(e => e.customStringType == 7).description; // 7 is UTC
        }
        else if (this.isStrap())
        {
            return common.customStrings.find(e => e.customStringType == 1).description; // 1 is Strap
        }
        else {
            return ""
        }
    })

    imageSource: ko.Computed<string> = ko.computed<string>(() => `/api/inmate/thumbnail/${this.inmateId()}`)
}
