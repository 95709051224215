﻿import moment = require("moment-timezone")
import ko = require('knockout')
import mapping = require('knockout.mapping')
import ApexCharts from 'apexcharts'

import { CommonViewModel } from "../Common"
//import { LoadingIndicator } from "../LoadingIndicator"
import { AlarmHistoryItem } from './AlarmHistoryItem'

const common: CommonViewModel = globalThis.DIG.Common

export class AlarmSummaryChart {
    alarms: ko.ObservableArray<AlarmHistoryItem> = ko.observableArray<AlarmHistoryItem>([])
    inmateId: any = ko.observable<number>()
    //loading: LoadingIndicator
    apexChart: ApexCharts
    private _container

    constructor(container: any, inmateId: number) {
        this._container = container;
        //this.loading = new LoadingIndicator(container);
        this.inmateId(inmateId);
        this._newChart();
    }

    rangeMode: ko.Observable<number> = ko.observable(0); //mode 0 - daily, mode 1 - weekly
    when: ko.Observable<moment.Moment> = ko.observable(moment());
    title: ko.Computed<string> = ko.computed(() => {
        let titleString = this.when().format("MMM D, YYYY");

        if (this.rangeMode() == 1) {
            let endTime = this.when().clone().endOf('week');

            if (endTime.format("MM") == this.when().format("MM")) {
                titleString = `${this.when().format("MMM D")} - ${endTime.format("D")}, ${this.when().format("YYYY")}`;
            }
            else {
                titleString = `${this.when().format("MMM D")} - ${endTime.format("MMM D")}, ${this.when().format("YYYY")}`;
            }
        }

        return titleString;
    });

    setInmate = (inmateId: number) => {
        this.inmateId(inmateId);
        this.when(moment());
    }

    changeRangeMode = (a, b) => {
        this.rangeMode(parseInt(b.target.dataset.view));
        this.getAlarms();
    }

    getAlarms() {
        //this.loading.show();

        let startTime = common.toFacilityTime(this.when().clone()).startOf('day');
        let endTime = common.toFacilityTime(this.when().clone()).endOf('day');

        if (this.rangeMode() == 1) {
            startTime.startOf('week');
            endTime.endOf('week');
        }

        this.when(startTime);


        $.ajax({
            url: `/api/inmate/${this.inmateId()}/alarmHistory?start=${startTime.format()}&end=${endTime.format()}`,
            cache: false,
            method: 'GET',
            success: (results) => {
                this.alarmListToSummary(results);
            },
            error: (a, b, c) => { console.error("InmateAlarms::getAlarmHistory()", a, b, c); },
            //complete: _ => this.loading.hide()
        });
    };

    alarmListToSummary = (data: Array<Object>) => {
        let counts = {};

        data.forEach(d => {
            const eventType = d['eventType'];
            counts[eventType] = (counts[eventType] ?? 0) + 1;
        })

        let chartData = {
            labels: [],
            data: []
        }

        for (const eventType in counts) {
            const id = parseInt(eventType);

            chartData.labels.push(common.eventTypes.getEventTypeById(id).description);
            chartData.data.push(counts[eventType]);
        }


        this.setData(chartData, false);
    }

    setData = (data, animate?: boolean) => {

        if (data.labels.length > 0) {
            this.apexChart.updateOptions({
                xaxis: {
                    categories: data.labels,
                    labels: {
                        show: true
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: function (val) {
                            return val.toFixed(0);
                        }
                    }
                },
            });

            this.apexChart.updateSeries([{
                name: 'Counts',
                data: data.data
            }]);
        }
        else {

            this.apexChart.updateSeries([{
                name: 'Counts',
                data: []
            }]);

            this.apexChart.updateOptions({
                xaxis: {
                    categories: [],
                    labels: {
                        show: false
                    }
                },
                noData: {
                    text: 'No Data'
                },
                yaxis: {
                    labels: {
                        show: false,

                    }
                },
            });

        }
    };

    today = () => {
        this.when(moment());
        this.getAlarms();
    };

    allowNext: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().clone().add(1, this.rangeMode() == 1 ? 'weeks' : 'days').isBefore(moment());
    });

    allowPrevious: ko.PureComputed<boolean> = ko.pureComputed(() => {
        return this.when().isAfter(moment().subtract(3, 'weeks'));
    });

    previous = () => {
        if (this.allowPrevious()) {
            this.when().subtract(1, this.rangeMode() == 1 ? 'weeks' : 'days'); //moment does in place replacement
            this.getAlarms();
        }
    };

    next = () => {
        if (this.allowNext()) {
            this.when().add(1, this.rangeMode() == 1 ? 'weeks' : 'days'); //moment does in place replacement
            this.getAlarms();
        }
    };

    private _newChart = () => {
        this.apexChart = new ApexCharts(this._container, {
            chart: {
                type: "bar",
                height: '100%',
                width: '100%'
            },
            series: [],
            noData: {
                text: 'Loading...'
            }
        });
        this.apexChart.render();
    }
}

if (globalThis.DIG === undefined) {
    globalThis.DIG = () => { };
}

if (globalThis.DIG.Alarms === undefined) {
    globalThis.DIG.Alarms = () => { };
}
